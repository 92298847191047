import { React, useState, useEffect, useRef} from "react";
import { Menu } from "./Menu";
// import {useLocation} from 'react-router-dom';

import { Table } from "./Table";
import { getLeaderboard } from "./loadData";

import { Auth } from "@supabase/auth-ui-react";
import { ButtonLogin, ButtonSignUp } from "./Navigation";

import hideButton from  "../assets/hideButton.svg"
import { initialState } from "./TableSettings/MacroTable";

import { shortNames, shortNames2Index, tableIndexes, indexNames, tables } from "./dataLeaderboard";

import { useParams } from "react-router-dom";

import { useNavigate, createSearchParams, useSearchParams, generatePath, useMatch, useLocation } from "react-router-dom";

import { tokens } from "./data";

import { HeaderOverview } from "./Overview";

import { overviewPages } from "./dataPortfolio";

import redirect_button from "../assets/redirect.png";

import { getWalletStats, getTransfers, getWalletInfo, getPrice } from "./loadData";

import { DropDown, HeaderToken } from "./Menu";

import { portfolioLayout } from "./dataPortfolio";

import { checkAddress } from "./loadData";

// import { Chart } from "./Chart";

import { Line } from "react-chartjs-2";

import {options, data } from "./ChartSettings/ReceivedSent.js"
import { add } from "date-fns";

import { tableStyle } from "./TableSettings/DefaultTable.js";

import { columns } from "./TableSettings/BuySellTable";

import { DataGrid } from "@mui/x-data-grid";

import { getElementsAtEvent } from "react-chartjs-2";

import { Trades } from "./PortfolioTrades.js";

import { PortfolioBalance } from "./PortfolioBalance.js";
import { PortfolioPnl } from "./PortfolioPnl.js";

import { LoadingPlaceholder } from "./LoadingPlaceholder.js";

function Tag(props) {
    return (
        <div className="tag">
            <p> { props.text } </p>
        </div>
    )
}

function Chart(props) {
    return (
        <Line ref={props.chartRef} options={options} data={data} redraw={true} />
    )
}

// function InfoBlock(props) {
//     return (
//         <div style={{width:"100%", 
//         display: 'flex', 
//         backgroundColor: '#110E1C', 
//         color:'white', 
//         fontFamily: 'SFProReg',
//         // justifyContent: 'center',
//         alignItems:'center',}}>
//             <p style={{marginLeft: '12px', margin:'0px'}}>{props.text}</p>
//             <p style={{marginLeft: 'auto', marginRight: '33px', fontFamily: 'SFPro', marginTop:'0px', marginBottom: '0px'}}>{props.value}</p>
//         </div>
//     )
// }

function DescriptionBlock(props) {
    const styleHeader = props.name ? {
        display: 'flex',
        fontSize: '14px',
        fontFamily: 'SFProBold',
        color: '#7B7A95',
        width: '100%',
        marginTop: '8px',
        marginBottom: '8px'
    } :
    {
        display: 'flex',
        fontSize: '16px',
        fontFamily: 'SFProReg',
        color: 'white',
        width: '100%'
    }

    return (
        <div style={styleHeader}>
            <p style={{marginLeft: '33px', marginTop: "0px", marginBottom: "0px"}}>{props.header}</p>
            {props.formatting && props.formatting(props.value) }
            {/* {props.formatting && props.formatting(props.va  lue)} */}
            {!props.formatting && <p style={{marginLeft:'auto', marginRight: '33px', marginTop: "0px", marginBottom: "0px"}}>{props.value}</p>}
        </div>
    )
}

export function InfoBlock(props) {
    // console.log("PROPS HEADER", props.header, props.fields)

    return (
        <div style={{width:"100%", 
        display: 'flex', 
        backgroundColor: '#110E1C', 
        flexDirection: 'column',
        // justifyContent: 'center',
        flex: '1 1',
        alignItems:'center',
        paddingTop: '12px',
        paddingBottom: '20px',
        gap: '8px',}}>
            <DescriptionBlock header={props.header} value={''} name={true}/>
            {props.fields.map((field) => (<DescriptionBlock header={field.name} value={props.stats[field.key]} formatting={field.formatting}/>))}
            {/* {props.text.map((text) => (<DescriptionBlock header={text[0]} value={text[1]}/>))} */}
            {/* <DescriptionBlock header={"Balance"} value={"72,000"}/>
            <DescriptionBlock header={"Balance ($)"} value={"$100,000"}/> */}
        </div>
    )
}

function MenuInfo(props) {
    // const [hideMenu, setHide] = useState(false)
    const [tokenInput, setTokenInput] = useState("")

    const [toggleDropDown, setDropDown] = useState(false)

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    function toggleMenu() {
        setDropDown(!toggleDropDown)
    }

    // console.log("MENU info", props.layout)

    return (
        <div className="walletInfo-menu" style={{flex: props.isMobile? '1 1': ''}}>
            { !props.hideMenu && <HeaderToken
                token={ props.selectedToken } 
                isHeader={ true }
                isClicked={ toggleDropDown }
                toggleFunction={ toggleMenu }
                custom={true}
                tokenInput={tokenInput}
                setTokenInput={setTokenInput}
            /> }
            { !props.hideMenu && toggleDropDown && <div className="dropDown-menu" style={{border: '0.5px solid white', borderRadius: '0'}}> 
                                        <DropDown 
                                            selectedToken={ props.selectedToken } 
                                            toggleFunction={ props.setNewToken }
                                            color={'rgb(43, 40, 53)'}
                                            tokenInput={tokenInput}
                                        /> 
                                </div> }
            <div style={{height: !props.hideMenu ? "calc(100vh - 62.5px - 60.5px - 50px - 120px - 50px - 12px)": "100%", display: 'flex', flexDirection: 'column', gap: '12px'}}>
                {/* <div style={{width:'100%', backgroundColor: '#110E1C', flex: '1 1'}}></div>
                <div style={{width:'100%', backgroundColor: '#110E1C', flex: '1 1'}}></div> */}
                {/* <InfoBlock text={[["Balance", '100,000'], ["Balance ($)", '$270,000']]}/> */}
                {props.layout.map((layout) => (<InfoBlock header={layout.header} fields={layout.fields} stats={props.stats} />))}
                {/* <InfoBlock header={props.layout[0].header} fields={props.layout[0].fields} stats={props.stats} /> */}
                {/* <InfoBlock text={[["NPL", '$3M'], ["Average NPL", '$2.1M'], ["Profit in %", "1720%"]]}/>
                <InfoBlock text={[["Volume Sent", '7M'], ["Volume Sent ($)", '$1.2M'], ["Volume Recieved", "5M"], ["Volume Recieved ($)", "$2.3M"]]}/>
                <InfoBlock text={[["Average Tx Volume", "15M"], ["Average Tx Volume ($)", "$3.1M"], ["First Transaction", "5 days after deploy"]]} /> */}
                {/* <InfoBlock text={"Balance ($)"} value={"270,000$"}/> */}
            </div>
        </div>
    )   
}

function PortfolioHeader(props) {
    // props.wallet
    // props.tags
    // props.isMobile
    console.log("PRRRROOOOPS")
    console.log('HEADER::loading', props.loading)

    return (
        <div className="chartHeader" style={{flexDirection: props.isMobile ? 'column':'row', alignItems: props.isMobile ? 'start':'center', justifyContent: props.isMobile ? 'center':'flex-start'}}>
            <div className="chartHeader-text" style={{marginLeft: '24px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                {/* <img style={{marginLeft: "0px"}} className="tokenField-logo" src={ tokens[selectedToken].img } alt="text" /> */}
                <p style={{margin: props.isMobile ? '0.5em 0px 0.5em 0.0px': '1em 0px 1em 0px'}}> Explore Wallet: </p>
                <LoadingPlaceholder isLoading={props.loading} height="24px" width="180px" margin="0px" marginLeft="10px">
                    <a style={{textDecoration: "none", color: "white", marginLeft: "0px", display: 'flex', flexDirection: 'row', alignItems: 'center'}} href={`${props.chainscan_url}address/${props.wallet}`} target={'_blank'}>
                        <a style={{textDecoration: "none", color: "white", marginLeft: "0px"}} href={`${props.chainscan_url}address/${props.wallet}`} target={'_blank'}> {props.invalidAddress? "Invalid Address" : props.wallet.slice(0, 6) + '...' + props.wallet.slice(props.wallet.length-4)}</a>
                        <svg class="redirect-icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="23" height="23">
                            <path d="M6 18L18 6" stroke="#ffffff" stroke-width="2" />
                            <path d="M12 6H18V12" fill="none" stroke="#ffffff" stroke-width="2" />
                        </svg>
                    </a>
                </LoadingPlaceholder>
            </div>
            {props.tags.length > 0 && <div style={{display: 'flex', marginLeft: props.isMobile ? '24px':'auto', marginBottom: props.isMobile ? '0.5em':'0px', marginRight: '24px', gap: "10px"}}>
                {props.tags.map(text => <Tag text={text} />)}
            </div>}
            {/* {!props.isMobile && <div style={{display: 'flex', marginRight: '24px', gap: "10px"}}>
                {props.tags.map(text => <Tag text={text} />)}
            </div> } */}
        </div>
    )
}

export function Portfolio(props) {
    const { walletAddress } = useParams();

    // console.log("wallet address", walletAddress)

    const [currentAddress, setCurrentAddress] = useState(walletAddress);
    const [walletInfo, setWalletInfo] = useState(null);

    const [selectedPage, setPage] = useState(overviewPages[0].shortName)

    const [pages, setPages] = useState([overviewPages[0]])

    const [loading, setLoading] = useState(true)

    const navigate = useNavigate();
  
    // const validPages = pages.map(p => p.shortName);

    const location = useLocation();

    function setNewRoute(addressSel, page) {
        let searchParams = {
            address: addressSel,
            page: page,
        }
        // navigate(`?${createSearchParams(searchParams)}`)
        if(page === 'trades') {
            searchParams['page'] = [page, location.pathname.split('/').slice(4)].join('/');
            console.log('slice searchParams', searchParams['page'])
        }
        const obtainedPath = generatePath("/portfolio/:address/:page", searchParams)
        const navString = `${obtainedPath}`
        //const navString = toolSel ? `?${createSearchParams(searchParams)}&${createSearchParams(toolSel)}` : `?${createSearchParams(searchParams)}`
        navigate(navString)
    }

    function setNewPage(page) {
        if(overviewPages.map(p => p.shortName).includes(page)){
            setPage(page)
            setNewRoute(currentAddress, page)
        }
    }

    useEffect(() => {
        setLoading(true)
        if(walletAddress) {
            getWalletInfo(walletAddress).then((data) => {
                if(data) {
                    setWalletInfo(data)
                    if(data.show_all) {
                        setPages(overviewPages)
                    } else {
                        setPages([overviewPages[0]])
                    }
                } else {
                    setWalletInfo(null)
                }
                setLoading(false)
            })
            setCurrentAddress(walletAddress)
        }
        // setLoading(false)
    }, [walletAddress])

    useEffect(() => {
        const urlParams = location.pathname.split('/')
        // const index = urlParams[2]
        if(urlParams.length < 4) {
            setNewPage(selectedPage)
        } else {
            const page = urlParams[3]
            console.log('page name', urlParams, page)
            page ? setNewPage(page) : setNewPage(selectedPage)
        }
    }, []);

    return (
        <main className="main" >
            <div class="chart2">
                <PortfolioHeader loading={loading} isMobile={props.isMobile} wallet={walletInfo === null ? '':walletInfo.address} tags={walletInfo === null ? []:walletInfo.tags} invalidAddress={walletInfo === null} chainscan_url={'https://etherscan.io/'}/>
                <HeaderOverview 
                    pages={overviewPages}
                    selectedPage={selectedPage} 
                    selectFunc={setNewPage} 
                    withoutMenu={true}/>
                {selectedPage === 'balance' && <PortfolioBalance isMobile={props.isMobile} isPhone={props.isPhone} address={currentAddress} />}
                {selectedPage === 'trades' && <Trades isMobile={props.isMobile} isPhone={props.isPhone} address={currentAddress}/>}
                {selectedPage === 'pnl' && <PortfolioPnl isMobile={props.isMobile} isPhone={props.isPhone} address={currentAddress}/>}
                
            </div>
            {/* <HeaderOverview 
                pages={overviewPages}
                selectedPage={selectedPage} 
                selectFunc={setNewPage} 
                withoutMenu={true}/> */}
            {/* {(walletInfo !== null) && `LOL batya=${walletInfo.tags[1]}`} */}
        </main>
    )
}

export function PortfolioOld(props) {
    const [pageSize, setPageSize] = useState(15);
    const [stats, setStats] = useState({})

    const [invalidAddress, setInvalid ]= useState(false);

    const [address, setAddress] = useState("0xb1050645b38ef4bd130d7743f4158fc700bec8a5")
    const [tags, setTags] = useState([])
    // const [selectedToken, setToken] = useState("ldo")
    const [selectedPage, setPage] = useState(overviewPages[0].shortName)

    const [selectedToken, setToken] = useState("ldo")
    const { user } = Auth.useUser();

    const [selectedIndex, setIndex] = useState(0)

    const [dataGridTable, setDataGridTable] = useState([])
    const [loadingData, setLoading] = useState(false)

    const [toggleDropDown, setDropDown] = useState(false)

    const tableRef = useRef()
    const chartRef = useRef()
    

    const location = useLocation();
    const navigate = useNavigate();

    // address = location.state.address
    // console.log(location.state.address, address)
    
    // if(location.state.address) {
    //     address = location.state.address
    // }

    function setNewPage(page) {
        // console.log("NEW page")
        // console.log("pgae layout", pageLayout)
        // console.log('new setting', overviewPages[0].layotDataNumbers)
        // setLayout({...overviewPages[0].layotDataNumbers})
        setPage(page)

        setNewRoute(selectedToken, address, page)
    }

    function cleanChart() {
        const chart = chartRef.current
        for (let i=0; i < chart.data.datasets.length;i+=1) {
            chart.data.datasets[i].data = []
            //console.log("OBTAIN DATA", datasets)
        }
        chart.resetZoom()
        chart.update()
    }
    
    useEffect(() => {
        if(location.state) {
            console.log("LOCAATION", location.state.address)
            setAddress(location.state.address)
        }
        const urlParams = location.pathname.split('/')
        // const index = urlParams[2]
        const address = urlParams[2]
        const index = urlParams[3]
        const token = urlParams[4]

        // console.log("INDEX - ", index)

        // const newIndex = index===undefined ? selectedIndex: (shortNames2Index[index]===undefined ? selectedIndex: shortNames2Index[index])
        const newToken = token===undefined ? selectedToken: (tokens[token]===undefined ? selectedToken : token)
        const newAddress = address === undefined ? "0xb1050645b38ef4bd130d7743f4158fc700bec8a5": address
        const newIndex = index === undefined ? "trades" : "trades" 
        setAddress(newAddress)
        setToken(newToken)
        // setIndex(newIndex)

        setNewRoute(newToken, newAddress, newIndex)

        // updateHeader(location.state? location.state.address:address, newToken) 
        // updateChart(location.state? location.state.address:address, newToken)

        updateHeader(newAddress, newToken)
        updateChart(newAddress, newToken)

        checkAddress(address).then((data) => {
            // console.log("RESPONSE", data)
            if(!data.valid_address) {
                setInvalid(true)
            }
        })

        // console.log("CHAARRRAAA", chartRef.current.options)
    }, []);

    function setNewRoute(tokenSel, addressSel, index) {
        let searchParams = {
            token: tokenSel,
            address: addressSel,
            index: index,
            // index: shortNames[indexSel],
        }
        // navigate(`?${createSearchParams(searchParams)}`)
        const obtainedPath = generatePath("/portfolio/:address/:index/:token", searchParams)
        const navString = `${obtainedPath}`
        //const navString = toolSel ? `?${createSearchParams(searchParams)}&${createSearchParams(toolSel)}` : `?${createSearchParams(searchParams)}`
        navigate(navString)
    }

    function setNewToken(tokenName) {
        setNewRoute(tokenName, address, 'trades')
        setToken(tokenName)

        cleanChart()
        updateHeader(address, tokenName) 
        updateChart(address, tokenName)
        // updateHeader(selectedIndex, tokenName) 
    }

    function toggleMenu() {
        setDropDown(!toggleDropDown)
    }

    function updateHeader(address, token) {
        getWalletStats(address, token).then((data) => {
            // console.log("DATA OBTAIN", data, data.tags)
            if (data.tags === undefined) {
                setTags([])
            } else {
                setTags(data.tags)
            }
            // console.log("TAGS", data.tags)
            setStats(data)
        })
    }

    function updateChart(address, token) {
        setLoading(true)
        getTransfers(address, token).then(([transfers, received_r, sent_r, price]) => {
            const chart = chartRef.current
            // console.log("PROCE OBTAIN", data[1])
            // console.log(chart.data.datasets[0])
            chart.data.datasets[0].data = price[0]
            chart.data.datasets[1].data = transfers["data_received"]
            chart.data.datasets[2].data = transfers["data_sent"]

            chart.data.datasets[2].pointRadius = sent_r
            chart.data.datasets[1].pointRadius = received_r

            chart.update()
            setDataGridTable(transfers['transfers'])
            setLoading(false)
        })
    }
    // function updateTable(index, token) {
    //     setLoading(true)
    //     getLeaderboard(tableIndexes[indexNames[index]].apiPath, token).then((data) => {
    //         console.log("OBTAIN TABLE DATA", data)
    //         setDataGridTable(data)
    //         setLoading(false)
    //     })
    // }

    // useEffect(() => {
    //     updateTable(selectedIndex)
    // }, []);

    return (
        <main className="main">
                { !user && <div className="locker" style={{position: "absolute", 
                  width: '100%',
                  height: 'calc(100% - 130px)', 
                //  background: 'red',
                  zIndex: 10, 
                  marginTop: 62.5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                //   backdropFilter: 'blur(5px)',
                  }}>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'}}> To see full dashboard </h3>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'    }}> you can try to hack this page </h3>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px'}}> or just sign up!  😉🍒 </h3>
                    <ButtonSignUp withoutMargin={true} />
                </div> }
            <div class="chart2">
                <PortfolioHeader isMobile={props.isMobile} wallet={address} tags={tags} invalidAddress={invalidAddress} chainscan_url={tokens[selectedToken].chainscan_url}/>
                <HeaderOverview 
                    pages={overviewPages}
                    selectedPage={selectedPage} 
                    selectFunc={setNewPage} 
                    withoutMenu={true}/>
                {/* <div className="main-portfolio-section">
                    {!props.isPhone && <div className="walletInfo" style={{borderBottom: 'solid 1 red'}}>
                        <MenuInfo 
                            selectedToken={selectedToken} 
                            setNewToken={setNewToken} 
                            layout={portfolioLayout.left}
                            stats={stats}
                            isMobile={props.isMobile}
                        />
                        {!props.isMobile && <div className="walletInfo-chart">
                                <Chart chartRef={chartRef}/>
                        </div>}

                        <MenuInfo 
                            selectedToken={selectedToken} 
                            setNewToken={setNewToken} 
                            layout={portfolioLayout.right}
                            stats={stats}
                            hideMenu={true}
                            isMobile={props.isMobile}
                        />
                    </div>}
                    {props.isPhone && <div className="walletInfo" style={{borderBottom: 'solid 1 red'}}> 
                        <MenuInfo 
                            selectedToken={selectedToken} 
                            setNewToken={setNewToken} 
                            layout={portfolioLayout.left}
                            stats={stats}
                            isMobile={props.isMobile}
                        />
                    </div>}
                    {props.isPhone && <div className="walletInfo" style={{borderBottom: 'solid 1 red'}}> 
                        <MenuInfo 
                            selectedToken={selectedToken} 
                            setNewToken={setNewToken} 
                            layout={portfolioLayout.right}
                            stats={stats}
                            hideMenu={true}
                            isMobile={props.isMobile}
                        />
                    </div>}
                    {props.isMobile && <div className="walletInfo" style={{borderBottom: 'solid 1 red'}}>
                        <div className="walletInfo-chart">
                                <Chart chartRef={chartRef}/>
                        </div>
                    </div>}
                
                {!invalidAddress && <div className="walletTransactions">
                    <DataGrid
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[15, 100]}
                        pagination
                        loading={loadingData}
                        rows={dataGridTable}
                        columns={columns}  
                        rowHeight={35}
                        headerHeight={35}
                        showCellRightBorder={true}
                        disableSelectionOnClick
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        sx={tableStyle}
                        ref={tableRef}
                    />
                    </div>}
                </div> */}
                {/* {selectedPage === 'trades' && <Trades address={address} token={selectedToken} isPhone={props.isPhone} isMobile={props.isMobile}/>} */}
            </div>

            {/* <div style={{background: 'purple', width: '100%'}}></div> */}
            {/* <Table tableData={dataGridTable} tableLoading={loadingData}/> */}
        </main>
    )
}