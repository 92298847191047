import { Line } from 'react-chartjs-2';
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { parseValueForTooltip } from './ChartSettings/utils';

import { TokenTableField, ShowPercentChange, ShowValue } from './TableSettings/utils';
import "./ProFeature.css"

const ipAddress = 'internal2.cherry-pick.xyz'
const protocol = 'https'

const LegendItem = ({ label, color, onClick, isVisible }) => {
  const lineStyle = {
    display: 'inline-block',
    width: '20px',
    // borderBottom: `2px solid ${color}`,
    marginRight: '5px',
    height: '2px',
    backgroundColor: `${color}`,
  };

  return (
    <div onClick={onClick} style={{fontFamily:"SFProLight", cursor: 'pointer', color: '#FFF', margin: '5px', opacity: isVisible ? 1 : 0.5, display: 'flex', alignItems:'center'}}>
      <span style={lineStyle}></span>
      {label}
    </div>
  );
};

const fetchTokens = async () => {
  try {
    const response = await fetch(`${protocol}://${ipAddress}/tokens/sopr-hourly?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching token data:', error);
    return []; // Return empty array in case of an error
  }
};

const fetchSoprData = async (token, startDate, endDate) => {
  const params = {
    api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
    resolution: 'hour',
    from: `${startDate.toISOString().slice(0, 19)}`,
    to: `${endDate.toISOString().slice(0, 19)}`,
    cohort: 'total',
    moving_average: 1,
    moving_average_type: 'mean',
  }
  const url = `${protocol}://${ipAddress}/${token.toLowerCase()}/sopr?` + new URLSearchParams(params);
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching SOPR data:', error);
    return [];
  }
};

const fetchPriceData = async (token, startDate, endDate) => {
  const params = {
    api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
    resolution: 'hour',
    from: `${startDate.toISOString().slice(0, 19)}`,
    to: `${endDate.toISOString().slice(0, 19)}`,
  }
  const url = `${protocol}://${ipAddress}/${token.toLowerCase()}/price?` + new URLSearchParams(params);
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching SOPR data:', error);
    return [];
  }
};

export const ProFeature = () => {
  console.log('full pro feature rerender')

  const [lineVisibility, setLineVisibility] = useState({
      MA1: true,
      MA3: true,
      MA5: true,
  });

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const chartDataCache = useRef({}); // Cache for storing loaded chart data
  const tableRef = useRef(null);
  const [rowHeight, setRowHeight] = useState(80); // Adjust to your row height: ;

  const [chartData, setChartData] = useState({});

  const [expandedRows, setExpandedRows] = useState(new Set());

  const [timeFreq, setTimeFreq] = useState(9);
  const [sliceSize, setSliceSize] = useState(9 * 24)

  const [allExpanded, setAllExpanded] = useState(false);

  const toggleLine = (lineName) => {
    setLineVisibility(prevState => ({
      ...prevState,
      [lineName]: !prevState[lineName]
    }));
  };  

  const handleRowClick = (rowId) => {
    console.log('just click', rowId)
    setExpandedRows(prevExpandedRows => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(rowId)) {
        // Already expanded, do nothing on single click
        // newExpandedRows.delete(rowId); 
      } else {
        newExpandedRows.add(rowId); // Add to expanded rows
      }
      return newExpandedRows;
    });
  };
  
  const handleRowDoubleClick = (rowId) => {
    console.log("doubl click")
    setExpandedRows(prevExpandedRows => {
      const newExpandedRows = new Set(prevExpandedRows);
      newExpandedRows.delete(rowId); // Remove from expanded rows
      return newExpandedRows;
    });
  };

  const toggleExpandAll = () => {
    setAllExpanded(prevAllExpanded => {
      if (prevAllExpanded) {
        // If currently all are expanded, collapse all
        setRowHeight(80)
        setExpandedRows(new Set());
        return false;
      } else {
        // If currently not all are expanded, expand all
        const allRowIds = new Set(rows.map(row => row.id));
        setRowHeight(360)
        setExpandedRows(allRowIds);
        return true;
      }
    });
  };  


  const renderValue = (val) => {
    return (<ShowValue value={val}/>)
  }

  const renderPercent = (val) => {
    return (<ShowPercentChange value={val} />)
  }

  const renderPrice = (val) => {
    return (<p>${parseValueForTooltip(val)}</p>)
  }

  const hourClasses = [
    {classname: 'sopr-value', headerName: 'SOPR', dictKey: 'sopr_0h', renderF: renderValue}, 
    {classname: 'sopr-percent-6h', headerName: '6h %', dictKey: 'sopr_percent_6h', renderF: renderPercent},
    {classname: 'sopr-percent-12h', headerName: '12h %', dictKey: 'sopr_percent_12h', renderF: renderPercent},
    {classname: 'sopr-percent-24h', headerName: '24h %', dictKey: 'sopr_percent_24h', renderF: renderPercent},
    {classname: 'price-value', headerName: 'Price', dictKey: 'price_0h', renderF: renderPrice},
    {classname: 'price-percent-6h', headerName: '6h %', dictKey: 'price_percent_6h', renderF: renderPercent},
    {classname: 'price-percent-12h', headerName: '12h %', dictKey: 'price_percent_12h', renderF: renderPercent},
    {classname: 'price-percent-24h', headerName: '24h %', dictKey: 'price_percent_24h', renderF: renderPercent},
  ]

  const calculateMovingAverage = (data, numberOfPoints) => {
    let movingAverage = [];
    let sum = 0;
  
    for (let i = 0; i < data.length; i++) {
      sum += data[i].sopr; // Replace 'value' with the property that holds your data
  
      if (i >= numberOfPoints - 1) {
        movingAverage.push(sum / numberOfPoints);
        sum -= data[i - (numberOfPoints - 1)].sopr;
      } else {
        movingAverage.push(null); // Not enough data points yet for a moving average
      }
    }
  
    return movingAverage;
  }; 

  const calculateMedianMovingAverage = (data, numberOfPoints) => {
    let movingAverage = [];
  
    // Helper function to find the median of an array
    const findMedian = (arr) => {
      const mid = Math.floor(arr.length / 2);
      const nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    };
  
    // Sliding window approach
    for (let i = 0; i < data.length; i++) {
      if (i >= numberOfPoints - 1) {
        // Extract the window
        const window = data.slice(i - numberOfPoints + 1, i + 1).map(d => d.sopr);
        movingAverage.push(findMedian(window));
      } else {
        movingAverage.push(null); // Not enough data points for moving average
      }
    }
  
    return movingAverage;
  };

  const sortedRows = useMemo(() => {
    let sortableRows = [...rows]; // Create a copy of the rows array
    if (sortConfig.key !== null) {
      sortableRows.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableRows;
  }, [rows, sortConfig]);

  const loadChartData = async (token) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 1); // Set to 1 month ago

    if (!chartDataCache.current[token] && !chartData[token]) {
      chartDataCache.current[token] = true; // Mark as being fetched
      const soprData = await fetchSoprData(token, startDate, endDate);
      const priceData = await fetchPriceData(token, startDate, endDate);
      setChartData(prevChartData => ({ ...prevChartData, [token]: { sopr: soprData, price: priceData, sopr_3MA: calculateMedianMovingAverage(soprData, 3), sopr_5MA: calculateMedianMovingAverage(soprData, 14)} }));
    }
  };


  
  
  const loadVisibleRowData = async () => {
    console.log('start')
    const tableElement = tableRef.current;
    const scrollTop = tableElement.scrollTop;
    const firstVisibleRow = Math.floor(scrollTop / rowHeight);
    const visibleRowCount = Math.ceil(tableElement.clientHeight / rowHeight);
    const lastVisibleRow = Math.min(firstVisibleRow + visibleRowCount, sortedRows.length);
  
    for (let i = firstVisibleRow; i < lastVisibleRow; i++) {
      const token = sortedRows[i].token;
      if (!chartData[token]) {
        await loadChartData(token);
      }
    }
  };
  

  const fetchInitialData = async () => {
    const tokenData = await fetchTokens();
    console.log('tokenData', tokenData)
    setRows(tokenData.map((token, index) => ({ ...token, id: index, chart: 'Loading...' })));
    setInitialDataLoaded(true);     
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (initialDataLoaded) {
      loadVisibleRowData();
    }
  }, [initialDataLoaded, sortConfig]); // Add sortConfig as a dependency
  
  useEffect(() => {
    console.log("set new slice size")
    switch (timeFreq) {
      case 3:
        setSliceSize(3 * 24) // Last 3 days
        break;
      case 9:
        setSliceSize(9 * 24) // Last 9 days
        break;
      case 30:
        setSliceSize(30 * 24) // Last 1 month
        break;
      default:
        setSliceSize(9 * 24) // Default to show all data
    }
  }, [timeFreq])

  
  useEffect(() => {
    const handleScroll = () => {
      loadVisibleRowData();
    };

    const tableElement = tableRef.current;
    tableElement.addEventListener('scroll', handleScroll);

    return () => {
      tableElement.removeEventListener('scroll', handleScroll);
    };
  }, [sortedRows]);

  const requestSort = useCallback((key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }, [sortConfig]);

  const thresholdPlugin = {
    id: 'thresholdPlugin',
    afterLayout: function (chart) {
      const ctx = chart.ctx;
      const yAxis = chart.scales.y;
      const yThreshold = yAxis.getPixelForValue(1.0);
      const gradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom);

      let relativeYThreshold = (yThreshold - yAxis.top) / (yAxis.bottom - yAxis.top);
      if(relativeYThreshold < 0.0) {
        relativeYThreshold = 0.0
      }
      if(relativeYThreshold > 1.0) {
        relativeYThreshold = 1.0
      }
      // console.log('threshold', relativeYThreshold, yThreshold, yAxis.top, yAxis.bottom,yAxis.top)
      // Adjust these color stops
      gradient.addColorStop(0, 'rgb(40, 226, 238)'); // Start with green
      gradient.addColorStop(relativeYThreshold, 'rgb(40, 226, 238)'); // Green up to the threshold
      gradient.addColorStop(relativeYThreshold, 'rgb(238, 40, 138)'); // Change to red at the threshold
      gradient.addColorStop(1, 'rgb(238, 40, 138)'); // Red after the threshold

      chart.data.datasets[0].borderColor = gradient;
    }
  };

  const renderChart = (token, id, rowHeight) => {
    let chartDataForToken = chartData[token];
    if (!chartDataForToken) {
      return 'Loading...';
    }

    // timeFreq === 30

    const labelsData = chartDataForToken.sopr.map(item => item.datetime)
    chartDataForToken = (timeFreq === 30 ? chartDataForToken.sopr_5MA : chartDataForToken.sopr_3MA).slice(-sliceSize)
    // console.log('redner token', token)
    const { minY, maxY } = determineYAxisLimits(chartDataForToken);


    const data = {
      labels: labelsData.slice(-sliceSize),
      datasets: [{
        label: 'SOPR',
        data: chartDataForToken,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
        borderWidth: 1.0,
      }]
    };

    const options = {
      scales: {
        x: { display: false },
        y: { display: false, min: minY, // Use the dynamically determined min value
        max: maxY, },
      },
      elements: {
        point: { radius: 0 },
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true // Disable tooltips or other plugins as needed
        },
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: 1,
              yMax: 1,
              borderColor: 'rgba(255, 255, 255, 0.6)',
              borderWidth: 1,
              order: 1,
              yScaleID: 'y',
              borderDash: [2, 2],
            }
          }
        }
      },
      maintainAspectRatio: false,
      animation: false,
      
    };

    return (<div className="sparklineCanvas" style={{height: rowHeight}}>
          <div style={{padding: '10px', width: '100%'}}>
            <Line id={`sparkline-${id}`} datasetIdKey='id' data={data} options={options} plugins={[thresholdPlugin]} />
          </div>
      </div>)
  };

  const determineYAxisLimits = (data) => {
    let maxY = 'auto';
    let minY = 'auto';
  
    // Find min and max values in the data
    const maxValue = Math.max(...data);
    const minValue = Math.min(...data);
  
    // Apply the conditions for maxY
    if (maxValue > 1.5) {
      maxY = 1.5;
    } else if (maxValue <= 1.05) {
      maxY = 1.05;
    }
  
    // Apply the conditions for minY
    if (minValue < 0.4) {
      minY = 0.4;
    } else if (minValue > 0.95) {
      minY = 0.95;
    }
  
    return { minY, maxY };
  };
  
 
  

  const expandedRenderChart = (token, id, rowHeight) => {
    let chartDataForToken = chartData[token];
    if (!chartDataForToken) {
      return 'Loading...';
    }

    const chartDataForTokenSopr = chartDataForToken.sopr.slice(-sliceSize)
    const chartDataForTokenPrice = chartDataForToken.price.slice(-sliceSize)
    const chartDataForTokenSopr_3MA = chartDataForToken.sopr_3MA.slice(-sliceSize)
    const chartDataForTokenSopr_5MA = chartDataForToken.sopr_5MA.slice(-sliceSize)
    
    const { minY, maxY } = determineYAxisLimits(chartDataForTokenSopr.map(item => item.sopr));
    // console.log('data', chartDataForToken, chartDataForToken.sopr_3MA.map(item => item.sopr))
    // Define different data or options for the expanded chart
    const expandedData = {
      labels: chartDataForTokenSopr.map(item => item.datetime),
      datasets: [
        {
          label: '5MA',
          data: chartDataForTokenSopr_5MA,
          fill: false,
          borderColor: '#EF5350',
          tension: 0.1,
          borderWidth: 1.5,
          spanGaps: true, 
          hidden: !lineVisibility.MA5,
        },
        {
          label: '3MA',
          data: chartDataForTokenSopr_3MA,
          fill: false,
          borderColor: '#66BB6A',
          tension: 0.1,
          borderWidth: 1.0,
          spanGaps: true, 
          hidden: !lineVisibility.MA3,
        },
        {
        label: 'SOPR',
        data: chartDataForTokenSopr.map(item => item.sopr),
        fill: false,
        borderColor: 'rgba(120, 144, 156, 0.6)',
        tension: 0.1,
        borderWidth: 1.0,
        hidden: !lineVisibility.MA1,
      },
      {
        // ... Other dataset configurations ...
        label: 'Price',
        data: chartDataForTokenPrice.map(item => item.mean),
        yAxisID: 'y1', // Associate this dataset with the secondary Y-axis
        borderColor: '#FFC107',
        tension: 0.1,
        borderWidth: 1.0,
        borderDash: [3, 3],
      },]
    };
  
    const expandedOptions = {
        scales: {
          x: {
            display: true, // Show the X-axis
            type: 'time',
            grid: {
              display: false, // Show grid lines for X-axis
              color: 'rgba(255, 255, 255, 0.1)', // Customize grid line color
              // Additional grid line customization
            },
            ticks: {
              maxTicksLimit: 5,
              beginAtZero: false,
              maxRotation: 0,
            }
            // Additional X-axis configuration
          },
          y: {
            display: true, // Show the Y-axis
            grid: {
              display: true, // Show grid lines for Y-axis
              color: 'rgba(255, 255, 255, 0.1)', // Customize grid line color
              // Additional grid line customization
            },
            ticks: {
              color: '#FFF', // Customize tick color
              // Additional tick customization
              // maxTicksLimit: 6,
              
            },
            min: minY, // Use the dynamically determined min value
            max: maxY, 
            // Additional Y-axis configuration
          },
          y1: {
            display: true, // Show the secondary Y-axis
            position: 'right', // Position it to the right
            grid: {
              drawOnChartArea: false, // Only draw the axis line, not the grid lines
            },
            ticks: {
              color: '#FFF', // Customize tick color for price axis
              // Additional tick customization for price axis
            },
            // You can add min and max for the price axis if needed
          },
        },
        elements: {
          point: { radius: 0 },
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true // Disable tooltips or other plugins as needed
          },
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: 1,
                yMax: 1,
                borderColor: 'rgb(255, 255, 255)',
                borderWidth: 1,
                order: 1,
                yScaleID: 'y'
              }
            }
          }
        },
        maintainAspectRatio: false,
        animation: false,
    };
  
    return (
      <div className="expandedSparklineCanvas" style={{ height: '360px', width: '100%' }}>
        <Line id={`sparkline-${id}`} datasetIdKey='id' data={expandedData} options={expandedOptions} />
      </div>
    );
  };

  return (
    <div ref={tableRef} style={{ height: '100vh', overflowY: 'auto', paddingLeft: '20px', paddingRight: '20px' }}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <h2 style={{color: 'white', fontFamily: 'SFProBold'}}>🫀 SOPR Heartbeat</h2>
        <p style={{color: 'white', fontFamily:"SFProLight", fontSize: '14px', textAlign: 'right', marginLeft: 'auto'}}>Experimental functionality made by CherryPick team for their friends SOPR-maniacs.</p>
      </div>
      <div className='table-menu'>
        <div className='options-button'>
          <button onClick={()=>setTimeFreq(3)} className={timeFreq === 3 ? 'is-clicked':''}>3D</button>
          <button onClick={()=>setTimeFreq(9)} className={timeFreq === 9 ? 'is-clicked':''}>9D</button>
          <button onClick={()=>setTimeFreq(30)} className={timeFreq === 30 ? 'is-clicked':''}>1M</button>
        </div>
        <div style={{marginLeft: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
        <LegendItem 
          label="MA=1"
          color="rgba(120, 144, 156, 0.6)" // Replace with actual color for MA=1
          onClick={() => toggleLine('MA1')}
          isVisible={lineVisibility.MA1}
        />
        <LegendItem 
          label="MA=3"
          color="#66BB6A" // Replace with actual color for MA=3
          onClick={() => toggleLine('MA3')}
          isVisible={lineVisibility.MA3}
        />
        <LegendItem 
          label="MA=14"
          color="#EF5350" // Replace with actual color for MA=5
          onClick={() => toggleLine('MA5')}
          isVisible={lineVisibility.MA5}
        />
        </div>
        <button onClick={toggleExpandAll} className='button-profeature is-clicked-pro' style={{marginLeft: 'auto'}}>{ allExpanded ?"Collapse all":"Expand all"}</button>
      </div>
      <table className='custom-table'>
        <thead>
          <tr style={{fontFamily: 'SFPro', textAlign: 'left', fontSize: '14px'}}>
            <th className="id">#</th>
            <th className="token">
              Token
            </th>
            {
                hourClasses.map((hourClass) => (
                  <th onClick={() => requestSort(hourClass.dictKey)} style={{cursor: 'pointer'}} className={hourClass.classname}>{((sortConfig.key === hourClass.dictKey) ? (sortConfig.direction === 'ascending' ? '🔼':'🔽'):'')+hourClass.headerName}</th>
                ))
            }
            <th className="chart-table">{"Chart" + ((timeFreq === 30) ? ' ( MA=14;': ' ( MA=3;') + `  ${timeFreq} days)`}</th>
          </tr>
        </thead>
        <tbody>
          {sortedRows.map((row) => (
            <tr className={!expandedRows.has(row.id) ? `row-onhover`:''} key={row.id} onClick={() => {!expandedRows.has(row.id) && handleRowClick(row.id)}} style={{ height: expandedRows.has(row.id) ? '360px' : `${rowHeight}px`, fontFamily: 'SFProReg', fontSize: '14px' }}>
              <td className='id'>{row.id}</ td>
              <td className="token">{<TokenTableField value={row.token}/>}</td>
              {expandedRows.has(row.id) ? (
        <>
          {/* SOPR values in a new format or location */}
          <td colSpan={1} > {/* Adjust colSpan as needed */}
            <div style={{display: 'flex', flexDirection: 'column'}}>
            {hourClasses.map(hourClass => (
              <div key={hourClass.dictKey}>
                <p>{hourClass.headerName}:</p> 
              </div>
            ))}
            </div>
          </td>
          <td colSpan={1}> {/* Adjust colSpan as needed */}
            <div>
            {hourClasses.map(hourClass => (
              <div key={hourClass.dictKey}>
                {hourClass.renderF(row[hourClass.dictKey])}
              </div>
            ))}
            </div>
          </td>
          {/* Expanded chart spanning across multiple columns */}
          <td colSpan={5}> {/* Adjust colSpan to span the remaining columns */}
            {expandedRenderChart(row.token, row.id, '300px')}
          </td>
          <td colSpan={2}> {/* Adjust colSpan to span the remaining columns */}
            <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', gap: '30px'}}>
                <a style={{fontFamily:"SFProLight", fontSize: '16px', color: 'white'}} href={`https://app.cherry-pick.xyz/${row.token}/sopr`} target="_blank">Go to SOPR chart ↗️</a>
                <button onClick={()=>{handleRowDoubleClick(row.id)}} className='button-profeature is-clicked-pro' style={{fontFamily:"SFProLight", fontSize: '16px', color: 'white'}}>Close</button>
            </div>
          </td>
        </>
      ) : (
        <>
          {/* Normal rendering of SOPR values */}
          {hourClasses.map(hourClass => (
            <td key={hourClass.dictKey} className={hourClass.classname}>
              {hourClass.renderF(row[hourClass.dictKey])}
            </td>
          ))}
          {/* Normal rendering of the chart */}
          <td className="chart-table">
            {renderChart(row.token, row.id, rowHeight)}
          </td>
        </>
      )}
    </tr>
  ))}
        </tbody>
      </table>
    </div>
  );
};

// export const ProFeature = () => {
//   const [rows, setRows] = useState([]);
//   const gridRef = useRef(null);
//   const rowHeight = 52; 
  
//   const fetchInitialData = async () => {
//     // setLoading(true);
//     const tokenData = await fetchTokens();
//     console.log('token dataa', tokenData)
//     setRows(tokenData.map((token, index) => ({ ...token, id: index, chart: 'Loading...' })));
//     // setLoading(false);
//   };

//   useEffect(() => {
//     fetchInitialData();
//   }, []);

//   const calculateVisibleRows = () => {
//     const scrollTop = gridRef.current.scrollTop;
//     console.log('scrolltop', scrollTop, gridRef.current.scrollY)
//     const firstVisibleRowIndex = Math.floor(scrollTop / rowHeight);
//     const visibleRowCount = Math.ceil(gridRef.current.clientHeight / rowHeight);
//     return rows.slice(firstVisibleRowIndex, firstVisibleRowIndex + visibleRowCount).map(row => row.id);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       const visibleRowIds = calculateVisibleRows();
//       console.log('Visible row IDs:', visibleRowIds); 
//       // Additional logic for loading data for these rows
//     };

//     const observer = new MutationObserver(handleScroll);
//     const config = { attributes: true, childList: true, subtree: true };
//     if (gridRef.current) {
//       observer.observe(gridRef.current, config);
//     }

//     return () => {
//       observer.disconnect();
//     };
//   }, [rows]);

//   return (
//     <div  ref={gridRef} style={{ width: '100%', height: 'calc(100vh - 64.5px)' }}>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         pageSize={30}
//         rowHeight={rowHeight}
//         rowsPerPageOptions={[30]}
//         pagination
//         getRowId={(row) => row.id}
//         sx={{ color: 'white' }}
//       />
//     </div>
//   );
// };


// export default MyDataGrid;


// export default MyDataGrid;