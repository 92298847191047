import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {titleLabel, labelTooltipRPD, titleTooltip, scaleX, scalePrice, titleTooltipRPD} from './utils.js'
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

export const data = {
    // labels: [],
    datasets: [
        {
            label: 'Price',
            type: 'line',
            data: [],
            fill: false,
            borderColor: 'rgb(255, 255, 255)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            backgroundColor: 'rgb(255, 255, 255)',
            yAxisID: 'y1',
        },
        {
            label: 'Volume',
            type: 'bar',
            data: [],
            fill: false,
            borderColor: 'rgb(40,226,238)',
            backgroundColor: 'rgb(40,226,238)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            yAxisID: 'y1',
            xAxisID: 'x2',
            indexAxis: 'y',
          },
          {
            label: 'Current date',
            type: 'line',
            data: [],
            fill: false,
            borderColor: 'rgb(238,40,138)',
            backgroundColor: 'rgb(238,40,138)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            yAxisID: 'y1',
          },

    ],
};


export const options = {
    responsive: true,
    animation: false,
    interaction: {
      intersect: false,
      mode: 'nearest',
    //   mode: 'y',
      axis: 'y',
    //   dataset: 'Bar'
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
                overScaleMode: 'xy',
            },
            pan: {
                enabled: true,
                overScaleMode: 'xy',
            },
            limits: {
                x: {minRange: 5},
            }
        },
        legend: {
            display: false,
            // position: 'top',
        },
        title: {
            display: false,
        },
        tooltip: {
            // mode: 'index',
            callbacks: {
              label: labelTooltipRPD,
              title: titleTooltipRPD,
            },
        },
    },
    scales: {
        // x: scaleX,
        // y1: scalePrice,
        x: scaleX,
        y1: scalePrice,
        // y2: {
        //     position: 'left',
        //     grid: {
        //         display: false,
        //     },
        //     // ticks: {
        //     // callback: function(val) {
        //     //     return "$" + parseValueForTooltip(val)
        //     // },
        //     // font: {
        //     //     size: 12,
        //     //     family: 'SFProLight',
        //     // },
        //     // color: '#9AA2AA',
        //     // min: 0,
        //     // max: 100,
        //     // },
        //     type: "linear",
        //     // type: 'logarithmic',
        // },
        x2: {
            position: 'top',
            grid: {
                display: false,
            },
            type: "linear",
            reverse: true,
        }
    },
};