import { gridClasses } from '@mui/x-data-grid';

export const tableStyle = {
    // m: 2,
    borderRadius: 0,
    color: 'white',
    borderWidth: '0px',
    borderColor: '#EE288A',
    marginLeft: '0px',
    marginRight: '0px',
    backgroundColor: 'rgba(238, 40, 138, 0.0)',
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: 'rgba(29, 26, 45, 0.5)',
        // backgroundColor: 'rgba(238, 40, 138, 0.1)',
    },
    '& .MuiDataGrid-columnHeaders': {
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiDataGrid-columnHeader': {
        marginLeft: '14px',
    },
    '& .MuiDataGrid-footerContainer': {
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiDataGrid-main': {
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiTablePagination-root': {
        color: 'white',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
        fontSize: '12px',
        fontFamily: 'SFPro',
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiDataGrid-cell': {
        fontFamily:'SFProLight',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        color: 'white',
        // boxSizing: '0.1px',
        // borderBottom: '1px',
        marginLeft: '14px',
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiSvgIcon-root': {
        color: 'white',
    },
    '& .MuiDataGrid-columnSeparator': {
        // visibility: 'hidden',
    },
    '& .datagrid-separator-disable .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
    },
    '& .MuiDataGrid-overlay': {
        // backgroundColor: 'rgba(255, 255, 255, 0.05)',
        backgroundColor: 'rgba(29, 26, 45, 0.8)',   
    },
    '& .MuiCircularProgress-root': {
        color: '#EE288A',
    },
    '& .MuiDataGrid-toolbarContainer': {
        backgroundColor: 'rgba(29, 26, 45, 0.5)', // Replace with your desired color
        color: 'rgb(172, 209, 251)',  // Replace with your desired color
    },
    '& .MuiDataGrid-toolbarContainer .MuiButton-root': {
         // Replace with your desired color
        color: 'rgb(172, 209, 251)', 
    },
    '& .MuiFormControlLabel-label': {
        color: 'black',
    }
}

export const tableSearchStyle = {
    // m: 2,
    // borderRadius: '8px',
    borderRadius: '0px',
    color: 'white',
    borderWidth: '0px',
    // borderBottom: '2.0px solid rgb(29, 26, 45)',
    // borderWidth: '1.0px',
    // borderWidth: '1px',
    // borderColor: '#ACD1FB',
    marginLeft: '0px',
    marginRight: '0px',
    // backgroundColor: '#110D1F',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: 'rgba(29, 26, 45, 0.5)',
        // backgroundColor: 'rgba(238, 40, 138, 0.1)',
    },
    '& .MuiDataGrid-columnHeaders': {
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiDataGrid-columnHeader': {
        marginLeft: '14px',
    },
    '& .MuiDataGrid-footerContainer': {
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiDataGrid-main': {
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiTablePagination-root': {
        color: 'white',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
        fontSize: '12px',
        fontFamily: 'SFPro',
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiDataGrid-cell': {
        fontFamily:'SFProLight',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        color: 'white',
        // boxSizing: '0.1px',
        // borderBottom: '1px',
        marginLeft: '14px',
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiSvgIcon-root': {
        color: 'white',
    },
    '& .MuiDataGrid-columnSeparator': {
        // visibility: 'hidden',
    },
    '& .datagrid-separator-disable .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
    },
    '& .MuiDataGrid-overlay': {
        // backgroundColor: 'rgba(255, 255, 255, 0.05)',
        backgroundColor: 'rgba(29, 26, 45, 0.8)',   
    },
    '& .MuiCircularProgress-root': {
        color: '#EE288A',
    },
    '& .MuiDataGrid-toolbarContainer': {
        // backgroundColor: 'rgba(29, 26, 45, 0.5)', // Replace with your desired color
        backgroundColor: 'rgba(29, 26, 45, 0.0)',
        color: 'rgb(172, 209, 251)',  // Replace with your desired color
        marginLeft: '20px',
    },
    // '& .MuiDataGrid-toolbarContainer .MuiButton-root': {
    //      // Replace with your desired color
    //     color: 'rgb(172, 209, 251)', 
    //     fontFamily: 'SFPro',
    // },
    '& .MuiButtonBase-root': {
        textTransform: 'none', // Normal text without uppercase
        textDecoration: 'underline', // Underlined text
        fontSize: '16px',
        fontFamily: 'SFPro',
        color: 'white',
        textUnderlineOffset: '4px',
        // Add more styles here
    },
    '& .MuiButtonBase-root:hover': {
        backgroundColor: '#2A273B',
        textDecoration: 'underline',
    },
    '& .MuiButton-startIcon': {
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px',
        backgroundColor: '#181526',
    }
}
