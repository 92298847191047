import { React, useState, useEffect, useRef} from "react";
import { Menu } from "./Menu";

import { Table } from "./Table";
import { getLeaderboard } from "./loadData";

import { Auth } from "@supabase/auth-ui-react";
import { ButtonLogin, ButtonSignUp } from "./Navigation";

import hideButton from  "../assets/hideButton.svg"
import { initialState } from "./TableSettings/MacroTable";

import { shortNames, shortNames2Index, tableIndexes, indexNames, tables } from "./dataLeaderboard";

import { useNavigate, createSearchParams, useSearchParams, generatePath, useMatch, useLocation } from "react-router-dom";

import { tokens } from "./data";

import { convertTokensToList } from "./Filter";

const listTokens = convertTokensToList(tokens)


export function LeaderBoard(props) {
    const [selectedToken, setToken] = useState("ldo")
    // const [selectedToken, setToken] = useState("arb")
    const { user } = Auth.useUser();

    const [selectedIndex, setIndex] = useState(0)

    const [dataGridTable, setDataGridTable] = useState([])
    const [loadingData, setLoading] = useState(false)

    const [toggleDropDown, setDropDown] = useState(false)

    const tableRef = useRef()

    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const urlParams = location.pathname.split('/')
        const index = urlParams[2]
        const token = urlParams[3]

        console.log("INDEX - ", index)

        const newIndex = index===undefined ? selectedIndex: (shortNames2Index[index]===undefined ? selectedIndex: shortNames2Index[index])
        const newToken = token===undefined ? selectedToken: (tokens[token]===undefined ? selectedToken : token)

        setToken(newToken)
        setIndex(newIndex)

        setNewRoute(newIndex, newToken)

        updateTable(newIndex, newToken) 
    }, []);

    function setNewRoute(indexSel, tokenSel) {
        let searchParams = {
            token: tokenSel,
            index: shortNames[indexSel],
        }
        // navigate(`?${createSearchParams(searchParams)}`)
        const obtainedPath = generatePath("/leaderboard/:index/:token", searchParams)
        const navString = `${obtainedPath}`
        //const navString = toolSel ? `?${createSearchParams(searchParams)}&${createSearchParams(toolSel)}` : `?${createSearchParams(searchParams)}`
        navigate(navString)
    }

    function setNewIndex(index) {
        setNewRoute(index, selectedToken)
        setDataGridTable([])
        setIndex(index)
        updateTable(index, selectedToken)
        console.log("TABLE", tableIndexes[indexNames[selectedIndex]])
    }

    function setNewToken(tokenName) {
        setNewRoute(selectedIndex, tokenName)
        setToken(tokenName)
        updateTable(selectedIndex, tokenName) 
    }

    function toggleMenu() {
        setDropDown(!toggleDropDown)
    }

    function updateTable(index, token) {
        setLoading(true)
        getLeaderboard(tableIndexes[indexNames[index]].apiPath, token).then((data) => {
            console.log("OBTAIN TABLE DATA", data)
            setDataGridTable(data)
            setLoading(false)
        })
    }

    // useEffect(() => {
    //     updateTable(selectedIndex)
    // }, []);

    return (
        <main className="main">
            { props.isMobile && toggleDropDown && <div className="blackout"></div>}
            { !props.isMobile && <Menu selectedToken={selectedToken} 
                  selectedIndex={selectedIndex} 
                  setNewIndex={setNewIndex}
                  setNewToken={setNewToken}
                  indexes={tables}
                  toggleMenu={toggleMenu}

                  tokens={listTokens}
                  old={true}
                  /> }
            { props.isMobile && toggleDropDown && <Menu 
                selectedToken={selectedToken} 
                selectedIndex={selectedIndex} 
                setNewIndex={setNewIndex}
                isMobile={props.isMobile}
                indexes={tables}
                setNewToken={setNewToken} 
                toggleMobile={toggleMenu}

                tokens={listTokens}
                old={true}
            />}
            <div class="chart2">
                <div class="tableContainer">
                { !user && <div className="locker" style={{position: "absolute", 
                  width: '100%',
                  height: 'calc(100% - 130px)', 
                //  background: 'red',
                  zIndex: 1, 
                  marginTop: 130,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                //   backdropFilter: 'blur(5px)',
                  }}>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'}}> To see full dashboard </h3>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'    }}> you can try to hack this page </h3>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px'}}> or just sign up!  😉🍒 </h3>
                    {/* <ButtonSignUp withoutMargin={true} /> */}
                    <div style={{display:'flex', flexDirection: 'row', marginTop: '30px'}}>
                        <ButtonLogin blackTheme={true} />
                        <ButtonSignUp blackTheme={true} withoutMargin={true}/>
                    </div>
                </div> }
                    <div style={{width: '100%', height: '93%'}}>
                        <div className="tableHeader" style={{backgroundColor: 'rgba(238, 40, 138, 0.0)', 
                                                     height: '62.5px',
                                                     alignItems: 'center',
                                                     display: 'flex',
                                                    //  borderBottom: '0.3px solid #EE288A',
                                                     borderBottom: '2px solid rgb(29, 26, 45)',

                                                     }}> 
                            <p style={{fontFamily: 'SFProMedium', 
                                fontSize: '20px', 
                                color: "white",
                                marginLeft: '24px',
                                }}> {indexNames[selectedIndex]}</p>   
                            <div style={{display: 'flex', marginLeft:'auto',}}>
                                {props.isMobile && !toggleDropDown && 
                                    <button class="menu-button-charts indexes-menu-button" id="indexesMenuButton" style={{marginRight:'25px'}} 
                                    onClick={() => toggleMenu()}>
                                        <svg viewBox="0 0 24 24">
                                            <path d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"/>
                                        </svg>
                                    </button>
                                    // <img src={ hideButton } style={{ marginRight:'25px', zIndex: 2}} alt="text" onClick={() => toggleMenu()} /> }
                                    }
                                    {props.isMobile && toggleDropDown && <button
                                            className="menu-button-charts"
                                            style={{marginRight:'25px'}}
                                            onClick={{}}
                                        >
                                    <svg viewBox="0 0 24 24">
                                    <path d="M18.36 6.64a1 1 0 0 0-1.41 0L12 11.59 7.05 6.64a1 1 0 1 0-1.41 1.41L10.59 13l-4.95 4.95a1 1 0 0 0 1.41 1.41L12 14.41l4.95 4.95a1 1 0 0 0 1.41-1.41L13.41 13l4.95-4.95a1 1 0 0 0 0-1.41z" />
                                    </svg>
                                </button>}
                                {/* {props.isMobile && <img src={ hideButton } style={{ marginRight:'25px', zIndex: 2}} alt="text" onClick={() => toggleMenu()} /> } */}
                            </div>                                
                        </div>
                        <Table 
                            initialState={tableIndexes[indexNames[selectedIndex]].initialState} 
                            tableData={dataGridTable} 
                            tableLoading={loadingData}
                            columnType={tableIndexes[indexNames[selectedIndex]].columnType}
                            tableRef={tableRef}
                        /> 
                    </div>
                </div>
            </div>

            {/* <div style={{background: 'purple', width: '100%'}}></div> */}
            {/* <Table tableData={dataGridTable} tableLoading={loadingData}/> */}
        </main>
    )
}