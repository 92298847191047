import React, { useEffect } from 'react';

function formatDate(date) {
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero indexed
  let year = date.getFullYear();
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');
  let seconds = date.getSeconds().toString().padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}


// PayWidget.js
const loadPayWidgetScript = (callback) => {
    const existingScript = document.getElementById('payWidgetScript');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = "https://widget.payselection.com/lib/pay-widget.js";
      script.id = 'payWidgetScript';
      document.body.appendChild(script);
  
      script.onload = () => {
        if (callback) callback();
      };
    } else if (callback) {
      callback();
    }
  };
  
const pay = ({ serviceId, publicKey, orderId, userId, amount, currency, description, email, monthNumber, priceAmount, onSuccess, onError, onClose }) => {
    loadPayWidgetScript(() => {
      const widget = new window.pw.PayWidget();
      let currentDateTime = new Date();

      // console.log("pay params")
      // console.log({
      //   serviceId,
      //   key: publicKey,
      //   logger: true,
      // }, {
      //   MetaData: { PaymentType: "Pay" },
      //   PaymentRequest: { 
      //     OrderId: orderId, 
      //     Amount: amount, 
      //     Currency: currency, 
      //     Description: description,
      //     ExtraData: {
      //       UserId: userId,
      //     } 
      //   },
      //   CustomerInfo: {
      //     Language: "en",
      //     ReceiptEmail: `${email}`,
      //     Email: `${email}`,
      //   }})

      widget.pay({
        serviceId,
        key: publicKey,
        logger: true,
      }, {
        MetaData: { PaymentType: "Pay" },
        PaymentRequest: { 
          OrderId: orderId, 
          Amount: amount, 
          Currency: currency, 
          Description: description,
          ExtraData: {
            UserId: userId,
          } 
        },
        // ReceiptData: {
        //   timestamp: formatDate(currentDateTime),
        //   receipt: {
        //     client: {
        //       email: `${email}`,
        //     },
        //     company: {
        //       email: "info@cherry-pick.xyz",
        //       sno: "usn_income",
        //       inn: "773461536579",
        //       payment_address: "https://cherry-pick.xyz",
        //     },
        //     items: [
        //       {
        //         name: `Standard plan ${monthNumber} month subscription`,
        //         price: priceAmount,
        //         quantity: 1,
        //         sum: priceAmount,
        //         measure: "0",
        //         payment_method: "full_prepayment",
        //         payment_object: "4",
        //         vat: {
        //           type: "none",
        //         }
        //       }
        //     ],
        //     payments: [
        //       {
        //         type: 1, 
        //         sum: priceAmount,
        //       }
        //     ],
        //     vats: [
        //       {
        //         type: 'none',
        //       }
        //     ],
        //     total: priceAmount,
        //   }
        // },
        CustomerInfo: {
          Language: "en",
          ReceiptEmail: `${email}`,
          Email: `${email}`,
        }
      }, {
        onSuccess,
        onError,
        onClose,
      }, {
        only2Level: true,
      });
    });
};
  
export default pay;