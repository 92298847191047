import { DataGrid } from '@mui/x-data-grid';
import { TokenTableField, ShowPercentChange } from './utils';
import { addCommas, parseValueForTooltip } from '../ChartSettings/utils';

import { ShowAddress } from './utils';
import { ShowDollar } from '../dataPortfolio';


export const columns = [
    { 
        field: 'number', 
        headerName: '', 
        width: 20,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{props.value}</p>)},
    },
    { 
        field: 'address', 
        headerName: 'Address', 
        width: 110,
        // headerClassName: 'datagrid-separator-disable', 
        renderCell: (props) =>  { return (<ShowAddress value={props.value.address} token={props.value.token} tag={props.value.tag}/>)},
    },
    {
        field: 'volume_dollar_received',
        headerName: 'Invested ($)',
        width: 120,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>${parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'nmpl',
        headerName: 'Profit / Loss ($)',
        width: 140,
        description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'roi',
        headerName: 'ROI (%)',
        width: 100,
        description: 'Return on Investment (ROI) shows how much a trader earned over time relative to how much he/she spent. ROI is the main measure to assess the profitability of a trader\'s strategy.',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<ShowDollar value={props.value} percent={true}  withoutDollar={true}/> )}
    },
    { 
        field: 'tx_number_received', 
        headerName: '# Buys', 
        width: 70,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{props.value}</p>)},
    },
    { 
        field: 'tx_number_sent', 
        headerName: '# Sells', 
        width: 70,
        renderCell: (props) =>  { return (<p>{props.value}</p>)},
    },
    { 
        field: 'balance', 
        headerName: 'Balance', 
        width: 100,
        cellClassName: 'datagrid-balance',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'netflow',
        headerName: '30d Δ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}  withoutDollar={true}/>) },
    },
    { 
        field: 'balance_dollar', 
        headerName: 'Balance ($)', 
        width: 100,
        cellClassName: 'datagrid-balance',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    // {
    //     field: 'nupl',
    //     headerName: 'Unrealised Profit / Loss ($)',
    //     width: 170,
    //     renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    // },
]

// export const initialState = {
//     sorting: {
//       sortModel: [{ field: 'mvrv_1d', sort: 'desc' }],
//     },
// }