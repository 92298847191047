import React, { createContext, useContext, useState } from 'react';

const SubscriptionContext = createContext();

export function useSubscription() {
  return useContext(SubscriptionContext);
}

export function SubscriptionProvider({ children }) {
  console.log('sub provider')
  const [subscriptionTier, setSubscriptionTier] = useState(0);
  const [filterPackageLevel, setFilterPackageLevel] = useState(0);
  const [subscriptionValidUntil, setSubscriptionValidUntil] = useState('-');
  const [subIsLoading, setSubIsLoading] = useState(true);

  const [referralCode, setReferral] = useState('')
  const [refDiscount, setRefDiscount] = useState(0)

  const [renewableSub, setRenewable] = useState(false)

  return (
    <SubscriptionContext.Provider 
        value={{ 
            subscriptionTier, 
            setSubscriptionTier, 
            filterPackageLevel, 
            setFilterPackageLevel, 
            subscriptionValidUntil,
            setSubscriptionValidUntil,
            referralCode,
            setReferral,
            renewableSub,
            setRenewable,
            refDiscount,
            setRefDiscount,
            subIsLoading, 
            setSubIsLoading }}>
      {children}
    </SubscriptionContext.Provider>
  );
}