import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";

import {parseBigValues, titleLabel, titleTooltip, scaleX, scalePrice} from './utils.js'
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

export const data = {
    // labels: [],
    datasets: [
        {
            label: 'Price',
            data: [],
            fill: false,
            borderColor: 'rgb(255, 255, 255)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            backgroundColor: 'rgb(255, 255, 255)',
            yAxisID: 'y1',
        },
        {
            label: 'Inflow',
            data: [],
            fill: false,
            borderColor: 'rgb(40,226,238)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            backgroundColor: 'rgb(40,226,238)',
            yAxisID: 'y',
          },
        {
            label: 'Outflow',
            data: [],
            fill: false,
            borderColor:  'rgb(243, 39, 136)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            backgroundColor: 'rgb(243, 39, 136)',
            yAxisID: 'y',
        },
    ],
};


export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
                overScaleMode: 'y',
            },
            pan: {
                enabled: true,
                overScaleMode: 'y',
            },
            limits: {
                x: {minRange: 5},
            }
        },
        legend: {
            display: true,
            position: 'top',
        },
        title: {
            display: false,
        },
        tooltip: {
            // mode: 'index',
            callbacks: {
              label: titleLabel,
              title: titleTooltip,
            },
        },
    },
    scales: {
        x: scaleX,
        y: {
            position: 'left',
            grid: {
                display: false,
            },
            // type: 'logarithmic',
            ticks: {
              callback: function(val) {
                return parseBigValues(val) 
              },
              font: {
                size: 12,
                family: 'SFProLight',
              },
              color: '#9AA2AA',
            },
            // type: 'logarithmic',
        },
        y1: scalePrice,
    },
  };