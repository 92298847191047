import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";

import {parseValueForTooltip, scaleX, scalePrice} from './utils.js'
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

export const data = {
    // labels: [],
    datasets: [
        {
            label: 'Price',
            data: [],
            fill: false,
            borderColor: 'rgb(255, 255, 255)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            backgroundColor: 'rgb(255, 255, 255)',
            yAxisID: 'y1',
            order: 3,
        },
        {
            label: 'Received',
            data: [],
            fill: false,
            borderColor: 'rgb(40,226,238)',
            tension: 0,
            pointRadius: 5.0,
            borderWidth: 0,
            backgroundColor: 'rgba(40,226,238, 0.8)',
            yAxisID: 'y1',
            order: 1,
          },
        {
            label: 'Sent',
            data: [],
            fill: false,
            borderColor:  'rgb(243, 39, 136)',
            tension: 0,
            pointRadius: 5.0,
            borderWidth: 0,
            backgroundColor: 'rgba(243, 39, 136, 0.8)',
            yAxisID: 'y1',
            order: 2,
        },
    ],
};


export const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
                overScaleMode: 'y',
            },
            pan: {
                enabled: true,
                overScaleMode: 'y',
            },
            limits: {
                x: {minRange: 5},
            }
        },
        legend: {
            display: true,
            position: 'top',
            
            labels: {
                color: '#FFFFFF',
                font: {
                    size: 16,
                }
                
            }
        },
        title: {
            display: false,
        },
        tooltip: {
            // mode: 'index',
            callbacks: {
              title: function(chart){
                if((chart.length) > 0) {
                    return chart[0].raw.x.slice(0, 13) + ':00'
                }
              },
              label: function(chart){
                let label = ''
                if (chart.dataset.label == 'Price') {
                    label = chart.dataset.label || '';
                    if (label) {
                        let value = parseValueForTooltip(chart.parsed.y)
                        if (value[0] == "-") {
                            label += ': -$' + value.slice(1,)
                        }
                        else {
                            label += ': $' + value
                        }
                        
                    }
                }
                else if (['Received', 'Sent'].includes(chart.dataset.label )) {
                    label = chart.dataset.label || '';
                    if (label) {
                        let value = parseValueForTooltip(chart.raw.v)
                        label += ': ' + value
                        value = parseValueForTooltip(chart.raw.v_dol)
                        label += ' ($' + value + ')'
                        
                    }
                }
                
                return label;
              }
            },
        },
    },
    scales: {
        x: scaleX,
        // y: {
        //     position: 'left',
        //     grid: {
        //         display: false,
        //     },
        //     // type: 'logarithmic',
        //     ticks: {
        //       callback: function(val) {
        //         return parseBigValues(val) 
        //       },
        //       font: {
        //         size: 12,
        //         family: 'SFProLight',
        //       },
        //       color: '#9AA2AA',
        //     },
        //     // type: 'logarithmic',
        // },
        y1: scalePrice,
    },
  };