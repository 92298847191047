import { React, useState, useEffect, useRef} from "react";
import { Chart } from "./Chart.js";
import { overviewPages  } from "./dataOverview.js";
import { getOverviewData, getGigantTransfers } from "./loadData.js";
import { parseBigValues, addCommas } from "./ChartSettings/utils.js";
import { shortNames2IndexV1 } from "./data";
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { StyledEngineProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { Paywall } from "./Main.js";
// import { globalConfig } from './data';

// const { shortNames2Index } = globalConfig;

export function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}


function convertTypeToEmoji(type) {
    if(type === "sc") {
        return '🤖'
    } else if (type === "cex") {
        return '🏦'
    } else if (type === "address") {
        return '🤑'
    } else {
        return ''
    }
}

function convertAgeToEmoji(age) {
    if(age === "<1d") {
        return '👶'
    } else if (age === "1d-1w") {
        return '🧒'
    } else if (age === "1w-1m") {
        return '👧'
    } else if (age === "1m-3m") {
        return '🧑‍🦱'
    } else if (age === "3m-6m") {
        return '🧔'
    } else if (age === "6m-1y") {
        return '👨‍🦰'
    } else if (age === "1y-2y") {
        return '🧓'
    } else if (age === "2y-3y") {
        return '👵'
    } else if (age === "3y-5y") {
        return '👵'
    } else {
        return ''
    }
} 

function convertWealthToEmoji(wealth) {
    if (wealth === "$10M+") {
        return '🐋'
    } else if (wealth === "$1M-10M") {
        return '🐳'
    } else if (wealth === "$100k-1M") {
        return '🦈'
    } else if (wealth === "$10k-100k") {
        return '🐬'
    } else if (wealth === "$1k-10k") {
        return '🦭'
    } else if (wealth === "$100-1k") {
        return '🐟'
    } else if (wealth === "$0-100") {
        return '🦐'
    } else {
        return ''
    }
}

const columns = [
  { field: 'hash', 
    headerName: 'Hash', 
    width: 90,
    cellClassName: 'datagrid-hash',
    renderCell: (props) => {return (
        <a href={`https://${props.value[1]}/tx/${props.value[0]}`} style={{color: 'white'}}>{props.value[0].slice(0, 8)}... </a>
    )}},
  { field: 'from', headerName: 'From', width: 100, headerClassName: 'datagrid-separator-disable',
    renderCell: (props) => {return (
        <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
            <span style={{fontSize: "16px"}} role="img" aria-label="enjoy">
                {convertTypeToEmoji(props.value[0])}
            </span> 
            <a href={`https://${props.value[2]}/address/${props.value[1]}`} style={{color: 'white', textDecoration: 'inherit'}}>{props.value[1].slice(0, 9)}</a>
        </div> )
    } 
   },
  { field: 'ageFrom', 
    description: 'This column shows how long the tokens were held on from account before were sold.',
    headerName: 'Age',
    headerClassName: 'datagrid-separator-disable',
    sortable: false,
    width: 70,
    renderCell: (props) => {
        return (
        <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
            <span style={{fontSize: "16px"}} role="img" aria-label="enjoy">
                {convertAgeToEmoji(props.value)}
            </span> 
            <p>{props.value}</p>
        </div> )
    }
  },
  { field: 'wealthFrom', 
    description: 'This column shows how much money the from account has.',
    headerName: 'Wealth',
    sortable: false,
    width: 100,
    cellClassName: 'datagrid-wealthFrom',
    renderCell: (props) => {
        return (
        <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
            <span style={{fontSize: "16px"}} role="img" aria-label="enjoy">
                {convertWealthToEmoji(props.value)}
            </span> 
            <p>{props.value}</p>
        </div> )
    } 
  },
  { field: 'to', headerName: 'To', width: 100, headerClassName: 'datagrid-separator-disable',
  renderCell: (props) => {return (
    <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
        <span style={{fontSize: "16px"}} role="img" aria-label="enjoy">
            {convertTypeToEmoji(props.value[0])}
        </span> 
        <a href={`https://${props.value[2]}/address/${props.value[1]}`} style={{color: 'white', textDecoration: 'inherit'}}>{props.value[1].slice(0, 9)}</a>
    </div> )
    } 
  },
  { field: 'ageTo', 
    description: 'This column shows how long the tokens were held on to account before were sold.',
    headerName: 'Age', 
    headerClassName: 'datagrid-separator-disable',
    sortable: false,
    width: 70,
    renderCell: (props) => {
        return (
        <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
            <span style={{fontSize: "16px"}} role="img" aria-label="enjoy">
                {convertAgeToEmoji(props.value)}
            </span> 
            <p>{props.value}</p>
        </div> )
    }
  },
  { field: 'wealthTo', 
    description: 'This column shows how much money the to account has.',
    headerName: 'Wealth',
    sortable: false,
    width: 100,
    cellClassName: 'datagrid-wealthTo',
    renderCell: (props) => {
        return (
        <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
            <span style={{fontSize: "16px"}} role="img" aria-label="enjoy">
                {convertWealthToEmoji(props.value)}
            </span> 
            <p>{props.value}</p>
        </div> )
    } 
  },
  { field: 'value', 
    headerName: 'Value',
    width: 70,
    headerClassName: 'datagrid-separator-disable',
    renderCell: (props) => {
        return (
            <p style={{}}> {parseBigValues(props.value)}</p>
        )
    } },
  { field: 'valueDollar', 
    headerName: 'Value ($)',
    width: 70,
    headerClassName: 'datagrid-separator-disable',
    renderCell: (props) => {
        return (
            <p style={{}}> {parseBigValues(props.value)}</p>
        )
    }  },
  {
    field: 'profit', 
    headerName: 'PnL ($)',
    description: 'How much profit or loss in dollars the address made as a result of this transfer.',
    width: 70,
    cellClassName: (props) => {
        if (props.value < 0) {
            return 'datagrid-profit-positive'
        } else {
            return 'datagrid-profit-negative'
        }
    },
    renderCell: (props) => {
        return (
            <p> {parseBigValues(props.value)}</p>
        )
    }
    },
  {
    field: 'time', 
    headerName: 'Time',
    width: 80,
    renderCell: (props) => {
        return (
            <p> {timeSince(props.value*1000)} ago</p>
        )
    },
  },
];

const dataResponse = [
    {
        hash: "0x1093c06effe6876fc8a336f7d40517eedb6aa7bec6cf341004e86f253e1ebfa2",
        timestamp: 1673023163,
        from: "0x8542196926c7a3baac43120f7d6a442c1f5bd343",
        to: "0xf29b0455b727fe2f05b6f9b052b1f711e48c984d",
        value: 57977066.3211729,
        valueDollar: 6235723.368173751, 
        fromType: "address",
        toType: "address",
        fromTag: "",
        toTag: "",
        fromBalance: "$1M-10M",
        toBalance: "$1M-10M",
        fromAge: "1d-1w",
        toAge: "<1d",
        profit: -191034.43352826507,
    },
    {
        hash: "0xa56cf0f132d4ddf38670ca648b26966d5592ad1d8d096abd43f29bc67038c9f3",
        timestamp: 1672938623,
        from: "0xf06f3a1e7efa8954ccf8b39214705fe742cd9986",
        to: "0xceb69f6342ece283b2f5c9088ff249b5d0ae66ea",
        value: 16896008.308891818,
        valueDollar: 1882891.165942904, 
        fromType: "address",
        toType: "address",
        fromTag: "",
        toTag: "",
        fromBalance: "$1M-10M",
        toBalance: "$1M-10M",
        fromAge: "1w-1m",
        toAge: "<1d",
        profit: 151738.63455215408,
    },
    {
        hash: "0x48d259cfaecfd65876b921e92c5cc4d84a2b4f0cd8fa30dde229aa9c05428263",
        timestamp: 1672903811,
        from: "0x6dfaa03bc938b2d1d52ba9c6548148285608dcd4",
        to: "0x28c6c06298d514db089934071355e5743bf21d60",
        value: 8899972,
        valueDollar: 1000178.8533600001, 
        fromType: "address",
        toType: "cex",
        fromTag: "",
        toTag: "binance",
        fromBalance: "$1M-10M",
        toBalance: "",
        fromAge: "<1d",
        toAge: "",
        profit: -8543.973119999899,
    },
    {
        hash: "0x14c9415a7ae4ea13742029597452d3825cf1f2cc3cd0d27a2775ca39d0822a12",
        timestamp: 1672828343,
        from: "0xcc477b21d471fb9394a56aace72c8d59ac80f6af",
        to: "0x3dd509eea1cbe2fe00fbbce496dd453bfde74e7f",
        value: 499998000,
        valueDollar: 51974792.1, 
        fromType: "sc",
        toType: "address",
        fromTag: "",
        toTag: "",
        fromBalance: "",
        toBalance: "$10M",
        fromAge: "",
        toAge: "<1d",
        profit: 51835937.940898515,
    },
]


// function parseTable(data) {
//     data.map((row, index) => {
//         return {id: index,
//         hash: row.hash,
//         from: row.fromTag === "" ? row.from : row.fromTag,
//         to: row.toTag === "" ? row.to : row.toTag,
//         typeFrom: row.fromType,
//         typeTo: convertTypeToEmoji(row.toType),
//         ageFrom: row.fromAge,
//         ageTo: convertAgeToEmoji(row.toAge),
//         wealthFrom: convertWealthToEmoji(row.fromBalance),
//         wealthTo: convertWealthToEmoji(row.toBalance),
//         value: row.value,
//         valueDollar: row.valueDollar,
//         profit: row.profit,
//         }
//     })
// }
// const rows = [{ id: 1, hash: '0xas343sdf324', from: '0xas343sdf324', to: '0x8123ab32', lastName: 'Snow', firstName: 'Jon', age: 35 }]
// const rows = dataResponse.map((row, index) => {
//     return {id: index,
//     hash: row.hash,
//     from: [(row.fromType !== 'address' ? row.fromType : ""), (row.fromTag === "" ? row.from : row.fromTag)],
//     to: [(row.toType !== 'address' ? row.toType : ""), (row.toTag === "" ? row.to : row.toTag)],
//     // typeFrom: row.fromType,
//     // typeTo: row.toType,
//     ageFrom: row.fromAge,
//     ageTo: row.toAge,
//     wealthFrom: row.fromBalance,
//     wealthTo: row.toBalance,
//     value: row.value,
//     valueDollar: row.valueDollar,
//     profit: row.profit,
//     time: new Date(row.timestamp),
//     }
// })

const rows = []



// const rows = [
//   { id: 1, hash: '0xas343sdf324', from: '0xas343sdf324', to: '0x8123ab32', lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, hash: '0xas343sdf324', from: '0xas343sdf324',lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, hash: '0xas343sdf324', from: '0xas343sdf324',lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, hash: '0xas343sdf324', from: '0xas343sdf324',lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, hash: '0xas343sdf324', from: '0xas343sdf324',lastName: 'Targaryen', firstName: 'Daenerys', age: null },
// //   { id: 6, hash: '0xas343sdf324', from: '0xas343sdf324',lastName: 'Melisandre', firstName: null, age: 150 },
// //   { id: 7, hash: '0xas343sdf324', from: '0xas343sdf324',lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
// //   { id: 8, hash: '0xas343sdf324', from: '0xas343sdf324',lastName: 'Frances', firstName: 'Rossini', age: 36 },
// //   { id: 9, hash: '0xas343sdf324', from: '0xas343sdf324',lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

// function DataTable() {
//   return (
//     <div style={{ height: 400, width: '100%' }}>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         pageSize={5}
//         rowsPerPageOptions={[5]}
//         checkboxSelection
//       />
//     </div>
//   );
// }

function PageButton(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)

    const isSelectedPage = (props.selectedPage === props.text.shortName)
    const style = { 
        cursor: 'pointer',
        fontFamily: 'SFProReg',
        color: isSelectedPage || (isMouseEnter)  ? "rgba(238, 40, 138, 1.0)" : "rgba(154, 162, 170, 1.0)",
        textAlign: "center",
        marginLeft: props.withoutMenu? "24px" : "44px",
    }

    return (
        <div className="overviewHeader-elements" style={ style } 
        onMouseEnter={ () => setMouseEnter(true)}  
        onMouseLeave={ () => setMouseEnter(false)}
        onClick={() => props.selectFunc(props.text.shortName)}> 
            <div style={{marginBottom: (isSelectedPage) || (isMouseEnter) ? "13px": "15px", color: '#ACD1FB'}}>
                {props.text.fullName} 
            </div>
            {((isSelectedPage) || (isMouseEnter)) && <div style={{
                                            width: "100%", 
                                            height: "2px", 
                                            backgroundColor: '#ACD1FB',
                                            borderTopLeftRadius: "15px",
                                            borderTopRightRadius: "15px"}}>
            </div>}
        </div>
    )
}

export function HeaderOverview(props) {
    return (
        <div className="overviewHeader" style={{gap: props.withoutMenu? "5px": "30px"}}>
            {props.pages.map(page => <PageButton text={page} selectFunc={props.selectFunc} selectedPage={props.selectedPage} withoutMenu={props.withoutMenu}/>)}
        </div>
    )
}

function BoxInfo(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)

    console.log("Box info", props.obj.redirectIndex, shortNames2IndexV1[props.obj.redirectIndex])
    const boxStyle = {
        cursor: props.obj.redirectIndex? 'pointer': '',
        backgroundColor: isMouseEnter && props.obj.redirectIndex? 'rgba(29, 26, 45, 0.15)':'',
    }
    return (
        <div className="boxInfo" style={boxStyle} 
        onClick={() => props.obj.redirectIndex? props.setNewIndex(shortNames2IndexV1[props.obj.redirectIndex]) : console.log('there is not redirect')}
        onMouseEnter={ () => setMouseEnter(true)}  
        onMouseLeave={ () => setMouseEnter(false)}>
            <p className="boxHeader">{props.obj.name}</p>
            <p className="boxText">{props.obj.parse(props.obj.value)}</p>
        </div>
    )
}

function RowInfo(props) {
    const styleBox = {
        marginLeft: props.withoutMenu? "24px": "44px",
        marginRight: props.withoutMenu? "24px": "44px",
        marginTop: props.withoutMenu? "24px": "44px",
    }
    return (
        <div>
        {!props.ismobile && <div className="boxOverview" style={styleBox}>
            {props.data.map(obj => <BoxInfo obj={obj} setNewIndex={props.setNewIndex} />)}
        </div>}
        {props.ismobile && <div className="boxOverview" style={styleBox}>
            {props.data.slice(0, 2).map(obj => <BoxInfo obj={obj} setNewIndex={props.setNewIndex} />)}
        </div>}
        {props.ismobile && <div className="boxOverview" style={{...styleBox, marginTop: "20px"}}>
            {props.data.slice(2, 4).map(obj => <BoxInfo obj={obj} setNewIndex={props.setNewIndex} />)}
        </div>}
        </div>
    )
}

function ChartInfo(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)

    const chartStyle = {
        cursor: 'pointer',
        backgroundColor: isMouseEnter ? 'rgba(29, 26, 45, 0.15)':'',
    }
    const redirectIndexName = props.redirectIndexName
    return (
        <div className="chartInfo" style={chartStyle} 
        onMouseEnter={ () => setMouseEnter(true)}  
        onMouseLeave={ () => setMouseEnter(false)}
        onClick={() => props.setNewIndex(shortNames2IndexV1[redirectIndexName])}>
            <p className="boxHeader" style={{marginBottom:"10px"}}> {props.name} </p>
            <div className="chartInfo-chart">
                    {/* <Chart chartRef={props.chartRef1} typeChart="doughnut" /> */}
                <Chart chartRef={ props.chartRef } typeChart={props.type} />
            </div>
                {/* <div style={{height:"10px", width:"100%"}}></div> */}
        </div>
    )
}

function ChartBlock(props) {
    return (
        <div>
        <div className="chartOverview" style={{flexDirection: props.ismobileBig? "column" : "row", 
                                                marginLeft: props.withoutMenu? "24px": "44px",
                                                marginRight: props.withoutMenu? "24px": "44px"}}>
            <ChartInfo chartRef={props.chartRef1} 
                       redirectIndexName={'total-tx-volume'} 
                       setNewIndex={props.setNewIndex} 
                       type="doughnut"
                       name="Transfers Volume by Balance Address Cohorts"/>
            {!props.ismobile && <div className="chartBox">
                {props.data.map(obj => <BoxInfo obj={obj} setNewIndex={props.setNewIndex} />)}
            </div>}
            <ChartInfo chartRef={props.chartRef2} 
                       redirectIndexName={'moved-coins-age-dist'} 
                       setNewIndex={props.setNewIndex} 
                       type="doughnut2"
                       name="Moved Coins Age Distribution"/>
        </div>
        {props.ismobile && <div className="chartBox" style={{flexDirection: "row", 
                                                            marginTop: "20px", 
                                                            marginLeft: props.withoutMenu? "24px": "44px", 
                                                            marginRight: props.withoutMenu? "24px": "44px"}}>
        {props.data.map(obj => <BoxInfo obj={obj} setNewIndex={props.setNewIndex} />)}
        </div>}
        </div>
    )
}

function TransfersLayout(props) {
    return (
        <div className="overview">
            {/* {props.token !== 'ldo' && <Paywall />} */}
            <RowInfo data={props.firstRow} 
                     ismobile={props.ismobileBig} 
                     withoutMenu={props.withoutMenu} 
                     setNewIndex={props.setNewIndex}/>
            <ChartBlock data={props.secondRow} 
                        token={props.token} 
                        ismobile={props.ismobile} 
                        ismobileBig={props.ismobileBig} 
                        withoutMenu={props.withoutMenu}
                        chartRef1={props.chartRef1}
                        chartRef2={props.chartRef2}
                        setNewIndex={props.setNewIndex}/>

            <div className="table" style={{width: '100%',}}>
                <div className="tableEl" style={{height: '400px', 
                                                marginLeft: props.withoutMenu? "24px" : "44px", 
                                                marginRight: props.withoutMenu? "24px" : "44px", 
                                                marginTop: "40px", 
                                                marginBottom: "44px", 
                                                }}>
                <div className="tableHeader" style={{backgroundColor: '#110D1F', 
                                                     height: '50px',
                                                     alignItems: 'center',
                                                     display: 'flex',
                                                     }}> 
                    <p style={{fontFamily: 'SFProReg', 
                                fontSize: '14px', 
                                color: "white",
                                marginLeft: '24px',
                                }}>
                        Giant Transfers
                    </p>                                    
                </div>
                <DataGrid
                    // components={{
                    //     LoadingOverlay: LinearProgress,
                    // }}
                    ref={props.tableRef}
                    loading={props.tableLoading}
                    rows={props.tableData}
                    columns={columns}
                    pageSize={9}
                    rowHeight={35}
                    headerHeight={35}
                    rowsPerPageOptions={[5]}
                    showCellRightBorder={true}
                    disableSelectionOnClick
                    // checkboxSelection
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                      }
                    sx={{
                        // m: 2,
                        borderRadius: 0,
                        color: 'white',
                        borderWidth: '0px',
                        borderColor: '#EE288A',
                        marginLeft: '0px',
                        marginRight: '0px',
                        backgroundColor: '#110D1F',
                        [`& .${gridClasses.row}.even`]: {
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            borderWidth: '0.0px',
                            borderColor: '#EE288A',
                        },
                        '& .MuiDataGrid-columnHeader': {
                            marginLeft: '14px',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            borderWidth: '0.0px',
                            borderColor: '#EE288A',
                        },
                        '& .MuiDataGrid-main': {
                            borderWidth: '0.0px',
                            borderColor: '#EE288A',
                        },
                        '& .MuiTablePagination-root': {
                            color: 'white',
                        },
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            fontSize: '12px',
                            fontFamily: 'SFProLight',
                            borderWidth: '0.0px',
                            borderColor: '#EE288A',
                        },
                        '& .MuiDataGrid-cell': {
                            fontFamily:'SFProLight',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '14px',
                            color: 'white',
                            // boxSizing: '0.1px',
                            // borderBottom: '1px',
                            marginLeft: '14px',
                            borderWidth: '0.0px',
                            borderColor: '#EE288A',
                        },
                        '& .MuiSvgIcon-root': {
                            color: 'white',
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            // visibility: 'hidden',
                        },
                        '& .datagrid-hash': {
                            // backgroundColor: '#d47483',
                            // color: '#1a3e72'
                            // fontSize: '18px',
                            borderRightWidth: '0.0px',
                            borderRightColor: '#EE288A',
                          },
                        '& .datagrid-wealthTo': {
                            borderRightWidth: '0.0px',
                            borderRightColor: '#EE288A',
                        },
                        '& .datagrid-wealthFrom': {
                            borderRightWidth: '0.0px',
                            borderRightColor: '#EE288A',
                        }, 
                        '& .datagrid-profit-positive': {
                            // backgroundColor: 'rgba(157, 33, 60, 1.0)',
                        },
                        '& .datagrid-profit-negative': {
                            // backgroundColor: 'rgba(110, 199, 224, 1.0)',
                        },
                        '& .datagrid-separator-disable .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                        '& .MuiDataGrid-overlay': {
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        },
                        '& .MuiCircularProgress-root': {
                            color: '#EE288A',
                        }
                    }}
                />
                <div className="tableHeader" style={{backgroundColor: 'transparent', 
                                                     height: '100px',
                                                     alignItems: 'center',
                                                     display: 'flex',
                                                     }}>                                 
                </div>
                </div>
            </div>
        </div>
    )
}

export function Overview(props) {
    //const overviewPagesNames = overviewPages.map(([bigName, smallName]) => bigName)
    //const overviewPagesSNames = overviewPages.map(([bigName, smallName]) => smallName)
    const [selectedPage, setPage] = useState(overviewPages[0].shortName)
    const [pageLayout, setLayout] = useState({...overviewPages[0].layotDataNumbers})

    const [dataGridTable, setDataGridTable] = useState([])
    const [loadingData, setLoading] = useState(false)

    const chartRef1 = useRef()
    const chartRef2 = useRef()

    const tableRef = useRef()

    function setNewPage(page) {
        console.log("NEW page")
        console.log("pgae layout", pageLayout)
        console.log('new setting', overviewPages[0].layotDataNumbers)
        setLayout({...overviewPages[0].layotDataNumbers})
        setPage(page)
    }

    function setPageData(data) {
        let newPageLayout = {...pageLayout}

        Object.entries(pageLayout).forEach(([rowName, rowList]) => {
            console.log("ROW list", rowList)
            rowList.forEach((element, i) => {
                if(element.key === 'token_price' || element.key === 'deployed') {
                    newPageLayout[rowName][i].value = data[element.key]
                } else if(element.key === 'holders' || element.key === 'number_transactions') {
                    newPageLayout[rowName][i].value = addCommas(data[element.key])
                } else {
                    newPageLayout[rowName][i].value =  parseBigValues(data[element.key])
                }
            })
        })
        setLayout(newPageLayout)
    }

    function cleanPageData() {
        let newPageLayout = {...pageLayout}

        Object.entries(pageLayout).forEach(([rowName, rowList]) => {
            console.log("ROW list", rowList)
            rowList.forEach((element, i) => {
                newPageLayout[rowName][i].value = ""
            })
        })
        setLayout(newPageLayout)
    }

    function cleanChart(ref) {
        const chart = ref.current
        console.log("CHAART", chart)
        for (let i=0; i < chart.data.datasets.length;i+=1) {
            chart.data.datasets[i].data = []
        }
        chart.update()
    }

    function updateChart(ref, data) {
        const chart = ref.current
        console.log("UPDATE CHART")
        chart.data.labels = Object.keys(data)
        chart.data.datasets[0].data = Object.values(data)
        chart.update()
    }

    useEffect(() => {
        // console.log("RUN useEffect")
        cleanPageData()
        cleanChart(chartRef1)
        cleanChart(chartRef2)
        getOverviewData(props.token, selectedPage).then((data) => {
            console.log("DATAA", data.transaction_volume)
            setPageData(data)
            updateChart(chartRef1, data.transaction_volume)
            updateChart(chartRef2, data.age_distribution)
        })

        setLoading(true)
        setDataGridTable([])
        getGigantTransfers(props.token).then((data) => {
            setDataGridTable(data)
            setLoading(false)
        })
    }, [props.token, selectedPage]);

    return (
        <div>
            <HeaderOverview pages={overviewPages} selectedPage={selectedPage} selectFunc={setNewPage} withoutMenu={props.withoutMenu}/>
            {(selectedPage === "transfers") && <TransfersLayout firstRow={pageLayout.firstRow} 
                                                                secondRow={pageLayout.middleRow} 
                                                                token={props.token} 
                                                                ismobile={props.ismobile} 
                                                                ismobileBig={props.ismobileBig} 
                                                                withoutMenu={props.withoutMenu}
                                                                chartRef1={chartRef1}
                                                                chartRef2={chartRef2}
                                                                setNewIndex={props.setNewIndex}
                                                                tableData={dataGridTable}
                                                                tableRef={tableRef}
                                                                tableLoading={loadingData}/>}
        </div>
    )
}