import { parseValueForTooltip } from "./utils";

export const data = {
    labels: ['$0-100', '$100-1k', '$1k-10k', '$10k-100k', '$100k-1M', '$10M+'],
    datasets: [
      {
        label: '# of Votes',
        data: [5, 21, 8, 3, 9, 2],
        backgroundColor: [
          'rgba(251, 218, 224, 0.9)',
          'rgba(245, 182, 192, 0.9)',
          'rgba(239, 127, 143, 0.9)',
          'rgba(235, 91, 112, 0.9)',
          'rgba(184, 48, 76, 0.9)',
          'rgba(157, 33, 60, 0.9)',
          'rgba(116, 28, 49, 0.9)',
          'rgba(81, 20, 35, 0.9)',
        ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        // ],
        borderWidth: 0,
      },
    ],
}

export const options =  {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'nearest',
    // axis: 'x',
  },
  plugins: {
    legend: {
      position: 'right',
      labels: {
        color: 'rgb(255, 255, 255)',
      }
    },
    custom_canvas_background_image: false,   // disable plugin 'p1' for this instance
    tooltip: {
      callbacks: {
        label: function titleLabel(context) {
          let label = context.label || '';
          if (label) {
            label += ': $' +  parseValueForTooltip(context.parsed) ;
          }
          return label
        }
      },
  },
  },
  cutout: 33,
  layout: {
    padding: {
      bottom: 20,
      top: 10,
    }
}
}

