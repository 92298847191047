import React from 'react';
import ReactDOM from 'react-dom';
import "./style.css";
import App from "./App";
import {BrowserRouter as Router} from 'react-router-dom';

import posthog from 'posthog-js';

// if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
//   posthog.init('phc_Y72y0JrkRWhfDei7pWzaRBbVjWVKdHC2p7ueVTSUBG5', { 
//       api_host: 'https://app.posthog.com',
//       opt_in_site_apps: true,
//   })
// }

posthog.init('phc_Y72y0JrkRWhfDei7pWzaRBbVjWVKdHC2p7ueVTSUBG5', { 
  api_host: 'https://app.posthog.com',
  opt_in_site_apps: true,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
        password: true, // Highly recommended as a minimum!!
    }
  }
})

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")   
)