import { useEffect, useState, useCallback } from 'react';
import { Auth } from "@supabase/auth-ui-react";
import { useSubscription } from './subscriptionContext';
import { isSubscriptionValid } from './supabaseClient';

import { getSubscriptionTier, getPackageTier, getReferralInfo } from './supabaseClient';

function UserSubscriptionHandler({ supabaseClient }) {
  const { user } = Auth.useUser();
  const { setSubscriptionTier, 
          setFilterPackageLevel, 
          subscriptionValidUntil, 
          setSubscriptionValidUntil, 
          subIsLoading, 
          setSubIsLoading,
          setReferral,
          setRenewable,
          setRefDiscount } = useSubscription();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  console.log('user sub', subIsLoading)

  const updateSubscriptionDetails = useCallback(async () => {
    if (!user) {
      setSubscriptionTier(0);
      setFilterPackageLevel(0);
      setSubscriptionValidUntil('-');
      setSubIsLoading(false);
      setReferral('-');
      setRefDiscount(0);
      setRenewable(false);
      return;
    }

    setSubIsLoading(true);
    console.log('user sub loaded data', )
    try {
      const subscriptionData = await getSubscriptionTier(user.id, supabaseClient);
      // ... Your logic for setting subscription tier and package level ...
      if (subscriptionData) {
        console.log('data subsc ', subscriptionData)
        const { tier_id, valid_until, renewable : renewableValue } = subscriptionData;
        const tier = isSubscriptionValid(valid_until) ? tier_id : 1;
        setSubscriptionTier(tier);
        setSubscriptionValidUntil(valid_until);
        setRenewable(renewableValue)
      } else {
        setSubscriptionTier(1); // Default tier if no data found
        setSubscriptionValidUntil('-'); 
        setRenewable(false)
      }

      // Fetch package data
      const packageData = await getPackageTier(user.id, supabaseClient)
      // console.log('packaage daata', packageData)

      if (packageData) {
        const {tier_id, valid_until } = packageData
        const tier = isSubscriptionValid(valid_until) ? tier_id : 0;
        setFilterPackageLevel(tier);
      } else {
        setFilterPackageLevel(0); // Default level if no data found
      }

      const refInfo = await getReferralInfo(user.id, supabaseClient)

      if(refInfo.referralCode !== '-') {
        setReferral(refInfo.referralCode)
        setRefDiscount(refInfo.discountPercentage)
      }
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    } finally {
      setSubIsLoading(false);
    }
  }, [user?.id]);

  useEffect(() => {
    // Always call updateSubscriptionDetails when the user object changes
    updateSubscriptionDetails();
  }, [user?.id]);

  useEffect(() => {
    if (!user) {
      return; // No need to subscribe if user is not logged in
    }

    const subscriptionSubscription = supabaseClient
      .channel('schema-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'user_subscriptions',
          filter: `user_id=eq.${user.id}`,
        },
        (payload) => {
            console.log("WAS OR NOOOOO ?")
            updateSubscriptionDetails();
        }
      )
      .subscribe();

    const packageSubscription = supabaseClient
      .channel('schema-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'user_packages',
          filter: `user_id=eq.${user.id}`,
        },
        (payload) => {
          updateSubscriptionDetails();
        }
      )
      .subscribe();

    return () => {
      supabaseClient.removeChannel(subscriptionSubscription);
      supabaseClient.removeChannel(packageSubscription);
    };
  }, [user, supabaseClient, updateSubscriptionDetails]);

  return null; // This component doesn't render anything visually
}

export default UserSubscriptionHandler;