export const overviewPages = [
    {
        fullName: "Transfers (Today)",
        shortName: "transfers",
        layotDataNumbers: {
            firstRow: [
                {
                    name: 'Token Price',
                    key: 'token_price',
                    value: '',
                    parse: (val) => "$"+`${val}`,
                    redirectIndex: "realised-price",
                },
                {
                    name: 'Number of Holders',
                    key: 'holders',
                    value: '',
                    parse: (val) => `${val}`,
                    redirectIndex: "number-addresses",
                },
                {
                    name: 'Market Capitalization',
                    key: 'market_cap',
                    value: '',
                    parse: (val) => "$"+`${val}`,
                    redirectIndex: false,
                },
                {
                    name: 'Deployed',
                    key: 'deployed',
                    value: '',
                    parse: (val) => `${val} days ago`,
                    redirectIndex: false,
                },
            ],
            middleRow: [
                {
                    name: 'Total Transfers Volume',
                    key: 'volume',
                    value: '',
                    parse: (val) => "$"+`${val}`,
                    redirectIndex: "total-tx-volume",
                },
                {
                    name: 'Number of Transfers',
                    key: 'number_transactions',
                    value: '',
                    parse: (val) => `${val}`,
                    redirectIndex: "number-tx"
                },
            ],
        }
    },
    // {
    //     fullName: "Profits & Losses",
    //     shortName: "pnl",
    //     layotDataNumbers: {
    //         firstRow: [
    //             {
    //                 name: 'Token price',
    //                 key: 'token_price',
    //                 value: '',
    //                 parse: (val) => "$"+`${val}`,
    //             },
    //             {
    //                 name: 'Number of holders',
    //                 key: 'holders',
    //                 value: '',
    //                 parse: (val) => `${val}`,
    //             },
    //             {
    //                 name: 'Market capitalization',
    //                 key: 'market_cap',
    //                 value: '',
    //                 parse: (val) => "$"+`${val}`,
    //             },
    //             {
    //                 name: 'Deployed',
    //                 key: 'deployed',
    //                 value: '',
    //                 parse: (val) => `${val} days ago`,
    //             },
    //         ],
    //         middleRow: [
    //             {
    //                 name: 'Volume',
    //                 key: 'volume',
    //                 value: '',
    //                 parse: (val) => `$${val}`,
    //             },
    //             {
    //                 name: 'Number of transactions',
    //                 key: 'number_transactions',
    //                 value: '',
    //                 parse: (val) => `${val}`,
    //             },
    //         ],
    //     }
    // },
    // {
    //     fullName: "Holders",
    //     shortName: "holders",
    // },
    // {
    //     fullName: "CEXs",
    //     shortName: "cexs"
    // }
]