import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {parseValueForTooltip, parseBigValues, titleTooltip, scaleX, scalePrice} from './utils.js'
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

const ColorMap2 = ['rgba(236,27,122,255)', 
  'rgba(238,96,144,255)', 
  'rgba(243,137,166,255)', 
  // 'rgba(233,174,194,255)',
  'rgba(253,211,222,255)', 
  'rgba(223,240,216,255)', 
  'rgb(195,227,181)', 
  'rgb(163,213,148)', 
  'rgba(144,200,121,255)', 
  'rgba(112,192,86,255)']

  const LongColorMap = [
    'rgba(39,235,103,255)',
    'rgba(72,239,174,255)',
    'rgba(89,242,223,255)',
    'rgba(104,227,245,255)',
    'rgba(114,197,247,255)',
    'rgba(123,163,249,255)',
    'rgba(129,128,252,255)',
    'rgba(132,92,253,255)',
    'rgba(132,46,254,255)',
    'rgba(153,8,230,255)',
    'rgba(173,16,205,255)',
    'rgba(197,25,181,255)',
    'rgba(216,31,159,255)',
    'rgba(234,38,138,255)',
  ]

// const CEX = [
//   'abcc', 'ascendex', 'bgogo', 'binance', 'bitmex', 'bitmart', 'bitfinex',
//   'bithumb', 'bitstamp', 'bittrex', 'coss.io', 'crex24', 'cobinhood', 'coinmetro',
//   'coinhako', 'coinone', 'compromised', 'crypto.com', 'derivatives', 'eth2_depositor',
//   'ftx', 'fiat_gateway', 'gbx', 'gate.io', 'gemini', 'hitbtc', 'hoo.com', 'hot_wallet',
//   'hotbit', 'huobi', 'kraken', 'kryptono', 'kucoin', 'latoken', 'liquid', 'okex', 'otc', 
//   'options_trading', 'other', 'paribu', 'poloniex', 'remitano', 'shapeshift', 'streamity',
//   'tagz', 'tidex', 'topbtc', 'trade.io', 'upbit', 'yunbi', 'zb.com'
// ]

export const data = {
    // labels: [],
    datasets: [
      {
        label: 'Price',
        type: 'line',
        data: [],
        fill: false,
        borderColor: 'rgb(255, 255, 255)',
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 1,
        backgroundColor: 'rgb(255, 255, 255)',
        yAxisID: 'y1',
        order: 1,
      },
      {
        label: 'Balance',
        type: 'line',
        fill: { target: {value: 0}},
        data: [],
        borderColor: 'rgb(40,226,238)',
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 2,
        backgroundColor: 'rgb(100,226,238)',
        yAxisID: 'y',
        order: 2,
      }
    ],
};


export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
                overScaleMode: 'y',
            },
            pan: {
                enabled: true,
                overScaleMode: 'y',
            },
            limits: {
              x: {minRange: 5},
              y: {min: 0,},
              y1: {enabled: false}
            }
        },
        legend: {
            display: false,
            position: 'top',
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                    label += ': ' +  parseValueForTooltip(context.parsed.y) ;
                }
                return label;
              },
              title: titleTooltip,
            },
        },
    },
    scales: {
        x: scaleX,
        y: {
          position: 'left',
          grid: {
              display: false,
          },
          ticks: {
            beginAtZero: true,
            callback: function(val) {
              return parseBigValues(val) 
            },
          },
          type: 'linear',
          stacked: true,
          color: '#9AA2AA',
          font: {
            size: 12,
            family: 'SFProLight',
          },
      },
      y1: scalePrice,
    },
  };