import { parseValueForTooltip } from "./ChartSettings/utils"


export function ShowDollar(props) {
    // if(props.value !== '') {
    if( typeof props.value !== 'string') {     
    const positiveValue = (props.value >= 0)

    const posColor = props.posColor ? props.posColor : '#28E2EE'
    const negColor = props.negColor ? props.negColor :'#EE288A'

    const styleText = {
        color: props.withoutColor? 'white' : (positiveValue ? posColor : negColor),
        marginLeft:'auto',
        marginRight: '33px',
        marginTop: "0px", 
        marginBottom: "0px",
    }

    if(props.margin) {
        styleText['marginRight'] = props.margin
        styleText['marginLeft'] = props.margin
    }

    if((props.value !== undefined) && (props.value !== '')) {
        // console.log("VALUE", props.value)
        return (
            <p style={styleText}> {props.withoutColor? "": (positiveValue? "+": "-")}{props.withoutDollar? "":"$"}{ parseValueForTooltip(Math.abs(props.value.toFixed(2)))}{props.percent? "%":""}</p>
        )
    }
    return (<p style={styleText}>No transfers</p>)
    }
    return <p>{props.value}</p>
}

export function ShowDollarPnl(props) {
    if( typeof props.value !== 'string') {      
        const positiveValue = (props.value >= 0)

        const posColor = props.posColor ? props.posColor : 'rgb(172, 209, 251)'
        const negColor = props.negColor ? props.negColor : 'rgb(238, 40, 138)'

        const styleText = {
            color: props.withoutColor? 'white' : (positiveValue ? posColor : negColor),
            margin: 0,
        }
        if((props.value !== undefined) && (props.value !== '')) {
            // console.log("VALUE", props.value)
            return (
                <p style={styleText}> {(positiveValue? "+": "-")}{props.withoutDollar? "":"$"}{ parseValueForTooltip(Math.abs(props.value.toFixed(2)))}{props.percent? "%":""}</p>
            )
        }
        return (<p style={styleText}>No transfers</p>)
        }
    return <p>{props.value}</p>
}

function ShowDate(props) {
    const styleText = {
        color: 'white',
        marginLeft:'auto',
        marginRight: '33px',
        marginTop: "0px", 
        marginBottom: "0px",
    }
    if ((props.value !== undefined) && (props.value !== '')) {
    // if(props.value !== undefined) {
        return (
            <p style={styleText}> {props.value.split('T')[0]}</p>
        )
    }
    return (<p style={styleText}></p>)
}

export const overviewPages = [
    {
        fullName: "Balance",
        shortName: "balance",
        layotDataNumbers: [

        ],
    },
    {
        fullName: "Profits & Losses",
        shortName: "pnl",
        layotDataNumbers: [

        ],
    },
    {
        fullName: "Trades",
        shortName: "trades",
        layotDataNumbers: [

        ],
    }
]

export const balanceLayout = {
    left: [
        {
            header: 'OVERVIEW',
            fields: [
                {name: 'ERC20 Tokens in Wallet', key: 'tokens_number', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true} withoutDollar={true}/>) }},
                {name: 'ERC20 Net Worth ($)', key: 'balance_dollar', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true}/>) }},
                {name: '7D Change ($)', key: 'nupl', formatting: (val) => { return (<ShowDollar value={val} />) }},
                {name: '30D Change ($)', key: 'realised_price', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true}/>) }}
            ]
        },
    ],
}

export const portfolioLayout = {
    left: [
        {
            header: 'HOLDINGS',
            fields: [
                {name: 'Balance', key: 'balance', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true} withoutDollar={true}/>) }},
                {name: 'Balance($)', key: 'balance_dollar', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true}/>) }},
                {name: 'Unrealised P&L', key: 'nupl', formatting: (val) => { return (<ShowDollar value={val} />) }},
                {name: 'Average Purchase Price', key: 'realised_price', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true}/>) }}
            ]
        },
        {
            header: 'PROFITS & LOSSES OVER TIME',
            fields: [
                {name: 'Profits', key: 'profits', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true}/>) }},
                {name: 'Losses', key: 'losses', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true}/>) }},
                {name: 'Net P&L', key: 'nrpl', formatting: (val) => { return (<ShowDollar value={val}/>) }},
                {name: 'ROI', key: 'roi',  formatting: (val) => { return (<ShowDollar value={val} percent={true}  withoutDollar={true}/> ) }},
                // {name: 'Average P&L', key: 'nrpl_average',  formatting: (val) => { return (<ShowDollar value={val}/> ) }},
            ]
        }
    ],
    right: [
        {
            header: 'VOLUME OVER TIME',
            fields: [
                {name: 'Received', key: 'volume_received', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true} withoutDollar={true}/>) }},
                {name: '', key: 'volume_dollar_received', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true}/>) }},
                {name: 'Sent', key: 'volume_sent', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true} withoutDollar={true}/>) }},
                {name: '', key: 'volume_dollar_sent', formatting: (val) => { return (<ShowDollar value={val} withoutColor={true}/>) }},
            ]
        },
        {
            header: 'NUMBER OF TRANSFERS',
            fields: [
                {name: 'Received', key: 'tx_number_received'},
                {name: 'Sent', key: 'tx_number_sent'}
            ]
        },
        {
            header: 'FIRST TRANSFER RECEIVED',
            fields: [
                {name: 'Date', key: 'first_transfer_received_datetime', formatting: (val) => { return (<ShowDate value={val}/>)}},
                {name: 'Days after token deployed', key:'delta_first_tx_from_deployed '}
            ]
        }
    ],
}