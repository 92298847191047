import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
//import watermarkPlugin from 'chartjs-plugin-watermark';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {parseBigValues, titleLabel, titleTooltip, scaleX, scalePrice} from './utils.js'

  import imagesrc from  "../../assets/watermark.png"
  const image = new Image();
  //image.src = 'https://www.chartjs.org/img/chartjs-logo.svg';
  image.src = imagesrc  
    //   image.width = "10px"
    //   image.height = "10px" 
  
  
  // const plugin = {
  //   id: 'custom_canvas_background_image',
  //   beforeDraw: (chart) => {
  //     if (image.complete) {
  //       image.width = 451
  //       image.height = 176
  //       console.log("img", image.width, image.height)
  //       const ctx = chart.ctx;
  //       const {top, left, width, height} = chart.chartArea;
  //       const x = left + width / 2 - image.width / 2;
  //       const y = top + height / 2 - image.height / 2;
  //       ctx.globalAlpha = 0.25;
  //       ctx.drawImage(image, x, y, 451, 176);
  //       ctx.globalAlpha = 1.0;
  //     } else {
  //       image.onload = () => chart.draw();
  //     }
  //   }
  // };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
    // plugin,
    //watermarkPlugin,
  );

export const data = {
    // labels: [],
    datasets: [
        {
            label: 'Price',
            data: [],
            fill: false,
            borderColor: 'rgb(255, 255, 255)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            backgroundColor: 'rgb(255, 255, 255)',
            yAxisID: 'y1',
        },
        {
            label: 'Losses',
            data: [],
            fill: false,
            borderColor: 'rgb(243, 39, 136)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            backgroundColor: 'rgb(243, 39, 136)',
            yAxisID: 'y',
          },
        {
            label: 'Profits',
            data: [],
            fill: false,
            borderColor: 'rgb(40,226,238)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            backgroundColor: 'rgb(40,226,238)',
            yAxisID: 'y',
        },
    ],
};


export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    // watermark: {
    //     image: "curve.png",
    //     x: 20,
    //     y: 10,
    //     width: 53,
    //     height: 60,
    //     opacity: 0.25,
    //     alignX: "left",
    //     alignY: "top",
    //     alignToChartArea: true,
    //     position: "back"
    // },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
                overScaleMode: 'y',
            },
            pan: {
                enabled: true,
                overScaleMode: 'y',
            },
            limits: {
                x: {minRange: 5},
            }
        },
        legend: {
            display: true,
            position: 'top',
        },
        title: {
            display: false,
        },
        tooltip: {
            // mode: 'index',
            callbacks: {
              label: titleLabel,
              title: titleTooltip,
            },
        },
    },
    scales: {
        x: scaleX,
        y: {
            position: 'left',
            grid: {
                display: false,
            },
            type: 'logarithmic',
            ticks: {
              callback: function(val) {
                return "$" + parseBigValues(val) 
              },
              font: {
                size: 12,
                family: 'SFProLight',
              },
              color: '#9AA2AA',
            },
            // type: 'logarithmic',
        },
        y1: scalePrice,
    },
    // plugin,
  };