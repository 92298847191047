import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {parseValueForTooltip, parseBigValues, titleTooltip, scaleX, scalePrice} from './utils.js'
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

const ColorMap2 = ['rgba(236,27,122,255)', 
  'rgba(238,96,144,255)', 
  'rgba(243,137,166,255)', 
  // 'rgba(233,174,194,255)',
  'rgba(253,211,222,255)', 
  'rgba(223,240,216,255)', 
  'rgb(195,227,181)', 
  'rgb(163,213,148)', 
  'rgba(144,200,121,255)', 
  'rgba(112,192,86,255)']

const ColorMap4 = [
  'rgb(243,39,211)',
  'rgba(225,76,216,255)',
  'rgba(201,108,221,255)',
  'rgba(190,123,224,255)',
  'rgba(164,149,226,255)',
  'rgba(141,171,230,255)',
  'rgba(115,191,234,255)',
  'rgba(84,210,236,255)'
]

const ColorMap3 = [
  // 'rgb(197,39,116)',
  'rgba(228,56,142,255)',
  'rgba(202,96,164,255)',
  'rgba(180,126,179,255)',
  'rgba(156,152,194,255)',
  'rgba(128,178,209,255)',
  'rgba(107,194,220,255)',
  'rgba(90,215,232,255)',
  'rgba(115,230,240,255)',
  'rgba(140,245,250,255)',
]

export const data = {
    labels: [],
    datasets: [
        {
          label: 'Price',
          type: 'line',
          data: [],
          fill: false,
          borderColor: 'rgb(255, 255, 255)',
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: 'rgb(255, 255, 255)',
          yAxisID: 'y1',
          order: 1,
        },
        {
          label: '<1d',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap3[0],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap3[0],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '1d-1w',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap3[1],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap3[1],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '1w-1m',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap3[2],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap3[2],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '1m-3m',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap3[3],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap3[3],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '3m-6m',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap3[4],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap3[4],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '6m-1y',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap3[5],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap3[5],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '1y-2y',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap3[6],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap3[6],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '2y-3y',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap3[7],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap3[7],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '3y-5y',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap3[8],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap3[8],
          yAxisID: 'y',
          order: 2,
        },
    ],
};


export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
                overScaleMode: 'y',
            },
            pan: {
                enabled: true,
                overScaleMode: 'y',
            },
            limits: {
              x: {minRange: 5},
              y: {min: 0,},
              y1: {enabled: false}
            }
        },
        legend: {
            display: true,
            position: 'top',
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                    label += ': ' +  parseValueForTooltip(context.parsed.y) ;
                }
                return label;
              },
              title: titleTooltip,
            },
        },
    },
    scales: {
        x: scaleX,
        y: {
          position: 'left',
          grid: {
              display: false,
          },
          ticks: {
            beginAtZero: true,
            callback: function(val) {
              return parseBigValues(val) 
            },
          },
          type: 'linear',
          stacked: true,
          color: '#9AA2AA',
          font: {
            size: 12,
            family: 'SFProLight',
          },
      },
      y1: scalePrice,
    },
  };