import { React, useState, useEffect, useRef} from "react";

import "./pricing.css";
import rocket from "../assets/rocket_pink.png"

import { insertUserSubscription, tryToBuy, verifyBeforePayment } from "./supabaseClient";
import { Auth } from "@supabase/auth-ui-react";

import { usePopup } from './popupContext';
import { useSubscription } from "./subscriptionContext";

import { useNavigate } from "react-router-dom";

import pay from './PayWidget';

import { v4 as uuidv4 } from "uuid";

import posthog from 'posthog-js';

import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51PHAa1JNBjqBZkQqKIoYwE4aNvArNHS6eZ0cTJOqBPn0v6T8afngQPqIOoBgQSwioqbWjryivjBULXKicl6MOzIs00eH43dDTg');
// const stripePromise = loadStripe('pk_test_51PHAa1JNBjqBZkQqwjKXqgMw1LbvipGGFU36X6wHkrlh21iHruxRSPY0r28JdAtnY5hzqTdmSopGu0oVOjhnIUE800WIHFwhN8');

function sendPostHogEvent(eventName, properties = {}) {
    posthog.capture(eventName, properties);
}

export function Pricing() {
    const [group, selectGroup] = useState(0)

    // <div className="price-section" style={{position:'absolute', top: '0px', left: '0px', width: '200px', height: '200px', backgroundColor: 'red'}}>
    //     1000
    // </div>
    return (
        <section className="price-section">
            <div className="price-header" style={{marginBottom: '30px'}}>
                <h1 className="gradientColor2"> API Beta Pricing </h1>
                <p className="subheader" > Backtest, explore, build your own solutions and more... <br></br> using CherryPick API</p>
            </div>
            {/* <div className="price-header" style={{marginBottom: '0px'}}>
                <p className="subheader" > Select your group </p>
            </div> */}
            <div className="type-selector">
                <div className="selector-wrapper">
                    <p className={`option ${group == 0 ? 'selected':''}`} onClick={() => {selectGroup(0)}}>For whitelisted</p>
                    <p className={`option ${group == 1 ? 'selected':''}`} onClick={() => {selectGroup(1)}}>For others</p>
                </div>
            </div>
            <div className="price-cards-table">
                <div className="price-first-row">
                    <div className="card">
                        <div className="card-header">
                            <h1> Standard </h1>
                            {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>$29</span></h3>
                            </div>}
                            <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>{group == 0 ? '$19': '$29'}</h1>
                                <p>per month</p>
                            </div>
                        </div>
                        <div className="card-description">
                            <p>Get access via API to on-chain metrics for ERC20 coins you see on the platform.</p>
                            <p style={{margin:0}}>Supported tokens:</p>
                            <ul>
                                <li> <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-assets' target='_blank'>All ERC-20 tokens</a> listed on the platform</li>
                            </ul>
                            <p style={{margin:0}}> All <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'> on-chain metrics</a>:</p>
                            <ul>
                                <li>up to day resolution</li>
                                <li>full historical data</li>
                            </ul>
                            <p style={{margin:0}}> Supported address cohorts:</p>
                            <ul>
                                <li>only <a style={{color: '#DD4088'}}  href='https://docs.cherry-pick.xyz/api/address-cohorts' target='_blank'>total cohort</a></li>
                            </ul>
                            {/* <p style={{margin:0}}>Leaderboards:</p>
                            <ul>
                                <li>limited to 25 addresses per query </li>
                            </ul> */}
                        </div>
                        <div className="card-button"  onClick={event => window.open('https://docs.cherry-pick.xyz/api/payment-instruction', '_blank')}>
                            <p>BUY</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h1> Pro </h1>
                            {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>$99</span></h3>
                            </div>}
                            <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}> {group == 0 ? '$59': '$99'}</h1>
                                <p>per month</p>
                            </div>
                        </div>
                        <div className="card-description" style={{marginBottom: '40px'}}>
                            <p>Supercharge your trading using unique address cohorts and data in hour resolution!</p>
                            <p style={{}}>Everything in Standard, plus</p>
                            <p style={{margin:0}}> All <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'> on-chain metrics</a>:</p>
                            <ul>
                                <li>up to hour resolution  </li>
                                {/* <li>prices in minute resolution</li> */}
                            </ul>
                            <p style={{margin:0}}> <a style={{color: '#DD4088'}}  href='https://docs.cherry-pick.xyz/api/address-cohorts' target='_blank'>Unique address cohorts</a>:</p>
                            <ul>
                                <li>balance cohorts</li>
                                <li>age cohorts</li>
                                <li>centralized exchanges/smart contracts</li>
                            </ul>
                            {/* <p style={{margin:0}}>Leaderboards:</p>
                            <ul>
                                <li>limited to 100 addresses per query </li>
                            </ul>
                            <p style={{margin:0}}>Query by specific wallet address:</p>
                            <ul>
                                <li>trades history for supported tokens</li>
                                <li>profits&losses for each trades</li>
                            </ul> */}
                        </div>
                        <div className="card-button" onClick={event => window.open('https://docs.cherry-pick.xyz/api/payment-instruction', '_blank')}>
                            <p>BUY</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h1> BRO </h1>
                            {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>$999</span></h3>
                            </div>}
                            <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>{group == 0 ? '$599': '$999'}</h1>
                                <p>per month</p>
                            </div>
                        </div>
                        <div className="card-description">
                            <p>Get access to the highest possible time resolution for on-chain metrics to see any even the smallest market fluctuations.</p>
                            <p style={{}}>Everything in Pro, plus</p>
                            <p style={{margin:0}}> All <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'> on-chain metrics</a>:</p>
                            <ul>
                                <li>up to block resolution </li>
                                {/* <li>prices in minute resolution</li> */}
                            </ul>
                            <p style={{margin:0}}>Friendship with CherryPick:</p>
                            <ul>
                                <li>you will become our BRO</li>
                            </ul>
                            {/* <p>Opportunity to become friends with cherrypick

Are you saying that we are corrupt? We're proud to say yes!</p> */}
                        </div>
                        <div className="card-button" onClick={event => window.open('https://docs.cherry-pick.xyz/api/payment-instruction', '_blank')}>
                            <p>BUY</p>
                        </div>
                    </div>
                </div>
                <div className="price-first-row">
                    <div className="card enterprise" >
                        <div className="enterprise-text" >
                            <div className="card-header" style={{alignItems: 'start'}}>
                                <h1> Enterprise </h1>
                                {/* <div className='price-month'>
                                    <h1>Unlimited</h1>
                                    <p>per month</p>
                                </div> */}
                            </div>
                            <div className="card-description" >
                            <p style={{marginBottom:0}}>For funds and crypto companies that are interested in:</p>
                            <ul>
                                <li>Gaining access to unique on-chain data</li>
                                <li>Conducting research or creating their own solutions</li>
                                <li>Developing on-chain analytics for your Web3 product/blockchain/coin</li>
                            </ul>
                            <p >Our team will be glad to collaborate with you, leveraging our expertise to create the optimal solution!</p>
                            </div>
                            <div className="card-button button-enterprise" onClick={event => window.open("https://docs.google.com/forms/d/e/1FAIpQLSdJlwt6B7R2VJVxigu4RmfBkpTLgeVUd9tHN6MrjVgKeKh5nw/viewform", '_blank')}>
                                <p>CONTACT US</p>
                            </div>
                        </div>
                        <div className="enterprise-img" style={{width: '50%'}}>
                            <div className="img-container">
                                <img className="img-img" src={rocket} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="price-header" style={{marginTop: '60px', marginBottom: '40px', height: '60px'}}>
                <h2 className="faq" > Frequently Asked Questions </h2>
            </div>
            <div className="price-first-row">
            <div className="card faq-description" style={{ maxWidth: 'calc(2016px - 6em)', textAlign: 'left', padding: '0em'}}>
                <div className="card-header" style={{borderBottom: '1.5px solid black'}}>
                    <h2 className="faq-header" > How do you accept payment?</h2>
                </div>
                <div className="card-description">
                    <p>
                    We are currently accepting payment in crypto. To pay, you leave an application, and we send you instructions for payment. After payment confirmation, we send you an API key that you can use for API queries.

We apologize for the inconvenience with the payment. In time we will have payment in fiat currency.  But we hope that the received data will compensate your inconvenience.
                    </p>
                </div>
                <div className="card-header" style={{borderBottom: '1.5px solid black'}}>
                    <h2 className="faq-header"> Is my subscription automatically renewed?</h2>
                </div>
                <div className="card-description">
                    <p>No. In order for your subscription to be renewed, you must pay for the next month and email us at info@cherrypick.xyz. After that your subscription will be extended.</p>
                </div>
            </div>
            </div>
            <div style={{width: '100%', height: '100px'}}></div>
        </section>
    )
}



export function PricingOnchain(props) {
    const navigate = useNavigate();

    const { subscriptionTier } = useSubscription();

    const [group, selectGroup] = useState(1)

    const { setPopup, showConfirmation } = usePopup();

    const { user, session } = Auth.useUser();

    const validUntil = new Date();
    validUntil.setMonth(validUntil.getMonth() + 1);
    // <div className="price-section" style={{position:'absolute', top: '0px', left: '0px', width: '200px', height: '200px', backgroundColor: 'red'}}>
    //     1000
    // </div>
    return (
        <section className="price-section" style={{background: 'none'}}>
            <div className="price-header" style={{marginBottom: '30px'}}>
                <h1 className="gradientColorWhite"> Pricing </h1>
                <p className="subheader" style={{color: 'white'}} > Get access to the most comprehensive on-chain data suite <br></br> on the widest set of supported tokens using CherryPick</p>
            </div>
            {/* <div className="price-header" style={{marginBottom: '0px'}}>
                <p className="subheader" > Select your group </p>
            </div> */}
            <div className="type-selector">
                <div className="selector-wrapper">
                    <p className={`option-white ${group == 0 ? 'selected':''}`} style={{color: group !== 0 ? 'white' : ''}} onClick={() => {selectGroup(0)}}>Bill Yearly (Save up to 35%)</p>
                    <p className={`option-white ${group == 1 ? 'selected':''}`} style={{color: group !== 1 ? 'white' : ''}} onClick={() => {selectGroup(1)}}>Bill Monthly</p>
                </div>
            </div>
            <div className="price-cards-table">
                <div className="price-first-row">

                <div className="black card" style={{border: "2px solid", borderColor: (subscriptionTier === 0) || (subscriptionTier === 1)  ? '#ACD1FB' : '#110D1F'}}>
                        <div className="card-header white">
                            <h1> Demo </h1>
                            {/* {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>Free</span></h3>
                            </div>} */}
                            {/* <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>{group == 0 ? '$299': '$399'}</h1>
                                <p>per month</p>
                            </div> */}
                            <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>$0</h1>
                                <p>per month</p>
                            </div>
                        </div>
                        <div className="card-description white" style={{marginBottom: '40px'}}>
                            <p>Get access to all on-chain metrics for the $LDO asset</p>
                            <p>This plan includes all Standard features but for only one asset</p>
                            <p>You already have it, just start:</p>
                            
                            {/* <p>Opportunity to become friends with cherrypick

Are you saying that we are corrupt? We're proud to say yes!</p> */}
                        </div>
                        <button data-attr="explore-demo" className="card-button-black" onClick={event => window.open('https://app.cherry-pick.xyz/ldo', '_blank')}>
                            <p>EXPLORE</p>
                        </button>
                    </div>
                    <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 2 ? '#ACD1FB' : '#110D1F'}}>
                        <div className="card-header">
                            <h1 className="white"> Standard </h1>
                            {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>$29</span></h3>
                            </div>}
                            <div className='price-month white'>
                                <h1 style={{marginTop: '10px'}}>{group == 0 ? '$19': '$29'}</h1>
                                <p>per month paid {group == 0 ? ' annually' : ' monthly'}</p>
                            </div>
                        </div>
                        <div className="white card-description">
                            <p>Get access to on-chain metrics for the biggest list of ERC20 tokens.</p>
                            <p style={{margin:0}}>Supported tokens:</p>
                            <ul>
                                <li> All ERC-20 tokens listed on the <a style={{color: '#DD4088'}} href='https://app.cherry-pick.xyz/ldo' target='_blank'> platform</a></li>
                            </ul>
                            <p style={{margin:0}}> All on-chain metrics:</p>
                            <ul>
                                <li>up to hour resolution</li>
                            </ul>
                            <p style={{margin:0}}> <a style={{color: '#DD4088'}}  href='https://docs.cherry-pick.xyz/api/address-cohorts' target='_blank'>Unique address cohorts</a>:</p>
                            <ul>
                                <li>balance cohorts</li>
                                <li>age cohorts</li>
                                <li>centralized exchanges/smart contracts</li>
                            </ul>
                            {/* <p style={{margin:0}}>Leaderboards:</p>
                            <ul>
                                <li>limited to 25 addresses per query </li>
                            </ul> */}
                            <p style={{ margin: 0 }}>Bonus:</p>
                            <ul>
                                <li>+300 altcoins with base on-chain metrics</li>
                                <li>Overvalued & undervalued table for 400 altcoins</li>
                            </ul>
                        </div>
                        {subscriptionTier !== 2 && <button data-attr={`pay-standard${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => 
                                    tryToBuy(user, 2, validUntil, props.supabaseClient).then((data) => {
                                        if(data.redirectPath) {
                                            navigate(data.redirectPath)
                                        }
                                        if(data.show) {
                                            // setPopup({
                                            //     show: data.show,
                                            //     header: data.header,
                                            //     messages: data.messages,
                                            // });
                                            showConfirmation(
                                                data.header, 
                                                data.messages,
                                                [
                                                  { label: 'Close', onClick: () => {} } // No-op for "No" button
                                                ]
                                            );
                                        }
                                    })
  }>
                            <p> PAY BY CARD</p>
                        </button>}
                        {subscriptionTier === 2 && <div className="card-button-selected">

                            <p> CURRENT PLAN</p>
                        </div>}
                    </div>
                    
                    <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 3 ? '#ACD1FB' : '#110D1F'}}>
                        <div className="card-header white">
                            <h1> Professional </h1>
                            {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>$99</span></h3>
                            </div>}
                            <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>{group == 0 ? '$69': '$99'}</h1>
                                <p>per month paid {group == 0 ? ' annually' : ' monthly'}</p>
                            </div>
                        </div>
                        <div className="card-description white" style={{marginBottom: '40px'}}>
                            <p>Get access to the highest possible time resolution for on-chain metrics to see any even the smallest market fluctuations.</p>
                            <p style={{}}>Everything in Standard, plus</p>
                            <p style={{margin:0}}> All on-chain metrics:</p>
                            <ul>
                                <li>up to 10 minute resolution </li>
                                {/* <li>prices in minute resolution</li> */}
                            </ul>
                            <p style={{margin:0}}>Friendship with CherryPick:</p>
                            <ul>
                                <li>Join our private community and and influence the development of the platform</li>
                                <li>Early access to new analytics tools and insights</li>
                            </ul>
                            {/* <p>Opportunity to become friends with cherrypick

Are you saying that we are corrupt? We're proud to say yes!</p> */}
                        </div>
                        {subscriptionTier !== 3 &&<button data-attr={`pay-pro${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => 
                                    tryToBuy(user, 3, validUntil, props.supabaseClient).then((data) => {
                                        if(data.redirectPath) {
                                            navigate(data.redirectPath)
                                        }
                                        if(data.show) {
                                            showConfirmation(
                                                data.header, 
                                                data.messages,
                                                [
                                                  { label: 'Close', onClick: () => {} } // No-op for "No" button
                                                ]
                                            );
                                        }
                                    })} > 
                            <p>PAY BY CARD</p>
                        </button>}
                        {subscriptionTier === 3 && <div className="card-button-selected">
                            <p> CURRENT PLAN</p>
                            </div>}
                    </div>
                </div>
                
            </div>
            <div className="price-header white" style={{marginTop: '60px', marginBottom: '40px', height: '60px'}}>
                <h2 className="faq" style={{textAlign: 'center'}}> Frequently Asked Questions </h2>
            </div>
            <div className="price-first-row">
            <div className="card faq-description black" style={{ maxWidth: 'calc(2016px - 6em)', textAlign: 'left', backgroundColor: 'transparent', padding: '0em'}}>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header" > How do you accept payment?</h2>
                </div>
                <div className="card-description  white">
                    <p>We accept debit/credit cards, including VISA and Mastercard. We use Stripe payment system to accept payments, which guarantees safety and security of payments!</p>
                </div>
                <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Is my subscription automatically renewed?</h2>
                </div>
                <div className="card-description  white">
                    <p>No. When your subscription ends, you will be automatically downgraded to the free plan.</p>
                </div>
                <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Is a trial available for your subscription plans?</h2>
                </div>
                <div className="card-description  white">
                    <p>No, however, while on the free Demo plan you can access the full functionality of the platform for one $LDO asset. This will allow you to familiarise yourself with all the functionality of the Platform.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> How can I cancel my subscription?</h2>
                </div>
                <div className="card-description  white">
                    <p>Your paid subscription will end automatically after the expiry date. Before that point, you cannot cancel your subscription.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> How do I get access to the API?</h2>
                </div>
                <div className="card-description  white">
                    <p>The API is only available on the Professional plan. You can read the API documentation <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'> here</a>.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Have another question?</h2>
                </div>
                <div className="card-description white">
                    <p>We would love to help - just email us at info@cherry-pick.xyz</p>
                </div>
            </div>
            </div>
            <div style={{width: '100%', height: '100px'}}></div>
        </section>
    )

    return (
        <Pricing />
    )
}

export function PricingTest(props) {
    const navigate = useNavigate();

    const [group, selectGroup] = useState(1)

    const { user, session } = Auth.useUser();

    const { subscriptionTier, referralCode, refDiscount } = useSubscription();

    const { setPopup, showConfirmation } = usePopup();

    const monthlyPrice = {
        0: 23,
        1: 29,
    }
    const monthNumbers = {
        0: '12',
        1: '1',
    }

    const validUntil = new Date();
    validUntil.setMonth(validUntil.getMonth() + 1);
    
    const handlePayment = (value) => {
        if(!user) {
            console.log("USER NOt login")
            return
        }
        const orderId = uuidv4();
        console.log("orderId", `${orderId}`)
        // console.log("userId", `${user.id}`)
        pay({
          serviceId: "22977",
          publicKey: "045ab0082cc98305d0d02439ad50591fa78dcd30e3d53eda4edadebcf77c5ad778fdae36a2a91398f1ff4db0800557d637133fa89e93ee0f4a9ff209998eb3c74e",
        //   serviceId: "22789",
        //   publickKey: "04edebf5c58e3c79710fe0b3cbab82330ab87ea8b4138c6ca849a11f72a4668ba6cba6d49d55989e5593e22ae24d33720120aa847e0e9e21e5c99cdc30dc8ce04a",
          orderId: `${orderId}`,
          userId: user.id,
          amount: `${value*86}`,
          currency: "RUB",
          description: `Standard Plan - Access to essential analytics features. Full support included.`,
          monthNumber: monthNumbers[group],
          email: user.email,
          priceAmount: value*86,
          onSuccess: function(res) {
            sendPostHogEvent('payment-successful',{
                subscriptionTier,
                annual: group === 0,
                amount: monthlyPrice[group]*(group === 0 ? 12:1),
            });
            if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on success
            console.log("onSuccess from shop", res); // Log success response
          },
          onError: function(res) {
            sendPostHogEvent('payment-error',{
                subscriptionTier,
                annual: group === 0,
                amount: monthlyPrice[group]*(group === 0 ? 12:1),
                errorMessage: res.message,
            });
            // if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on error
            console.log("onFail from shop", res); // Log error response
          },
          onClose: function(res) {
            sendPostHogEvent('payment-close',{
                subscriptionTier,
                annual: group === 0,
                amount: monthlyPrice[group]*(group === 0 ? 12:1),
            });
            console.log("onClose from shop", res); // Log when widget is closed
          }
        });
    };
    // <div className="price-section" style={{position:'absolute', top: '0px', left: '0px', width: '200px', height: '200px', backgroundColor: 'red'}}>
    //     1000
    // </div>
    return (
        <section className="price-section" style={{background: 'none'}}>
            <div className="price-header" style={{marginBottom: '30px'}}>
                <h1 className="gradientColorWhite"> Pricing </h1>
                <p className="subheader" style={{color: 'white'}} > Get access to the most comprehensive on-chain data suite <br></br> on the widest set of supported tokens using CherryPick</p>
            </div>
            {referralCode !== '-' && <div className="price-header" style={{marginBottom: '30px'}}>
                <p className="subheader" style={{color: 'white'}} > Thanks to the referral code <span style={{color: '#B3D0F7'}}>{`${referralCode}`}</span>, you have a <span style={{color: '#B3D0F7'}}>{`${refDiscount}`}% </span> discount on purchasing the standard package.</p>
                {/* for the next 24 hours */}
            </div>}
            {/* <div className="price-header" style={{marginBottom: '0px'}}>
                <p className="subheader" > Select your group </p>
            </div> */}
            {/* <div className="type-selector" style={group===0 ? {marginBottom: 0}:{}}>
                <div className="selector-wrapper">
                    <p className={`option-white ${group == 0 ? 'selected':''}`} style={{color: group !== 0 ? 'white' : ''}} onClick={() => {selectGroup(0)}}>Bill Yearly (-20%)</p>
                    <p className={`option-white ${group == 1 ? 'selected':''}`} style={{color: group !== 1 ? 'white' : ''}} onClick={() => {selectGroup(1)}}>Bill Monthly</p>
                </div>
            </div> */}
            { group===0 && <p style={{color: 'white', alignItems:'center', textAlign: 'center', marginBottom: '70px', fontFamily:"SFProReg", fontSize: '20px'}}>Save up to 70$</p>}
            <div className="price-cards-table">
                <div className="price-first-row">

                <div className="black card" style={{border: "2px solid", borderColor: (subscriptionTier === 0) || (subscriptionTier === 1)  ? '#ACD1FB' : '#110D1F'}}>
                        <div className="card-header white">
                            <h1> Demo </h1>
                            {/* {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>Free</span></h3>
                            </div>} */}
                            {/* <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>{group == 0 ? '$299': '$399'}</h1>
                                <p>per month</p>
                            </div> */}
                            <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>$0</h1>
                                <p>per month</p>
                            </div>
                        </div>
                        <div className="card-description white" style={{marginBottom: '40px'}}>
                            <p>Get access to all on-chain metrics for the $LDO asset</p>
                            <p>This plan includes all Standard features but for only one asset</p>
                            <p>You already have it, just start:</p>
                            
                            {/* <p>Opportunity to become friends with cherrypick

Are you saying that we are corrupt? We're proud to say yes!</p> */}
                        </div>
                        <button data-attr="explore-demo" 
                                className="card-button-black" 
                                onClick={event => {
                                        sendPostHogEvent('explore-button-clicked',{
                                            subscriptionTier,
                                            annual: group === 0,
                                            amount: monthlyPrice[group]*(group === 0 ? 12:1),
                                        } 
                                        )
                                        window.open('https://app.cherry-pick.xyz/ldo', '_blank')
                                    }}>
                            <p>EXPLORE</p>
                        </button>
                    </div>
                    <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 2 ? '#ACD1FB' : '#110D1F'}}>
                        <div className="card-header">
                            <h1 className="white"> Standard </h1>
                            {referralCode !== '-' && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>${monthlyPrice[group]}</span></h3>
                            </div>}
                            <div className='price-month white'>
                                <h1 style={{marginTop: '10px'}}>${Math.ceil(monthlyPrice[group] * (100 - refDiscount)/100)}</h1>
                                <p>per month paid {group == 0 ? ' annually' : ' monthly'}</p>
                            </div>
                        </div>
                        <div className="white card-description">
                            <p>Get access to on-chain metrics for the biggest list of ERC20 tokens.</p>
                            <p style={{margin:0}}>Supported tokens:</p>
                            <ul>
                                <li> All ERC-20 tokens listed on the <a style={{color: '#DD4088'}} href='https://app.cherry-pick.xyz/ldo' target='_blank'> platform</a></li>
                            </ul>
                            <p style={{margin:0}}> All on-chain metrics:</p>
                            <ul>
                                <li>up to hour resolution</li>
                            </ul>
                            <p style={{margin:0}}> <a style={{color: '#DD4088'}}  href='https://docs.cherry-pick.xyz/api/address-cohorts' target='_blank'>Unique address cohorts</a>:</p>
                            <ul>
                                <li>balance cohorts</li>
                                <li>age cohorts</li>
                                <li>centralized exchanges/smart contracts</li>
                            </ul>
                            {/* <p style={{margin:0}}>Leaderboards:</p>
                            <ul>
                                <li>limited to 25 addresses per query </li>
                            </ul> */}
                        </div>
                        {subscriptionTier !== 2 && <button data-attr={`pay-standard${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => 
                                    verifyBeforePayment(user, 2, validUntil, props.supabaseClient).then((data) => {
                                        if(data.redirectPath) {
                                            navigate(data.redirectPath)
                                        }
                                        if(data.show) {
                                            showConfirmation(
                                                data.header, 
                                                data.messages,
                                                [
                                                  { label: 'Close', onClick: () => {} } // No-op for "No" button
                                                ]
                                            );
                                        }
                                        if(data.pay) {
                                            sendPostHogEvent('pay-button-clicked',{
                                                subscriptionTier,
                                                annual: group === 0,
                                                amount: monthlyPrice[group]*(group === 0 ? 12:1),
                                            } 
                                            )
                                            handlePayment(monthlyPrice[group]*(group === 0 ? 12:1))
                                        }
                                    })} > 
                            <p> PAY </p>
                        </button>}
                        {subscriptionTier === 2 && <div className="card-button-selected">

                            <p> CURRENT PLAN</p>
                        </div>}
                    </div>
                    
                    <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 3 ? '#ACD1FB' : '#110D1F'}}>
                    <div className="card-header white">
                        <h1> Professional </h1>
                    </div>
                    <div className="card-description white" style={{marginBottom: '40px'}}>
                        <p>Includes everything in the Standard plan, plus:</p>
                        <p style={{margin:0}}>Exclusive On-Chain Metrics:</p>
                        <ul>
                            <li>Up to 10-minute resolution for unparalleled data granularity.</li>
                        </ul>
                        <p style={{margin:0}}><a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'>Access to API:</a></p>
                        <ul>
                            <li>All on-chain metrics up to block resolution.</li>
                        </ul>
                        {/* <p style={{margin:0}}>Enhanced Collaboration with CherryPick:</p>
                        <ul>
                            <li>Join our private community and influence the development of the platform.</li>
                            <li>Early access to new analytics tools and insights.</li>
                        </ul> */}
                        <p style={{margin:0}}>Tailored Solutions:</p>
                        <ul>
                            <li>Collaborate with our team to customize features and tools to your exact needs.</li>
                            <li>Unlock the ultimate professional solution designed to give you a competitive edge.</li>
                        </ul>
                        <p>Ready to elevate your analytics experience?</p>
                    </div>
                        {subscriptionTier !== 3 &&<button data-attr={`pay-pro${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => {
                            sendPostHogEvent('contact-sales-clicked',{
                                subscriptionTier,
                                annual: group === 0,
                                amount: monthlyPrice[group]*(group === 0 ? 12:1),
                            } 
                            )
                         window.open("https://calendly.com/cherry-pick/professional-plan-consult", "_blank")}} > 
                            <p>CONTACT SALES</p>
                        </button>}
                        {subscriptionTier === 3 && <div className="card-button-selected">
                            <p> CURRENT PLAN</p>
                            </div>}
                    </div>
                </div>
                
            </div>
            <div className="price-header white" style={{marginTop: '60px', marginBottom: '40px', height: '60px'}}>
                <h2 className="faq" > Frequently Asked Questions </h2>
            </div>
            <div className="price-first-row">
            <div className="card faq-description black" style={{ maxWidth: 'calc(2016px - 6em)', textAlign: 'left', backgroundColor: 'transparent', padding: '0em'}}>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header" > How do you accept payment?</h2>
                </div>
                <div className="card-description  white">
                    <p>We accept debit/credit cards, including VISA and Mastercard. We use Stripe payment system to accept payments, which guarantees safety and security of payments!</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header" > Why is the price when paying different from the Pricing?</h2>
                </div>
                <div className="card-description  white">
                    <p>We charge in rouble equivalent, so the price in dollars may be slightly different. Sorry for this inconvenience, we will fix it very soon. If you have any problems, please contact our support on <a style={{color: '#DD4088'}} href='https://t.me/+w7w4rlpdh-M3MmQ8' target='_blank'>Telegram</a>, <a style={{color: '#DD4088'}} href='https://discord.gg/AWkjXPCkCY' target='_blank'>Discord</a> or <a style={{color: '#DD4088'}} href='mailto:info@cherry-pick.xyz' target='_blank'>email</a>.</p>
                </div>
                <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Is my subscription automatically renewed?</h2>
                </div>
                <div className="card-description  white">
                    <p>No. When your subscription ends, you will be automatically downgraded to the free plan.</p>
                </div>
                <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Is a trial available for your subscription plans?</h2>
                </div>
                <div className="card-description  white">
                    <p>No, however, while on the free Demo plan you can access the full functionality of the platform for one $LDO asset. This will allow you to familiarise yourself with all the functionality of the Platform.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> How can I cancel my subscription?</h2>
                </div>
                <div className="card-description  white">
                    <p>Your paid subscription will end automatically after the expiry date. Before that point, you cannot cancel your subscription.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> How do I get access to the API?</h2>
                </div>
                <div className="card-description  white">
                    <p>The API is only available on the Professional plan. You can read the API documentation <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'> here</a>.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Have another question?</h2>
                </div>
                <div className="card-description white">
                    <p>We would love to help - just email us at info@cherry-pick.xyz</p>
                </div>
            </div>
            </div>
            <div style={{width: '100%', height: '100px'}}></div>
        </section>
    )

}

export function PricingTestNoProd(props) {
    const navigate = useNavigate();

    const [group, selectGroup] = useState(0)

    const { user, session } = Auth.useUser();

    const { subscriptionTier, referralCode, refDiscount } = useSubscription();

    const { setPopup, showConfirmation } = usePopup();

    const monthlyPrice = {
        0: 24,
        1: 29,
    }
    const monthNumbers = {
        0: '12',
        1: '1',
    }

    const validUntil = new Date();
    validUntil.setMonth(validUntil.getMonth() + 1);

    const handleCheckout = async (annual) => {
        if(!user) {
            console.log("User not login")
            return
        }
        const userId = user.id; // Ensure user.id is correctly accessed
        // console.log('User ID:', userId);   

        const priceId = annual ? 'price_1PMoxaJNBjqBZkQq1jKsOUur' : 'price_1PJgIiJNBjqBZkQqQN7LiCwC';
        // const priceId = annual ? 'price_1PMYJyJNBjqBZkQq13UOjTtG' : 'price_1PMYIqJNBjqBZkQqdkOuxYbC'; // Replace with your actual price IDs from Stripe
        const successUrl = 'https://app.cherry-pick.xyz/account';
        const cancelUrl = 'https://app.cherry-pick.xyz/pricing';
    
        try {
            console.log("sent promocode", referralCode)
            const response = await props.supabaseClient.functions.invoke('create-checkout-session', {
              body: {
                priceId,
                successUrl,
                cancelUrl,
                referralCode,
                userId: userId,
                userEmail: user.email,
              },
            });
      
            if (response.error) {
              console.error(response.error.message);
            } else {
              const { id } = response.data;
              const stripe = await stripePromise;
              console.log('id stripe', id)
              const { error } = await stripe.redirectToCheckout({ sessionId: id });
      
              if (error) {
                console.error('Error redirecting to Stripe Checkout:', error.message);
              }
            }
          } catch (error) {
            console.error('Error invoking function:', error);
          }
    };
    
    const handlePayment = (value) => {
        if(!user) {
            console.log("USER NOt login")
            return
        }
        const orderId = uuidv4();
        console.log("orderId", `${orderId}`)
        // console.log("userId", `${user.id}`)
        pay({
          serviceId: "22977",
          publicKey: "045ab0082cc98305d0d02439ad50591fa78dcd30e3d53eda4edadebcf77c5ad778fdae36a2a91398f1ff4db0800557d637133fa89e93ee0f4a9ff209998eb3c74e",
        //   serviceId: "22789",
        //   publicKey: "04edebf5c58e3c79710fe0b3cbab82330ab87ea8b4138c6ca849a11f72a4668ba6cba6d49d55989e5593e22ae24d33720120aa847e0e9e21e5c99cdc30dc8ce04a",
          orderId: `${orderId}`,
          userId: user.id,
          amount: `${value*86}`,
          currency: "RUB",
          description: `Standard Plan - Access to essential analytics features. Full support included.`,
          monthNumber: monthNumbers[group],
          email: user.email,
          priceAmount: value*86,
          onSuccess: function(res) {
            sendPostHogEvent('payment-successful',{
                subscriptionTier,
                annual: group === 0,
                amount: monthlyPrice[group]*(group === 0 ? 12:1),
            });
            if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on success
            console.log("onSuccess from shop", res); // Log success response
          },
          onError: function(res) {
            sendPostHogEvent('payment-error',{
                subscriptionTier,
                annual: group === 0,
                amount: monthlyPrice[group]*(group === 0 ? 12:1),
                errorMessage: res.message,
            });
            // if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on error
            console.log("onFail from shop", res); // Log error response
          },
          onClose: function(res) {
            sendPostHogEvent('payment-close',{
                subscriptionTier,
                annual: group === 0,
                amount: monthlyPrice[group]*(group === 0 ? 12:1),
            });
            console.log("onClose from shop", res); // Log when widget is closed
          }
        });
    };
    // <div className="price-section" style={{position:'absolute', top: '0px', left: '0px', width: '200px', height: '200px', backgroundColor: 'red'}}>
    //     1000
    // </div>
    return (
        <section className="price-section" style={{background: 'none'}}>
            <div className="price-header" style={{marginBottom: '30px'}}>
                <h1 className="gradientColorWhite"> Pricing </h1>

                {/* <p className="subheader" style={{color: '#FFD700'}} >IMPORTANT NOTICE</p> */}
                {/* <p className="subheader" style={{color: '#FFD700', textAlign: 'left'}} > <span style={{fontFamily: "SFProBold"}}>IMPORTANT NOTICE:</span> We are experiencing technical issues with our payment system, which will be resolved by 1, June. During this period, we would be happy to assist you with direct invoices via our support team. Please contact us using Telegram or any other convenient method. We apologize for the inconvenience and appreciate your patience. 🙏🍒</p> */}
                <p className="subheader" style={{color: 'white'}} > Get access to the most comprehensive on-chain data suite <br></br> on the widest set of supported tokens using CherryPick</p>
            </div>
            {referralCode !== '-' && <div className="price-header" style={{marginBottom: '30px'}}>
                <p className="subheader" style={{color: 'white'}} > Thanks to the referral code <span style={{color: '#B3D0F7'}}>{`${referralCode}`}</span>, you have a <span style={{color: '#B3D0F7'}}>{`${refDiscount}`}% </span> discount on purchasing the standard package.</p>
                {/* for the next 24 hours */}
            </div>}
            {/* <div className="price-header" style={{marginBottom: '0px'}}>
                <p className="subheader" > Select your group </p>
            </div> */}
            <div className="type-selector" style={group===0 ? {marginBottom: 0}:{}}>
                <div className="selector-wrapper">
                    <p className={`option-white ${group == 0 ? 'selected':''}`} style={{color: group !== 0 ? 'white' : ''}} onClick={() => {selectGroup(0)}}>Bill Yearly (-15%)</p>
                    <p className={`option-white ${group == 1 ? 'selected':''}`} style={{color: group !== 1 ? 'white' : ''}} onClick={() => {selectGroup(1)}}>Bill Monthly</p>
                </div>
            </div>
            { group===0 && <p style={{color: 'white', alignItems:'center', textAlign: 'center', marginBottom: '70px', fontFamily:"SFProReg", fontSize: '20px'}}>Save up to 70$</p>}
            <div className="price-cards-table">
                <div className="price-first-row">

                <div className="black card" style={{border: "2px solid", borderColor: (subscriptionTier === 0) || (subscriptionTier === 1)  ? '#ACD1FB' : '#110D1F'}}>
                        <div className="card-header white">
                            <h1> Demo </h1>
                            {/* {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>Free</span></h3>
                            </div>} */}
                            {/* <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>{group == 0 ? '$299': '$399'}</h1>
                                <p>per month</p>
                            </div> */}
                            <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>$0</h1>
                                <p>per month</p>
                            </div>
                        </div>
                        <div className="card-description white" style={{marginBottom: '40px'}}>
                            <p>Get access to all on-chain metrics for the $LDO asset</p>
                            <p>This plan includes all Standard features but for only one asset</p>
                            <p>You already have it, just start:</p>
                            
                            {/* <p>Opportunity to become friends with cherrypick

Are you saying that we are corrupt? We're proud to say yes!</p> */}
                        </div>
                        <button data-attr="explore-demo" 
                                className="card-button-black" 
                                onClick={event => {
                                        sendPostHogEvent('explore-button-clicked',{
                                            subscriptionTier,
                                            annual: group === 0,
                                            amount: monthlyPrice[group]*(group === 0 ? 12:1),
                                        } 
                                        )
                                        window.open('https://app.cherry-pick.xyz/ldo', '_blank')
                                    }}>
                            <p>EXPLORE</p>
                        </button>
                    </div>
                    <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 2 ? '#ACD1FB' : '#110D1F'}}>
                        <div className="card-header">
                            <h1 className="white"> Standard </h1>
                            {referralCode !== '-' && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>${monthlyPrice[group]}</span></h3>
                            </div>}
                            <div className='price-month white'>
                                <h1 style={{marginTop: '10px'}}>${Math.ceil(monthlyPrice[group] * (100 - refDiscount)/100)}</h1>
                                <p>per month paid {group == 0 ? ' annually' : ' monthly'}</p>
                            </div>
                        </div>
                        <div className="white card-description">
                            <p>Get access to on-chain metrics for the biggest list of ERC20 tokens.</p>
                            <p style={{margin:0}}>Supported tokens:</p>
                            <ul>
                                <li> All ERC-20 tokens listed on the <a style={{color: '#DD4088'}} href='https://app.cherry-pick.xyz/ldo' target='_blank'> platform</a></li>
                            </ul>
                            <p style={{margin:0}}> All on-chain metrics:</p>
                            <ul>
                                <li>up to hour resolution</li>
                            </ul>
                            <p style={{margin:0}}> <a style={{color: '#DD4088'}}  href='https://docs.cherry-pick.xyz/api/address-cohorts' target='_blank'>Unique address cohorts</a>:</p>
                            <ul>
                                <li>balance cohorts</li>
                                <li>age cohorts</li>
                                <li>centralized exchanges/smart contracts</li>
                            </ul>
                            <p style={{ margin: 0 }}>Bonus:</p>
                            <ul>
                                <li>+300 altcoins with base on-chain metrics</li>
                                <li>Overvalued & undervalued table for 300 altcoins</li>
                            </ul>
                            {/* <p style={{margin:0}}>Leaderboards:</p>
                            <ul>
                                <li>limited to 25 addresses per query </li>
                            </ul> */}
                        </div>
                        {subscriptionTier !== 2 && <button data-attr={`pay-standard${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => 
                                    verifyBeforePayment(user, 2, validUntil, props.supabaseClient).then((data) => {
                                        if(data.redirectPath) {
                                            navigate(data.redirectPath)
                                        }
                                        if(data.show) {
                                            showConfirmation(
                                                data.header, 
                                                data.messages,
                                                [
                                                  { label: 'Close', onClick: () => {} } // No-op for "No" button
                                                ]
                                            );
                                        }
                                        if(data.pay) {
                                            sendPostHogEvent('pay-button-clicked',{
                                                subscriptionTier,
                                                annual: group === 0,
                                                amount: monthlyPrice[group]*(group === 0 ? 12:1),
                                            } 
                                            )
                                            // handlePayment(Math.ceil(monthlyPrice[group] * (100 - refDiscount)/100)*(group === 0 ? 12:1))
                                            handleCheckout(group === 0)
                                        }
                                    })} > 
                            <p> PAY </p>
                        </button>}
                        {subscriptionTier === 2 && <div className="card-button-selected">

                            <p> CURRENT PLAN</p>
                        </div>}
                    </div>
                    
                    <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 3 ? '#ACD1FB' : '#110D1F'}}>
                    <div className="card-header white">
                        <h1> Professional </h1>
                    </div>
                    <div className="card-description white" style={{marginBottom: '40px'}}>
                        <p>Includes everything in the Standard plan, plus:</p>
                        <p style={{margin:0}}>Exclusive On-Chain Metrics:</p>
                        <ul>
                            <li>Up to 10-minute resolution for unparalleled data granularity.</li>
                        </ul>
                        <p style={{margin:0}}><a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'>Access to API:</a></p>
                        <ul>
                            <li>All on-chain metrics up to block resolution.</li>
                        </ul>
                        {/* <p style={{margin:0}}>Enhanced Collaboration with CherryPick:</p>
                        <ul>
                            <li>Join our private community and influence the development of the platform.</li>
                            <li>Early access to new analytics tools and insights.</li>
                        </ul> */}
                        <p style={{margin:0}}>Tailored Solutions:</p>
                        <ul>
                            <li>Collaborate with our team to customize features and tools to your exact needs.</li>
                            <li>Unlock the ultimate professional solution designed to give you a competitive edge.</li>
                        </ul>
                        <p>Ready to elevate your analytics experience?</p>
                    </div>
                        {subscriptionTier !== 3 &&<button data-attr={`pay-pro${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => {
                            sendPostHogEvent('contact-sales-clicked',{
                                subscriptionTier,
                                annual: group === 0,
                                amount: monthlyPrice[group]*(group === 0 ? 12:1),
                            } 
                            )
                         window.open("https://calendly.com/cherry-pick/professional-plan-consult", "_blank")}} > 
                            <p>CONTACT SALES</p>
                        </button>}
                        {subscriptionTier === 3 && <div className="card-button-selected">
                            <p> CURRENT PLAN</p>
                            </div>}
                    </div>
                </div>
                
            </div>
            <div className="price-header white" style={{marginTop: '60px', marginBottom: '40px', height: '60px'}}>
                <h2 className="faq" > Frequently Asked Questions </h2>
            </div>
            <div className="price-first-row">
            <div className="card faq-description black" style={{ maxWidth: 'calc(2016px - 6em)', textAlign: 'left', backgroundColor: 'transparent', padding: '0em'}}>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header" > How do you accept payment?</h2>
                </div>
                <div className="card-description  white">
                    <p>We accept debit/credit cards, including VISA and Mastercard. We use Stripe payment system to accept payments, which guarantees safety and security of payments!</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header" > My payment failed / my card is not accepted by Stripe, what should I do?</h2>
                </div>
                <div className="card-description  white">
                    <p>Don't worry! We won't leave you without a CherryPick subscription! Write us on <a style={{color: '#DD4088'}} href='https://t.me/+w7w4rlpdh-M3MmQ8' target='_blank'>Telegram</a>, <a style={{color: '#DD4088'}} href='https://discord.gg/AWkjXPCkCY' target='_blank'>Discord</a> or <a style={{color: '#DD4088'}} href='mailto:info@cherry-pick.xyz' target='_blank'>email</a> and we'll help you with your payment.</p>
                </div>
                <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Is my subscription automatically renewed?</h2>
                </div>
                <div className="card-description  white">
                    <p>Yes, your subscription will be automatically renewed after the end of the billing period. You can view the next charge date on the <a style={{color: '#DD4088'}} href='https://app.cherry-pick.xyz/account' target='_blank'> Account</a> page.</p>
                </div>
                <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> How can I cancel my subscription?</h2>
                </div>
                <div className="card-description  white">
                    <p>You can cancel your subscription at any time on the <a style={{color: '#DD4088'}} href='https://app.cherry-pick.xyz/account' target='_blank'>Account</a> page. Once canceled, your subscription will remain active until the end of the billing period and will be deactivated at the end of the billing period.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> I currently have a monthly subscription and I want to upgrade to an annual subscription. How do I do this?</h2>
                </div>
                <div className="card-description  white">
                    <p>To do this, you need to cancel your current subscription, wait until the end of the billing period and purchase an annual subscription.</p>
                </div>

                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> I have a referral code, what does it mean?</h2>
                </div>
                <div className="card-description white">
                    <p>Your referral code allows you to purchase subscriptions at a discount. The code and the discount it gives you is displayed on the <a style={{color: '#DD4088'}} href='https://app.cherry-pick.xyz/account' target='_blank'>Account</a> page.
                    You do not need to enter this code anywhere, it is applied automatically. If you have a referral code activated, the prices on the Pricing page will be displayed with the referral discount.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> I have a promo code, what should I do with it?</h2>
                </div>
                <div className="card-description white">
                    <p>A promo code allows you to get a discount at the moment of payment. You need to enter the promo code on the payment page yourself. Note that the discount from the promo code does not stack with other discounts, including discounts from referral codes. If you already have a referral code activated, you will not be able to use the promo code.</p>
                </div>

                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> How do I get access to the API?</h2>
                </div>
                <div className="card-description  white">
                    <p>The API is only available on the Professional plan. You can read the API documentation <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'> here</a>.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Have another question?</h2>
                </div>
                <div className="card-description white">
                    <p>We would love to help - just write us on <a style={{color: '#DD4088'}} href='https://t.me/+w7w4rlpdh-M3MmQ8' target='_blank'>Telegram</a>, <a style={{color: '#DD4088'}} href='https://discord.gg/AWkjXPCkCY' target='_blank'>Discord</a> or <a style={{color: '#DD4088'}} href='mailto:info@cherry-pick.xyz' target='_blank'>email</a>.</p>
                </div>
            </div>
            </div>
            <div style={{width: '100%', height: '100px'}}></div>
        </section>
    )

}

export function PricingTestNoProd22(props) {
    const navigate = useNavigate();

    const [group, selectGroup] = useState(1)

    const { user, session } = Auth.useUser();

    const { subscriptionTier, referralCode, refDiscount } = useSubscription();

    const { setPopup, showConfirmation } = usePopup();

    const monthlyPrice = {
        0: 23,
        1: 29,
    }
    const monthNumbers = {
        0: '12',
        1: '1',
    }

    const validUntil = new Date();
    validUntil.setMonth(validUntil.getMonth() + 1);

    const handleCheckout = async (annual) => {
        if(!user) {
            console.log("User not login")
            return
        }
        const userId = user.id; // Ensure user.id is correctly accessed
        console.log('User ID:', userId);    

        const priceId = annual ? 'price_1PJgIiJNBjqBZkQq02Undldb' : 'price_1PJgIiJNBjqBZkQqQN7LiCwC'; // Replace with your actual price IDs from Stripe
        const successUrl = window.location.origin + '/account'
        // + '/success';
        const cancelUrl = window.location.origin 
        // + '/cancel';
    
        try {
            console.log("sent promocode", referralCode)
            const response = await props.supabaseClient.functions.invoke('create-checkout-session', {
              body: {
                priceId,
                successUrl,
                cancelUrl,
                referralCode,
                userId: userId,
              },
            });
            
            if (response.error) {
              console.error(response.error.message);
            } else {
              const { id } = response.data;
              const stripe = await stripePromise;
              console.log('id stripe', id)
              const { error } = await stripe.redirectToCheckout({ sessionId: id });
      
              if (error) {
                console.error('Error redirecting to Stripe Checkout:', error.message);
              }
            }
          } catch (error) {
            console.error('Error invoking function:', error);
          }
    };
    
    const handlePayment = (value) => {
        if(!user) {
            console.log("USER NOt login")
            return
        }
        const orderId = uuidv4();
        console.log("orderId", `${orderId}`)
        // console.log("userId", `${user.id}`)
        pay({
          serviceId: "22977",
          publicKey: "045ab0082cc98305d0d02439ad50591fa78dcd30e3d53eda4edadebcf77c5ad778fdae36a2a91398f1ff4db0800557d637133fa89e93ee0f4a9ff209998eb3c74e",
        //   serviceId: "22789",
        //   publicKey: "04edebf5c58e3c79710fe0b3cbab82330ab87ea8b4138c6ca849a11f72a4668ba6cba6d49d55989e5593e22ae24d33720120aa847e0e9e21e5c99cdc30dc8ce04a",
          orderId: `${orderId}`,
          userId: user.id,
          amount: `${value*86}`,
          currency: "RUB",
          description: `Standard Plan - Access to essential analytics features. Full support included.`,
          monthNumber: monthNumbers[group],
          email: user.email,
          priceAmount: value*86,
          onSuccess: function(res) {
            sendPostHogEvent('payment-successful',{
                subscriptionTier,
                annual: group === 0,
                amount: monthlyPrice[group]*(group === 0 ? 12:1),
            });
            if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on success
            console.log("onSuccess from shop", res); // Log success response
          },
          onError: function(res) {
            sendPostHogEvent('payment-error',{
                subscriptionTier,
                annual: group === 0,
                amount: monthlyPrice[group]*(group === 0 ? 12:1),
                errorMessage: res.message,
            });
            // if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on error
            console.log("onFail from shop", res); // Log error response
          },
          onClose: function(res) {
            sendPostHogEvent('payment-close',{
                subscriptionTier,
                annual: group === 0,
                amount: monthlyPrice[group]*(group === 0 ? 12:1),
            });
            console.log("onClose from shop", res); // Log when widget is closed
          }
        });
    };
    // <div className="price-section" style={{position:'absolute', top: '0px', left: '0px', width: '200px', height: '200px', backgroundColor: 'red'}}>
    //     1000
    // </div>
    return (
        <section className="price-section" style={{background: 'none'}}>
            <div className="price-header" style={{marginBottom: '30px'}}>
                <h1 className="gradientColorWhite"> Pricing </h1>

                {/* <p className="subheader" style={{color: '#FFD700'}} >IMPORTANT NOTICE</p> */}
                <p className="subheader" style={{color: '#FFD700', textAlign: 'left'}} > <span style={{fontFamily: "SFProBold"}}>IMPORTANT NOTICE:</span> We are experiencing technical issues with our payment system, which will be resolved by 1, June. During this period, we would be happy to assist you with direct invoices via our support team. Please contact us using Telegram or any other convenient method. We apologize for the inconvenience and appreciate your patience. 🙏🍒</p>
                <p className="subheader" style={{color: 'white'}} > Get access to the most comprehensive on-chain data suite <br></br> on the widest set of supported tokens using CherryPick</p>
            </div>
            {referralCode !== '-' && <div className="price-header" style={{marginBottom: '30px'}}>
                <p className="subheader" style={{color: 'white'}} > Thanks to the referral code <span style={{color: '#B3D0F7'}}>{`${referralCode}`}</span>, you have a <span style={{color: '#B3D0F7'}}>{`${refDiscount}`}% </span> discount on purchasing the standard package.</p>
                {/* for the next 24 hours */}
            </div>}
            <div className="price-header" style={{marginBottom: '0px'}}>
                <p className="subheader" > Select your group </p>
            </div>
            <div className="type-selector" style={group===0 ? {marginBottom: 0}:{}}>
                <div className="selector-wrapper">
                    <p className={`option-white ${group == 0 ? 'selected':''}`} style={{color: group !== 0 ? 'white' : ''}} onClick={() => {selectGroup(0)}}>Bill Yearly (-20%)</p>
                    <p className={`option-white ${group == 1 ? 'selected':''}`} style={{color: group !== 1 ? 'white' : ''}} onClick={() => {selectGroup(1)}}>Bill Monthly</p>
                </div>
            </div>
            { group===0 && <p style={{color: 'white', alignItems:'center', textAlign: 'center', marginBottom: '70px', fontFamily:"SFProReg", fontSize: '20px'}}>Save up to 70$</p>}
            <div className="price-cards-table">
                <div className="price-first-row">

                <div className="black card" style={{border: "2px solid", borderColor: (subscriptionTier === 0) || (subscriptionTier === 1)  ? '#ACD1FB' : '#110D1F'}}>
                        <div className="card-header white">
                            <h1> Demo </h1>
                            {/* {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>Free</span></h3>
                            </div>} */}
                            {/* <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>{group == 0 ? '$299': '$399'}</h1>
                                <p>per month</p>
                            </div> */}
                            <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>$0</h1>
                                <p>per month</p>
                            </div>
                        </div>
                        <div className="card-description white" style={{marginBottom: '40px'}}>
                            <p>Get access to all on-chain metrics for the $LDO asset</p>
                            <p>This plan includes all Standard features but for only one asset</p>
                            <p>You already have it, just start:</p>
                            
                            {/* <p>Opportunity to become friends with cherrypick

Are you saying that we are corrupt? We're proud to say yes!</p> */}
                        </div>
                        <button data-attr="explore-demo" 
                                className="card-button-black" 
                                onClick={event => {
                                        sendPostHogEvent('explore-button-clicked',{
                                            subscriptionTier,
                                            annual: group === 0,
                                            amount: monthlyPrice[group]*(group === 0 ? 12:1),
                                        } 
                                        )
                                        window.open('https://app.cherry-pick.xyz/ldo', '_blank')
                                    }}>
                            <p>EXPLORE</p>
                        </button>
                    </div>
                    <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 2 ? '#ACD1FB' : '#110D1F'}}>
                        <div className="card-header">
                            <h1 className="white"> Standard </h1>
                            {referralCode !== '-' && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>${monthlyPrice[group]}</span></h3>
                            </div>}
                            <div className='price-month white'>
                                <h1 style={{marginTop: '10px'}}>${Math.ceil(monthlyPrice[group] * (100 - refDiscount)/100)}</h1>
                                <p>per month paid {group == 0 ? ' annually' : ' monthly'}</p>
                            </div>
                        </div>
                        <div className="white card-description">
                            <p>Get access to on-chain metrics for the biggest list of ERC20 tokens.</p>
                            <p style={{margin:0}}>Supported tokens:</p>
                            <ul>
                                <li> All ERC-20 tokens listed on the <a style={{color: '#DD4088'}} href='https://app.cherry-pick.xyz/ldo' target='_blank'> platform</a></li>
                            </ul>
                            <p style={{margin:0}}> All on-chain metrics:</p>
                            <ul>
                                <li>up to hour resolution</li>
                            </ul>
                            <p style={{margin:0}}> <a style={{color: '#DD4088'}}  href='https://docs.cherry-pick.xyz/api/address-cohorts' target='_blank'>Unique address cohorts</a>:</p>
                            <ul>
                                <li>balance cohorts</li>
                                <li>age cohorts</li>
                                <li>centralized exchanges/smart contracts</li>
                            </ul>
                            <p style={{ margin: 0 }}>Bonus:</p>
                            <ul>
                                <li>+300 altcoins with base on-chain metrics</li>
                                <li>Overvalued & undervalued table for 300 altcoins</li>
                            </ul>
                            {/* <p style={{margin:0}}>Leaderboards:</p>
                            <ul>
                                <li>limited to 25 addresses per query </li>
                            </ul> */}
                        </div>
                        {subscriptionTier !== 2 && <button data-attr={`pay-standard${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => 
                                    verifyBeforePayment(user, 2, validUntil, props.supabaseClient).then((data) => {
                                        if(data.redirectPath) {
                                            navigate(data.redirectPath)
                                        }
                                        if(data.show) {
                                            showConfirmation(
                                                data.header, 
                                                data.messages,
                                                [
                                                  { label: 'Close', onClick: () => {} } // No-op for "No" button
                                                ]
                                            );
                                        }
                                        if(data.pay) {
                                            sendPostHogEvent('pay-button-clicked',{
                                                subscriptionTier,
                                                annual: group === 0,
                                                amount: monthlyPrice[group]*(group === 0 ? 12:1),
                                            } 
                                            )
                                            // handlePayment(Math.ceil(monthlyPrice[group] * (100 - refDiscount)/100)*(group === 0 ? 12:1))
                                            handleCheckout(group === 0)
                                        }
                                    })} > 
                            <p> PAY </p>
                        </button>}
                        {subscriptionTier === 2 && <div className="card-button-selected">

                            <p> CURRENT PLAN</p>
                        </div>}
                    </div>
                    
                    <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 3 ? '#ACD1FB' : '#110D1F'}}>
                    <div className="card-header white">
                        <h1> Professional </h1>
                    </div>
                    <div className="card-description white" style={{marginBottom: '40px'}}>
                        <p>Includes everything in the Standard plan, plus:</p>
                        <p style={{margin:0}}>Exclusive On-Chain Metrics:</p>
                        <ul>
                            <li>Up to 10-minute resolution for unparalleled data granularity.</li>
                        </ul>
                        <p style={{margin:0}}><a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'>Access to API:</a></p>
                        <ul>
                            <li>All on-chain metrics up to block resolution.</li>
                        </ul>
                        {/* <p style={{margin:0}}>Enhanced Collaboration with CherryPick:</p>
                        <ul>
                            <li>Join our private community and influence the development of the platform.</li>
                            <li>Early access to new analytics tools and insights.</li>
                        </ul> */}
                        <p style={{margin:0}}>Tailored Solutions:</p>
                        <ul>
                            <li>Collaborate with our team to customize features and tools to your exact needs.</li>
                            <li>Unlock the ultimate professional solution designed to give you a competitive edge.</li>
                        </ul>
                        <p>Ready to elevate your analytics experience?</p>
                    </div>
                        {subscriptionTier !== 3 &&<button data-attr={`pay-pro${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => {
                            sendPostHogEvent('contact-sales-clicked',{
                                subscriptionTier,
                                annual: group === 0,
                                amount: monthlyPrice[group]*(group === 0 ? 12:1),
                            } 
                            )
                         window.open("https://calendly.com/cherry-pick/professional-plan-consult", "_blank")}} > 
                            <p>CONTACT SALES</p>
                        </button>}
                        {subscriptionTier === 3 && <div className="card-button-selected">
                            <p> CURRENT PLAN</p>
                            </div>}
                    </div>
                </div>
                
            </div>
            <div className="price-header white" style={{marginTop: '60px', marginBottom: '40px', height: '60px'}}>
                <h2 className="faq" > Frequently Asked Questions </h2>
            </div>
            <div className="price-first-row">
            <div className="card faq-description black" style={{ maxWidth: 'calc(2016px - 6em)', textAlign: 'left', backgroundColor: 'transparent', padding: '0em'}}>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header" > How do you accept payment?</h2>
                </div>
                <div className="card-description  white">
                    <p>We accept debit/credit cards, including VISA, Mastercard and MIR.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header" > Why is the price when paying different from the Pricing?</h2>
                </div>
                <div className="card-description  white">
                    <p>We charge in rouble equivalent, so the price in dollars may be slightly different. Sorry for this inconvenience, we will fix it very soon. If you have any problems, please contact our support on <a style={{color: '#DD4088'}} href='https://t.me/+w7w4rlpdh-M3MmQ8' target='_blank'>Telegram</a>, <a style={{color: '#DD4088'}} href='https://discord.gg/AWkjXPCkCY' target='_blank'>Discord</a> or <a style={{color: '#DD4088'}} href='mailto:info@cherry-pick.xyz' target='_blank'>email</a>.</p>
                </div>
                <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Is my subscription automatically renewed?</h2>
                </div>
                <div className="card-description  white">
                    <p>No. When your subscription ends, you will be automatically downgraded to the free plan.</p>
                </div>
                <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Is a trial available for your subscription plans?</h2>
                </div>
                <div className="card-description  white">
                    <p>No, however, while on the free Demo plan you can access the full functionality of the platform for one $LDO asset. This will allow you to familiarise yourself with all the functionality of the Platform.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> How can I cancel my subscription?</h2>
                </div>
                <div className="card-description  white">
                    <p>Your paid subscription will end automatically after the expiry date. Before that point, you cannot cancel your subscription.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> How do I get access to the API?</h2>
                </div>
                <div className="card-description  white">
                    <p>The API is only available on the Professional plan. You can read the API documentation <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'> here</a>.</p>
                </div>
                <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
                    <h2 className="faq-header"> Have another question?</h2>
                </div>
                <div className="card-description white">
                    <p>We would love to help - just email us at info@cherry-pick.xyz</p>
                </div>
            </div>
            </div>
            <div style={{width: '100%', height: '100px'}}></div>
        </section>
    )

}

// export function PricingTestNoProd(props) {
//     const navigate = useNavigate();

//     const [group, selectGroup] = useState(0)

//     const { user, session } = Auth.useUser();

//     const { subscriptionTier } = useSubscription();

//     const { setPopup, showConfirmation } = usePopup();

//     const monthlyPrice = {
//         0: 23,
//         1: 29,
//     }

//     const validUntil = new Date();
//     validUntil.setMonth(validUntil.getMonth() + 1);
    
//     const handlePayment = (value) => {
//         if(!user) {
//             console.log("USER NOt login")
//             return
//         }
//         const orderId = uuidv4();
//         // console.log("orderId", `${orderId}`)
//         // console.log("userId", `${user.id}`)
//         pay({
//           serviceId: "22789",
//           publicKey: "04edebf5c58e3c79710fe0b3cbab82330ab87ea8b4138c6ca849a11f72a4668ba6cba6d49d55989e5593e22ae24d33720120aa847e0e9e21e5c99cdc30dc8ce04a",
//           orderId: `${orderId}`,
//           userId: user.id,
//           amount: `${value*86}`,
//           currency: "RUB",
//           description: "Standard Plan - Access to essential analytics features. Billed monthly. Full support included.",
//           email: user.email,
//           onSuccess: function(res) {
//             if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on success
//             console.log("onSuccess from shop", res); // Log success response
//           },
//           onError: function(res) {
//             // if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on error
//             console.log("onFail from shop", res); // Log error response
//           },
//           onClose: function(res) {
//             console.log("onClose from shop", res); // Log when widget is closed
//           }
//         });
//     };
//     // <div className="price-section" style={{position:'absolute', top: '0px', left: '0px', width: '200px', height: '200px', backgroundColor: 'red'}}>
//     //     1000
//     // </div>
//     return (
//         <section className="price-section" style={{background: 'none'}}>
//             <div className="price-header" style={{marginBottom: '30px'}}>
//                 <h1 className="gradientColorWhite"> Pricing </h1>
//                 <p className="subheader" style={{color: 'white'}} > Get access to the most comprehensive on-chain data suite <br></br> on the widest set of supported tokens using CherryPick</p>
//             </div>
//             {/* <div className="price-header" style={{marginBottom: '0px'}}>
//                 <p className="subheader" > Select your group </p>
//             </div> */}
//             <div className="type-selector" style={group===0 ? {marginBottom: 0}:{}}>
//                 <div className="selector-wrapper">
//                     <p className={`option-white ${group == 0 ? 'selected':''}`} style={{color: group !== 0 ? 'white' : ''}} onClick={() => {selectGroup(0)}}>Bill Yearly (-20%)</p>
//                     <p className={`option-white ${group == 1 ? 'selected':''}`} style={{color: group !== 1 ? 'white' : ''}} onClick={() => {selectGroup(1)}}>Bill Monthly</p>
//                 </div>
//             </div>
//             { group===0 && <p style={{color: 'white', alignItems:'center', textAlign: 'center', marginBottom: '70px', fontFamily:"SFProReg", fontSize: '20px'}}>Save up to 70$</p>}
//             <div className="price-cards-table">
//                 <div className="price-first-row">

//                 <div className="black card" style={{border: "2px solid", borderColor: (subscriptionTier === 0) || (subscriptionTier === 1)  ? '#ACD1FB' : '#110D1F'}}>
//                         <div className="card-header white">
//                             <h1> Demo </h1>
//                             {/* {group == 0 && <div className="price-discount">
//                                 <h3><span className='strikediag withpadding'>Free</span></h3>
//                             </div>} */}
//                             {/* <div className='price-month'>
//                                 <h1 style={{marginTop: '10px'}}>{group == 0 ? '$299': '$399'}</h1>
//                                 <p>per month</p>
//                             </div> */}
//                             <div className='price-month'>
//                                 <h1 style={{marginTop: '10px'}}>$0</h1>
//                                 <p>per month</p>
//                             </div>
//                         </div>
//                         <div className="card-description white" style={{marginBottom: '40px'}}>
//                             <p>Get access to all on-chain metrics for the $LDO asset</p>
//                             <p>This plan includes all Standard features but for only one asset</p>
//                             <p>You already have it, just start:</p>
                            
//                             {/* <p>Opportunity to become friends with cherrypick

// Are you saying that we are corrupt? We're proud to say yes!</p> */}
//                         </div>
//                         <button data-attr="explore-demo" className="card-button-black" onClick={event => window.open('https://app.cherry-pick.xyz/ldo', '_blank')}>
//                             <p>EXPLORE</p>
//                         </button>
//                     </div>
//                     <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 2 ? '#ACD1FB' : '#110D1F'}}>
//                         <div className="card-header">
//                             <h1 className="white"> Standard </h1>
//                             {group == 0 && <div className="price-discount">
//                                 <h3><span className='strikediag withpadding'>${monthlyPrice[1]}</span></h3>
//                             </div>}
//                             <div className='price-month white'>
//                                 <h1 style={{marginTop: '10px'}}>${monthlyPrice[group]}</h1>
//                                 <p>per month paid {group == 0 ? ' annually' : ' monthly'}</p>
//                             </div>
//                         </div>
//                         <div className="white card-description">
//                             <p>Get access to on-chain metrics for the biggest list of ERC20 tokens.</p>
//                             <p style={{margin:0}}>Supported tokens:</p>
//                             <ul>
//                                 <li> All ERC-20 tokens listed on the <a style={{color: '#DD4088'}} href='https://app.cherry-pick.xyz/ldo' target='_blank'> platform</a></li>
//                             </ul>
//                             <p style={{margin:0}}> All on-chain metrics:</p>
//                             <ul>
//                                 <li>up to hour resolution</li>
//                             </ul>
//                             <p style={{margin:0}}> <a style={{color: '#DD4088'}}  href='https://docs.cherry-pick.xyz/api/address-cohorts' target='_blank'>Unique address cohorts</a>:</p>
//                             <ul>
//                                 <li>balance cohorts</li>
//                                 <li>age cohorts</li>
//                                 <li>centralized exchanges/smart contracts</li>
//                             </ul>
//                             {/* <p style={{margin:0}}>Leaderboards:</p>
//                             <ul>
//                                 <li>limited to 25 addresses per query </li>
//                             </ul> */}
//                         </div>
//                         {subscriptionTier !== 2 && <button data-attr={`pay-standard${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => 
//                                     verifyBeforePayment(user, 2, validUntil, props.supabaseClient).then((data) => {
//                                         if(data.redirectPath) {
//                                             navigate(data.redirectPath)
//                                         }
//                                         if(data.show) {
//                                             showConfirmation(
//                                                 data.header, 
//                                                 data.messages,
//                                                 [
//                                                   { label: 'Close', onClick: () => {} } // No-op for "No" button
//                                                 ]
//                                             );
//                                         }
//                                         if(data.pay) {
//                                             handlePayment(monthlyPrice[group]*(group === 0 ? 12:1))
//                                         }
//                                     })} > 
//                             <p> PAY </p>
//                         </button>}
//                         {subscriptionTier === 2 && <div className="card-button-selected">

//                             <p> CURRENT PLAN</p>
//                         </div>}
//                     </div>
                    
//                     <div className="black card" style={{border: "2px solid", borderColor: subscriptionTier === 3 ? '#ACD1FB' : '#110D1F'}}>
//                     <div className="card-header white">
//                         <h1> Professional </h1>
//                     </div>
//                     <div className="card-description white" style={{marginBottom: '40px'}}>
//                         <p>Includes everything in the Standard plan, plus:</p>
//                         <p style={{margin:0}}>Exclusive On-Chain Metrics:</p>
//                         <ul>
//                             <li>Up to 10-minute resolution for unparalleled data granularity.</li>
//                         </ul>
//                         <p style={{margin:0}}><a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'>Access to API:</a></p>
//                         <ul>
//                             <li>All on-chain metrics up to block resolution.</li>
//                         </ul>
//                         {/* <p style={{margin:0}}>Enhanced Collaboration with CherryPick:</p>
//                         <ul>
//                             <li>Join our private community and influence the development of the platform.</li>
//                             <li>Early access to new analytics tools and insights.</li>
//                         </ul> */}
//                         <p style={{margin:0}}>Tailored Solutions:</p>
//                         <ul>
//                             <li>Collaborate with our team to customize features and tools to your exact needs.</li>
//                             <li>Unlock the ultimate professional solution designed to give you a competitive edge.</li>
//                         </ul>
//                         <p>Ready to elevate your analytics experience?</p>
//                     </div>
//                         {subscriptionTier !== 3 &&<button data-attr={`pay-pro${group===0 ? '-annualy':''}`} className="card-button-black" onClick={() => 
//                          window.open("https://calendly.com/cherry-pick/professional-plan-consult", "_blank")} > 
//                             <p>CONTACT SALES</p>
//                         </button>}
//                         {subscriptionTier === 3 && <div className="card-button-selected">
//                             <p> CURRENT PLAN</p>
//                             </div>}
//                     </div>
//                 </div>
                
//             </div>
//             <div className="price-header white" style={{marginTop: '60px', marginBottom: '40px', height: '60px'}}>
//                 <h2 className="faq" > Frequently Asked Questions </h2>
//             </div>
//             <div className="price-first-row">
//             <div className="card faq-description black" style={{ maxWidth: 'calc(2016px - 6em)', textAlign: 'left', backgroundColor: 'transparent'}}>
//                 <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
//                     <h2 className="faq-header" > How do you accept payment?</h2>
//                 </div>
//                 <div className="card-description  white">
//                     <p>We accept debit/credit cards, including VISA, Mastercard and MIR.</p>
//                 </div>
//                 <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
//                     <h2 className="faq-header"> Is my subscription automatically renewed?</h2>
//                 </div>
//                 <div className="card-description  white">
//                     <p>No. When your subscription ends, you will be automatically downgraded to the free plan.</p>
//                 </div>
//                 <div className="card-header  white" style={{borderBottom: '1.5px solid white'}}>
//                     <h2 className="faq-header"> Is a trial available for your subscription plans?</h2>
//                 </div>
//                 <div className="card-description  white">
//                     <p>No, however, while on the free Demo plan you can access the full functionality of the platform for one $LDO asset. This will allow you to familiarise yourself with all the functionality of the Platform.</p>
//                 </div>
//                 <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
//                     <h2 className="faq-header"> How can I cancel my subscription?</h2>
//                 </div>
//                 <div className="card-description  white">
//                     <p>Your paid subscription will end automatically after the expiry date. Before that point, you cannot cancel your subscription.</p>
//                 </div>
//                 <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
//                     <h2 className="faq-header"> How do I get access to the API?</h2>
//                 </div>
//                 <div className="card-description  white">
//                     <p>The API is only available on the Professional plan. You can read the API documentation <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'> here</a>.</p>
//                 </div>
//                 <div className="card-header white" style={{borderBottom: '1.5px solid white'}}>
//                     <h2 className="faq-header"> Have another question?</h2>
//                 </div>
//                 <div className="card-description white">
//                     <p>We would love to help - just email us at info@cherry-pick.xyz</p>
//                 </div>
//             </div>
//             </div>
//             <div style={{width: '100%', height: '100px'}}></div>
//         </section>
//     )
// } 


export function PricingTest2() {
    const [group, selectGroup] = useState(0)

    const { user, session } = Auth.useUser();
    
    const handlePayment = () => {
        if(!user) {
            console.log("USER NOt login")
            return
        }
        const orderId = uuidv4();
        console.log("orderId", `${orderId}`)
        console.log("userId", `${user.id}`)
        pay({
          serviceId: "22789",
          publicKey: "04edebf5c58e3c79710fe0b3cbab82330ab87ea8b4138c6ca849a11f72a4668ba6cba6d49d55989e5593e22ae24d33720120aa847e0e9e21e5c99cdc30dc8ce04a",
          orderId: `${orderId}`,
          userId: user.id,
          amount: "29",
          currency: "RUB",
          description: "YourDescription",
          onSuccess: function(res) {
            if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on success
            console.log("onSuccess from shop", res); // Log success response
          },
          onError: function(res) {
            // if (res.returnUrl) window.location.replace(res.returnUrl); // Redirect on error
            console.log("onFail from shop", res); // Log error response
          },
          onClose: function(res) {
            console.log("onClose from shop", res); // Log when widget is closed
          }
        });
    };
    // <div className="price-section" style={{position:'absolute', top: '0px', left: '0px', width: '200px', height: '200px', backgroundColor: 'red'}}>
    //     1000
    // </div>
    return (
        <section className="price-section">
            <div className="price-header" style={{marginBottom: '30px'}}>
                <h1 className="gradientColor2"> API Beta Pricing </h1>
                <p className="subheader" > Backtest, explore, build your own solutions and more... <br></br> using CherryPick API</p>
            </div>
            {/* <div className="price-header" style={{marginBottom: '0px'}}>
                <p className="subheader" > Select your group </p>
            </div> */}
            <div className="type-selector">
                <div className="selector-wrapper">
                    <p className={`option ${group == 0 ? 'selected':''}`} onClick={() => {selectGroup(0)}}>For whitelisted</p>
                    <p className={`option ${group == 1 ? 'selected':''}`} onClick={() => {selectGroup(1)}}>For others</p>
                </div>
            </div>
            <div className="price-cards-table">
                <div className="price-first-row">
                    <div className="card">
                        <div className="card-header">
                            <h1> Standard </h1>
                            {group == 0 && <div className="price-discount">
                                <h3><span className='strikediag withpadding'>$29</span></h3>
                            </div>}
                            <div className='price-month'>
                                <h1 style={{marginTop: '10px'}}>{group == 0 ? '$19': '$29'}</h1>
                                <p>per month</p>
                            </div>
                        </div>
                        <div className="card-description">
                            <p>Get access via API to on-chain metrics for ERC20 coins you see on the platform.</p>
                            <p style={{margin:0}}>Supported tokens:</p>
                            <ul>
                                <li> <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-assets' target='_blank'>All ERC-20 tokens</a> listed on the platform</li>
                            </ul>
                            <p style={{margin:0}}> All <a style={{color: '#DD4088'}} href='https://docs.cherry-pick.xyz/api/list-of-metrics' target='_blank'> on-chain metrics</a>:</p>
                            <ul>
                                <li>up to day resolution</li>
                                <li>full historical data</li>
                            </ul>
                            <p style={{margin:0}}> Supported address cohorts:</p>
                            <ul>
                                <li>only <a style={{color: '#DD4088'}}  href='https://docs.cherry-pick.xyz/api/address-cohorts' target='_blank'>total cohort</a></li>
                            </ul>
                            {/* <p style={{margin:0}}>Leaderboards:</p>
                            <ul>
                                <li>limited to 25 addresses per query </li>
                            </ul> */}
                        </div>
                        <div className="card-button"  onClick={handlePayment}>
                            <p>BUY</p>
                        </div>
                    </div>
                    
                    
                </div>
               
            </div>
            
            <div style={{width: '100%', height: '100px'}}></div>
        </section>
    )
}