import { React, useState, useRef, useEffect} from "react";
import { tokens, indexes } from "./data";
import arrow from "../assets/arrow.svg";
import ethereumLogo from "../assets/tokenLogos/ethereum.png"
import hideButton from "../assets/hideButton.svg"
import logo from "../assets/cherrypick_logo.svg"
import { tables } from "./dataTokenTracker"

import { DropDownTokens } from "./DropDownMenu";
import { convertTokensToList } from "./Filter";

import { ButtonLogin } from "./Navigation";

import { useTokens } from "./TokensContext";

import { TokenStyle } from "./DropDownMenu";

import "./Menu.css"

const listTokens = convertTokensToList(tokens)

function checkStarting(item, search) {
    return (item.name.toLowerCase().startsWith(search.toLowerCase())) 
                || 
           (!(item.symbol === undefined) ? item.symbol.toLowerCase().startsWith(search.toLowerCase()): false)
                ||
           (item.token_address.startsWith(search)) 
  }
  
function checkIncluding(item, search) {
    return (item.name.toLowerCase().includes(search.toLowerCase())) 
                || 
            (!(item.symbol === undefined) ? item.symbol.toLowerCase().includes(search.toLowerCase()): false) 
                ||
            (item.token_address.includes(search)) 
}

function convertToBlob(dict) {
    let resDict = {...dict}
    resDict.selectedFilters = Array.from(resDict.selectedFilters)

    Object.keys(resDict.fields).forEach(key => {
        // console.log('key', key, dict[key], Array.isArray(dict[key]))
        if(resDict.fields[key] instanceof Set) {
            resDict.fields[key] = Array.from(resDict.fields[key])
        }
    })
    return resDict
}

function getIndexByNewToken(items, token) {
    let index = 0
  
    items.forEach((item, id) => {
      if(item.slug === token) {
        index = id
      }
    })
    return items[index]
  }

export function HeaderToken(props) {
    // const [tokenInput, setTokenInput] = useState("")

    const [isMouseEnter, setMouseEnter] = useState(false)
    const style = props.custom ? 
    {
        backgroundColor: isMouseEnter? '#2B2835':'#110E1C',
        borderBottom: '0px',
        marginBottom: '12px',
    } 
        : 
    { 
        backgroundColor: isMouseEnter? "rgba(238, 40, 138, 0.2)" : "",
        cursor: 'pointer',
    }

    const styleLogo = {marginLeft: props.custom ? '12px':'33px'}

    return (
        <div className="tokenField" style={ style } 
            onMouseEnter={ () => setMouseEnter(true)}  
            onMouseLeave={ () => setMouseEnter(false)}
            onClick={ props.toggleFunction }
        >
            {props.isClicked &&
                <input 
                        autoFocus
                        style={{
                            backgroundColor: 'transparent',
                            outline: '0 none',
                            border: '0px',
                            // border: '2px solid rgba(255, 255, 255, 1)',
                            // borderRadius: '2px',
                            color: 'white',
                            fontSize: '14px',
                            height: '50px',
                            textIndent: '16px',
                            marginLeft: '20px'
                        }}
                        // placeholder='Write token for search'
                        type="text"
                        value={props.tokenInput}
                        onChange={(e) => props.setTokenInput(e.target.value)}
                    />
            }
            {!props.isClicked && <img className="tokenField-logo" style={styleLogo} src={ tokens[props.token].img } alt="text" />}
            {!props.isClicked && <div className="tokenField-name" >
                { tokens[props.token].name }
            </div>}
            <img className="tokenField-arrow" style={ { transform: props.isClicked ? 'rotate(180deg)': '' }} src={ arrow } />
        </div>
    )
}


function TokenField(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)
    const style = { 
        backgroundColor: isMouseEnter? (props.color? props.color : "rgba(238, 40, 138, 0.2)") : "",
        borderBottom: "0px",
        height: "40px",
        cursor: 'pointer',
    }
    const logoSize = "23px"
    if (!props.token.startsWith(props.tokenInput.toLowerCase())) {
        return (<div></div>)
    }
    return (
        <div className="tokenField" style={ style } 
            onMouseEnter={ () => setMouseEnter(!isMouseEnter) } 
            onMouseLeave={ () => setMouseEnter(!isMouseEnter) }
            onClick={ () => props.toggleFunction(props.token)}
        >
            <img className="tokenField-logo" style={{ width: logoSize, height: logoSize}} 
            src={ tokens[props.token].img } alt="text" />
            <div className="tokenField-name" 
            style={{ fontSize: "14px",
                     fontFamily: "SFProLight"}}>
                { tokens[props.token].name }
            </div>
        </div>
    )
}

export function DropDown(props) {
    return Object.entries(tokens).map(([key, token]) => (
        <TokenField 
            key={ key } 
            token={ key } 
            isHeader={ false } 
            selectedToken={ props.selectedToken } 
            toggleFunction={ props.toggleFunction }
            color={props.color}
            tokenInput={props.tokenInput}
            />
    ))
}

function IndexField(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)
    //console.log("Selected", props.selectedIndex)
    let isSelected = ((props.index === props.selectedIndex) || (props.index === props.selectedIndex[props.header]))
    const style = { 
        // backgroundColor: isMouseEnter || isSelected? "rgba(238, 40, 138, 0.2)" : "",
        backgroundColor: isMouseEnter || isSelected? "rgba(29, 26, 45, 0.5)" : "",
        cursor: 'pointer',
    }
    // console.log("SELECTED index", props.index, tokens[props.selectedToken].showingIndexes)
    let show = true
    if(props.selectedToken === 'btc') {
        show = tokens[props.selectedToken].showingIndexes.includes(props.index)
    }

    const textStyle = {
        color: (isMouseEnter || isSelected) && show ? "rgb(172, 209, 251)" : "rgb(154, 162, 170)",
        fontFamily: isSelected? 'SFpro' : 'SFProLight',
        marginLeft: isSelected? '28px': '33px',
        filter: show? '':'blur(1.5px)',
    }

    if(!show) {
        return (
            <div className="blockIndexes-index" style={{}}
            >
                    <div className="blockIndexes-text" style={textStyle}>{props.text}</div>
                    <p style={{marginTop:'0',
                               marginBottom:'0',
                               marginRight: '10px',
                               marginLeft: 'auto',
                               fontFamily: 'SFProLight',
                               fontSize: '14px',
                               border: "1px solid rgba(238, 40, 138, 1.0)",
                               borderRadius: "5px",
                               paddingLeft: "3px",
                               paddingRight: "3px",
                               color: "white",}}> Soon </p>
            </div>
        )
        // return 
    }

    return (
        <div className="blockIndexes-index" style={ style }
            onMouseEnter={ () => setMouseEnter(!isMouseEnter) } 
            onMouseLeave={ () => setMouseEnter(!isMouseEnter) }
            onClick={ () => {
                props.toggleFunction(props.index, props.header)
                if(props.isMobile) {props.toggleMobile()}
            }}
        >
            {isSelected && <div className="blockIndexes-box"></div> }
            <div className="blockIndexes-text" style={textStyle}>{props.text}</div>
        </div>
    )
}


export function IndexBlock(props) {
    //let header = "Transactions"
    //let indexes = ["Moving P&L", "SOPR", "On-chain volumes", "Whale outflows"]

    //indexes.map((text, index) => console.log(text, typeof index))
    
    let resIndexes = props.indexes.map((text, index) => <IndexField 
                                                              header={ props.header }
                                                              index={ props.startIndex + index } 
                                                              text={text}
                                                              selectedIndex={ props.selectedIndex }
                                                              toggleFunction={ props.toggleFunction }
                                                              toggleMobile={props.toggleMobile}
                                                              isMobile={props.isMobile }
                                                              selectedToken={props.selectedToken}
                                                              />)

    return (
        <div className="blockIndexes">
            <div className="blockIndexes-header">
                { props.header }
            </div>
            { resIndexes }
        </div>
    )
}

export function Menu(props) {
    // const [selectedToken, setToken] = useState("uniswap")
    // const [selectedIndex, setIndex] = useState(0)
    const myRef = useRef();

    // const { tokens: tokenItems, loading, error } = useTokens();
    // console.log('tokensdata', tokenItems)

    const tokenItems = props.tokens

    const [tokenInput, setTokenInput] = useState("")

    const [hideMenu, setHide] = useState(false)
    const [toggleDropDown, setDropDown] = useState(false)

    let styleMenu = hideMenu? {flex: "0 1 80px", minWidth: "50px"} : {}
    styleMenu['position'] = props.isMobile ? "absolute": ""
    styleMenu['backgroundColor'] = props.isMobile ? "#060111": ""

    const [isMouseEnter, setMouseEnter] = useState(false)
    const styleSubscribe = {cursor: 'pointer', background: isMouseEnter? '#FF54A9': '#EE288A'}
    // function setNewIndex(index) {
    //     console.log("New index", index)
    //     setIndex(index)
    // }

    // function setNewToken(tokenName) {
    //     console.log('setNewToken', tokenName)
    //     setToken(tokenName)
    // }
    useEffect(() => {
        // if (!hideMenu) return;
        // This function checks if the clicked target is inside our ref (i.e., our component)
        function handleClickOutside(event) {
          if (myRef.current && !myRef.current.contains(event.target)) {

            console.log("You clicked outside of me!");
            if(props.isMobile) {
                props.toggleMobile()
            }
          }
        }
    
        // Attach the click event listener
        document.addEventListener("mousedown", handleClickOutside);
    
        // Cleanup the event listener on component unmount
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    function toggleMenu() {
        setDropDown(!toggleDropDown)
    }

    let indexBlocks = []
    let currentIndex = 0
    for (let key in props.indexes) {
        // console.log("SHOWING INDEXES", props.selectedToken, props.showingIndexes, props.indexes[key])
        console.log("SHOWING INDEXES", props.showingIndexes)
        indexBlocks.push(<IndexBlock header={ key } 
                                     indexes={props.indexes[key]} 
                                     startIndex={currentIndex}
                                     selectedIndex={ props.selectedIndex } 
                                     toggleFunction={ props.setNewIndex }
                                     toggleMobile={props.toggleMobile}
                                     isMobile={props.isMobile }
                                     selectedToken={props.selectedToken}
                                     />)
        currentIndex += props.indexes[key].length
    }

    return (
        <div className="menu" style={styleMenu} ref={myRef}>
            {/* { !hideMenu && <div className="blockchainField"> */}
                {/* <img className="tokenField-logo" style={{width: '30px' , height: '30px'}} src={ logo } alt="text" /> */}
                {/* <img src={ logo } /> */}
                {/* <div className="tokenField-name" >
                    Ethereum
                </div> */}
                {/* <div className="chartHeader-text" style={{marginLeft: '0px'}}>
                    herrypick
                </div> */}
            {/* </div>
            } */}
            
            {/* <DropDownTokens menuButton={true} items={listTokens} size={5} selectedToken={props.selectedToken} setNewToken={props.setNewToken}/> */}
            { (!hideMenu ) && !props.old && <DropDownTokens 
                            menuButton={true} 
                            items={tokenItems} 
                            size={5} 
                            selectedToken={props.selectedToken} 
                            setNewToken={props.setNewToken}
                            
                            getItemFunc={getIndexByNewToken}
                            setElement={(item) => props.setNewToken(item.slug)}

                            renderStyle={ 
                                    (input, header, additionalProps = {}) => <TokenStyle item={{name: input.name, img: input.logo}} header={header} {...additionalProps}/>
                                }
                            placeholder={"Search by name, address..."}

                            checkIncludingFunc={checkIncluding}
                            checkStartingFunc={checkStarting}
                            
                        />}
            { (!hideMenu ) && props.old && <DropDownTokens 
                            menuButton={true} 
                            items={tokenItems} 
                            size={5} 
                            selectedToken={props.selectedToken} 
                            setNewToken={props.setNewToken}
                            
                            placeholder={"Search by name..."}
                            
                        />}
            
            {/* { !hideMenu && <HeaderToken
                token={ props.selectedToken } 
                isHeader={ true }
                isClicked={ toggleDropDown }
                toggleFunction={ toggleMenu }
                tokenInput={tokenInput}
                setTokenInput={setTokenInput}
            /> } */}
            { !hideMenu && toggleDropDown && <div className="dropDown-menu"> 
                                        <DropDown 
                                            selectedToken={ props.selectedToken } 
                                            toggleFunction={ props.setNewToken }
                                            tokenInput={tokenInput}
                                        /> 
                                </div> }
            {/* { toggleDropDown && <div style={{color:"white"}}> Fuck this all! </div>} */}
            {/* <IndexBlock selectedIndex={ selectedIndex } toggleFunction={ setNewIndex }/>
            <IndexBlock selectedIndex={ selectedIndex } toggleFunction={ setNewIndex }/> */}
            <div className="blocksContainer">
            { !hideMenu && indexBlocks }
            { !props.isMobile && !hideMenu && <div className="subscribeSection">
                <div className="subscribeSection-el1">
                    {/* <div style={styleSubscribe} role="button" onClick={()=> openInNewTab("https://twitter.com/cherrypick_xyz")} 
                    onMouseEnter={ () => setMouseEnter(true) } 
                    onMouseLeave={ () => setMouseEnter(false) }
                    className="subscribe">
                        <p style={{color: isMouseEnter? "": ""}}> Insights </p>
                    </div> */}
                    <ButtonLogin blackTheme={true} name={'SUPPORT'} link={'https://discord.gg/83GV9zTt2r'}/>
                    {/* <img src={ hideButton } alt="text" onClick={() => setHide(!hideMenu)} /> */}
                    <button className="menu-button-charts" onClick={() => setHide(!hideMenu)}>
                        <svg viewBox="0 0 24 24">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                        </svg>
                    </button>
                </div>
                {/* <div className="subscribeSection-el2">
                    <div className="digest">
                        <p>Weekly digest</p>
                    </div>
                </div> */}
            </div> }
            </div>
            { hideMenu && <div className="subscribeSection">
                <div className="subscribeSection-el1" style={{marginLeft: '18px'}}>
                    {/* <img src={ hideButton } alt="text" onClick={() => setHide(!hideMenu)} /> */}
                    <button className="menu-button-charts" onClick={() => setHide(!hideMenu)}>
                        <svg viewBox="0 0 24 24">
                            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"/>
                        </svg>
                    </button>
                </div> 
                </div> }
        </div>
    )   
}

export function MenuLeaderBoard(props) {
    const myRef = useRef();

    const [hideMenu, setHide] = useState(false)
    const [toggleDropDown, setDropDown] = useState(false)

    let styleMenu = hideMenu? {flex: "0 1 80px", minWidth: "50px"} : {}
    styleMenu['position'] = props.isMobile ? "absolute": ""
    styleMenu['backgroundColor'] = props.isMobile ? "#060111": ""

    const [isMouseEnter, setMouseEnter] = useState(false)
    const styleSubscribe = {cursor: 'pointer', background: isMouseEnter? '#FF54A9': '#EE288A'}
    // function setNewIndex(index) {
    //     console.log("New index", index)
    //     setIndex(index)
    // }

    // function setNewToken(tokenName) {
    //     console.log('setNewToken', tokenName)
    //     setToken(tokenName)
    // }

    useEffect(() => {
        // This function checks if the clicked target is inside our ref (i.e., our component)
        function handleClickOutside(event) {
          if (myRef.current && !myRef.current.contains(event.target)) {
            console.log("You clicked outside of me!");
            if(props.isMobile) {
                props.toggleMobile()
            }
          }
        }
    
        // Attach the click event listener
        document.addEventListener("mousedown", handleClickOutside);
    
        // Cleanup the event listener on component unmount
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    function toggleMenu() {
        setDropDown(!toggleDropDown)
    }

    let indexBlocks = []
    let currentIndex = 0
    for (let key in tables) {
        indexBlocks.push(<IndexBlock header={ key } 
                                     indexes={tables[key]} 
                                     startIndex={currentIndex}
                                     selectedIndex={ props.selectedIndex } 
                                     toggleFunction={ props.setNewIndex }
                                     toggleMobile={props.toggleMobile}
                                     isMobile={props.isMobile }/>)
        currentIndex += tables[key].length
    }

    return (
        <div className="menu" style={styleMenu} ref={myRef}>
            <div className="blocksContainer">
            { !hideMenu && indexBlocks }
            { !props.isMobile && !hideMenu && <div className="subscribeSection">
                <div className="subscribeSection-el1">
                    {/* <div style={styleSubscribe} role="button" onClick={()=> openInNewTab("https://twitter.com/cherrypick_xyz")} 
                    onMouseEnter={ () => setMouseEnter(true) } 
                    onMouseLeave={ () => setMouseEnter(false) }
                    className="subscribe">
                        <p style={{color: isMouseEnter? "": ""}}> Insights </p>
                    </div> */}
                    <ButtonLogin blackTheme={true} name={'SUPPORT'} link={'https://discord.gg/83GV9zTt2r'}/>
                    {/* <img src={ hideButton } alt="text" onClick={() => setHide(!hideMenu)} /> */}
                    <button className="menu-button-charts" onClick={() => setHide(!hideMenu)}>
                        <svg viewBox="0 0 24 24">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                        </svg>
                    </button>
                </div>
                {/* <div className="subscribeSection-el2">
                    <div className="digest">
                        <p>Weekly digest</p>
                    </div>
                </div> */}
            </div> }
            </div>
            { hideMenu && <div className="subscribeSection">
                <div className="subscribeSection-el1" style={{marginLeft: '18px'}}>
                    {/* <img src={ hideButton } alt="text" onClick={() => setHide(!hideMenu)} /> */}
                    <button className="menu-button-charts" onClick={() => setHide(!hideMenu)}>
                        <svg viewBox="0 0 24 24">
                            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"/>
                        </svg>
                    </button>
                </div> 
                </div> }
        </div>
    )   
}

// function SubList(props) {
//     function ListElement(props) {
//         const [isMouseEnter, setMouseEnter] = useState(false)

//         const styleElement = {
//             color: (props.selected || isMouseEnter) ? '#ACD1FB':'white',
//             margin: 0,  
//             fontFamily: 'SFProReg',
//             fontSize: '14px',
//             marginLeft: '20px',
//         }

//         return (
//             <div style={{paddingBottom: '5px', 
//                          paddingTop: '5px', 
//                          backgroundColor: (props.selected || isMouseEnter) ? 'rgba(29, 26, 45, 0.5)':'', 
//                          cursor: 'pointer',}}
//                  onMouseEnter={ () => setMouseEnter(true)}  
//                  onMouseLeave={ () => setMouseEnter(false)}
//                  onClick={() => props.toggleFunction(props.id)}
//                 >
//                 <p style={styleElement}>{props.name}</p>
//             </div>
//         )
//     }

//     async function saveFilter() {
//         if(props.user) {
//             const {data, error} = await props.supabaseClient
//                                     .from('filters')
//                                     .insert([
//                                         {
//                                             user_id: props.user.id,
//                                             filter_data: convertToBlob(props.defaultVariables),
//                                             is_public: 'false',
//                                             name: 'Your New Filter',
//                                         }
//                                     ])
//                                     .select()
//             console.log("SAVE DATa", data, error)
//             props.setFilters(prevFilters => {
//                 return [...prevFilters, {id: data.id, group: "FILTERS", name: "Your New Filter"}]
//             })
//             props.setFilter(data.id, props.defaultVariables)
//         }
//         // props.setModalIsOpen(true)
//     }


//     return (
//         <div>
//             <div className="menu-sublist">
//                 <h1 style={{marginLeft: '20px', marginTop: '25.72px'}}>{props.group}</h1>
//                 {props.data.map(value => (value.group === props.group) && <ListElement key={value.id} id={value.id} name={value.name} selected={props.selectedId===value.id} toggleFunction={props.toggleFunction} />) }
//                 <div class="filter-button" onClick={() => saveFilter()}>
//                     <div class="plus-sign">+</div>
//                 </div>
//             </div>
//         </div>
//     )

// }

// export function MenuFilters(props) {

//     function getNamesByGroup(data) {
//         const groups = new Set(data.map(element => element.group))
//         console.log('GET NAMES', groups)
//         return Array.from(groups)
//     }

//     return (
//         <div className="menu" style={{borderRight: '2px solid rgb(29, 26, 45)'}}>
//             {getNamesByGroup(props.filters).map(value => <SubList group={value} data={props.filters} selectedId={props.id} toggleFunction={props.toggleFunction} saveFilter={props.saveFilter}
//                                                             user={props.user}
//                                                             defaultVariables={props.defaultVariables}
//                                                             supabaseClient={props.supabaseClient}
//                                                             setFilter={props.setFilter}
//                                                             setFilters={props.setFilters}/>)}
//         </div>
//     )
// }


// export function MenuFilters({ groupedFilters, activeFilterId, onSelectFilter }) {
//     return (
//         <div className="menu-filters" style={{color: 'white', width: '25    0px'}}>
//             {Object.entries(groupedFilters).map(([groupName, filtersList]) => (
//                 <div key={groupName} className="filter-group">
//                     <h3 className="group-title">{groupName}</h3>
//                     <ul>
//                         {filtersList.map(filter => (
//                             <li key={filter.id}>
//                                 <button
//                                     className={filter.id === activeFilterId ? 'active' : ''}
//                                     onClick={() => onSelectFilter(filter.id)}
//                                 >
//                                     {filter.name}
//                                 </button>
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//             ))}
//             <button className="create-new">Create New Filter</button>
//         </div>
//     );
// }
// export const Tooltip = ({ children, message }) => (
//     <div className="tooltip">
//       {children}
//       <span className="tooltiptext">{message}</span>
//     </div>
// );

export function MenuFilters({
    filtersByGroup,
    activeFilterId,
    onSelectFilter,
    createFilter
    // user,
    // defaultVariables,
    // supabaseClient,
    // setFilter,
    // setFilters
}) {
    function ListElement({ id, filter, selected, toggleFunction }) {
        const [isMouseEnter, setMouseEnter] = useState(false);

        const styleElement = {
            color: (selected || isMouseEnter) ? '#ACD1FB' : 'white',
            margin: 0,
            fontFamily: 'SFProReg',
            fontSize: '14px',
            marginLeft: '8px',
        };

        return (
            <div
                style={{
                    paddingBottom: '5px',
                    paddingTop: '5px',
                    backgroundColor: (selected || isMouseEnter) ? 'rgba(29, 26, 45, 0.5)' : '',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
                onMouseEnter={() => setMouseEnter(true)}
                onMouseLeave={() => setMouseEnter(false)}
                onClick={() => toggleFunction(id)}
            >
                <img className="tokenField-logo" style={{marginLeft: '20px', width: '20px', height: '20px'}} src={ tokens[filter.token].img } alt="text" />
                <p style={styleElement}>{filter.name}</p>
                {!filter.isSaved && (
                //   <Tooltip message="This filter is not saved. It is currently stored in your browser's memory and can be lost at any time. To save the filter and make it public, click the 'SAVE' button.">
                    <span className="warning-icon">⚠️</span>
                //   </Tooltip>
                )}
            </div>
        );
    }

    return (
        <div className="menu" style={{ borderRight: '2px solid rgb(29, 26, 45)', display: 'flex', flexDirection: 'column' }}>
            <div className="menu-content">
            {Object.entries(filtersByGroup).map(([groupName, filters]) => (
                <div>
                    <div className="menu-sublist">
                                 <div className="sublist-header">
                                    <h1 style={{marginLeft: '20px', marginTop: '25.72px'}}>{groupName}</h1>
                                    {groupName === "Custom Filters" && (<div className="filter-button" onClick={createFilter}>
                                        <div className="plus-sign">
                                            <div className="horizontal-bar"></div>
                                            <div className="vertical-bar"></div>
                                        </div>
                                    </div>)}
                                 </div>
                                 
                                 {filters.map(filter => (
                            <ListElement
                                key={filter.id}
                                id={filter.id}
                                filter={filter}
                                selected={activeFilterId === filter.id}
                                toggleFunction={onSelectFilter}
                            />
                        ))}
                                 {/* {groupName === "Your filters" && (
                                    <div className="filter-button" onClick={createFilter}>
                                        <div className="plus-sign">
                                            <div className="horizontal-bar"></div>
                                            <div className="vertical-bar"></div>
                                        </div>
                                    </div>
                                )} */}
                    </div>
                </div>
                // <div key={groupName} className="filter-group">
                //     <h3 className="group-title" style={{ marginLeft: '20px', marginTop: '25.72px' }}>{groupName}</h3>
                //     <ul>
                //         {filters.map(filter => (
                //             <ListElement
                //                 key={filter.id}
                //                 id={filter.id}
                //                 name={filter.name}
                //                 selected={activeFilterId === filter.id}
                //                 toggleFunction={onSelectFilter}
                //             />
                //         ))}
                //     </ul>
                //     {groupName === "Your filters" && (
                //         <div className="filter-button" onClick={saveFilter}>
                //             <div className="plus-sign">+</div>
                //         </div>
                //     )}
                // </div>
            ))}
            </div>
            <div className="menu-footer">
        {/* Footer content here */}
                <div className="button-class button-query" style={{height: '40px', marginLeft: '0'}} 
                    onClick={() => {window.open('https://discord.gg/27yKY5jbRE', '_blank', 'noopener,noreferrer');}}>
                    <p style={{padding: '0em 2em', margin: 0}}>SUPPORT</p>
                </div>
      </div>
        </div>
    );
}



// export default function Menu() {
//     return (
//         <div className="menu">
//             <DropDown />
//         </div>
//     )
// }