import { DataGrid } from '@mui/x-data-grid';
import { TokenTableField, NewTokenTableField, ShowPercentChange } from './utils';
import { addCommas, parseValueForTooltip } from '../ChartSettings/utils';


const columnNames = [
    'Token', 
    'Price', 
    '1d %', 
    '7d %', 
    'Realised Cap', 
    '7d %', 
    '30d %',
    'Market Cap',
    '7d %',
    '30d %',
    'Number of addresses',
    '30d %'
]

export const columns = [
    { 
        field: 'token_address', 
        headerName: 'Token', 
        width: 200,
        cellClassName: 'datagrid-token',
        renderCell: (props) => { return (<NewTokenTableField value={props.value}/>) },
        sortable: false,
    },
    {
        field: 'price_0d',
        headerName: 'Price',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'price_percent_7d',
        headerName: '7d %',
        width: 70,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    {
        field: 'price_percent_30d',
        headerName: '30d %',
        width: 70,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    {
        field: 'realised_price_1d',
        headerName: 'Realised Price',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'realised_price_percent_7d',
        headerName: '7d %',
        width: 70,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    {
        field: 'realised_price_percent_30d',
        headerName: '30d %',
        width: 70,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    {
        field: 'mvrv_1d',
        headerName: 'MVRV',
        width: 100,
        description: 'MVRV=(Market Cap)/(Realised Cap)',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'mvrv_percent_7d',
        headerName: '7d %',
        width: 70,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
    {
        field: 'mvrv_percent_30d',
        headerName: '30d %',
        width: 70,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    },
]

export const initialState = {
    sorting: {
      sortModel: [{ field: 'mvrv_1d', sort: 'desc' }],
    },
}