import { React }from 'react';
import { usePopup } from './popupContext';

const Popup = () => {
  const { popup, setPopup } = usePopup();

  if (!popup.show) return null;

  const handleActionClick = (action) => {
    if (action.onClick) {
      action.onClick();
    }
    setPopup({ ...popup, show: false });
  };


  return (
    <div className="popup">
        <div className="popup-content">
            <h1>{popup.header}</h1>
            {popup.messages.map((message, index) => <p key={index}>{message}</p>)}
            <div className="popup-actions">
              {popup.actions.map((action, index) => (
                <button key={index} style={action.style ? action.style : {marginLeft: 0}} className="button-class" onClick={() => handleActionClick(action)}>
                  <p key={index} style={{padding: '0em 2em', margin: 0}}>{action.label}</p>
                </button>
              ))}
            </div>
        </div>
    </div>
  );
};

export default Popup;