import { DataGrid } from '@mui/x-data-grid';
import { TokenTableField, ShowPercentChange } from './utils';
import { addCommas, parseValueForTooltip } from '../ChartSettings/utils';

import { ShowAddress, ShowPercent, timeSince } from './utils';
import { ShowDollar } from '../dataPortfolio';

export const columns = [
    { 
        field: 'id', 
        headerName: '', 
        width: 5,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{props.value}</p>)},
    },
    { 
        field: 'address', 
        headerName: 'Address', 
        width: 200,
        // headerClassName: 'datagrid-separator-disable', 
        renderCell: (props) =>  { return (<ShowAddress value={props.value.address} token={props.value.token} tag={props.value.tag}/>)},
    },
    { 
        field: 'balance', 
        headerName: 'Balance', 
        width: 100,
        cellClassName: 'datagrid-balance',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}</p>)},
        hide: true,
    },
    { 
        field: 'balance_dollar', 
        headerName: 'Balance ($)', 
        width: 100,
        cellClassName: 'datagrid-balance',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    { 
        field: 'tx_number_received', 
        headerName: '# Transfers Received', 
        width: 130,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{props.value}</p>)},
    },
    { 
        field: 'tx_number_sent', 
        headerName: '# Transfers Sent', 
        width: 130,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{props.value}</p>)},
    },
    { 
        field: 'tx_number_total', 
        headerName: '# Transfers', 
        width: 80,
        renderCell: (props) =>  { return (<p>{props.value}</p>)},
    },
    {
        field: 'nupl',
        headerName: 'Net Unrealised P&L',
        width: 140,
        description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'nmpl',
        headerName: 'Net Profit / Loss',
        width: 140,
        description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'profits',
        headerName: 'Realised Profit',
        width: 140,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'losses',
        headerName: 'Realised Loss',
        width: 140,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={-1 * props.value}/>) },
    },
    {
        field: 'win_rate',
        headerName: 'Win Rate',
        width: 70,
        description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowPercent value={props.value}/>) },
    },
    {
        field: 'average_roi_per_tx',
        headerName: 'Avg. ROI',
        width: 100,
        description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<ShowDollar value={props.value} percent={true}  withoutDollar={true}/> )},
    },

    { 
        field: 'volume', 
        headerName: 'Total Volume', 
        width: 125,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}</p>)},
    },
    { 
        field: 'volume_dollar', 
        headerName: 'Total Volume ($)', 
        width: 125,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },

    { 
        field: 'volume_received', 
        headerName: 'Volume Received', 
        width: 125,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}</p>)},
    },
    { 
        field: 'volume_dollar_received', 
        headerName: 'Volume Received ($)', 
        width: 125,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    { 
        field: 'volume_sent', 
        headerName: 'Volume Sent', 
        width: 125,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}</p>)},
    },
    { 
        field: 'volume_dollar_sent', 
        headerName: 'Volume Sent ($)', 
        width: 125,
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },


    {
        field: 'inflow_dollar_1d',
        headerName: '1d Inflow ($)',
        width: 100,
        // description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'outflow_dollar_1d',
        headerName: '1d Outflow ($)',
        width: 100,
        // description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'netflow_dollar_1d',
        headerName: '1d Netflow ($)',
        width: 100,
        // description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        // renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'npl_1d',
        headerName: '1d P&L',
        width: 100,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'inflow_dollar_7d',
        headerName: '7d Inflow ($)',
        width: 100,
        // description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'outflow_dollar_7d',
        headerName: '7d Outflow ($)',
        width: 100,
        // description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'netflow_dollar_7d',
        headerName: '7d Netflow ($)',
        width: 100,
        // description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        // renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'npl_7d',
        headerName: '7d P&L',
        width: 100,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },

    {
        field: 'inflow_dollar_30d',
        headerName: '30d Inflow ($)',
        width: 100,
        // description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'outflow_dollar_30d',
        headerName: '30d Outflow ($)',
        width: 100,
        // description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'netflow_dollar_30d',
        headerName: '30d Netflow ($)',
        width: 100,
        // description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        headerClassName: 'datagrid-separator-disable',
        // renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'npl_30d',
        headerName: '30d P&L',
        width: 100,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },

    {
        field: 'first_transfer_datetime',
        headerName: 'First Transfer Time',
        width: 150,
        renderCell: (props) => { return (<p> {timeSince(props.value)} ago</p>)}
    },
    {
        field: 'first_transfer_received_datetime',
        headerName: 'First Transfer Received Time',
        width: 150,
        renderCell: (props) => { return (<p> {timeSince(props.value)} ago</p>)}
    },
    {
        field: 'first_transfer_sent_datetime',
        headerName: 'First Transfer Sent Time',
        width: 150,
        renderCell: (props) => { return (<p> {timeSince(props.value)} ago</p>)}
    },
    {
        field: 'last_transfer_datetime',
        headerName: 'Last Transfer Time',
        width: 150,
        renderCell: (props) => { return (<p> {timeSince(props.value)} ago</p>)}
    },
    {
        field: 'last_transfer_received_datetime',
        headerName: 'Last Transfer Received Time',
        width: 150,
        renderCell: (props) => { return (<p> {timeSince(props.value)} ago</p>)}
    },
    {
        field: 'last_transfer_sent_datetime',
        headerName: 'Last Transfer Sent Time',
        width: 150,
        renderCell: (props) => { return (<p> {timeSince(props.value)} ago</p>)}
    },

    { 
        field: 'pers_transfers_with_sc', 
        headerName: '% of Transfers with Smart Contracts', 
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}%</p>)},
    },
    { 
        field: 'pers_transfers_with_cex', 
        headerName: '% of Transfers with CEXs', 
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}%</p>)},
    },
    { 
        field: 'pers_transfers_with_dex', 
        headerName: '% of Transfers with DEXs', 
        width: 150,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}%</p>)},
    },
    { 
        headerName: '', 
        width: 200,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{props.value}</p>)},
    },
]