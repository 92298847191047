import { getShortNameMapping } from "./data"

import { columns as columnsTopGainers} from "./TableSettings/TopGainers.js"
import { columns7days, columns30days} from "./TableSettings/TopGainersPeriod.js"
import { columns as columnsTopHolders, columns_sc as columnsTopSC,
    columns_cex as columnsTopCEX, columns_fund as columnsTopFunds} from "./TableSettings/TopHolders.js"
import {columns_flows_1d as columnTopFlows1days,
    columns_flows_7d as columnTopFlows7days,
    columns_flows_30d as columnTopFlows30days} from "./TableSettings/TopNetflow.js"

// import { initialState as initialStateHODL } from "./TableSettings/HODLTable.js";
// import { initialState as initialStateMacro } from "./TableSettings/MacroTable.js";
// import { initialState as initialStateCEX } from "./TableSettings/CEXTable";

export const tables = {
    "Top Wallets by Balance": [
        "Top Holders (excluding Exchanges & Contracts)",
        "Top Funds",
        "Top Exchanges",
        // "Top Smart Contracts",
    ],
    "Top Buying/Selling Addresses": [
        "1d Top Buying Addresses",
        "1d Top Selling Addresses",
        "7d Top Buying Addresses",
        "7d Top Selling Addresses",
        "30d Top Buying Addresses",
        "30d Top Selling Addresses",
    ],

    "Top Gainers": [
        "Top Gainers by ROI",
        "Top Gainers by Net Profit",
        "7d Top Gainers by ROI",
        "7d Top Gainers by Net Profit",
        "30d Top Gainers by ROI",
        "30d Top Gainers by Net Profit",
    ],

    "Top Losers": [
        "Top Losers by ROI",
        "Top Losers by Net Profit",
        "7d Top Losers by ROI",
        "7d Top Losers by Net Profit",
        "30d Top Losers by ROI",
        "30d Top Losers by Net Profit",
    ],
    "Early Investors": [
        "Early Investors by Net Profit",
        "Early Investors by Balance",
    ],
}


export const tableIndexes = {
    "1d Top Buying Addresses": {
        shortName: "top-addresses-inflow-1days",
        apiPath: "top-addresses-inflow-1days",
        columnType: columnTopFlows1days,
    },
    "1d Top Selling Addresses": {
        shortName: "top-addresses-outflow-1days",
        apiPath: "top-addresses-outflow-1days",
        columnType: columnTopFlows1days,
    },
    "7d Top Buying Addresses": {
        shortName: "top-addresses-inflow-7days",
        apiPath: "top-addresses-inflow-7days",
        columnType: columnTopFlows7days,
    },
    "7d Top Selling Addresses": {
        shortName: "top-addresses-outflow-7days",
        apiPath: "top-addresses-outflow-7days",
        columnType: columnTopFlows7days,
    },
    "30d Top Buying Addresses": {
        shortName: "top-addresses-inflow-30days",
        apiPath: "top-addresses-inflow-30days",
        columnType: columnTopFlows30days,
    },
    "30d Top Selling Addresses": {
        shortName: "top-addresses-outflow-30days",
        apiPath: "top-addresses-outflow-30days",
        columnType: columnTopFlows30days,
    },


    "Top Funds": {
        shortName: "top-holders-funds",
        apiPath: "top-holders-funds",
        columnType: columnsTopFunds,
    },

    "Top Holders (excluding Exchanges & Contracts)": {
        shortName: "top-holders-addresses",
        apiPath: "top-holders-addresses",
        columnType: columnsTopHolders,
    },
    "Top Exchanges": {
        shortName: "top-holders-cex",
        apiPath: "top-holders-cex",
        columnType: columnsTopCEX,
    },
    // "Top Smart Contracts": {
    //     shortName: "top-holders-sc",
    //     apiPath: "top-holders-sc",
    //     columnType: columnsTopSC,
    // },

    "Early Investors by Net Profit": {
        shortName: "early-investors-net-profit",
        apiPath: "early-investors-net-profit",
        columnType: columnsTopGainers,
    },
    "Early Investors by Balance": {
        shortName: "early-investors-balance",
        apiPath: "early-investors-balance",
        columnType: columnsTopGainers,
    },

    "Top Gainers by ROI": {
        shortName: "top-gainers-roi",
        apiPath: "top-gainers-roi",
        columnType: columnsTopGainers,
    },
    "Top Gainers by Net Profit": {
        shortName: "top-gainers-net-profit",
        apiPath: "top-gainers-net-profit",
        columnType: columnsTopGainers,
    },
    "7d Top Gainers by ROI": {
        shortName: "top-gainers-roi-7days",
        apiPath: "top-gainers-roi-7days",
        columnType: columns7days,
    },
    "7d Top Gainers by Net Profit": {
        shortName: "top-gainers-net-profit-7days",
        apiPath: "top-gainers-net-profit-7days",
        columnType: columns7days,
    },
    "30d Top Gainers by ROI": {
        shortName: "top-gainers-roi-30days",
        apiPath: "top-gainers-roi-30days",
        columnType: columns30days,
    },
    "30d Top Gainers by Net Profit": {
        shortName: "top-gainers-net-profit-30days",
        apiPath: "top-gainers-net-profit-30days",
        columnType: columns30days,
    },
    // "Whales": {
    //     shortName: "top-balances",
    //     apiPath: "top-balances",
    //     columnType: columnsWhales,
    // },
    // "Holders by All-Time Volume": {
    //     shortName: "top-holders-volume",
    //     apiPath: "top-holders-volume",
    //     columnType: columnsWhales,
    // }

    "Top Losers by ROI": {
        shortName: "top-losers-roi",
        apiPath: "top-losers-roi",
        columnType: columnsTopGainers,
    },
    "Top Losers by Net Profit": {
        shortName: "top-losers-net-profit",
        apiPath: "top-losers-net-profit",
        columnType: columnsTopGainers,
    },
    "7d Top Losers by ROI": {
        shortName: "top-losers-roi-7days",
        apiPath: "top-losers-roi-7days",
        columnType: columns7days,
    },
    "7d Top Losers by Net Profit": {
        shortName: "top-losers-net-profit-7days",
        apiPath: "top-losers-net-profit-7days",
        columnType: columns7days,
    },
    "30d Top Losers by ROI": {
        shortName: "top-losers-roi-30days",
        apiPath: "top-losers-roi-30days",
        columnType: columns30days,
    },
    "30d Top Losers by Net Profit": {
        shortName: "top-losers-net-profit-30days",
        apiPath: "top-losers-net-profit-30days",
        columnType: columns30days,
    },
}

export const [shortNames, shortNames2Index] = getShortNameMapping(tables, tableIndexes)
export const indexNames = Object.entries(tables).flatMap(([key, name]) => name)