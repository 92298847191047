import { DataGrid } from '@mui/x-data-grid';
import { TokenTableField, ShowPercentChange, TokenTableFieldHash, AmountBalance, timeSince } from './utils';
import { addCommas, parseValueForTooltip } from '../ChartSettings/utils';
import { ShowDollar } from '../dataPortfolio';
import { gridClasses } from '@mui/x-data-grid';


export const columns = [
    { 
        field: 'token_address', 
        headerName: 'Token', 
        width: 320,
        cellClassName: 'datagrid-token',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<TokenTableFieldHash params={props}/>) },
        sortable: false,
    },
    // {
    //     field: 'price',
    //     headerName: 'Price',
    //     width: 150,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)}
    // },
    // {
    //     field: 'balance',
    //     headerName: 'Balance',
    //     width: 200,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<AmountBalance params={props}/>) }
    // },
    {
        field: 'balance_dollar',
        headerName: 'Value',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'total_pl',
        headerName: 'Overall PnL',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{<ShowDollar value={props.value} posColor={'rgb(172, 209, 251)'}/>}</p>)}
    },
    {
        field: 'unrealised_pl',
        headerName: 'Unrealised PnL',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{<ShowDollar value={props.value}  posColor={'rgb(172, 209, 251)'} />}</p>)}
    },
    {
        field: 'realised_pl',
        headerName: 'Realised PnL',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{<ShowDollar value={props.value} posColor={'rgb(172, 209, 251)'} />}</p>)}
    }
    // {
    //     field: 'num_transfers',
    //     headerName: '# Transfers ',
    //     width: 100,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>{props.value}</p>)}
    // },
    // {
    //     field: 'last_transfer_timestamp',
    //     headerName: 'Last Transfer',
    //     width: 120,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>{`${timeSince(props.value)} ago`}</p>)}
    // }
    // {
    //     field: 'last_block_number',
    //     headerName: '# Block',
    //     width: 100,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>{props.value}</p>)}
    // }
]

export const miniColumns = [
    { 
        field: 'token_address', 
        headerName: 'Token', 
        width: 260,
        cellClassName: 'datagrid-token',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<TokenTableFieldHash params={props}/>) },
        sortable: false,
    },
    // {
    //     field: 'balance',
    //     headerName: 'Balance',
    //     width: 150,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<AmountBalance params={props}/>) }
    // },
    {
        field: 'balance_dollar',
        headerName: 'Value',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'total_pl',
        headerName: 'Overall PnL',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{<ShowDollar value={props.value} posColor={'rgb(172, 209, 251)'} />}</p>)}
    },
    // {
    //     field: 'price',
    //     headerName: 'Price',
    //     width: 100,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)}
    // },
    // {
    //     field: 'num_transfers',
    //     headerName: '# Transfers ',
    //     width: 100,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>{props.value}</p>)}
    // },
    // ,
    // {
    //     field: 'last_transfer_timestamp',
    //     headerName: 'Last Transfer',
    //     width: 100,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>{`${timeSince(props.value)} ago`}</p>)}
    // }
    // {
    //     field: 'last_block_number',
    //     headerName: '# Block',
    //     width: 100,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>{props.value}</p>)}
    // }
]

export const realisedColumns = [
    { 
        field: 'token_address', 
        headerName: 'Token', 
        width: 320,
        cellClassName: 'datagrid-token',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<TokenTableFieldHash params={props}/>) },
        sortable: false,
    },
    {
        field: 'realised_pl',
        headerName: 'Realised PnL',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{<ShowDollar value={props.value} posColor={'rgb(172, 209, 251)'}/>}</p>)}
    },
    {
        field: 'realised_profit',
        headerName: 'Profit',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{<ShowDollar value={props.value} posColor={'rgb(172, 209, 251)'}/>}</p>)}
    },
    {
        field: 'realised_loss',
        headerName: 'Loss',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{<ShowDollar value={props.value} posColor={'rgb(172, 209, 251)'}/>}</p>)}
    },
    {
        field: 'num_transfers',
        headerName: '# Transfers ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{props.value}</p>)}
    },

]

export const miniRealisedColumns = [
    { 
        field: 'token_address', 
        headerName: 'Token', 
        width: 260,
        cellClassName: 'datagrid-token',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<TokenTableFieldHash params={props}/>) },
        sortable: false,
    },
    {
        field: 'realised_pl',
        headerName: 'Realised PnL',
        width: 150,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{<ShowDollar value={props.value} posColor={'rgb(172, 209, 251)'}/>}</p>)}
    },
]

export const tableStyle = {
    // m: 2,
    borderRadius: 0,
    color: 'white',
    borderWidth: '0px',
    borderColor: '#EE288A',
    marginLeft: '0px',
    marginRight: '0px',
    backgroundColor: 'rgba(238, 40, 138, 0.0)',
    // [`& .${gridClasses.row}.even`]: {
    //     backgroundColor: 'rgba(29, 26, 45, 0.5)',
        
    // },
    '.transparent-row': {
        opacity: 0.5, // Adjust the opacity as needed
    },
    '& .MuiDataGrid-columnHeaders': {
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiDataGrid-columnHeader': {
        // marginLeft: '14px',
        padding: '0px',
    },
    '& .MuiDataGrid-footerContainer': {
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiDataGrid-main': {
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiTablePagination-root': {
        color: 'white',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
        fontSize: '14px',
        fontFamily: 'SFProLight',
        borderWidth: '0.0px',
        borderColor: '#EE288A',
    },
    '& .MuiDataGrid-row': {
        borderTop: 'solid 0.5px rgb(123, 122, 149)',
        // height: '50px',
        // maxHeight: '50px',
        // minHeight: '50px',
        // borderBottom: 'solid 1px red',
    },
    '& .MuiDataGrid-cell': {
        fontFamily:'SFProLight',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        color: 'white',
        // boxSizing: '0.1px',
        // borderBottom: '1px',
        // marginLeft: '14px',
        borderWidth: '0.0px',
        borderColor: '#EE288A',
        padding: '0px',
    },
    '& .MuiSvgIcon-root': {
        color: 'white',
    },
    '& .MuiDataGrid-columnSeparator': {
        // visibility: 'hidden',
    },
    '& .datagrid-separator-disable .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
    },
    '& .MuiDataGrid-overlay': {
        // backgroundColor: 'rgba(255, 255, 255, 0.05)',
        backgroundColor: 'rgba(29, 26, 45, 0.8)',   
    },
    '& .MuiCircularProgress-root': {
        color: '#EE288A',
    },
    '& .MuiDataGrid-toolbarContainer': {
        backgroundColor: 'rgba(29, 26, 45, 0.5)', // Replace with your desired color
        color: 'rgb(172, 209, 251)',  // Replace with your desired color
    },
    '& .MuiDataGrid-toolbarContainer .MuiButton-root': {
         // Replace with your desired color
        color: 'rgb(172, 209, 251)', 
    },
    '& .MuiFormControlLabel-label': {
        color: 'black',
    }
}