import { React, useState, useEffect, useRef} from "react";

import { Auth } from "@supabase/auth-ui-react";
import { ButtonSignUp } from "./Navigation";

import { useNavigate, createSearchParams, useSearchParams, generatePath, useMatch, useLocation } from "react-router-dom";

import { tokens } from "./data";

import { HeaderOverview } from "./Overview";

import { overviewPages } from "./dataPortfolio";

import redirect_button from "../assets/redirect.png";

import { getWalletStats, getTransfers, getPrice } from "./loadData";

import { DropDown, HeaderToken } from "./Menu";

import { portfolioLayout } from "./dataPortfolio";

import { checkAddress } from "./loadData";

import { Line } from "react-chartjs-2";

import {options, data } from "./ChartSettings/ReceivedSent.js"

import { tableStyle } from "./TableSettings/DefaultTable.js";

import { columns } from "./TableSettings/BuySellTable";

import { DataGrid } from "@mui/x-data-grid";

function Tag(props) {
    return (
        <div className="tag">
            <p> { props.text } </p>
        </div>
    )
}

function Chart(props) {
    return (
        <Line ref={props.chartRef} options={options} data={data} redraw={true} />
    )
}

// function InfoBlock(props) {
//     return (
//         <div style={{width:"100%", 
//         display: 'flex', 
//         backgroundColor: '#110E1C', 
//         color:'white', 
//         fontFamily: 'SFProReg',
//         // justifyContent: 'center',
//         alignItems:'center',}}>
//             <p style={{marginLeft: '12px', margin:'0px'}}>{props.text}</p>
//             <p style={{marginLeft: 'auto', marginRight: '33px', fontFamily: 'SFPro', marginTop:'0px', marginBottom: '0px'}}>{props.value}</p>
//         </div>
//     )
// }

function DescriptionBlock(props) {
    const styleHeader = props.name ? {
        display: 'flex',
        fontSize: '14px',
        fontFamily: 'SFProBold',
        color: '#7B7A95',
        width: '100%',
        marginTop: '8px',
        marginBottom: '8px'
    } :
    {
        display: 'flex',
        fontSize: '16px',
        fontFamily: 'SFProReg',
        color: 'white',
        width: '100%'
    }

    return (
        <div style={styleHeader}>
            <p style={{marginLeft: '33px', marginTop: "0px", marginBottom: "0px"}}>{props.header}</p>
            {props.formatting && props.formatting(props.value) }
            {/* {props.formatting && props.formatting(props.va  lue)} */}
            {!props.formatting && <p style={{marginLeft:'auto', marginRight: '33px', marginTop: "0px", marginBottom: "0px"}}>{props.value}</p>}
        </div>
    )
}

function InfoBlock(props) {
    // console.log("PROPS HEADER", props.header, props.fields)

    return (
        <div style={{width:"100%", 
        display: 'flex', 
        backgroundColor: '#110E1C', 
        flexDirection: 'column',
        // justifyContent: 'center',
        flex: '1 1',
        alignItems:'center',
        paddingTop: '12px',
        paddingBottom: '20px',
        gap: '8px',}}>
            <DescriptionBlock header={props.header} value={''} name={true}/>
            {props.fields.map((field) => (<DescriptionBlock header={field.name} value={props.stats[field.key]} formatting={field.formatting}/>))}
            {/* {props.text.map((text) => (<DescriptionBlock header={text[0]} value={text[1]}/>))} */}
            {/* <DescriptionBlock header={"Balance"} value={"72,000"}/>
            <DescriptionBlock header={"Balance ($)"} value={"$100,000"}/> */}
        </div>
    )
}

function MenuInfo(props) {
    // const [hideMenu, setHide] = useState(false)
    const [tokenInput, setTokenInput] = useState("")

    const [toggleDropDown, setDropDown] = useState(false)

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    function toggleMenu() {
        setDropDown(!toggleDropDown)
    }

    // console.log("MENU info", props.layout)

    return (
        <div className="walletInfo-menu" style={{flex: props.isMobile? '1 1': ''}}>
            { !props.hideMenu && <HeaderToken
                token={ props.selectedToken } 
                isHeader={ true }
                isClicked={ toggleDropDown }
                toggleFunction={ toggleMenu }
                custom={true}
                tokenInput={tokenInput}
                setTokenInput={setTokenInput}
            /> }
            { !props.hideMenu && toggleDropDown && <div className="dropDown-menu" style={{border: '0.5px solid white', borderRadius: '0'}}> 
                                        <DropDown 
                                            selectedToken={ props.selectedToken } 
                                            toggleFunction={ props.setNewToken }
                                            color={'rgb(43, 40, 53)'}
                                            tokenInput={tokenInput}
                                        /> 
                                </div> }
            <div style={{height: !props.hideMenu ? "calc(100vh - 62.5px - 60.5px - 50px - 120px - 50px - 12px)": "100%", display: 'flex', flexDirection: 'column', gap: '12px'}}>
                {/* <div style={{width:'100%', backgroundColor: '#110E1C', flex: '1 1'}}></div>
                <div style={{width:'100%', backgroundColor: '#110E1C', flex: '1 1'}}></div> */}
                {/* <InfoBlock text={[["Balance", '100,000'], ["Balance ($)", '$270,000']]}/> */}
                {props.layout.map((layout) => (<InfoBlock header={layout.header} fields={layout.fields} stats={props.stats} />))}
                {/* <InfoBlock header={props.layout[0].header} fields={props.layout[0].fields} stats={props.stats} /> */}
                {/* <InfoBlock text={[["NPL", '$3M'], ["Average NPL", '$2.1M'], ["Profit in %", "1720%"]]}/>
                <InfoBlock text={[["Volume Sent", '7M'], ["Volume Sent ($)", '$1.2M'], ["Volume Recieved", "5M"], ["Volume Recieved ($)", "$2.3M"]]}/>
                <InfoBlock text={[["Average Tx Volume", "15M"], ["Average Tx Volume ($)", "$3.1M"], ["First Transaction", "5 days after deploy"]]} /> */}
                {/* <InfoBlock text={"Balance ($)"} value={"270,000$"}/> */}
            </div>
        </div>
    )   
}

export function Trades(props) {
    const [pageSize, setPageSize] = useState(15);
    const [stats, setStats] = useState({})

    const [invalidAddress, setInvalid ]= useState(false);

    // const [address, setAddress] = useState("0xb1050645b38ef4bd130d7743f4158fc700bec8a5")
    const [tags, setTags] = useState([])

    const [selectedToken, setToken] = useState(props.token ? props.token :"ldo")
 
    const [dataGridTable, setDataGridTable] = useState([])
    const [loadingData, setLoading] = useState(false)

    const [toggleDropDown, setDropDown] = useState(false)

    const tableRef = useRef()
    const chartRef = useRef()
    
    const navigate = useNavigate();
    const location = useLocation();


    function cleanChart() {
        const chart = chartRef.current
        for (let i=0; i < chart.data.datasets.length;i+=1) {
            chart.data.datasets[i].data = []
            //console.log("OBTAIN DATA", datasets)
        }
        chart.resetZoom()
        chart.update()
    }

    function setNewRoute(tokenSel) {
        // console.log('set new token rout', tokenSel)
        let searchParams = {
            token: tokenSel,
            address: props.address,
        }
        // console.log('set new token route location pathname', location.pathname, searchParams)
        const obtainedPath = generatePath("/portfolio/:address/trades/:token", searchParams)
        
        // console.log('set new token route path', obtainedPath)
        const navString = `${obtainedPath}`
        //const navString = toolSel ? `?${createSearchParams(searchParams)}&${createSearchParams(toolSel)}` : `?${createSearchParams(searchParams)}`
        navigate(navString)
    }

    function setNewToken(tokenName) {
        // console.log('set new token', tokenName)
        setNewRoute(tokenName)
        setToken(tokenName)

        cleanChart()
        updateHeader(props.address, tokenName) 
        updateChart(props.address, tokenName)
        // updateHeader(selectedIndex, tokenName) 
    }

    function toggleMenu() {
        setDropDown(!toggleDropDown)
    }

    function updateHeader(address, token) {
        getWalletStats(address, token).then((data) => {
            // console.log("DATA OBTAIN", data, data.tags)
            if (data.tags === undefined) {
                setTags([])
            } else {
                setTags(data.tags)
            }
            // console.log("TAGS", data.tags)
            setStats(data)
        })
    }

    function updateChart(address, token) {
        setLoading(true)
        getTransfers(address, token).then(([transfers, received_r, sent_r, price]) => {
            const chart = chartRef.current
            // console.log("PROCE OBTAIN", data[1])
            // console.log(chart.data.datasets[0])
            chart.data.datasets[0].data = price[0]
            chart.data.datasets[1].data = transfers["data_received"]
            chart.data.datasets[2].data = transfers["data_sent"]

            chart.data.datasets[2].pointRadius = sent_r
            chart.data.datasets[1].pointRadius = received_r

            chart.update()
            setDataGridTable(transfers['transfers'])
            setLoading(false)
        })
    }

    useEffect(() => {
        const urlParams = location.pathname.split('/')
        console.log('current path', location.pathname, urlParams)
        // setNewToken(selectedToken)
        if(urlParams.length > 4 && urlParams[4] !== '') {
            setNewToken(urlParams[4])
        } else {
            setNewToken(selectedToken)
        }
    }, [])

    return (
        <div className="main-portfolio-section">
                    {!props.isPhone && <div className="walletInfo" style={{borderBottom: 'solid 1 red'}}>
                        <MenuInfo 
                            selectedToken={selectedToken} 
                            setNewToken={setNewToken} 
                            layout={portfolioLayout.left}
                            stats={stats}
                            isMobile={props.isMobile}
                        />
                        {/* <MenuInfo selectedToken={selectedToken} setNewToken={setNewToken} /> */}
                        {!props.isMobile && <div className="walletInfo-chart">
                                <Chart chartRef={chartRef}/>
                        </div>}

                        <MenuInfo 
                            selectedToken={selectedToken} 
                            setNewToken={setNewToken} 
                            layout={portfolioLayout.right}
                            stats={stats}
                            hideMenu={true}
                            isMobile={props.isMobile}
                        />
                    </div>}
                    {props.isPhone && <div className="walletInfo" style={{borderBottom: 'solid 1 red'}}> 
                        <MenuInfo 
                            selectedToken={selectedToken} 
                            setNewToken={setNewToken} 
                            layout={portfolioLayout.left}
                            stats={stats}
                            isMobile={props.isMobile}
                        />
                    </div>}
                    {props.isPhone && <div className="walletInfo" style={{borderBottom: 'solid 1 red'}}> 
                        <MenuInfo 
                            selectedToken={selectedToken} 
                            setNewToken={setNewToken} 
                            layout={portfolioLayout.right}
                            stats={stats}
                            hideMenu={true}
                            isMobile={props.isMobile}
                        />
                    </div>}
                    {props.isMobile && <div className="walletInfo" style={{borderBottom: 'solid 1 red'}}>
                        <div className="walletInfo-chart">
                                <Chart chartRef={chartRef}/>
                        </div>
                    </div>}
                
                {!invalidAddress && <div className="walletTransactions">
                    <DataGrid
                        // initialState={props.initialState}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[15, 100]}
                        pagination
                        loading={loadingData}
                        rows={dataGridTable}
                        columns={columns}
                        // pageSize={15}   
                        rowHeight={35}
                        headerHeight={35}
                        // rowsPerPageOptions={[5, 15, 25]}
                        showCellRightBorder={true}
                        disableSelectionOnClick
                        // checkboxSelection
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        sx={tableStyle}
                        ref={tableRef}
                        // initialState={{
                        //     pageSize: 15,
                        // }}
                        // pageSize={15} 
                        // rowsPerPageOptions={[15, 100]}
                    />
                    </div>}
        </div>
    )
}