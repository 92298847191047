const ipAddress = 'internal2.cherry-pick.xyz'
const protocol = 'https'

export const index2NameNew = {
    0: "price",

    1: "mpl",
    2: "sopr",
    3: "sopr_pl",
    4: "onchain_volume",
    5: "realised_price",
    6: "realised_cap",
}

export const index2Name = {
    0: "price",

    1: "realised_profit_loss_ratio",
    2: "mpl",
    3: "mpl_cohorts",
    4: "realised_profit_loss_ratio",
    5: "num_addresses",
    6: "num_addresses_cohorts",
    7: "num_addresses_net",
    8: "realised_price_cohorts",

    10: "mpl",
    11: "sopr",
    12: "onchain_volume",
    13: "realised_price",
    14: "realised_cap",
    15: "sc_flows",

    16: "mpl",
    17: "mpl",
    18: "mpl_cohorts",
    19: "realised_profit_loss_ratio",
    20: "sopr",
    21: "sopr_pl",

    22: "mpl",
    23: "mpl_cohorts",
    24: "realised_profit_loss_ratio",

    25: "onchain_volume",
    26: "average_transaction_volume",
    27: "num_transactions",

    // 16: "num_active_addresses",
    28: "balance_cohort_flows",
    29: "balance_cohort_flows",
    30: "moving_realised_price",
    31: "onchain_volume_cohorts",

    32: "realised_price_dist",
    
    33: "upl",
    34: "upl_cohorts",
    35: "supply_pl",
    36: "supply_pl_cohorts",
    37: "num_addresses_pl",

    38: "num_addresses",
    39: "num_addresses_cohorts",
    40: "num_addresses_net",
    41: "realised_price",
    42: "realised_price_cohorts",
    43: "realised_cap",
    44: "balance_cohorts",
    45: "realised_cap_cohorts",
    46: "balance_cohorts",
    47: "realised_cap_cohorts",

    48: "cex_balance",
    49: "cex_flows",
    50: "cex_flows",
    51: "cex_flows_cohorts",
    52: "cex_cohort_flows",

    53: "sc_balance",
    54: "sc_flows",
    55: "sc_flows",

    // 16: "num_active_addresses",
    // 17: "balance_cohort_flows",
    // 18: "balance_cohort_flows",
    // 19: "moving_realised_price",

    // 20: "onchain_volume_cohorts",

    // 21: "realised_price_dist",
    
    // 22: "upl",
    // 23: "upl_cohorts",
    // 24: "supply_pl",
    // 25: "supply_pl_cohorts",
    // 26: "num_addresses_pl",

    // 27: "num_addresses_cohorts",
    // 28: "realised_price",
    // 29: "realised_cap",
    // 30: "balance_cohorts",
    // 31: "realised_cap_cohorts",
    // 32: "balance_cohorts",
    // 33: "realised_cap_cohorts",

    // 34: "cex_balance",
    // 35: "cex_flows",
    // 36: "cex_flows",
    // 37: "cex_flows_cohorts",
    // 38: "cex_cohort_flows",

    // 39: "sc_balance",
    // 40: "sc_flows",
    // 41: "sc_flows",
}

// const CEX = [
//     'abcc', 'ascendex', 'bgogo', 'binance', 'bitmex', 'bitmart', 'bitfinex',
//     'bithumb', 'bitstamp', 'bittrex', 'coss.io', 'crex24', 'cobinhood', 'coinmetro',
//     'coinhako', 'coinone', 'compromised', 'crypto.com', 'derivatives', 'eth2_depositor',
//     'ftx', 'fiat_gateway', 'gbx', 'gate.io', 'gemini', 'hitbtc', 'hoo.com', 'hot_wallet',
//     'hotbit', 'huobi', 'kraken', 'kryptono', 'kucoin', 'latoken', 'liquid', 'okex', 'otc', 
//     'options_trading', 'other', 'paribu', 'poloniex', 'remitano', 'shapeshift', 'streamity',
//     'tagz', 'tidex', 'topbtc', 'trade.io', 'upbit', 'yunbi', 'zb.com'
//   ]

const CEX = [
    'binance', 'bitmex', 'bitfinex', 'bittrex', 'crypto.com', 
    'ftx', 'gate.io',  'huobi', 'kraken',  'kucoin', 'other',
  ]

//   "Overview",
//         "Moving Profits & Losses",
//         "SOPR",
//         "Total Transaction Volume",
//         "Realised Price",
//         "Realised Capitalization", 
//         "CEX Inflow & Outflow",

export const parseIndexFieldsNew = {
    0: ["close"],

    1: ["losses", "profits"],
    2: ["sopr"],
    3: ["sopr_losses", "sopr_profits"],
    4: ["volume"],
    5: ["realised_price"],
    6: ["market_cap", "realised_cap"],
    
}


export const parseIndexFields = {
    0: ["close"],

    1: ["ratio"],
    2: ["losses", "profits"],
    3: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],
    4: ["ratio"],
    5: ["num_addresses"],
    6: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    7: ["num_addresses"],
    8: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],

    10: ["losses", "profits"],
    11: ["sopr"],
    12: ["onchain_volume"],
    13: ["realised_price"],
    14: ["market_cap", "realised_cap"],
    15: ["inflow", "outflow"],

    16: ["losses", "profits"],
    17: ["nmpl"],
    18: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],
    19: ["ratio"],
    20: ["sopr"],
    21: ["sopr_losses", "sopr_profits"],

    22: ["losses", "profits"],
    23: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],
    24: ["ratio"],

    25: ["onchain_volume"],
    26: ["average_volume"],
    27: ["tx_number"],
    // 16: ["num_addresses"],
    28: ["inflow", "outflow"],
    29: ["netflow"],
    30: ["moving_realised_price"],
    31: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],

    32: [""], 

    33: ["unrealised_losses", "unrealised_profits"],
    34: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    35: ["supply_losses", "supply_profits"],
    36: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    37: ["num_addresses_losses", "num_addresses_profits"],
    
    38: ["num_addresses"],
    39: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    40: ["num_addresses"],
    41: ["realised_price"],
    42: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    43: ["market_cap", "realised_cap"],
    44: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y", "5y-7y", 'Exchanges', 'Smart Contracts'],
    45: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y", "5y-7y", 'Exchanges', 'Smart Contracts'],
    46: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts'],
    47: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts'],

    48: ["balance"],
    49: ["inflow", "outflow"],
    50: ["netflow"],
    51: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    52: ["netflow"],

    53: ["balance"],
    54: ["inflow", "outflow"],
    55: ["netflow"],

    // 16: ["num_addresses"],
    // 17: ["inflow", "outflow"],
    // 18: ["netflow"],
    // 19: ["moving_realised_price"],
    // 20: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y"],

    // 21: [""], 

    // 22: ["unrealised_losses", "unrealised_profits"],
    // 23: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    // 24: ["supply_losses", "supply_profits"],
    // 25: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    // 26: ["num_addresses_losses", "num_addresses_profits"],

    
    // 27: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    // 28: ["realised_price"],
    // 29: ["market_cap", "realised_cap"],
    // 30: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y", "5y-7y", 'Exchanges', 'Smart Contracts'],
    // 31: ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y", "5y-7y", 'Exchanges', 'Smart Contracts'],
    // 32: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts'],
    // 33: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts'],


    // 34: ["balance"],
    // 35: ["inflow", "outflow"],
    // 36: ["netflow"],
    // 37: ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+"],
    // 38: ["netflow"],

    // 39: ["balance"],
    // 40: ["inflow", "outflow"],
    // 41: ["netflow"],
}

const toolName2params = {
    "Resolution": "resolution",
    "Metric": "metric",
    "Cohort": "cohort",
    "Moving average": "moving_average",
    "Equivalent": "equivalent",
    "Sample": "sample",
    "Cohort type":"cohort_type",
    "CEX": "CEX",
    "Address Type": "address_type",
    "Moving average type": "moving_average_type",
}

async function getRequest(index, token, params, version) {
    // console.log("GET REQUEST", `${protocol}://${ipAddress}/${token}/${index2Name[index]}?` + new URLSearchParams(params))
    if(version === '2') {
        const response = await fetch(`${protocol}://${ipAddress}/v2/${token}/${index2NameNew[index]}?` + new URLSearchParams(params), {
            method: 'GET',
        })
        return await response.json()
    }
    const response = await fetch(`${protocol}://${ipAddress}/${token}/${index2Name[index]}?` + new URLSearchParams(params), {
        method: 'GET',
    })
    return await response.json()
}

function parseGeneral(index, data, params, version) {
    // let dataOutput = [data.map(point => point['datetime'])]
    var fields = version === '2' ? parseIndexFieldsNew[index] : parseIndexFields[index]
    if ("cohort_type" in params) {
        if (params["cohort_type"] == "age") {
            fields = ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y",  "5y-7y", 'Exchanges', 'Smart Contracts']
        }
        else if (params["cohort_type"] == "balance") {
            fields = [
                "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3", 
                "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
                'Exchanges', 'Smart Contracts'
            ]
        }
        else {
            fields = ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts']
        }
    }

    let dataOutput = []
    for (const field of fields) {
        // console.log("FIELD:", parseIndexFields[index])
        // console.log("FIELD:", field)
        // console.log("FIELD:", parseIndexFields[index][0])
        // console.log("FIELD:", data.slice(0, 10))
        // console.log("DATA", data.map(point => point[field]))
        // dataOutput.push(data.map(point => point[field]))

        dataOutput.push(data.map(point => {return {x: point['datetime'].split('T').join(' ') , y: point[field]}}))
    }
    return dataOutput
}

function parseSopr(index, data, params) {
    // let dataOutput = [data.map(point => point['datetime'])]
    console.log("PARSE SOPR")
    var fields = parseIndexFields[index]
    if ("cohort_type" in params) {
        if (params["cohort_type"] == "age") {
            fields = ["<1d", "1d-1w", "1w-1m", "1m-3m", "3m-6m", "6m-1y", "1y-2y", "2y-3y", "3y-5y",  "5y-7y", 'Exchanges', 'Smart Contracts']
        }
        else if (params["cohort_type"] == "balance") {
            fields = [
                "balance_cohort_0", "balance_cohort_1", "balance_cohort_2", "balance_cohort_3", 
                "balance_cohort_4", "balance_cohort_5", "balance_cohort_6", "balance_cohort_7",
                'Exchanges', 'Smart Contracts'
            ]
        }
        else {
            fields = ["$0-100", "$100-1k", "$1k-10k", "$10k-100k", "$100k-1M", "$1M-10M", "$10M+", 'Exchanges', 'Smart Contracts']
        }
    }

    let dataOutput = []
    for (const field of fields) {
        // console.log("FIELD:", parseIndexFields[index])
        // console.log("FIELD:", field)
        // console.log("FIELD:", parseIndexFields[index][0])
        // console.log("FIELD:", data.slice(0, 10))
        // console.log("DATA", data.map(point => point[field]))
        // dataOutput.push(data.map(point => point[field]))
        console.log("PARSE SOPR", data)
        dataOutput.push(data.map(point => {return {x: point['datetime'].split('T').join(' ') , y: point[field] === '' ? NaN : point[field]}}))
    }
    return dataOutput
}

function parseRealisedPriceDist(index, data) {
    // let dates = []
    // let dists = []
    const dates = data.map(point => point.datetime)
    const dists = data.map(point => point.dist)
    // let i=0
    // for(i=0; i < data.length; i++) {
    //     dists.push(data[i].dist.map(point => {return {y:point.price_level, x:point.value}}))
    //     dates.push(data[i].datetime)
    // }
    const dataOutput = [dates, dists, dates.length]
    return dataOutput
}

function parse(index, data, params, version) {
    // if ((index !== 3) && (index !== 17)) {
    if (index !== 32) {
        return parseGeneral(index, data, params, version)
    } else {
        return parseRealisedPriceDist(index, data)
    }
}

export async function getData(index, token, selectedTools, tools, version) {
    let params = {
        from: '2017-01-01T00:00:00',
        to: '2027-01-07T00:00:00',
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
    }
    for (const tool in selectedTools) {
        console.log("tools", tool, tools, toolName2params[tool], tools[tool], selectedTools[tool])
        params[toolName2params[tool]] = tools[tool][selectedTools[tool]]
    }
    // const params = {
    //     resolution: 'day',
    //     from: '2020-01-01T00:00:00',
    //     to: '2022-01-07T00:00:00'
    // }
    console.log("Parameters", params)
    const data = await getRequest(index, token, params, version).then((data) => parse(index, data, params, version))
    // if(token !== 'btc') {
    //     const data = await getRequest(index, token, params).then((data) => parse(index, data, params))
    // } else {
    //     const data
    // }

    let priceParams = {
        from: params.from,
        to: params.to,
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
    }
    priceParams['resolution'] = 'resolution' in params ? params.resolution : 'day'

    if (priceParams['resolution'] == 'block') {
        priceParams['resolution'] = 'minute'
    }
    const price = await getRequest(0, token, priceParams, version).then((data) => parse(0, data, [], version))

    console.log('DATA:')
    console.log(data)
    console.log('Price:')
    console.log(price)
    
    return [data, price]
    // return [data, price[1]]

}

// async function parseData(index, data) {

// }

// function updateChart(index, chartRef, data) {

// }

export async function getMPL(token) {
    const params = {
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
        resolution: 'day',
        from: '2020-01-01T00:00:00',
        to: '2027-01-07T00:00:00'
    }
    // const options = {
    //     method: 'GET',
    //     body: JSON.stringify(params)
    // }
    // const response = await fetch('http://192.168.1.94/sushi/mpl?' + new URLSearchParams(params), {
    //     method: 'GET',

    // })
    if (token == 'uniswap') {
        token = 'uni'
    }
    const response = await fetch(`${protocol}://${ipAddress}/${token}/mpl?` + new URLSearchParams(params), {
        method: 'GET',
    })
    console.log("GET RESPONSE")
    console.log(response)
    return await response.json()
}

export async function getPrice(token) {
    const params = {
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
        resolution: 'day',
        from: '2017-01-01T00:00:00',
        to: '2027-01-07T00:00:00'
    }
    // const options = {
    //     method: 'GET',
    //     body: JSON.stringify(params)
    // }
    // const response = await fetch('http://192.168.1.94/sushi/mpl?' + new URLSearchParams(params), {
    //     method: 'GET',

    // })
    if (token == 'uniswap') {
        token = 'uni'
    }
    const response = await fetch(`${protocol}://${ipAddress}/${token}/price?` + new URLSearchParams(params), {
        method: 'GET',
    })
    console.log("GET RESPONSE")
    return await response.json()
}


async function getOverviewRequest(token, page) {
    //const requestString = `${protocol}://${ipAddress}/${token}/${page}`
    const requestString = `${protocol}://${ipAddress}/${token}/overview?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`
    // console.log("GET REQUEST", `${protocol}://${ipAddress}/${token}/${index2Name[index]}?` + new URLSearchParams(params))
    const response = await fetch(requestString, {
        method: 'GET',
    })
    return await response.json()
}

export async function getRealisedCap(index) {
    // const requestString = `${protocol}://${ipAddress}/tokens/${index}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const requestString = `${protocol}://${ipAddress}${index === 'macro' ? '/v2/tokens/realised_price': `/tokens/${index}`}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, {
        method: 'GET',
    })

    const unparsedData = await response.json()
    console.log("DATA unnpaarsed", unparsedData)
    // return unparsedData
    return parseRealisedCap(unparsedData)
}

export async function getTokens() {
    const requestString = `${protocol}://${ipAddress}/v2/tokens_list?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, {
        method: 'GET',
    })

    const dataList = await response.json()

    // const tokensDict = data.reduce((acc, token) => {
    //     acc[token.token_address] = {
    //       symbol: token.symbol,
    //       name: token.name,
    //       slug: token.slug,
    //       logo: token.logo
    //     };
    //     return acc;
    //   }, {});
    
    return dataList
}

export async function getTable(index) {
    const requestString = `${protocol}://${ipAddress}/tokens/${index}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, {
        method: 'GET',
    })

    const unparsedData = await response.json()
    console.log("DATA unnpaarsed", unparsedData)
    // return unparsedData
    return parseRealisedCap(unparsedData)
}

export async function getLeaderboard(index, token) {
    const requestString = `${protocol}://${ipAddress}/${token}/leaderboard/${index}?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, {
        method: 'GET',
    })

    const unparsedData = await response.json()
    console.log("DATA unnpaarsed", unparsedData)
    // return unparsedData
    return parseLeaderboard(unparsedData, token)
}

function parseLeaderboard(data, token) {
    let res = []
    for (const [key, value] of Object.entries(data)) {
        value['id'] = key
        value['address'] = {address: value['address'], 'token':token, tag: value['tag']}

        res.push(value)
    }
    return res
}

export async function getWalletStats(address, token) {
    const requestString = `${protocol}://${ipAddress}/${address}/${token}/stats?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()

    return data
}

export async function getWalletInfo(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/overview?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, 
        {method: 'GET',
    })
    const data = await response.json()

    if(data.status === 200) {
        if(data.result.is_contract || data.result.is_erc20) {
            data['show_all'] = false
        } else {
            data['show_all'] = true
        }
        return data.result
    }

    return false
}

export async function getWalletBalanceInfo(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/balance?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, 
        {method: 'GET',
    })
    const data = await response.json()

    console.log("WALLET INFO::", data)

    if(data.status === 200) {
        data.result.balances_by_tokens.forEach((element, id) => {
            element['id'] = id
        })
        return data.result
    }

    return false
}

export async function getWalletPerformanceInfo(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/performance?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response = await fetch(requestString, 
        {method: 'GET',
    })
    const data = await response.json()

    console.log("WALLET INFO::", data)

    if(data.status === 200) {
        data.result.tokens_by_total_pl.forEach((element, id) => {
            element['id'] = id
        })
        data.result.tokens_by_realised_pl.forEach((element, id) => {
            element['id'] = id
        })
        return data.result
    }

    return false
}

export async function getTransfers(address, token, res='auto') {
    const requestString = `${protocol}://${ipAddress}/${address}/${token}/transfers?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()
    parseDataTransfers(data)

    let priceParams = {
        from: '2017-01-01T00:00:00',
        to: '2027-01-07T00:00:00',
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
        // resolution:  'day',
        resolution: res,
    }

    const [received_r, sent_r] = parseRadiuses(data)

    const price = await getRequest(0, token, priceParams).then((data) => parse(0, data, []))

    // console.log('DATA:')
    // console.log(data)
    // console.log('Price:')
    // console.log(price)
    
    return [data, received_r, sent_r, price]
}

export async function getBalanceHistory(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/balance_history?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()

    if(data.status === 200) {
        let values = data.result 
        parseBalance(values)

        return values
    }

    return false
}

export async function getPLHistory(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/total_pl_history?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()

    if(data.status === 200) {
        let values = data.result 
        parsePL(values)

        return values
    }

    return false
}

export async function getRealisedPLHistory(address) {
    const requestString = `${protocol}://${ipAddress}/v2/address/${address}/realised_pl_history?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()

    if(data.status === 200) {
        let values = data.result 
        parseRealisedPL(values)

        return values
    }

    return false
}

function parseRadiuses(data) {
    const received_r = data.data_received.map((point) => point.r)
    const sent_r = data.data_sent.map((point) => point.r)
    return [received_r, sent_r]
}

function parseDataTransfers(data) {
    data.data_received.forEach(element => {
        if(element.x.length >= 12) {
            element.x = element.x.split('T').join(' ').slice(0, 13) + ':00'
        }
        // element.x = element.x + (element.x.length < 12 ? '' : ':00')
    });
    data.data_sent.forEach(element => {
        if(element.x.length >= 12) {
            element.x = element.x.split('T').join(' ').slice(0, 13) + ':00'
        }
        // element.x = element.x.split('T').join(' ').slice(0, 13)
        // element.x = element.x + (element.x.length < 12 ? '' : ':00')
    });
}

function parseBalance(data) {
    data.forEach(element => {
        element.y = element.balance_dollar
        element.x = element.week_start
    })
}

function parsePL(data) {
    data.forEach(element => {
        element.y = element.total_pl
        element.x = element.week_start
    })
}

function parseRealisedPL(data) {
    data.forEach(element => {
        element.y = element.realised_pl_by_token
        element.x = element.week_start
        element.y2 = element.realised_profit
        element.y3 = element.realised_loss
    })
}

function calculatePercent(val1, val2) {
    return ((val2 - val1)/val1) * 100.0
}

function parseRealisedCap(data) {
    let res = []
    for (const [key, value] of Object.entries(data)) {
        value['id'] = key
        // const percent7d = calculatePercent(value['realised_cap_7d'], value['realised_cap_1d'])
        // const percent30d = calculatePercent(value['realised_cap_30d'], value['realised_cap_1d'])
        // // currevalue['realised_cap_1d']
        // value['realised_cap_7d'] = percent7d
        // value['realised_cap_30d'] = percent30d

        // const percent7dM = calculatePercent(value['market_cap_7d'], value['market_cap_1d'])
        // const percent30dM = calculatePercent(value['market_cap_30d'], value['market_cap_1d'])

        // value['market_cap_7d'] = percent7dM
        // value['market_cap_30d'] = percent30dM

        res.push(value)
    }
    return res
}

function parseGigantTransfers(dataResponse, token) {
    const scanLink = token === 'arb' ? 'arbiscan.io' : 'etherscan.io'
    const data = dataResponse.map((row, index) => {
        return {
            id: index,
            hash: [
                    row.transaction_hash, 
                    scanLink
                  ],
            from: [
                    (row.from_address_type !== 'address' ? row.from_address_type : ""), 
                    (row.from_address_tag === "" ? row.from_address : row.from_address_tag),
                    scanLink,
                  ],
            to: [
                    (row.to_address_type !== 'address' ? row.to_address_type : ""), 
                    (row.to_address_tag === "" ? row.to_address : row.to_address_tag),
                    scanLink,
                ],
            // typeFrom: row.fromType,
            // typeTo: row.toType,
            ageFrom: row.from_address_age_cohort,
            ageTo: row.to_address_age_cohort,
            wealthFrom: row.from_address_balance_cohort,
            wealthTo: row.to_address_balance_cohort,
            value: row.value,
            valueDollar: row.value_dollar,
            profit: row.npl,
            time: new Date(row.block_timestamp),
        }
    })
    return data
} 

export async function checkAddress(address) {
    const requestString = `${protocol}://${ipAddress}/${address}/is_valid?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97`

    const response  = await fetch(requestString, {
        method: 'GET',
    })

    const data = await response.json()
    return data
}

async function getGigantTransfersRequest(token, limit) {
    const requestString = `${protocol}://${ipAddress}/${token}/giant_transactions?api_key=8d1c8518-65b8-4c63-8a9b-afbb32c97c97&limit=${limit}`

    const response = await fetch(requestString, {
        method: 'GET',
    })
    return await response.json()
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}



export async function getOverviewData(token, page) {
    const data = await getOverviewRequest(token, page)

    return data
}

export async function getGigantTransfers(token, limit=200) {
    const data = parseGigantTransfers(await getGigantTransfersRequest(token, limit), token)
    
    return data
}