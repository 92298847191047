import React, { createContext, useState, useEffect, useContext } from 'react';

import { getTokens } from './loadData';

const TokensContext = createContext();

export const TokensProvider = ({ children }) => {
  const [tokens, setTokens] = useState([]);
  const [tokenAddressMap, setTokenAddressMap] = useState({});
  const [slugMap, setSlugMap] = useState({});
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const dataList = await getTokens();
        setTokens(dataList);

        const tokenAddrMap = {};
        const slgMap = {};

        dataList.forEach(item => {
          tokenAddrMap[item.token_address] = item;

          const { logo, ...rest } = item;
          const newItem = { ...rest, img: logo };
          // if(newItem.version === 'v2') {
          //     newItem.showingIndexes = [2, 3]
          // }
          slgMap[item.slug] = newItem;
        });

        setTokenAddressMap(tokenAddrMap);
        setSlugMap(slgMap);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTokens();
  }, []);

  return (
    <TokensContext.Provider value={{ tokens, tokenAddressMap, slugMap, loading, error }}>
      {children}
    </TokensContext.Provider>
  );
};

export const useTokens = () => {
  return useContext(TokensContext);
};