import { DataGrid } from '@mui/x-data-grid';
import { TokenTableField, ShowPercentChange } from './utils';
import { useNavigate } from "react-router-dom";
import { addCommas, parseValueForTooltip, parseBigValues } from '../ChartSettings/utils';

import { ShowAddress } from './utils';
import { ShowDollar } from '../dataPortfolio';

export function ShowDollarBigValues(props) {
    // if(props.value !== '') {
    if( typeof props.value !== 'string') {      
    const positiveValue = (props.value >= 0)
    const styleText = {
        color: props.withoutColor? 'white' : (positiveValue ? '#28E2EE' : '#EE288A'),
        marginLeft:'auto',
        marginRight: '33px',
        marginTop: "0px", 
        marginBottom: "0px",
    }

    if((props.value !== undefined) && (props.value !== '')) {
        // console.log("VALUE", props.value)
        return (
            <p style={styleText}> {props.withoutColor? "": (positiveValue? "+": "-")}{props.withoutDollar? "":"$"}{ parseBigValues(Math.abs(props.value.toFixed(2)))}{props.percent? "%":""}</p>
        )
    }
    return (<p style={styleText}>No transfers</p>)
    }
    return <p>{props.value}</p>
}

export function ShowAddressCustomFund(props) {
    const styleText = {
        color: 'white',
        cursor: 'pointer',
    }
    const navigate = useNavigate()
    // function redirect() {

    // }
    var address = props.value.slice(0, 8) + '...'
    console.log("TAG")
    console.log(props)
    if ((props.tag !== '')) {
        address = props.tag
    }

    return (
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'row'}}>
            {/* <u style={styleText} onClick={() => {navigate(`/portfolio/${props.value}/trades/${props.token}`)}} > {props.value.slice(0, 8)}...</u> */}
            <a style={styleText} href={`http://app.cherry-pick.xyz/portfolio/${props.value}/trades/${props.token}`} target="_blank" > {props.prefix}{address}</a>
        </div>
    )
}

export function ShowAddressCustom(props) {
    const styleText = {
        color: 'white',
        cursor: 'pointer',
    }
    const navigate = useNavigate()
    // function redirect() {

    // }
    var address = props.value.slice(0, 8) + '...'
    console.log("TAG")
    console.log(props)
    if ((props.tag !== '')) {
        address = props.tag
    }

    return (
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'row'}}>
            {/* <u style={styleText} onClick={() => {navigate(`/portfolio/${props.value}/trades/${props.token}`)}} > {props.value.slice(0, 8)}...</u> */}
            <a> {props.prefix}{address}</a>
        </div>
    )
}


export const columns = [
    { 
        field: 'number', 
        headerName: '', 
        width: 20,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{props.value}</p>)},
    },
    { 
        field: 'address', 
        headerName: 'Address', 
        width: 110,
        // headerClassName: 'datagrid-separator-disable', 
        renderCell: (props) =>  { return (<ShowAddress value={props.value.address} token={props.value.token} tag={props.value.tag}/>)},
    },
    
    { 
        field: 'balance', 
        headerName: 'Balance', 
        width: 100,
        cellClassName: 'datagrid-balance',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseBigValues(props.value)}</p>)},
    },
    { 
        field: 'balance_dollar', 
        headerName: 'Balance ($)', 
        width: 100,
        cellClassName: 'datagrid-balance',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'nupl',
        headerName: 'Unrealised Profit / Loss ($)',
        width: 180,
        description: 'Unrealised Profit / Loss value shows how much money address earn or loss if he/she sell all his coins.',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'volume_received',
        headerName: 'Bought Amount',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={parseBigValues(props.value)}/>) },
    },
    {
        field: 'volume_sent',
        headerName: 'Sold Amount',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<p> {parseBigValues(props.value)}</p>) },
    },
    {
        field: 'nmpl',
        headerName: 'Realised Profit / Loss ($)',
        width: 140,
        description: 'Realised Profit / Loss value shows how much money the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    
    
]

export const columns_cex = [
    { 
        field: 'number', 
        headerName: '', 
        width: 20,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{props.value}</p>)},
    },
    { 
        field: 'address', 
        headerName: 'Address', 
        width: 110,
        // headerClassName: 'datagrid-separator-disable', 
        renderCell: (props) =>  { return (<ShowAddressCustom value={props.value.address} token={props.value.token} tag={props.value.tag} prefix={"🏦 "}/>)},
    },
    
    { 
        field: 'balance', 
        headerName: 'Balance', 
        width: 100,
        cellClassName: 'datagrid-balance',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseBigValues(props.value)}</p>)},
    },
    { 
        field: 'balance_dollar', 
        headerName: 'Balance ($)', 
        width: 100,
        cellClassName: 'datagrid-balance',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'netflow_7d',
        headerName: '7d Δ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollarBigValues value={props.value}  withoutDollar={true}/>) },
    },
    {
        field: 'netflow_dollar_7d',
        headerName: '7d Δ ($)',
        width: 120,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}  />) },
    },

    {
        field: 'netflow',
        headerName: '30d Δ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollarBigValues value={props.value}  withoutDollar={true}/>) },
    },
    {
        field: 'netflow_dollar',
        headerName: '30d Δ ($)',
        width: 120,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}  />) },
    },

    // { 
    //     field: 'tx_number_received', 
    //     headerName: '# Buys', 
    //     width: 70,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) =>  { return (<p>{props.value}</p>)},
    // },
    // { 
    //     field: 'tx_number_sent', 
    //     headerName: '# Sells', 
    //     width: 70,
    //     renderCell: (props) =>  { return (<p>{props.value}</p>)},
    // },
    // {
    //     field: 'volume_received',
    //     headerName: 'Bought Amount',
    //     width: 100,
    //     // headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props)  => { return (<ShowDollar value={parseBigValues(props.value)}/>) },
    // },
    // {
    //     field: 'volume_sent',
    //     headerName: 'Sold Amount',
    //     width: 100,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props)  => { return (<p> {parseBigValues(props.value)}</p>) },
    // },
    
]

export const columns_fund = [
    { 
        field: 'number', 
        headerName: '', 
        width: 20,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{props.value}</p>)},
    },
    { 
        field: 'address', 
        headerName: 'Address', 
        width: 180,
        // headerClassName: 'datagrid-separator-disable', 
        renderCell: (props) =>  { return (<ShowAddressCustomFund value={props.value.address} token={props.value.token} tag={props.value.tag} prefix={"💸 "}/>)},
    },

    {
        field: 'nmpl',
        headerName: 'Realised Profit / Loss ($)',
        width: 180,
        description: 'Profit / Loss value shows how much the address has earned or lost in dollar terms over time. It takes into account all the sells tarades and does not include unrealised profits and losses.',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    
    { 
        field: 'balance', 
        headerName: 'Balance', 
        width: 100,
        cellClassName: 'datagrid-balance',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseBigValues(props.value)}</p>)},
    },
    { 
        field: 'balance_dollar', 
        headerName: 'Balance ($)', 
        width: 120,
        cellClassName: 'datagrid-balance',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'nupl',
        headerName: 'Unrealised Profit / Loss ($)',
        width: 180,
        description: 'Unrealised Profit / Loss value shows how much money address earn or loss if he/she sell all his coins.',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'netflow_7d',
        headerName: 'Balance 7d Δ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollarBigValues value={props.value}  withoutDollar={true}/>) },
    },
    {
        field: 'netflow_dollar_7d',
        headerName: 'Balance 7d Δ ($)',
        width: 120,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}  />) },
    },

    {
        field: 'netflow',
        headerName: 'Balance 30d Δ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollarBigValues value={props.value}  withoutDollar={true}/>) },
    },
    {
        field: 'netflow_dollar',
        headerName: 'Balance 30d Δ ($)',
        width: 120,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}  />) },
    },
    {
        field: 'boom',
        headerName: '',
        width: 120,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (
            <p></p>
        )},
    },
]

export const columns_sc = [
    { 
        field: 'number', 
        headerName: '', 
        width: 20,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{props.value}</p>)},
    },
    { 
        field: 'address', 
        headerName: 'Address', 
        width: 110,
        // headerClassName: 'datagrid-separator-disable', 
        renderCell: (props) =>  { return (<ShowAddressCustom value={props.value.address} token={props.value.token} tag={props.value.tag} prefix={"🤖 "}/>)},
    },
    { 
        field: 'balance', 
        headerName: 'Balance', 
        width: 100,
        cellClassName: 'datagrid-balance',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseBigValues(props.value)}</p>)},
    },
    { 
        field: 'balance_dollar', 
        headerName: 'Balance ($)', 
        width: 100,
        cellClassName: 'datagrid-balance',
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'netflow_7d',
        headerName: '7d Δ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollarBigValues value={props.value}  withoutDollar={true}/>) },
    },
    {
        field: 'netflow_dollar_7d',
        headerName: '7d Δ ($)',
        width: 120,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}  />) },
    },

    {
        field: 'netflow',
        headerName: '30d Δ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollarBigValues value={props.value}  withoutDollar={true}/>) },
    },
    {
        field: 'netflow_dollar',
        headerName: '30d Δ ($)',
        width: 120,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}  />) },
    },
    
]

