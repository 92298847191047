const ipAddress = 'internal2.cherry-pick.xyz'
const protocol = 'https'

export async function getUserParams(userId, supabase) {
    // Get the user's subscription
    const { data: userSubscription, error: userSubscriptionError } = await supabase
      .from('user_subscriptions')
      .select('tier_id, valid_until')
      .eq('user_id', userId)
      .order('valid_until', { ascending: false })
      .limit(1);
  
    if (userSubscriptionError) {
      console.error(userSubscriptionError);
      return;
    }
  
    // Validate the subscription
    const today = new Date();
    const validSubscription = userSubscription && userSubscription[0] && new Date(userSubscription[0].valid_until) >= today;
  
    // Get the user's filters
    const { data: userFilters, error: userFiltersError } = await supabase
      .from('filters')
      .select('*')
      .eq('user_id', userId);
  
    if (userFiltersError) {
      console.error(userFiltersError);
      return;
    }
  
    return {
      validSubscription,
      subscriptionTier: validSubscription ? userSubscription[0].tier_id : 1,
      filters: userFilters
    };
  }

export async function getFilterParams() {
    const params = {
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
    }
    // console.log("GET REQUEST", `https://${ipAddress}/filtering/params?` + new URLSearchParams(params))
    const response = await fetch(`${protocol}://${ipAddress}/filtering/params?`+ new URLSearchParams(params), {
        method: 'GET',
    })
    const config = await response.json()
    const sortParams = await parseSortParams(config)
    const variables = await parseParams(config)
    const forms = await parseForms(config)

    const criteriaFieldsMapping = await getCriteriaFieldsMapping(config)

    // const variables = await parseVariables(config)
    // const variables = await parseParams(config)
    
    return [config, sortParams, forms, variables, criteriaFieldsMapping]
}

async function getCriteriaFieldsMapping(config) {
    const criteriaFieldsMapping = {};

    config.forEach(criteria => {
        criteriaFieldsMapping[criteria.id] = criteria.subparams.map(field => field.id);
    })

    return criteriaFieldsMapping
}

async function parseFields(params) {
    let dictFields = {}

    params.forEach(param => {
        let subparamIdList = []
        param.subparams.forEach(subparam => {
            subparamIdList.push(subparam.id)
        })
        dictFields[param.id] = subparamIdList
    })

    return dictFields
}

async function parseForms(params) {
    const keysToKeep = ['is_default', 'group', 'name', 'is_order_param', 'id'];

    const forms = filterKeys(params, keysToKeep)
    return forms
}

async function parseParams(params) {
    let variables = {
        'selectedFilters': new Set(),
        'fields': {},
    }
    
    params.forEach(param => {
        if(param.is_default) {
            variables.selectedFilters.add(param.id)
        }
        param.subparams.forEach(subparam => {
            if((subparam.type !== 'multi-select') && (subparam.type !== 'select')) {
                variables.fields[subparam.id] = subparam.default !== null ? subparam.default : "";
            } else if(subparam.type === 'multi-select') {
                let extractedSubparams = new Set()
                subparam.subsubparams.forEach(subsubparam => {
                    // console.log("EXtract", subsubparam)
                    if(subsubparam.is_default) {
                        // console.log("EXtract", subsubparam.id)
                        extractedSubparams.add(subsubparam.id)
                    }
                })
                // console.log("EXtractED", extractedSubparams)
                variables.fields[subparam.id] = new Set(extractedSubparams)
                // console.log("copyed ", variables.fields[subparam.id] )
            } else if(subparam.type === 'select'){
                subparam.subsubparams.forEach(subsubparam => {
                    // console.log("EXtract", subsubparam)
                    if(subsubparam.is_default) {
                        // console.log("EXtract", subsubparam.id)
                        variables.fields[subparam.id] = subsubparam.id
                    }
                })
            }
        })
    })

    return variables
}

// async function parseFilters(params) {
//     let filters = []
    
//     params.forEach(param => {
//         if(param.is_default) {
            
//             param.subparams.forEach(subparam => {
//             })
//             variables.selectedFilters.add(param.id)
//         }
//         param.subparams.forEach(subparam => {
//             if((subparam.type !== 'multi-select') && (subparam.type !== 'select')) {
//                 variables.fields[subparam.id] = subparam.default
//             } else if(subparam.type === 'multi-select') {
//                 let extractedSubparams = new Set()
//                 subparam.subsubparams.forEach(subsubparam => {
//                     // console.log("EXtract", subsubparam)
//                     if(subsubparam.is_default) {
//                         // console.log("EXtract", subsubparam.id)
//                         extractedSubparams.add(subsubparam.id)
//                     }
//                 })
//                 // console.log("EXtractED", extractedSubparams)
//                 variables.fields[subparam.id] = new Set(extractedSubparams)
//                 // console.log("copyed ", variables.fields[subparam.id] )
//             } else if(subparam.type === 'select'){
//                 subparam.subsubparams.forEach(subsubparam => {
//                     // console.log("EXtract", subsubparam)
//                     if(subsubparam.is_default) {
//                         // console.log("EXtract", subsubparam.id)
//                         variables.fields[subparam.id] = subsubparam.id
//                     }
//                 })
//             }
//         })
//     })

//     return variables
// }

async function parseVariables(params) {
    let variables = []

    params.map(query => {
        variables.push(
            {
                'is_default': query.is_default,
                'fields': parseSubparams(query.subparams),
            }
        )
    })
    
    return variables
}

function parseSubparams(subparams) {
    return subparams.map(param => {return {'default': param.default, 'id': param.id}})
}

async function parseSortParams(params) {
    let sortParams = []
    params.forEach(element => {
        if(element.is_order_param) {
            sortParams.push({id: element.id, name:element.name})
        }
    });
    return sortParams
}

async function parseRenderStyle(params) {
    params.map((value) => {
        return {
            is_default: value.is_default,
            group: value.group,
        }
    })
}

async function parseParamValue(params) {

}

function filterKeys(dictList, keysToKeep) {
    return dictList.map(dict => {
        return keysToKeep.reduce((obj, key) => {
            if(dict[key] !== undefined) {
              obj[key] = dict[key];
            }
            return obj;
          }, {})
    })
}

function createJson(data) {
    const resJson = {
        'token': 'link',
        'order_by': {
            'filter_id': '0',
            'order_type': 'Desc',
        },
        'filters': {
            "selected_filters": [
                0
            ],
            "fields": {
                "1": 0,
                "2": 0,
                "3": 0,
            }
        }
    }
    return resJson
}

export async function getWallets(jsonData, token) {
    const params = {
        api_key: '8d1c8518-65b8-4c63-8a9b-afbb32c97c97',
    }

    const requestString = `${protocol}://${ipAddress}/filtering/filter_addresses?` + new URLSearchParams(params)

    // console.log("SENT JSON", jsonData)
    const response = await fetch(requestString, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
    },
        body: jsonData
    })

    // console.log("response JSON", response)
    return parseWallets(await response.json(), token)
}

function parseWallets(response, token) {
    let res = []
    for (const [key, value] of Object.entries(response)) {
        value['id'] = key
        value['address'] = {address: value['address'], 'token': token, tag: value['tag']}

        res.push(value)
    }
    return res
}
