
import { InfoBlock } from "./Portfolio"
import { balanceLayout } from "./dataPortfolio";

import { useState, useRef, useEffect } from "react";

import { Line, Chart } from "react-chartjs-2";

import {options, data, optionsRealisedPL, dataRealisedPL } from "./ChartSettings/PortfolioPNL.js"

import { getWalletPerformanceInfo } from "./loadData.js";

import { getPLHistory, getRealisedPLHistory } from "./loadData.js";

import { tableStyle } from "./TableSettings/BalanceTable.js";

// import { columns } from "./TableSettings/BuySellTable";

import { columns, miniColumns, miniRealisedColumns, realisedColumns } from "./TableSettings/PerformanceTable.js";
import { parseValueForTooltip } from "./ChartSettings/utils.js";
import { DataGrid } from "@mui/x-data-grid";

import { styled } from '@mui/material/styles';

import { LoadingPlaceholder, ChartLoadingPlaceholder } from "./LoadingPlaceholder.js";

import { timeSince } from "./Overview.js";

import { calculatePercentChange } from "./ChartSettings/NetWorth.js";

import { ShowDollarPnl } from "./dataPortfolio";

import { parseBigValues } from "./ChartSettings/utils.js";

import jdenticon from "jdenticon/standalone";

import { returnStyle } from "./PortfolioBalance.js";


import "./PortfolioBalance.css";
import "./PortfolioPnl.css"

const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

const color = {
    'negative': ['rgba(238, 40, 138, 0.15)', 'rgba(238, 40, 138, 0.01)', 'rgba(238, 40, 138, 1.0)', 'rgba(238, 40, 138, 0.5)'],
    'positive': ['rgba(172, 209, 251, 0.15)', 'rgba(172, 209, 251, 0.01)', 'rgba(172, 209, 251, 1.0)', 'rgba(172, 209, 251, 0.5)']
}


function ToolTipDescription(props) {
    if(props.text === '') {
        return
    }
    return (
        <div className="tooltip" style={{marginRight: 'auto', height: 'auto', width: '40px', display: 'flex', marginLeft: '4px', alignItems: 'center'}}>
                        <div style={{marginTop: '2px', marginLeft: '4px'}}>
                            <svg class="info-icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="rgb(123, 122, 149)" stroke-width="1.7"/>
                                <text x="12" y="16" text-anchor="middle" font-size="12" font-family="'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" fill="rgb(123, 122, 149)" font-weight="bold">i</text>
                                {/* <circle cx="8" cy="8" r="6.67" fill="none" stroke="#ffffff" stroke-width="1.5"/>
                                <text x="8" y="10.6666666667" text-anchor="middle" font-size="12" font-family="'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" fill="#ffffff" font-weight="bold">i</text> */}
                            </svg>
                        </div>
                        {(
                            <span className="tooltiptext-small" style={{maxWidth: '400px', padding: '14px', flexDirection: 'column', display: 'flex'}}>
                                {/* {console.log("DESCR", cryptoIndexes[indexNames[selectedIndex]].description)}
                                {console.log("DESCR", cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[0])} */}
                                {/* {<DescriptionBlock header={cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[0]} texts={cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[1]}/>} */}
                                {'ℹ️ ' + props.text}
                                {/* <button className="full-guide-button" onClick={handleFullGuideClick}>
                                    Full Guide<span className="arrow-down">↓</span>
                                </button> */}
                            </span>
                        )}
                        
    </div>)
}

const crosshairPlugin = (setCurrentPoint, getColorSign) => ({
    id: 'crosshairPlugin',
    afterDatasetsDraw: (chart) => {
    //   console.log('afer crossher')
    //   if (chart.id !== 'netWorthChart') return;  // Only apply to the specific chart
      const ctx = chart.ctx;
      const xAxis = chart.scales.x;
      const yAxis = chart.scales.y;
      const tooltip = chart.tooltip;
  
      if (tooltip._active && tooltip._active.length) {
        const activePoint = tooltip._active[0];
        const { x, y } = activePoint.element;

        const colorSign = getColorSign();

        // Draw vertical (y-axis) dotted line
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([5, 5]); // Dotted pattern: 10px line, 10px gap
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 1.5; // Thicker line
        ctx.strokeStyle = 'rgba(128, 128, 128, 0.5)'; // Grey color
        ctx.stroke();
        ctx.restore();

        // console.log('color try', color, colorSign)
        // Draw horizontal (x-axis) fine dotted line
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([2, 4]); // Fine dotted pattern: 2px line, 4px gap
        ctx.moveTo(xAxis.left, y);
        ctx.lineTo(xAxis.right, y);
        ctx.lineWidth = 0.75; // Thinner line
        ctx.strokeStyle = color[colorSign][3];
        // 'rgba(238, 40, 138, 0.5)'; // Lovely color
        ctx.stroke();
        ctx.restore();
  
        // // Draw vertical line
        // ctx.save();
        // ctx.beginPath();
        // ctx.moveTo(x, yAxis.top);
        // ctx.lineTo(x, yAxis.bottom);
        // ctx.lineWidth = 1;
        // ctx.strokeStyle = 'rgba(238, 40, 138, 0.5)';
        // ctx.stroke();
        // ctx.restore();
  
        // // Draw horizontal line
        // ctx.save();
        // ctx.beginPath();
        // ctx.moveTo(xAxis.left, y);
        // ctx.lineTo(xAxis.right, y);
        // ctx.lineWidth = 1;
        // ctx.strokeStyle = 'rgba(238, 40, 138, 0.5)';
        // ctx.stroke();
        // ctx.restore();

        // Draw circle at the center of the crosshair
        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, 5, 0, 2 * Math.PI); // Circle with radius 5
        ctx.fillStyle = color[colorSign][2] 
        // 'rgba(238, 40, 138, 1.0)'; // Red fill
        ctx.fill();
        ctx.lineWidth = 2; // Border width
        // ctx.strokeStyle = 'white'; // White border
        ctx.strokeStyle = 'rgb(17, 14, 28)';
        ctx.stroke();
        ctx.restore();

        // Update the current point state
        const datasetIndex = activePoint.datasetIndex;
        const dataIndex = activePoint.index;
        const dataset = chart.data.datasets[datasetIndex];
        const data = dataset.data[dataIndex];
        setCurrentPoint(data);
      }
    },
    beforeTooltipDraw: (chart) => {
    //   if (chart.id !== 'netWorthChart') return;  // Only apply to the specific chart
      const tooltip = chart.tooltip;
      if (tooltip._active && tooltip._active.length) {
        if (!tooltip.positioners) {
            tooltip.positioners = {}; // Initialize positioners if undefined
        }
        if (!tooltip.positioners.nearest) {
          tooltip.positioners.nearest = (elements, eventPosition) => {
            const element = elements[0];
            return {
              x: element.element.x,
              y: element.element.y,
            };
          };
        }
      }
    },
    afterEvent: (chart, args) => {
      const event = args.event;
      const tooltip = chart.tooltip;

      if (event.type === 'mouseout') {
        // Clear the crosshair by redrawing the chart without active points
        tooltip._active = [];
        chart.draw();

        setCurrentPoint(null);
      }

      if (tooltip._active && tooltip._active.length) {
        chart.draw();
      }
    //   console.log('before crosher2')
    }
});

const NetWorthLayout = ({ firstPoint, lastPoint }) => {
    if(firstPoint === null || lastPoint === null) {
        return
    }
    const changeValue = (lastPoint.y - firstPoint.y)
    // const percentChange = (changeValue / firstPoint.y * 100).toFixed(2);
    const percentChange = calculatePercentChange(changeValue, firstPoint.y)
    const percentClass = percentChange >= 0 ? 'positive' : 'negative';
    // console.log('percent - ', currentValue, firstValue)
    const prefix = percentChange >= 0 ? '+':''
    return (
        <div className={`networth-tooltip-percent ${percentClass}`} style={{fontSize: '20px', height:'24px'}}>
            {prefix}${percentChange}% (${parseValueForTooltip(Math.abs(changeValue))})
        </div>
    )
}

const calculatePercentageChange = (oldValue, newValue) => {
    if (oldValue === null || oldValue === undefined || oldValue === 0) return '--%';
    const prefix = (newValue - oldValue) >= 0 ? '+' : ''
    return `${prefix}${(((newValue - oldValue) / oldValue) * 100).toFixed(2)}%`;
  };
  
const calculateChanges = (data) => {
    const dataLength = data.length;
    const lastDataPoint = data[dataLength - 1];
    const currentBalance = lastDataPoint.balance_dollar;
  
    const oneMonthIndex = dataLength - 5;  // Approximately 4 weeks ago
    const sixMonthsIndex = dataLength - 25; // Approximately 24 weeks ago
    const oneYearIndex = dataLength - 49;  // Approximately 48 weeks ago
  
    const oneMonthBalance = oneMonthIndex >= 0 ? data[oneMonthIndex].balance_dollar : null;
    const sixMonthsBalance = sixMonthsIndex >= 0 ? data[sixMonthsIndex].balance_dollar : null;
    const oneYearBalance = oneYearIndex >= 0 ? data[oneYearIndex].balance_dollar : null;
  
    const oneMonthChange = calculatePercentageChange(oneMonthBalance, currentBalance);
    const sixMonthsChange = calculatePercentageChange(sixMonthsBalance, currentBalance);
    const oneYearChange = calculatePercentageChange(oneYearBalance, currentBalance);
  
    return {
      oneMonth: oneMonthChange,
      sixMonths: sixMonthsChange,
      oneYear: oneYearChange
    };
};

function ChartPNL(props) {
    const chartRef = useRef(null);

    const colorSignRef = useRef('positive');

    const [currentPoint, setCurrentPoint] = useState(null);
    const [lastPoint, setLastPoint] = useState(null)
    const [firstPoint, setFirstPoint] = useState(null)

    const [colorSign, setColorSign] = useState(null)

    const cleanChart = () => {
        const chart = chartRef.current;
        if (chart) {
          for (let i = 0; i < chart.data.datasets.length; i += 1) {
            chart.data.datasets[i].data = [];
          }
          chart.resetZoom();
          chart.update();
        }
    };

    const updateChart = (address) => {
        props.setLoading(true);
        console.log("PROPS SET TO TRUE")
        getPLHistory(address).then((values) => {
            if(values && values.length > 0) {
                const chart = chartRef.current

                props.setChanges(calculateChanges(values))
                console.log("values", values)
                chart.data.datasets[0].data = values
                const firstElement = values[0]
                const lastElement = values[values.length - 1]
                setFirstPoint(firstElement)
                setLastPoint(lastElement)

                setColorSign(lastElement.y - firstElement.y >= 0 ? 'positive' : 'negative')

                chart.update()
            }
            console.log("PROPS SET TO FALSE")
            props.setLoading(false)
          
        });
      };

    useEffect(() => {
        colorSignRef.current = colorSign;
    }, [colorSign]);

    useEffect(() => {
        console.log("SET NEW PROPS", props.loading)
    }, [props.loading])

    useEffect(() => {
        if(colorSign === null){
            return
        }
        const chart = chartRef.current
        const ctx = chart.ctx;

        // console.log("chart scales", chart.scales['y'].getPixelForValue(0))

        // Assuming the scale is linear and zero is included in the y-axis
        const yScale = chart.scales['y']; // Adjust based on your axis ID!
        const zeroY = yScale.getPixelForValue(0); // Get the pixel for the zero value

        // Create gradients
        const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);

        // Calculate the stops based on zero position
        const zeroRelative = zeroY / chart.height;
        // console.log('zero relative pnl', zeroRelative)

        // Colors (assuming color object is predefined appropriately)
        const topColor = color[colorSign][0];
        const bottomColor = color[colorSign][1];

        // Gradient above zero (green to transparent)
        gradient.addColorStop(0, color['positive'][0]); // Fully colored at the top
        gradient.addColorStop(Math.min(Math.max(0, zeroRelative ), 1), color['positive'][1]); // Fully transparent close to zero

        // Gradient below zero (transparent to red)
        gradient.addColorStop(Math.max(Math.min(1, zeroRelative ), 0), color['negative'][1]); // Transparent close to zero
        gradient.addColorStop(1, color['negative'][0]); // Fully colored at the bottom

        const gradientBorder = ctx.createLinearGradient(0, 0, 0, chart.height);
        gradientBorder.addColorStop(0, color['positive'][2]); // Fully colored at the top
        gradientBorder.addColorStop(Math.min(Math.max(0, zeroRelative ), 1), color['positive'][2]); // Fully transparent close to zero

        // Gradient below zero (transparent to red)
        gradientBorder.addColorStop(Math.max(Math.min(1, zeroRelative ), 0), color['negative'][2]); // Transparent close to zero
        gradientBorder.addColorStop(1, color['negative'][2]); // Fully colored at the bottom


        // Apply the gradient to the dataset
        //chart.data.datasets[0].borderColor = color[colorSign][2];
        chart.data.datasets[0].borderColor = gradientBorder;
        chart.data.datasets[0].backgroundColor = gradient;
        chart.update();

        // const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
        // // console.log('color sign', colorSign)
        // gradient.addColorStop(0, color[colorSign][0]);
        // gradient.addColorStop(1, color[colorSign][1]);

        // // console.log('gradient', gradient)

        // chart.data.datasets[0].borderColor = color[colorSign][2]
        // chart.data.datasets[0].backgroundColor = gradient;
        // chart.update()
    }, [colorSign])

    useEffect(() => {
        if(currentPoint === null) {
            if(firstPoint !== null && lastPoint !== null) {
                setColorSign(lastPoint.y - firstPoint.y >= 0 ? 'positive' : 'negative')
            }
        } else {
            setColorSign(currentPoint.y - firstPoint.y >= 0 ? 'positive' : 'negative')
        }
    }, [currentPoint])
    
    useEffect(() => {
        cleanChart();
        updateChart(props.address);
    }, [props.address]);

    const getColorSign = () => colorSignRef.current;

    return (
        <div className="walletOverview-chart" style={{borderRadius: '8px'}}>
            <div style={{ height:'auto', color: 'white' }}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                    <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>{props.chartName}</p>
                    <ToolTipDescription text={'This chart displays the historical changes in overall profit and loss. The overall P&L includes both Realised P&L (the actual profit and loss obtained from the sales of coins) and Unrealised P&L (the profit and loss the address would achieve if all held coins were sold at the current moment).'} />
                </div>
                {/* <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Net Worth</p> */}
                <LoadingPlaceholder isLoading={props.loading} height="56px" width="320px">
                    <div class="networth-tooltip-text" style={{fontSize: '46px', height: '56px'}}>
                        {/* {(lastPoint !== null) && `$${parseValueForTooltip(lastPoint.y)}`}  */}
                        {(lastPoint !== null) && <ShowDollarPnl withoutColor={true} value={lastPoint.y} margin={'0px'}/>}
                    </div>
                </LoadingPlaceholder>
                {/* <LoadingPlaceholder isLoading={props.loading} height="24px" width="280px">
                    <NetWorthLayout firstPoint={firstPoint} lastPoint={lastPoint} />
                </LoadingPlaceholder> */}
            </div>
            <div className="walletOverview-chart-chart">
            {props.loading && (
        <ChartLoadingPlaceholder isLoading={props.loading} height="100%" />
      )}
                <Line id="netWorthChart" ref={chartRef} data={data} redraw={true} options={options} plugins={[crosshairPlugin(setCurrentPoint, getColorSign)]} />
            </div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '32px'}}>
            <LoadingPlaceholder isLoading={props.loading} height="18px" width="120px">
                {(firstPoint !== null) && <div className="networth-tooltip-date" style={{color: 'rgb(123, 122, 149)'}}>{formatDate(firstPoint.x)}</div>}
            </LoadingPlaceholder>
            <LoadingPlaceholder isLoading={props.loading} height="18px" width="120px" marginLeft="auto">
                {(lastPoint !== null) && <div className="networth-tooltip-date" style={{color: 'rgb(123, 122, 149)', marginLeft: 'auto', textAlign: 'right'}}>{formatDate(lastPoint.x)}</div>}
            </LoadingPlaceholder>
        </div>
        </div>

    )
}

function ChartRealisedPNL(props) {
    const chartRef = useRef(null);

    const colorSignRef = useRef('positive');

    const [currentPoint, setCurrentPoint] = useState(null);
    const [lastPoint, setLastPoint] = useState(null)
    const [firstPoint, setFirstPoint] = useState(null)

    const [colorSign, setColorSign] = useState(null)

    const cleanChart = () => {
        const chart = chartRef.current;
        if (chart) {
          for (let i = 0; i < chart.data.datasets.length; i += 1) {
            chart.data.datasets[i].data = [];
          }
          chart.resetZoom();
          chart.update();
        }
    };

    const updateChart = (address) => {
        props.setLoading(true);
        console.log("PROPS SET TO TRUE")
        getRealisedPLHistory(address).then((values) => {
            if(values && values.length > 0) {
                const chart = chartRef.current

                props.setChanges(calculateChanges(values))

                const pnlData = values.map(value => ({x: value.x, y: value.y}))
                const profitData = values.map(value => ({x: value.x, y: value.y2}))
                const lossData = values.map(value => ({x: value.x, y: value.y3}))
                console.log("values", values)
                chart.data.datasets[0].data = pnlData
                chart.data.datasets[1].data = profitData
                chart.data.datasets[2].data = lossData
                const firstElement = values[0]
                const lastElement = values[values.length - 1]
                setFirstPoint(firstElement)
                setLastPoint(lastElement)

                setColorSign(lastElement.y - firstElement.y >= 0 ? 'positive' : 'negative')

                chart.update()
            }
            console.log("PROPS SET TO FALSE")
            props.setLoading(false)
          
        });
      };

    useEffect(() => {
        colorSignRef.current = colorSign;
    }, [colorSign]);

    useEffect(() => {
        console.log("SET NEW PROPS", props.loading)
    }, [props.loading])

    useEffect(() => {
        if(colorSign === null){
            return
        }
        const chart = chartRef.current
        const ctx = chart.ctx;

        // console.log("chart scales", chart.scales['y'].getPixelForValue(0))

        // Assuming the scale is linear and zero is included in the y-axis
        const yScale = chart.scales['y']; // Adjust based on your axis ID!
        const zeroY = yScale.getPixelForValue(0); // Get the pixel for the zero value

        // Create gradients
        const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);

        // Calculate the stops based on zero position
        const zeroRelative = zeroY / chart.height;
        // console.log('zero relative', zeroRelative)

        // Colors (assuming color object is predefined appropriately)
        const topColor = color[colorSign][0];
        const bottomColor = color[colorSign][1];

        // Gradient above zero (green to transparent)
        gradient.addColorStop(0, color['positive'][0]); // Fully colored at the top
        gradient.addColorStop(Math.min(Math.max(0, zeroRelative ), 1), color['positive'][1]); // Fully transparent close to zero

        // Gradient below zero (transparent to red)
        gradient.addColorStop(Math.max(Math.min(1, zeroRelative ), 0), color['negative'][1]); // Transparent close to zero
        gradient.addColorStop(1, color['negative'][0]); // Fully colored at the bottom

        const gradientBorder = ctx.createLinearGradient(0, 0, 0, chart.height);
        gradientBorder.addColorStop(0, color['positive'][2]); // Fully colored at the top
        gradientBorder.addColorStop(Math.min(Math.max(0, zeroRelative ), 1), color['positive'][2]); // Fully transparent close to zero

        // Gradient below zero (transparent to red)
        gradientBorder.addColorStop(Math.max(Math.min(1, zeroRelative ), 0), color['negative'][2]); // Transparent close to zero
        gradientBorder.addColorStop(1, color['negative'][2]); // Fully colored at the bottom


        // Apply the gradient to the dataset
        //chart.data.datasets[0].borderColor = color[colorSign][2];
        chart.data.datasets[0].borderColor = gradientBorder;
        chart.data.datasets[0].backgroundColor = gradient;
        // chart.data.datasets[1].borderColor = gradientBorder;
        chart.data.datasets[2].borderColor = gradientBorder;
        chart.update();

        // const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
        // // console.log('color sign', colorSign)
        // gradient.addColorStop(0, color[colorSign][0]);
        // gradient.addColorStop(1, color[colorSign][1]);

        // // console.log('gradient', gradient)

        // chart.data.datasets[0].borderColor = color[colorSign][2]
        // chart.data.datasets[0].backgroundColor = gradient;
        // chart.update()
    }, [colorSign])

    useEffect(() => {
        if(currentPoint === null) {
            if(firstPoint !== null && lastPoint !== null) {
                setColorSign(lastPoint.y - firstPoint.y >= 0 ? 'positive' : 'negative')
            }
        } else {
            setColorSign(currentPoint.y - firstPoint.y >= 0 ? 'positive' : 'negative')
        }
    }, [currentPoint])
    
    useEffect(() => {
        cleanChart();
        updateChart(props.address);
    }, [props.address]);

    const getColorSign = () => colorSignRef.current;

    return (
        <div className="walletOverview-chart" style={{borderRadius: '8px'}}>
            <div style={{ height:'auto', color: 'white' }}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                    <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>{props.chartName}</p>
                    <ToolTipDescription text={'This chart displays the historical changes in realised profit and loss. The Realised P&L is the actual profit and loss obtained from the sales of coins.'} />
                </div>
                {/* <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Net Worth</p> */}
                <LoadingPlaceholder isLoading={props.loading} height="56px" width="300px">
                    <div class="networth-tooltip-text" style={{fontSize: '46px', height: '56px'}}>
                        {/* {(lastPoint !== null) && `$${parseValueForTooltip(lastPoint.y)}`}  */}
                        {(lastPoint !== null) && <ShowDollarPnl  withoutColor={true}  value={lastPoint.y} margin={'0px'}/>}
                    </div>
                </LoadingPlaceholder>
                {/* <LoadingPlaceholder isLoading={props.loading} height="24px" width="280px">
                    <NetWorthLayout firstPoint={firstPoint} lastPoint={lastPoint} />
                </LoadingPlaceholder> */}
            </div>
            <div className="walletOverview-chart-chart">
            {props.loading && (
        <ChartLoadingPlaceholder isLoading={props.loading} height="100%" />
      )}
                {/* <Line id="netWorthChart" ref={chartRef} data={data} redraw={true} options={optionsRealisedPL} plugins={[crosshairPlugin(setCurrentPoint, getColorSign)]} /> */}
                <Chart id="netWorthChart" type="bar" ref={chartRef} data={dataRealisedPL} redraw={true} options={optionsRealisedPL} plugins={[crosshairPlugin(setCurrentPoint, getColorSign)]} /> 
            </div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '32px'}}>
            <LoadingPlaceholder isLoading={props.loading} height="18px" width="120px">
                {(firstPoint !== null) && <div className="networth-tooltip-date" style={{color: 'rgb(123, 122, 149)'}}>{formatDate(firstPoint.x)}</div>}
            </LoadingPlaceholder>
            <LoadingPlaceholder isLoading={props.loading} height="18px" width="120px" marginLeft="auto">
                {(lastPoint !== null) && <div className="networth-tooltip-date" style={{color: 'rgb(123, 122, 149)', marginLeft: 'auto', textAlign: 'right'}}>{formatDate(lastPoint.x)}</div>}
            </LoadingPlaceholder>
        </div>
        </div>

    )
}

function TokenPerformance(props) {
    const [tokens, setTokens] = useState(["---", "---", "---"])
    const [profits, setPorfits] = useState(["---", "---", "---"])
    const [logos, setLogos] = useState(["", "", ""])

    function prepareLogo(logo, address) {
        if(logo === '') {
            const size = 32
            const svgImg = jdenticon.toSvg(address, size)
            const encodedSVG = encodeURIComponent(svgImg)
            logo = `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
        }
        return logo
    }

    return (
        <div className="walletInformation-raw" style={{justifyContent: 'space-between'}}>
            {props.loading && <LoadingPlaceholder isLoading={props.loading} height="40px" width="100px" margin="0px">
                <div class="token-container">
                </div>
            </LoadingPlaceholder>}
            {props.loading && <LoadingPlaceholder isLoading={props.loading} height="40px" width="100px" margin="0px">
                <div class="token-container">
                </div>
            </LoadingPlaceholder>}
            {props.loading && <LoadingPlaceholder isLoading={props.loading} height="40px" width="100px" margin="0px">
                <div class="token-container">
                </div>
            </LoadingPlaceholder>}
            {!props.loading && 
                // tokens.map((tokenName, index) => 
                //     {
                //         const logo = props.stats[props.table_name]?.[index]?.logo || logos[index];
                //         const token = props.stats[props.table_name]?.[index]?.symbol || tokenName;
                //         let profit = props.stats[props.table_name]?.[index] 
                //                                 ? parseBigValues(props.stats[props.table_name][index][props.value_name])  // If exists, parse it
                //                                 : profits[index];

                //         const style = returnStyle(profit)
                //         const prefix = style === 'positive' ? '+':'-'
                //         if(style === 'negative') {
                //             profit = profit.slice(1)
                //         }
                //         return (
                //             <div class="token-container">
                //                 <img alt="text" style={{marginLeft: "0px", width: '32px', height: '32px'}} className="tokenField-logo" src={ prepareLogo(logo, token) } />
                //                 <div class="token-details">
                //                     <div class="token-name">{token}</div>
                //                     <div class={`token-value ${style}`} style={{fontFamily: "SFProLight"}}>{prefix+`$${profit}`}</div>
                //                 </div>
                //             </div>
                //         )
                //     }
                // )

                props.stats?.[props.table_name]?.map((object) => {
                    const logo = object?.logo;
                    const token = object?.symbol;
                    let profit =  parseBigValues(object[props.value_name])  // If exists, parse it

                    const style = returnStyle(profit)
                    const prefix = style === 'positive' ? '+':'-'
                    if(style === 'negative') {
                        profit = profit.slice(1)
                    }
                    return (
                        <div class="token-container">
                            <img alt="text" style={{marginLeft: "0px", width: '32px', height: '32px'}} className="tokenField-logo" src={ prepareLogo(logo, token) } />
                            <div class="token-details">
                                <div class="token-name">{token}</div>
                                <div class={`token-value ${style}`} style={{fontFamily: "SFProReg"}}>{prefix+`$${profit}`}</div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

function Information(props) {
    return (
        <div className="walletInformation">
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Performance Breakdown</p>
            </div>
            {/* <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Information</p> */}
            {/* <div className="walletInformation-raw">
                <div className="walletInformation-raw-name">
                    Total P&L
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    <ShowDollarPnl value={props.stats.total_pl} margin={'0px'}/>
                </div>}
            </div> */}
            {/* <div className="walletInformation-raw">
                <div className="walletInformation-raw-name">
                    =
                </div>
            </div> */}
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name" style={{display: 'flex'}}>
                    <span style={{lineHeight: '22px'}}>Unrealised P&L</span>
                    <ToolTipDescription text={'Unrealised Profit & Loss represents the amount of profit or loss that all the coins currently held in the wallet would generate if they were sold at the present moment. This is potential P&L because the actual sale has not yet occurred.'} />
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    <ShowDollarPnl value={props.stats.unrealised_pl} margin={'0px'}/>
                </div>}
                {/* <div className="walletInformation-raw-value">
                    8
                </div> */}
            </div>
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name" style={{display: 'flex'}}>
                    <span style={{lineHeight: '22px'}}>Realised P&L</span>
                    <ToolTipDescription text={'Realised Profit & Loss is the amount of profit or loss that has been obtained from the sale of coins.'} />
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    <ShowDollarPnl value={props.stats.realised_pl} margin={'0px'}/>
                </div>}
            </div>
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name" style={{marginLeft: '40px'}}>
                    Profit
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    {/* {`$${parseValueForTooltip(props.stats.realised_profit)}`} */}
                    <ShowDollarPnl value={props.stats.realised_profit} margin={'0px'}/>
                </div>}
            </div>
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name" style={{marginLeft: '40px'}}>
                    Loss
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    {/* {`$${parseValueForTooltip(props.stats.realised_loss)}`} */}
                    <ShowDollarPnl value={props.stats.realised_loss} margin={'0px'}/>
                </div>}
            </div>
            {/* <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)', marginTop: '40px'}}>Net Worth Change</p> */}
            { (props.loading || props.stats?.['top3_pl']?.length !== 0) && <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', marginTop: '24px'}}>
                <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Top Performing Coins</p>
                <ToolTipDescription text={'These are the top 3 coins that generate the highest overall profit, considering both unrealised P&L and realised P&L.'} />
            </div> }
            <TokenPerformance stats={props.stats} loading={props.loading} table_name={'top3_pl'} value_name={'total_pl'} />
        </div>
    )
}

function Information2(props) {
    return (
        <div className="walletInformation">
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Trading Performance Overview</p>
                <ToolTipDescription text={'These metrics reflect the amount of profit and loss the address has obtained from selling ERC20 coins, as well as the Win Rate (the percentage of profitable coin sales).'} />
            </div>
            {/* <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Information</p> */}
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name">
                    Realised P&L
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    <ShowDollarPnl value={props.stats.realised_pl} margin={'0px'}/>
                </div>}
            </div>
            {/* <div className="walletInformation-raw">
                <div className="walletInformation-raw-name">
                    =
                </div>
            </div> */}
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name" style={{marginLeft: '40px'}}>
                    Profit
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    <ShowDollarPnl value={props.stats.realised_profit} margin={'0px'}/>
                    {/* {`$${parseValueForTooltip(props.stats.realised_profit)}`} */}
                </div>}
            </div>
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name" style={{marginLeft: '40px'}} >
                    Loss
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    <ShowDollarPnl value={props.stats.realised_loss} margin={'0px'}/>
                    {/* {`$${parseValueForTooltip(props.stats.realised_loss)}`} */}
                </div>}
            </div>
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name" >
                    Win Rate
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    {`${props.stats.win_rate != '-' ? parseValueForTooltip(props.stats.win_rate*100)+'%' : props.stats.win_rate}`}
                </div>}
            </div>
            {/* <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)', marginTop: '40px'}}>Net Worth Change</p> */}
            { (props.loading || props.stats?.['top3_rp']?.length !== 0) && 
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', marginTop: '24px'}}>
                <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Top Gaining Coins</p>
                <ToolTipDescription text={'These are the top 3 coins that generate the highest realised profit.'} />
            </div> }
            <TokenPerformance stats={props.stats} loading={props.loading} table_name={'top3_rp'} value_name={'realised_profit'} />
            { (props.loading || props.stats?.['top3_rl']?.length !== 0) && 
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', marginTop: '24px'}}>
                <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Top Losing Coins</p>
                <ToolTipDescription text={'These are the top 3 coins that generate the highest realised loss.'} />
            </div> }
            <TokenPerformance stats={props.stats} loading={props.loading} table_name={'top3_rl'} value_name={'realised_loss'} />
        </div>
    )
}

function MenuInfo(props) {
    // const [hideMenu, setHide] = useState(false)
    // const [tokenInput, setTokenInput] = useState("")

    // const [toggleDropDown, setDropDown] = useState(false)

    // const openInNewTab = url => {
    //     window.open(url, '_blank', 'noopener,noreferrer');
    // };

    // function toggleMenu() {
    //     setDropDown(!toggleDropDown)
    // }


    return (
        <div className="walletOverview-overview" style={{flex: props.isMobile? '1 1': '', borderRadius: '8px'}}>
            <div style={{height: !props.hideMenu ? "100%": "100%", display: 'flex', flexDirection: 'column', gap: '12px', borderRadius: '8px'}}>
                {/* <div style={{width:'100%', backgroundColor: '#110E1C', flex: '1 1'}}></div>
                <div style={{width:'100%', backgroundColor: '#110E1C', flex: '1 1'}}></div> */}
                {/* <InfoBlock text={[["Balance", '100,000'], ["Balance ($)", '$270,000']]}/> */}
                {props.layout.map((layout) => (<InfoBlock header={layout.header} fields={layout.fields} stats={props.stats} />))}
                {/* <InfoBlock header={props.layout[0].header} fields={props.layout[0].fields} stats={props.stats} /> */}
                {/* <InfoBlock text={[["NPL", '$3M'], ["Average NPL", '$2.1M'], ["Profit in %", "1720%"]]}/>
                <InfoBlock text={[["Volume Sent", '7M'], ["Volume Sent ($)", '$1.2M'], ["Volume Recieved", "5M"], ["Volume Recieved ($)", "$2.3M"]]}/>
                <InfoBlock text={[["Average Tx Volume", "15M"], ["Average Tx Volume ($)", "$3.1M"], ["First Transaction", "5 days after deploy"]]} /> */}
                {/* <InfoBlock text={"Balance ($)"} value={"270,000$"}/> */}
            </div>
        </div>
    )   
}

const TransparentRow = styled('div')(({ theme }) => ({
    opacity: 0.5, // Adjust the opacity as needed
}));

export function PortfolioPnl(props) {
    const [stats, setStats] = useState({})
    const [pageSize, setPageSize] = useState(7);
    const [loadingData, setLoading] = useState(true)
    const [loadingChart, setLoadingChart] = useState(true);
    const [dataGridTable, setDataGridTable] = useState([])

    const [realisedDataGridTable, setRealisedDataGridTable] = useState([])

    const [changes, setChanges] = useState({ oneMonth: '--%', sixMonths: '--%', oneYear: '--%' });

    const tableRef = useRef()
    const chartRef = useRef()


    const getRowClassName = (params) => {
        // Apply the TransparentRow class if logo is 0
        // console.log('get row class')
        if (params.row.logo === '') {
            // console.log('logo')
          return 'transparent-row';
        }
        return '';
    };

    useEffect(() => {
        setLoading(true)
        getWalletPerformanceInfo(props.address).then((data) => {
            console.log("GET WALLETS:: start")
            if(data) {
                console.log("GET WALLETS:: inside condition", data)
                setStats(data)

                setDataGridTable(data.tokens_by_total_pl)
                setRealisedDataGridTable(data.tokens_by_realised_pl)
            }
        }).catch(error => {
            console.error('Error fetching wallet info:', error);
        }).finally(() => {
            // Ensuring setLoading is called only after fetch completes
            // console.log('props change loading', loadingData)
            setLoading(false);
        });
    }, [])

    return (
        <div className="main-portfolio-section">
                <div className="walletOverview-info" style={{borderBottom: 'solid 1 red'}}>

                    <Information loading={loadingData} stats={stats} changes={changes}/>
                    <ChartPNL address={props.address} setChanges={setChanges} loading={loadingChart} setLoading={setLoadingChart} chartName={'Overall Profit & Loss'}/>
                </div>
                
                {props.address !== null && 
                
                <div className="walletBalances-info">
                <div className="walletBalances">
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Coins by Overall P&L</p>
                        <ToolTipDescription text={"This table displays all the coins the address has ever held, along with their values for Overall, Realized, and Unrealized Profit & Loss. Please note that this list includes only the ERC20 coins supported on our platform. Other assets will gradually be added to the platform."}/>
                    </div>
                    <DataGrid
    
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[7, 100]}
                            pagination
                            loading={loadingData}
                            rows={dataGridTable}
                            columns={props.isPhone ? miniColumns: columns}

                            rowHeight={60}
                            headerHeight={35}

                            showCellRightBorder={true}
                            disableSelectionOnClick

                            getRowClassName={getRowClassName}
                            sx={tableStyle}
                            ref={tableRef}
                            autoHeight={true}
                        />
                    </div>
                </div>
                }
                <div className="walletOverview-info" style={{borderBottom: 'solid 1 red'}}>
                    <Information2 loading={loadingData} stats={stats} changes={changes}/>
                    <ChartRealisedPNL address={props.address} setChanges={setChanges} loading={loadingChart} setLoading={setLoadingChart} chartName={'Realised Profit & Loss'}/>
                </div>
                {props.address !== null && 
                
                <div className="walletBalances-info">
                <div className="walletBalances">
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Coins by Realised P&L</p>
                        <ToolTipDescription text={"This table displays all the coins the address has ever held, along with their values for Realised Profit, Loss and Total Realized Profit & Loss. Please note that this list includes only the ERC20 coins supported on our platform. Other assets will gradually be added to the platform."}/>
                    </div>
                    <DataGrid
    
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[7, 100]}
                            pagination
                            loading={loadingData}
                            rows={realisedDataGridTable}
                            columns={props.isPhone ? miniRealisedColumns: realisedColumns}

                            rowHeight={60}
                            headerHeight={35}

                            showCellRightBorder={true}
                            disableSelectionOnClick

                            getRowClassName={getRowClassName}
                            sx={tableStyle}
                            ref={tableRef}
                            autoHeight={true}
                        />
                    </div>
                </div>
                }
        </div>
    )
}