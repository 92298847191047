// import { useState } from 'react'
// import { supabase } from './supabaseClient'
import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import {
    // Import predefined theme
    ThemeSupa,
  } from '@supabase/auth-ui-shared'
import { useNavigate, useLocation } from "react-router-dom";
import "./auth.css"

import iconGoogle from "../assets/icons/icon_google.png"

const supabase = createClient(
    'https://xjllubhirqqferhrmgbs.supabase.co', 
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhqbGx1YmhpcnFxZmVyaHJtZ2JzIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzc4NDc0NDQsImV4cCI6MTk5MzQyMzQ0NH0.fdfxmBEPbHKPBIcvyQX60RB85kSStpP_wP-t9HmelCE'
)


const AuthComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [mode, setMode] = useState('login');
    const [message, setMessage] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [isRecoveryMode, setIsRecoveryMode] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [referralCode, setReferralCode] = useState('');


    useEffect(() => {
        // Check if the 'type' is passed in the state when navigating to this component
        const type = location.state?.type;
        // Set the mode based on the passed state or default to 'login'
        setMode(type || 'login');

        const code = location.state?.referralCode || localStorage.getItem('referralCode');

        if (code) {
            setReferralCode(code);
            // console.log("ref code - ", code)
        }

        supabase.auth.getUser()
            .then(response => {
                if (response.error) {
                    console.error('Error fetching user:', response.error.message);
                    return;
                }
                if (response.data.user) {
                    const fetchedUser = response.data.user;
                    console.log("User is logged in:", fetchedUser);
                    
                    // Apply referral code if provided
                    if (code) {
                        // console.log("User is already logged in: ", fetchedUser);
                        applyReferralCode(fetchedUser.id, code);
                        console.log("update ref in effect")
                        navigate('/account')
                    }
                }
            });
    }, [location.state]);


    useEffect(() => {
        supabase.auth.onAuthStateChange((event, session) => {
            if (event === "PASSWORD_RECOVERY") {
                setIsRecoveryMode(true);
            }
        });

        // return () => {
        //     authListener.unsubscribe();
        // };
    }, []);

    const switchMode = () => {
        setMode(mode === 'login' ? 'signup' : 'login');
        setMessage('');
    };

    const checkEmailExists = async (email) => {
        try {
            const { data, error } = await supabase
                .from('users')
                .select('is_verified')
                .eq('email', email)
                .single();
    
            if (error) {
                console.error('Error checking email:', error);
                return false; // or handle the error as needed
            }
    
            return data ? data.is_verified : false;
        } catch (err) {
            console.error('Exception when checking email:', err);
            return false; // or handle the exception as needed
        }
    };

    const applyReferralCode = async (userId, referralCode) => {
        try {
            const { data, error } = await supabase.from('users').update({
                referral_code: referralCode
            }).match({ id: userId });
    
            if (error) {
                console.error('Error updating referral code:', error);
                return { error };
            }
    
            console.log('Referral code updated successfully:', data);
            return { data };
        } catch (err) {
            console.error('Exception in updating referral code:', err);
            return { error: err };
        }
    };

    const requestPasswordReset = async () => {
        const { data, error } = await supabase.auth.resetPasswordForEmail(email, {redirectTo: 'https://app.cherry-pick.xyz/login',});
        if (error) setMessage(error.message);
        else setMessage(`Please check your email for the password reset link, including your Spam folder if it's not in the inbox.`);
    };

    const handlePasswordReset = async () => {
        const { error } = await supabase.auth.updateUser({
            password: newPassword
        });

        if (error) {
            setMessage(`Error updating password: ${error.message}`);
        } else {
            setMessage('Password updated successfully');
            setIsRecoveryMode(false);
            navigate('/login'); // Redirect to login page
        }
    };

    const handleAuthSuccess = () => {
        navigate('/filter'); // Redirect to the home route
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (mode === 'signup') {
            const emailExists = await checkEmailExists(email);
            if (emailExists) {
                setMessage('An account with this email already exists. Please log in.');
                setMode('login') // Optional: Switch to login view
                return;
            }

            // Setup base signup data
            const signupData = {
                email,
                password,
                options: {}
            };

            // Conditionally add referra code to signup data if it exists
            if (referralCode) {
                signupData.options.data = { referral_code: referralCode };
                signupData.options.redirectTo = 'https://app.cherry-pick.xyz/account';
            }

            // Proceed with normal sign-up process
            const { user, error: signUpError } = await supabase.auth.signUp(signupData);

            if (signUpError) {
                setMessage(signUpError.message);
            } else {
                setMessage(`Please check your email for the confirmation link, including your Spam folder if it's not in the inbox.`);
            }
        } else if (mode === 'login') {
            // Handle login
            const { error: loginError, data: loginData } = await supabase.auth.signInWithPassword({ email, password });

            if (loginError) {
                setMessage(loginError.message);
            } else {
                // Successful login
                // Redirect or handle the logged-in state

                if (referralCode) {
                    // console.log('start update ref code', loginData.user, loginData.session)
                    // Use loginData.user.id directly since it holds the authenticated user's info after login
                    const userId = loginData.user.id; // Correctly accessing the user ID
                    // console.log('Current User ID:', userId);
                    const res = await applyReferralCode(userId, referralCode)
                    console.log('apply ref code in sign in')
                    // console.log("RES update ref code", res)
                    navigate('/account')
                } else {
                    setMessage('Login successful');
                    navigate('/home');
                }
            }
        }
        // if (!error) {
        //     handleAuthSuccess();
        // }
    };

    const handleGoogleSignIn = async () => {
        let redirectToUrl = 'https://app.cherry-pick.xyz/home'
        
        if (referralCode) {
            console.log("I have referral code! Be aware bro!")
            redirectToUrl = `https://app.cherry-pick.xyz/referral?referral_code=${referralCode}`;
        }

        console.log("Redirect URL being set to:", redirectToUrl);

        const { user, session, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            // options: {
            //     redirectTo: 'http://localhost:3000/home'
            // }

            // options: {
            //     redirectTo: referralCode? `https://app.cherry-pick.xyz/referral?referral_code=${referralCode}` : 'https://app.cherry-pick.xyz/filter'
            // }

            options: {
                redirectTo: redirectToUrl
            }
        });
    
        if (error) {
            console.error('Error signing in with Google:s', error);
            setMessage(error.message);
        } else if (user) {
            if (referralCode) {
                // console.log('start update ref code', loginData.user, loginData.session)
                // Use loginData.user.id directly since it holds the authenticated user's info after login
                const userId = user.id; // Correctly accessing the user ID
                // console.log('Current User ID:', userId);
                const res = await applyReferralCode(userId, referralCode)
                console.log('update ref in login')
                // console.log("RES update ref code", res)
                navigate('/account')
            } else {
                navigate('/home'); // Redirect to home on successful login
            }
        }
    };

    const headerNames = {
        'login': 'Log In',
        'reset': 'Forgot your password?',
        'signup': 'Sign Up'
    }

    return (
        <div style={{backgroundColor: 'transparent',
                    color: 'white',
                        display: 'flex', 
                        flexDirection: 'column', 
                        maxWidth: '400px', 
                        height: 'auto', 
                        borderRadius: '8px',
                        border: "1px solid rgb(172, 209, 251)", 
                        // boxShadow: "rgba(255, 255, 255, 0.1) 0px 0px 10px 0px", 
                        // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        }}>
            <div style={{padding: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', 
                                                    textDecoration: 'none',
                                                    fontFamily: 'SFProBold',
                                                    fontSize: '28px',
                                                    marginTop: 0,
                                                    marginBottom: '16px',
                                                    }}> <span style={{color: '#DB3E88'}}>C</span>HERRYPICK</p>
            <p style={{fontFamily: 'SFProBold', marginTop: 0, fontSize: '24px', marginBottom: (mode === 'reset' ? '0em':'1em')}}>{isRecoveryMode? 'Reset your password':headerNames[mode]}</p>
            {!isRecoveryMode && (mode !== 'reset' ? (
            <div style={{width: '100%'}}>
            <button className="button-submit" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center', gap: '12px'}} onClick={handleGoogleSignIn}>
                <img src={iconGoogle} width={20} height={20}/>
                <p style={{margin:0}}>WITH GOOGLE</p>
            </button>
            <div class="line-container" style={{marginTop: '18px'}}>
                <div class="line"></div>
                <span class="line-text">OR</span>
                <div class="line"></div>
            </div>
            </div>) : (<p style={{fontFamily: 'SFProReg', fontSize: '16px'}}>Enter your email address and we will send you instructions to reset your password</p>))}
            {/* {isRecoveryMode && (<p style={{fontFamily: 'SFProReg', fontSize: '16px'}}>Please enter your new password</p>)} */}
            {isRecoveryMode ? (
                // Password Reset View
                <div style={{width: '100%'}}>
                    <label for="password-input" className='label-auth'>Password</label>
                    <input
                        type="password"
                        id="password-input"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="New Password"
                        className='pwd-input'
                    />
                    <button className="button-submit" onClick={handlePasswordReset}>CONTINUE</button>
                </div>
            ) : (
                // Normal Authentication View
                mode === 'login' || mode === 'signup' ? (
                    <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '10px'}}>
                        {/* <div className="input-wrapper">
                        <label for="email-input">Email</label>
                        <input id="email-input" className='email-input' type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                        
                        </div> */}
                        <label for="email-input" className='label-auth'>Email</label>
                        <input id="email-input" className='email-input' type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                        <label for="password-input" className='label-auth'>Password</label>
                        <input id="password-input" className='pwd-input' type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                        <button className="button-submit" type="submit">CONTINUE</button>
                        {/* <button className="button-submit" type="submit">{mode === 'login' ? 'Log In' : 'Sign Up'}</button> */}
                    </form>
                ) : (
                    // Password Reset Request View
                    <div style={{width: '100%'}}>
                        <label for="email-input" className='label-auth'>Email</label>
                        <input className='email-input' type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email for password reset" />
                        <button className="button-submit" onClick={requestPasswordReset}>CONTINUE</button>
                    </div>
                )
            )}
            {!isRecoveryMode && mode === 'login' && (
                // Forgot Password Button
                <button className='button-text' style={{marginTop: '18px'}} onClick={() => setMode('reset')}>Forgot your password?</button>
            )}
            {!isRecoveryMode && (
                // Switch Mode Button
                <button className='button-text' onClick={switchMode} style={{ marginTop: (mode !== 'login') ? '18px' : '5px'}}>
                    {mode === 'login' ? `Don't have an account? Sign up` : 'Already have an account? Log in'}
                </button>
            )}
            {message && <p className='error-msg'>{message}</p>}
            <p style={{color: 'white', fontFamily: 'SFProLight', textAlign:'center', fontSize: '14px', marginTop: '25px'}}>By signing up you agree to our <a href="https://cherry-pick.xyz/terms-and-conditions" target="_blank" style={{color: 'white'}}>Terms & Conditions</a> and <a href="https://cherry-pick.xyz/privacy-policy" target="_blank" style={{color: 'white'}}>Privacy Policy</a></p>
            </div>
        </div>
    );    
};

export default AuthComponent;

// export default function Auth2(props) {

//     return (
//         <Auth
//             supabaseClient={supabase}
//             localization={{
//                 variables: {
//                   sign_in: {
//                     password_label: 'Password',
//                     button_label: "SIGN IN",
//                   },
//                   sign_up: {
//                     button_label: "SIGN UP",
//                     confirmation_text: "Check your email for the confirmation link. Check your Spam folder if you don't see the email."
//                   },
//                   forgotten_password: {
//                     confirmation_text: "Check your email for the password reset link. Check your Spam folder if you don't see the email."
//                   }
//                 },
//             }}
//             appearance={{ 
//                 theme: ThemeSupa,
//                 variables: {
//                     default: {
//                         colors: {
//                             brand: "#EE288A",
//                             brandAccent: '#FF54A9', 
//                             brandButtonText: 'black',
//                             inputLabelText: 'white',
//                             inputText: 'white',
//                             inputBorder: 'white',
//                             // inputBorderHover: '#EE288A',
//                             // inputBorderFocus: '#EE288A',
//                             inputBorderHover: 'white',
//                             inputBorderFocus: 'white',
//                             inputBackground: 'transparent',
//                             inpurtPlaceHolder: '#8B8C96',
//                             dividerBackground: 'rgba(238, 40, 138, 0.3)',   
//                             defaultButtonBackground: 'rgba(238, 40, 138, 0.0)',
//                             defaultButtonBackgroundHover: 'white',
//                             defaultButtonBorder: 'rgba(255, 255, 255, 1.0)'
//                             // defaultButtonBackground: '#EE288A',
//                             // inputBorder: '#EE288A',
//                         },
//                         fonts: {
//                             bodyFontFamily: "SFProLight",
//                             buttonFontFamily: "SFProBold",
//                             inputFontFamily: "SFProLight",
//                             labelFontFamily: "SFProLight"
//                         },
//                         fontSizes: {
//                             baseButtonSize: "16px",
//                             baseBodySize: "14px",
//                         },
//                         space: {
//                             buttonPadding: "",
//                         }
//                     },
//                 },
//                 style: {
//                     message: {color: 'white'},
//                     button: {width: 'auto', borderRadius: "2px", height: "50px", lineHeight: "19px", letterSpacing:"0.1em"},
//                     input: {borderRadius: "2px", borderWidth: "2px", height: "50px"} //, borderColor: 'white'}
//                     // label: {background: 'white'},
//                     // anchor: { color: 'blue' },
//                     // divider: {background: 'white'}
//                     //  container: {background: 'white'}
//                 } 
//             }}
//             providers={[]}
//             // theme="dark"
//             redirectTo='https://app.cherry-pick.xyz/login'
//             view={props.view}
//             socialLayout='horizontal'
//         />
//     )
// }

// const { user, session } = Auth.useUser();
    // const auth = Auth.useUser();
    // const { session } = Auth.UserContextProvider
    // console.log("USER", user, session)
    // const navigate = useNavigate()

    // useEffect(() => {
    //     supabase.auth.onAuthStateChange((event) => {
    //      // you can look at the event here to determine if you should run it
    //      // for example if (event === 'PASSWORD_RECOVERY')
    //     //   console.log("EVENT", event)
    //       if (event === 'SIGNED_IN') {
    //         // navigate('/token-tracker')
    //         navigate('/login', {state:{type:'sign_up'}})
    //         // navigate('/login', {state:{type:'update_password'}})
    //       }
    //     })
    // }, [])
    // console.log("SESSION", session)

    // const navigate = useNavigate()

    // if(user && (props.view !== 'update_password')) {
    //     navigate('/token-tracker')
    // }