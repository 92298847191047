import React, { createContext, useState, useContext } from 'react';
import Toast from './Toast';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [popup, setPopup] = useState({ show: false, header: '', messages: [], actions: [] });
  const [toastInfo, setToastInfo] = useState({ isVisible: false, message: '', type: '' });

  // Show a toast message
  const showToast = (message, type = 'success') => {
    console.log("SHOW TOAAASTER")
    setToastInfo({ isVisible: true, message, type });
    setTimeout(() => {
      setToastInfo({ isVisible: false, message: '', type: '' });
    }, 5000); // The toast will disappear after 3 seconds
  };

  const showConfirmation = (header, messages, actions) => {
    setPopup({ show: true, header, messages, actions });
  };

  return (
    <PopupContext.Provider value={{ popup, setPopup, showConfirmation, toastInfo, showToast  }}>
      {children}
      {toastInfo.isVisible && <Toast message={toastInfo.message} type={toastInfo.type} />}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }

  return context;
};