import { useState, useRef, useEffect } from "react"
import { IndexBlock } from "./Menu"
import { tools } from "./data"
import hideButton from "../assets/hideButton.svg"

export default function ToolMenu(props) {
    const myRef = useRef()

    console.log("TOOLS", props.tools)
    const [hideMenu, setHide] = useState(false)
    let indexBlocks = []
    let currentIndex = 0
    for (let key in props.tools) {
        if (props.tools[key].length > 1) {
            indexBlocks.push(<IndexBlock header={ key } 
                indexes={props.tools[key]} 
                startIndex={currentIndex}
                selectedIndex={ props.selectedIndex } 
                toggleFunction={ props.setNewIndex }
                isMobile={ props.isMobile }
                toggleMobile={props.toggleTool}/>)
        }
        //currentIndex += tools[key].length
    }
    const styleMenu = hideMenu? {flex: "1 1 0px", minWidth: "20px"} : {}

    useEffect(() => {
        // This function checks if the clicked target is inside our ref (i.e., our component)
        function handleClickOutside(event) {
          if (myRef.current && !myRef.current.contains(event.target)) {
            console.log("You clicked outside of me!");
            if(props.isMobile) {
                props.toggleTool()
            }
          }
        }
    
        // Attach the click event listener
        document.addEventListener("mousedown", handleClickOutside);
    
        // Cleanup the event listener on component unmount
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

    return (
        <div style={styleMenu} className="menu2" ref={myRef}>
            { !hideMenu && indexBlocks }
            {/* { !hideMenu && <div className="subscribeSection">
                <div className="subscribeSection-el1">
                    <img src={ hideButton } style={{marginRight: "auto", marginLeft: "0px"}} alt="text" onClick={() => setHide(!hideMenu)} />
                </div> 
            </div> } */}
        </div>
    )
}