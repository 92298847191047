import React from "react";
import { useRef, useEffect } from "react";
import { cryptoIndexesV1 } from "./data";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
    ArcElement,
  } from 'chart.js';

import { Line, Bar, Doughnut, Chart  as Area } from 'react-chartjs-2';
import zoomPlugin, { resetZoom } from 'chartjs-plugin-zoom';

import "chartjs-adapter-date-fns";
import {enUS as en} from 'date-fns/locale';

import { data as transVolumeData, options as transVolumeOptions } from "./ChartSettings/TransactionVolume.js"
import { data as movedCoinsData, options as movedCoinsOptions } from "./ChartSettings/MovedCoinsAge.js"  
// import { globalConfig } from './data';

// const { cryptoIndexes } = globalConfig;

ChartJS.register(
    ArcElement,
    TimeScale,
    CategoryScale,
    LinearScale,  
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
  );

const months = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
}

function parseFloat(value) {
  if (value >= 0.1) {
    return value.toFixed(2)
  }
  if (value >= 0.01) {
    return value.toFixed(3)
  }
  if (value >= 0.001) {
    return value.toFixed(4)
  }
  if (value >= 0.0001) {
    return value.toFixed(5)
  }
  else {
    return value.toFixed(6)
  }
}

const ColorMap1 = ['rgb(236,69,136)', 'rgb(244,108,172)', 'rgb(243,139,164)', 'rgb(235,172,195)', 
                    'rgba(253,212,221,255)', 'rgb(195,227,181)', 'rgb(172,212,148)', 'rgb(125,197,96)']
const ColorMap2 = ['rgba(236,27,122,255)', 
                  'rgba(238,96,144,255)', 
                  'rgba(243,137,166,255)', 
                  // 'rgba(233,174,194,255)',
                  'rgba(253,211,222,255)', 
                  'rgba(223,240,216,255)', 
                  'rgb(195,227,181)', 
                  'rgb(163,213,148)', 
                  'rgba(144,200,121,255)', 
                  'rgba(112,192,86,255)']

const ColorMap12 = [
  'rgba(5,75,217,255)',
  'rgba(17,110,237,255)',
  'rgba(70,148,242,255)',
  'rgba(133,160,241,255)',
  'rgba(202,166,240,255)',
  'rgba(250,179,232,255)',
  'rgba(253,160,219,255)',
  'rgba(253,121,199,255)',
  'rgba(250,66,175,255)'
]

export const optionsArea = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'nearest',
    axis: 'x',
  },
  plugins: {
    filler: {
      propagate: false
    },
    'samples-filler-analyser': {
      target: 'chart-analyser'
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
          speed: 0.05
        },
        pinch: {
          enabled: true
        },
        mode: 'xy',
        overScaleMode: 'y',
      },
      pan: {
        enabled: true,
        overScaleMode: 'y',
      },
      limits: {
        x: {minRange: 5},
        y: {min: 0, max: 100},
        y1: {enabled: false}
      }
    },
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      mode: 'index',
      callbacks: {
        label: function(context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ' + parseFloat(context.parsed.y)
              if (label.startsWith('Price')) {
                label += ' $';
              }
              else {
                label += ' %';
              }
            }
            return label;
          }
      }
    }
  },
  scales: {
      x: {
          grid: {
              display: false
          },
          ticks: {
              callback: function(val) {
                let date = this.getLabelForValue(val).split("-")
                return `${months[parseInt(date[1])]+ " " + date[2]}`
              },
              font: {
                size: 12, 
                family: 'SFProLight',
              },
              color: '#9AA2AA',
              maxTicksLimit: 5,
              maxRotation: 0,
              minRotation: 0,
          },
          // type: 'time',
      },
      y: {
          position: 'left',
          grid: {
              display: false,
          },
          ticks: {
            beginAtZero: true,
          },
          type: 'linear',
          stacked: true,
          max: 30,
      },
      y1: {
        position: 'right',
        grid: {
            display: false,
        },
        ticks: {
          callback: function(val) {
            if (val/1000000 > 1) {
              return `${(val/1000000).toFixed(2)}mln$`
            } else if (val/1000 > 1) {
              return `${(val/1000).toFixed(2)}k$`
            }
            return `${val.toFixed(2)}$`
          },
          font: {
            size: 12,
            family: 'SFProLight',
          },
          color: '#9AA2AA',
        },
        // type: 'logarithmic',
    }
  },
};

export const dataArea = {
  labels: [],
  datasets: [
      {
        label: 'Price',
        type: 'line',
        data: [],
        fill: false,
        borderColor: 'rgb(1, 1, 1)',
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 1,
        backgroundColor: 'rgb(1, 1, 1)',
        yAxisID: 'y1',
        order: 1,
      },
      {
        label: '$0-100',
        type: 'line',
        fill: { target: {value: 0}},
        data: [],
        borderColor: ColorMap2[0],
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 1,
        backgroundColor: ColorMap2[0],
        yAxisID: 'y',
        order: 2,
      },
      {
        label: '$100-1k',
        type: 'line',
        fill: { target: {value: 0}},
        data: [],
        borderColor: ColorMap2[1],
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 1,
        backgroundColor: ColorMap2[1],
        yAxisID: 'y',
        order: 2,
      },
      {
        label: '$1k-10k',
        type: 'line',
        fill: { target: {value: 0}},
        data: [],
        borderColor: ColorMap2[2],
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 1,
        backgroundColor: ColorMap2[2],
        yAxisID: 'y',
        order: 2,
      },
      {
        label: '$10k-100k',
        type: 'line',
        fill: { target: {value: 0}},
        data: [],
        borderColor: ColorMap2[3],
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 1,
        backgroundColor: ColorMap2[3],
        yAxisID: 'y',
        order: 2,
      },
      {
        label: '$100k-1M',
        type: 'line',
        fill: { target: {value: 0}},
        data: [],
        borderColor: ColorMap2[4],
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 1,
        backgroundColor: ColorMap2[4],
        yAxisID: 'y',
        order: 2,
      },
      {
        label: '$1M-10M',
        type: 'line',
        fill: { target: {value: 0}},
        data: [],
        borderColor: ColorMap2[5],
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 1,
        backgroundColor: ColorMap2[5],
        yAxisID: 'y',
        order: 2,
      },
      {
        label: '$10M+',
        type: 'line',
        fill: { target: {value: 0}},
        data: [],
        borderColor: ColorMap2[6],
        tension: 0,
        pointRadius: 0.0,
        borderWidth: 1,
        backgroundColor: ColorMap2[6],
        yAxisID: 'y',
        order: 2,
      },
  ],
};
  
export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          // drag: {
          //   enabled: true,
          //   mode: 'x',
          // },
          mode: 'x',
          // overScaleMode: 'xy',
        },
        pan: {
          enabled: true,
          mode: 'xy',
          overScaleMode: 'xy',
        },
      },
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
        x: {
            type: 'time',
              adapters: {
                date: {
                    locale: en
                }
              },
            grid: {
                display: false
            },
            // ticks: {
            //     callback: function(val) {
            //       let date = this.getLabelForValue(val).split("-")
            //       return `${months[parseInt(date[1])]+ " " + date[2]}`
            //     },
            //     font: {
            //       size: 12, 
            //       family: 'SFProLight',
            //     },
            //     color: '#9AA2AA',
            //     maxTicksLimit: 5,
            //     maxRotation: 0,
            //     minRotation: 0,
            // }
        },
        y: {
            position: 'left',
            grid: {
                display: false,
            },
            type: 'logarithmic',
            ticks: {
              callback: function(val) {
                if (val/1000000 > 1) {
                  return `${(val/1000000).toFixed(2)}mln$`
                } else if (val/1000 > 1) {
                  return `${(val/1000).toFixed(2)}k$`
                }
                return `${val.toFixed(2)}$`
              },
              font: {
                size: 12,
                family: 'SFProLight',
              },
              color: '#9AA2AA',
            },
            // type: 'logarithmic',
        },
        y1: {
          position: 'right',
          grid: {
              display: false,
          },
          ticks: {
            callback: function(val) {
              if (val/1000000 > 1) {
                return `${(val/1000000).toFixed(2)}mln$`
              } else if (val/1000 > 1) {
                return `${(val/1000).toFixed(2)}k$`
              }
              return `${val.toFixed(2)}$`
            },
            font: {
              size: 12,
              family: 'SFProLight',
            },
            color: '#9AA2AA',
          },
          // type: 'logarithmic',
      }
    },
  };
  
const labels = ['2020-08-26', '2020-08-27', '2020-08-28', '2020-08-29', '2020-08-30', '2020-08-31'] 

export const data = {
    // labels,
    datasets: [
        {
          label: 'Price',
          data: [],
          fill: false,
          borderColor: 'rgb(128, 237, 153)',
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: 'rgb(128, 237, 153)',
          yAxisID: 'y1',
        },
        {
            label: 'Losses',
            data: [],
            fill: false,
            borderColor: 'rgb(243, 39, 136)',
            tension: 0,
            pointRadius: 0.0,
            borderWidth: 1,
            backgroundColor: 'rgb(243, 39, 136)',
            yAxisID: 'y',
          },
        {
          label: 'Profit',
          data: [],
          fill: false,
        //   borderColor: 'rgb(238,117,46)',
          borderColor: 'rgb(40,226,238)',
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
        //   backgroundColor: 'rgb(238,117,46)',
          backgroundColor: 'rgb(40,226,238)',
          yAxisID: 'y',
        },
    ],
};


export function Chart(props) {
    // console.log("result", props.indexName)
    // console.log("Type line chart", cryptoIndexes[props.indexName].typeChart)
    if(props.typeChart === "doughnut") {
      console.log("OH FUCK")
      console.log(transVolumeData.datasets)
      console.log(transVolumeOptions)
      return (
        <Doughnut ref={props.chartRef} data={transVolumeData}
        options={ transVolumeOptions }
        />
      )
    } else if (props.typeChart === "doughnut2"){
        return (
          <Doughnut ref={props.chartRef} data={movedCoinsData}
          options={movedCoinsOptions}
          />
        )
    } else {
    let index = cryptoIndexesV1[props.indexName]
    index.typeOptions['maintainAspectRatio'] = props.isMobile? false : true
    // console.log("PROPS", props.isMobile, index.typeOptions['maintainAspectRatio'])
    if (index.typeChart === "line") {
      return (
        <Line ref={props.chartRef} options={index.typeOptions} data={index.typeData} redraw={true} />
      )
    } else if (index.typeChart === "bar") {
      //console.log("Type line chart", index.typeChart)
      return (
        <Area ref={props.chartRef} options={index.typeOptions} data={index.typeData} redraw={true} />
      )
    } else if (index.typeChart === "area") {
      // console.log("Type stacked area chart", index.typeChart)
      return (
        <Area ref={props.chartRef} options={index.typeOptions} data={index.typeData} redraw={true}/>
      )
    }
    }
}
