import { DataGrid, 
    GridToolbar, 
    GridToolbarContainer,  
    GridOverlay,
    GridToolbarExport,
    useGridApiContext
    } from '@mui/x-data-grid';
import { StyledEngineProvider } from '@mui/material/styles';
import { parseBigValues, addCommas } from "./ChartSettings/utils.js";
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';

import { tokens } from "./data";

import { tableStyle, tableSearchStyle } from "./TableSettings/DefaultTable.js"

import LinearProgress from '@mui/material/LinearProgress';

import { styled } from '@mui/system';

import criteriaIcon from '../assets/icons/icon_criteria.svg'

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: '40px',
    backgroundColor: 'rgba(172, 209, 251, 0.3)',
    borderRadius: '8px',
    border: '1px solid rgb(172, 209, 251)',
    '& .MuiLinearProgress-bar': {
        backgroundColor: 'rgb(172, 209, 251)',
    }
}));

export function Table(props) {
    console.log('initial-', props.initialState)
    // console.log('column type2-', columns)

    return (
                <DataGrid
                    // components={{
                    //     LoadingOverlay: LinearProgress,
                    // }}
                    // ref={props.tableRef}
                    // loading={props.tableLoading}
                    // rows={props.tableData}
                    initialState={props.initialState}
                    loading={props.tableLoading}
                    rows={props.tableData}
                    columns={props.columnType}
                    pageSize={29}
                    rowHeight={35}
                    headerHeight={35}
                    rowsPerPageOptions={[5]}
                    showCellRightBorder={true}
                    disableSelectionOnClick
                    // checkboxSelection
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                      }
                    sx={tableStyle}
                    ref={props.tableRef}
                />
        // </div>
    )
}

function CustomLoadingOverlay(props) {
    return (
      <GridOverlay>
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ position: 'relative', width: '50%', marginBottom: '10px' }}>
                    <CustomLinearProgress 
                        variant="determinate" 
                        value={props.loadingPercent} 
                    />
                    <div style={{ 
                        position: 'absolute', 
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)',
                        fontFamily: 'SFProLight',
                        color: 'white'  // Choose a color that provides contrast
                    }}>
                        {`${props.loadingPercent.toFixed(0)}%`}
                    </div>
                </div>

                <div style={{
                    fontFamily: 'SFProLight',
                    fontSize: '14px'
                }}>Please wait 1-2 minutes.</div>
        </div>
      </GridOverlay>
    );
}
  

export function BigTable(props) {

    const [columnVisibility, setColumnVisibility] = useState({
        first_transfer_datetime: false,
        last_transfer_datetime: false,
        tx_number_received: false,
        tx_number_sent: false,
        profits: false, 
        losses: false,
        volume_received: false,
        volume_dollar_received: false, 
        volume_sent: false,
        volume_dollar_sent: false, 
        inflow_dollar_1d: false, 
        outflow_dollar_1d: false, 
        netflow_dollar_1d: false, 
        npl_1d: false,
        inflow_dollar_7d: false, 
        outflow_dollar_7d: false, 
        inflow_dollar_30d: false, 
        outflow_dollar_30d: false, 
        netflow_dollar_30d: false, 
        npl_30d: false,
        first_transfer_datetime: false,
        first_transfer_received_datetime: false,
        first_transfer_sent_datetime: false,
        last_transfer_received_datetime: false,
        last_transfer_sent_datetime: false,
        pers_transfers_with_sc: false,
        pers_transfers_with_cex: false,
        volume: false,
        // ... any other columns you want to hide by default
    });

    const handleColumnVisibilityChange = (newState) => {
        setColumnVisibility(newState);
    };

    return (
        <DataGrid
            key={props.gridKey}
            columnVisibilityModel={columnVisibility}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            // components={{
            //     LoadingOverlay: LinearProgress,
            // }}
            // ref={props.tableRef}
            // loading={props.tableLoading}
            // rows={props.tableData}
            columns={props.columnType}
            loading={props.tableLoading}
            rows={props.tableData}
            pageSize={10}
            rowHeight={35}
            headerHeight={35}
            rowsPerPageOptions={[10]}
            showCellRightBorder={true}
            // disableSelectionOnClick
            checkboxSelection
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
            sx={tableSearchStyle}
            ref={props.tableRef}
            components={{
                Toolbar: GridToolbar,
                LoadingOverlay: CustomLoadingOverlay,
            }}
            componentsProps={{
                loadingOverlay: {loadingPercent: props.loadingPercent } // Pass the state value to the custom component
            }}
            localeText={{
                noRowsLabel: 'No data available. Please submit a different request.'
            }}
        />
// </div>
)
}