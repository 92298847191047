import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSubscription } from './subscriptionContext';

const FilterContext = createContext();

export const useFilters = () => {
    return useContext(FilterContext);
};

export const FilterProvider = ({ children, supabaseClient }) => {
    const { subscriptionTier } = useSubscription();
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        // Fetch filters. Given RLS, only appropriate filters will be returned.
        fetchFilters();     

        if (subscriptionTier === 3) {
            const subscription = supabaseClient
                .channel('filter-db-changes')  // Using a custom name for clarity
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: 'filters',
                        filter: `user_id=eq.${supabaseClient.auth.user()?.id}`,
                    },
                    () => {
                        fetchFilters();
                    }
                )
                .subscribe();

            return () => {
                supabaseClient.removeChannel(subscription);
            };
        }
    }, [subscriptionTier]);

    const fetchFilters = async () => {
        const { data, error } = await supabaseClient.from('filters').select('*');
        console.log("OBAINED data filter sync", data, error)
        if (!error && data) setFilters(data);
        // Handle error if needed
    };

    return (
        <FilterContext.Provider value={{ filters: filters, canModify: subscriptionTier === 3 }}>
            {children}
        </FilterContext.Provider>
    );
};  