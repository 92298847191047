
import { InfoBlock } from "./Portfolio"
import { balanceLayout } from "./dataPortfolio";

import { useState, useRef, useEffect } from "react";

import { Line } from "react-chartjs-2";

import {options, data } from "./ChartSettings/NetWorth.js"

import { getWalletBalanceInfo } from "./loadData.js";

import { getBalanceHistory } from "./loadData.js";

import { tableStyle } from "./TableSettings/BalanceTable.js";

// import { columns } from "./TableSettings/BuySellTable";

import { columns, miniColumns } from "./TableSettings/BalanceTable.js";
import { parseValueForTooltip } from "./ChartSettings/utils.js";
import { DataGrid } from "@mui/x-data-grid";

import { styled } from '@mui/material/styles';

import { LoadingPlaceholder, ChartLoadingPlaceholder } from "./LoadingPlaceholder.js";

import { timeSince } from "./Overview.js";

import { calculatePercentChange } from "./ChartSettings/NetWorth.js";

import { parseBigValues } from "./ChartSettings/utils.js";


import "./PortfolioBalance.css";

const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

const color = {
    'negative': ['rgba(238, 40, 138, 0.15)', 'rgba(238, 40, 138, 0)', 'rgba(238, 40, 138, 1.0)', 'rgba(238, 40, 138, 0.5)'],
    'positive': ['rgba(172, 209, 251, 0.15)', 'rgba(172, 209, 251, 0.0)', 'rgba(172, 209, 251, 1.0)', 'rgba(172, 209, 251, 0.5)']
}

function ToolTipDescription(props) {
    if(props.text === '') {
        return
    }
    return (
        <div className="tooltip" style={{marginRight: 'auto', height: 'auto', width: '40px', display: 'flex', marginLeft: '4px', alignItems: 'center'}}>
                        <div style={{marginTop: '2px', marginLeft: '4px'}}>
                            <svg class="info-icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                <circle cx="12" cy="12" r="10" fill="none" stroke="rgb(123, 122, 149)" stroke-width="1.7"/>
                                <text x="12" y="16" text-anchor="middle" font-size="12" font-family="'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" fill="rgb(123, 122, 149)" font-weight="bold">i</text>
                                {/* <circle cx="8" cy="8" r="6.67" fill="none" stroke="#ffffff" stroke-width="1.5"/>
                                <text x="8" y="10.6666666667" text-anchor="middle" font-size="12" font-family="'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" fill="#ffffff" font-weight="bold">i</text> */}
                            </svg>
                        </div>
                        {(
                            <span className="tooltiptext-small" style={{maxWidth: '400px', padding: '14px', flexDirection: 'column', display: 'flex'}}>
                                {/* {console.log("DESCR", cryptoIndexes[indexNames[selectedIndex]].description)}
                                {console.log("DESCR", cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[0])} */}
                                {/* {<DescriptionBlock header={cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[0]} texts={cryptoIndexes[indexNames[selectedIndex]].description?.[0]?.[1]}/>} */}
                                {'ℹ️ ' + props.text}
                                {/* <button className="full-guide-button" onClick={handleFullGuideClick}>
                                    Full Guide<span className="arrow-down">↓</span>
                                </button> */}
                            </span>
                        )}
                        
    </div>)
}

const crosshairPlugin = (setCurrentPoint, getColorSign) => ({
    id: 'crosshairPlugin',
    afterDatasetsDraw: (chart) => {
    //   console.log('afer crossher')
    //   if (chart.id !== 'netWorthChart') return;  // Only apply to the specific chart
      const ctx = chart.ctx;
      const xAxis = chart.scales.x;
      const yAxis = chart.scales.y;
      const tooltip = chart.tooltip;
  
      if (tooltip._active && tooltip._active.length) {
        const activePoint = tooltip._active[0];
        const { x, y } = activePoint.element;

        const colorSign = getColorSign();

        // Draw vertical (y-axis) dotted line
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([5, 5]); // Dotted pattern: 10px line, 10px gap
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 1.5; // Thicker line
        ctx.strokeStyle = 'rgba(128, 128, 128, 0.5)'; // Grey color
        ctx.stroke();
        ctx.restore();

        // console.log('color try', color, colorSign)
        // Draw horizontal (x-axis) fine dotted line
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([2, 4]); // Fine dotted pattern: 2px line, 4px gap
        ctx.moveTo(xAxis.left, y);
        ctx.lineTo(xAxis.right, y);
        ctx.lineWidth = 0.75; // Thinner line
        ctx.strokeStyle = color[colorSign][3];
        // 'rgba(238, 40, 138, 0.5)'; // Lovely color
        ctx.stroke();
        ctx.restore();
  
        // // Draw vertical line
        // ctx.save();
        // ctx.beginPath();
        // ctx.moveTo(x, yAxis.top);
        // ctx.lineTo(x, yAxis.bottom);
        // ctx.lineWidth = 1;
        // ctx.strokeStyle = 'rgba(238, 40, 138, 0.5)';
        // ctx.stroke();
        // ctx.restore();
  
        // // Draw horizontal line
        // ctx.save();
        // ctx.beginPath();
        // ctx.moveTo(xAxis.left, y);
        // ctx.lineTo(xAxis.right, y);
        // ctx.lineWidth = 1;
        // ctx.strokeStyle = 'rgba(238, 40, 138, 0.5)';
        // ctx.stroke();
        // ctx.restore();

        // Draw circle at the center of the crosshair
        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, 5, 0, 2 * Math.PI); // Circle with radius 5
        ctx.fillStyle = color[colorSign][2] 
        // 'rgba(238, 40, 138, 1.0)'; // Red fill
        ctx.fill();
        ctx.lineWidth = 2; // Border width
        // ctx.strokeStyle = 'white'; // White border
        ctx.strokeStyle = 'rgb(17, 14, 28)';
        ctx.stroke();
        ctx.restore();

        // Update the current point state
        const datasetIndex = activePoint.datasetIndex;
        const dataIndex = activePoint.index;
        const dataset = chart.data.datasets[datasetIndex];
        const data = dataset.data[dataIndex];
        setCurrentPoint(data);
      }
    },
    beforeTooltipDraw: (chart) => {
    //   if (chart.id !== 'netWorthChart') return;  // Only apply to the specific chart
      const tooltip = chart.tooltip;
      if (tooltip._active && tooltip._active.length) {
        if (!tooltip.positioners) {
            tooltip.positioners = {}; // Initialize positioners if undefined
        }
        if (!tooltip.positioners.nearest) {
          tooltip.positioners.nearest = (elements, eventPosition) => {
            const element = elements[0];
            return {
              x: element.element.x,
              y: element.element.y,
            };
          };
        }
      }
    },
    afterEvent: (chart, args) => {
      const event = args.event;
      const tooltip = chart.tooltip;

      if (event.type === 'mouseout') {
        // Clear the crosshair by redrawing the chart without active points
        tooltip._active = [];
        chart.draw();

        setCurrentPoint(null);
      }

      if (tooltip._active && tooltip._active.length) {
        chart.draw();
      }
    //   console.log('before crosher2')
    }
});

const NetWorthLayout = ({ firstPoint, lastPoint }) => {
    if(firstPoint === null || lastPoint === null) {
        return
    }
    const changeValue = (lastPoint.y - firstPoint.y)
    // const percentChange = (changeValue / firstPoint.y * 100).toFixed(2);
    const percentChange = calculatePercentChange(changeValue, firstPoint.y)
    const percentClass = percentChange >= 0 ? 'positive' : 'negative';
    // console.log('percent - ', currentValue, firstValue)
    const prefix = percentChange >= 0 ? '+':''
    return (
        <div className={`networth-tooltip-percent ${percentClass}`} style={{fontSize: '20px', height:'24px'}}>
            {prefix}${percentChange}% (${parseValueForTooltip(Math.abs(changeValue))})
        </div>
    )
}

const calculatePercentageChange = (oldValue, newValue) => {
    if (oldValue === null || oldValue === undefined || oldValue === 0) return '--%';
    const prefix = (newValue - oldValue) >= 0 ? '+' : ''
    return `${prefix}${parseBigValues((((newValue - oldValue) / oldValue) * 100).toFixed(2))}%`;
  };
  
const calculateChanges = (data) => {
    const dataLength = data.length;
    const lastDataPoint = data[dataLength - 1];
    const currentBalance = lastDataPoint.balance_dollar;
  
    const oneMonthIndex = dataLength - 5;  // Approximately 4 weeks ago
    const sixMonthsIndex = dataLength - 25; // Approximately 24 weeks ago
    const oneYearIndex = dataLength - 49;  // Approximately 48 weeks ago
  
    const oneMonthBalance = oneMonthIndex >= 0 ? data[oneMonthIndex].balance_dollar : null;
    const sixMonthsBalance = sixMonthsIndex >= 0 ? data[sixMonthsIndex].balance_dollar : null;
    const oneYearBalance = oneYearIndex >= 0 ? data[oneYearIndex].balance_dollar : null;
  
    const oneMonthChange = calculatePercentageChange(oneMonthBalance, currentBalance);
    const sixMonthsChange = calculatePercentageChange(sixMonthsBalance, currentBalance);
    const oneYearChange = calculatePercentageChange(oneYearBalance, currentBalance);
  
    return {
      oneMonth: oneMonthChange,
      sixMonths: sixMonthsChange,
      oneYear: oneYearChange
    };
};

function ChartBalance(props) {
    const chartRef = useRef(null);

    const colorSignRef = useRef('positive');

    const [currentPoint, setCurrentPoint] = useState(null);
    const [lastPoint, setLastPoint] = useState(null)
    const [firstPoint, setFirstPoint] = useState(null)

    const [colorSign, setColorSign] = useState(null)

    const cleanChart = () => {
        const chart = chartRef.current;
        if (chart) {
          for (let i = 0; i < chart.data.datasets.length; i += 1) {
            chart.data.datasets[i].data = [];
          }
          chart.resetZoom();
          chart.update();
        }
    };

    const updateChart = (address) => {
        props.setLoading(true);
        console.log("PROPS SET TO TRUE")
        getBalanceHistory(address).then((values) => {
            if(values && values.length > 0) {
                const chart = chartRef.current

                props.setChanges(calculateChanges(values))
                console.log("values", values)
                chart.data.datasets[0].data = values
                const firstElement = values[0]
                const lastElement = values[values.length - 1]
                setFirstPoint(firstElement)
                setLastPoint(lastElement)

                setColorSign(lastElement.y - firstElement.y >= 0 ? 'positive' : 'negative')

                chart.update()
            }
            console.log("PROPS SET TO FALSE")
            props.setLoading(false)
          
        });
      };

    useEffect(() => {
        colorSignRef.current = colorSign;
    }, [colorSign]);

    useEffect(() => {
        console.log("SET NEW PROPS", props.loading)
    }, [props.loading])

    useEffect(() => {
        if(colorSign === null){
            return
        }
        const chart = chartRef.current
        const ctx = chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
        // console.log('color sign', colorSign)
        gradient.addColorStop(0, color[colorSign][0]);
        gradient.addColorStop(1, color[colorSign][1]);

        // console.log('gradient', gradient)

        chart.data.datasets[0].borderColor = color[colorSign][2]
        chart.data.datasets[0].backgroundColor = gradient;
        chart.update()
    }, [colorSign])

    useEffect(() => {
        if(currentPoint === null) {
            if(firstPoint !== null && lastPoint !== null) {
                setColorSign(lastPoint.y - firstPoint.y >= 0 ? 'positive' : 'negative')
            }
        } else {
            setColorSign(currentPoint.y - firstPoint.y >= 0 ? 'positive' : 'negative')
        }
    }, [currentPoint])
    
    useEffect(() => {
        cleanChart();
        updateChart(props.address);
    }, [props.address]);

    // useEffect(() => {
    //     if (chartRef.current) {
    //         console.log('visibility', props.loading)
    //         chartRef.current.style.visibility = props.loading === false ? 'visible' : 'hidden';
    //     }
    // }, [props.loading])

    const getColorSign = () => colorSignRef.current;

    return (
        <div className="walletOverview-chart" style={{borderRadius: '8px'}}>
            <div style={{ height:'auto', color: 'white' }}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                    <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Net Worth</p>
                    <ToolTipDescription text={'This chart represents history of wallet balance changes in dollar equivalent. Please note that at the moment only ERC20 coins in the Ethereum ecosystem are taken into account here.'} />
                </div>
                {/* <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Net Worth</p> */}
                <LoadingPlaceholder isLoading={props.loading} height="56px" width="300px">
                    <div class="networth-tooltip-text" style={{fontSize: '46px', height: '56px'}}>
                        {(lastPoint !== null) && `$${parseValueForTooltip(lastPoint.y)}`} 
                    </div>
                </LoadingPlaceholder>
                <LoadingPlaceholder isLoading={props.loading} height="24px" width="280px">
                    <NetWorthLayout firstPoint={firstPoint} lastPoint={lastPoint} />
                </LoadingPlaceholder>
            </div>
            <div className="walletOverview-chart-chart">
            {props.loading && (
        <ChartLoadingPlaceholder isLoading={props.loading} height="100%" />
      )}
                <Line id="netWorthChart" ref={chartRef} data={data} redraw={true} options={options} plugins={[crosshairPlugin(setCurrentPoint, getColorSign)]} />
            </div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: '32px'}}>
            <LoadingPlaceholder isLoading={props.loading} height="18px" width="120px">
                {(firstPoint !== null) && <div className="networth-tooltip-date" style={{color: 'rgb(123, 122, 149)'}}>{formatDate(firstPoint.x)}</div>}
            </LoadingPlaceholder>
            <LoadingPlaceholder isLoading={props.loading} height="18px" width="120px" marginLeft="auto">
                {(lastPoint !== null) && <div className="networth-tooltip-date" style={{color: 'rgb(123, 122, 149)', marginLeft: 'auto', textAlign: 'right'}}>{formatDate(lastPoint.x)}</div>}
            </LoadingPlaceholder>
        </div>
        </div>

    )
}

export function returnStyle(value) {
    if(value.slice(0, 2) == '--') {
         return ''
    }
    if(value[0] === '-') {
        return 'negative'
    }
    return 'positive'
}

function Information(props) {
    return (
        <div className="walletInformation">
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Information</p>
                <ToolTipDescription text={''} />
            </div>
            {/* <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Information</p> */}
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name">
                    Balance
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    {`$${parseValueForTooltip(props.stats.balance_dollar)}`}
                </div>}
            </div>
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name">
                    # Tokens
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    {`${props.stats.tokens_number}`}
                </div>}
                {/* <div className="walletInformation-raw-value">
                    8
                </div> */}
            </div>
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name">
                    # Transfers
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    {`${props.stats.num_transfers}`}
                </div>}
            </div>
            <div className="walletInformation-raw">
                <div className="walletInformation-raw-name">
                    Last Tx
                </div>
                {props.loading && <LoadingPlaceholder isLoading={props.loading} height="19px" width="100px" margin="0px" marginLeft="auto">
                    <div className="walletInformation-raw-value">
                    </div>
                </LoadingPlaceholder>}
                {!props.loading && <div className="walletInformation-raw-value">
                    {props.stats.last_transfer_timestamp === '' ? "No Txns":`${timeSince(new Date(props.stats.last_transfer_timestamp))} ago`}
                </div>}
            </div>
            {/* <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)', marginTop: '40px'}}>Net Worth Change</p> */}
            <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', marginTop: '24px'}}>
                <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Net Worth Change</p>
                <ToolTipDescription text={'Shows the wallet balance change in dollar equivalent for 1 month, 6 months and 1 year. Only ERC20 coins in the Ethereum ecosystem are taken into account here.'} />
            </div>
            <div className="walletInformation-raw" style={{gap: '8px'}}>
                <LoadingPlaceholder isLoading={props.loadingChart} height="80.5px" width="100px" margin="0px" flex="1 1">
                    <div className="walletInformation-raw-performace">
                        <div className={`walletInformation-raw-performace-header ${returnStyle(props.changes.oneMonth)}`}>
                            {props.changes.oneMonth}
                        </div>
                        <div className="walletInformation-raw-performace-text">
                            1M
                        </div>
                    </div>
                </LoadingPlaceholder>
                <LoadingPlaceholder isLoading={props.loadingChart} height="80.5px" width="100px" margin="0px" flex="1 1">
                    <div className="walletInformation-raw-performace">
                        <div className={`walletInformation-raw-performace-header ${returnStyle(props.changes.sixMonths)}`}>
                            {props.changes.sixMonths}
                        </div>
                        <div className="walletInformation-raw-performace-text">
                            6M
                        </div>
                    </div>
                </LoadingPlaceholder>
                <LoadingPlaceholder isLoading={props.loadingChart} height="80.5px" width="100px" margin="0px" flex="1 1">
                    <div className="walletInformation-raw-performace">
                        <div className={`walletInformation-raw-performace-header ${returnStyle(props.changes.oneYear)}`}>
                            {props.changes.oneYear}
                        </div>
                        <div className="walletInformation-raw-performace-text">
                            1Y
                        </div>
                    </div>
                </LoadingPlaceholder>
            </div>
            
        </div>
    )
}

function MenuInfo(props) {
    // const [hideMenu, setHide] = useState(false)
    // const [tokenInput, setTokenInput] = useState("")

    // const [toggleDropDown, setDropDown] = useState(false)

    // const openInNewTab = url => {
    //     window.open(url, '_blank', 'noopener,noreferrer');
    // };

    // function toggleMenu() {
    //     setDropDown(!toggleDropDown)
    // }


    return (
        <div className="walletOverview-overview" style={{flex: props.isMobile? '1 1': '', borderRadius: '8px'}}>
            <div style={{height: !props.hideMenu ? "100%": "100%", display: 'flex', flexDirection: 'column', gap: '12px', borderRadius: '8px'}}>
                {/* <div style={{width:'100%', backgroundColor: '#110E1C', flex: '1 1'}}></div>
                <div style={{width:'100%', backgroundColor: '#110E1C', flex: '1 1'}}></div> */}
                {/* <InfoBlock text={[["Balance", '100,000'], ["Balance ($)", '$270,000']]}/> */}
                {props.layout.map((layout) => (<InfoBlock header={layout.header} fields={layout.fields} stats={props.stats} />))}
                {/* <InfoBlock header={props.layout[0].header} fields={props.layout[0].fields} stats={props.stats} /> */}
                {/* <InfoBlock text={[["NPL", '$3M'], ["Average NPL", '$2.1M'], ["Profit in %", "1720%"]]}/>
                <InfoBlock text={[["Volume Sent", '7M'], ["Volume Sent ($)", '$1.2M'], ["Volume Recieved", "5M"], ["Volume Recieved ($)", "$2.3M"]]}/>
                <InfoBlock text={[["Average Tx Volume", "15M"], ["Average Tx Volume ($)", "$3.1M"], ["First Transaction", "5 days after deploy"]]} /> */}
                {/* <InfoBlock text={"Balance ($)"} value={"270,000$"}/> */}
            </div>
        </div>
    )   
}

const TransparentRow = styled('div')(({ theme }) => ({
    opacity: 0.5, // Adjust the opacity as needed
}));

export function PortfolioBalance(props) {
    const [stats, setStats] = useState({})
    const [pageSize, setPageSize] = useState(15);
    const [loadingData, setLoading] = useState(false)
    const [loadingChart, setLoadingChart] = useState(false);
    const [dataGridTable, setDataGridTable] = useState([])

    const [changes, setChanges] = useState({ oneMonth: '--%', sixMonths: '--%', oneYear: '--%' });

    const tableRef = useRef()
    const chartRef = useRef()


    const getRowClassName = (params) => {
        // Apply the TransparentRow class if logo is 0
        // console.log('get row class')
        if (params.row.logo === '') {
            // console.log('logo')
          return 'transparent-row';
        }
        return '';
    };

    useEffect(() => {
        setLoading(true)
        getWalletBalanceInfo(props.address).then((data) => {
            console.log("GET WALLETS:: start")
            if(data) {
                console.log("GET WALLETS:: inside condition", data)
                setStats(data)

                setDataGridTable(data.balances_by_tokens)
            }
            setLoading(false)
        })
    }, [])

    return (
        <div className="main-portfolio-section">
                    <div className="walletOverview-info" style={{borderBottom: 'solid 1 red'}}>

                        <Information loading={loadingData} loadingChart={loadingChart} stats={stats} changes={changes}/>
                        <ChartBalance address={props.address} setChanges={setChanges} loading={loadingChart} setLoading={setLoadingChart}/>
                    </div>
                    {/* {props.isPhone && <div className="walletOverview-info" style={{borderBottom: 'solid 1 red'}}> 

                        <Information loading={loadingData} loadingChart={loadingChart} stats={stats} changes={changes}/>
                    </div>}
                    {props.isMobile && <div className="walletOverview-info" style={{borderBottom: 'solid 1 red'}}>
                                    <ChartBalance address={props.address} setChanges={setChanges} loading={loadingChart} setLoading={setLoadingChart}/>
                    </div>} */}
                
                {props.address !== null && 
                
                <div className="walletBalances-info">
                <div className="walletBalances">
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontFamily: 'SFProMedium', color: 'rgb(123, 122, 149)'}}>Portfolio</p>
                        <ToolTipDescription text={"This table lists all the tokens currently held in the wallet."}/>
                    </div>
                    <DataGrid
    
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[15, 100]}
                            pagination
                            loading={loadingData}
                            rows={dataGridTable}
                            columns={props.isPhone ? miniColumns: columns}

                            rowHeight={60}
                            headerHeight={35}

                            showCellRightBorder={true}
                            disableSelectionOnClick

                            getRowClassName={getRowClassName}
                            sx={tableStyle}
                            ref={tableRef}
                            autoHeight={true}
                        />
                    </div>
                </div>
                }
        </div>
    )
}