import { React, useState, useEffect, useRef, useMemo} from "react";
import { Menu, MenuFilters } from "./Menu";
import { MenuLeaderBoard } from "./Menu";

import { isEqual } from 'lodash';

import { BigTable } from "./Table";
import { getLeaderboard } from "./loadData";

import { Auth } from "@supabase/auth-ui-react";
import { ButtonLogin, ButtonSignUp } from "./Navigation";

import hideButton from  "../assets/hideButton.svg"
import { initialState } from "./TableSettings/MacroTable";

import { shortNames, shortNames2Index, tableIndexes, indexNames, tables } from "./dataLeaderboard";

import { useNavigate, createSearchParams, useSearchParams, generatePath, useMatch, useLocation } from "react-router-dom";

import { tokens } from "./data";

import {DropdownMenuSingleSelect} from "./DropDownMenu";
import { DropDownTokens } from "./DropDownMenu";
import { DropDownMultiSelect } from "./DropDownMenu";
import { DropDownParameterFiltering } from "./DropDownMenu";

import { getUserParams, getFilterParams } from "./loadFilter";

import "./filter.css";
import { FilterName, FieldName, FieldInt, Field, IntegerInput, FloatInput, DateInput, SelectField, MultiSelectField } from "./Fields";

import deleteButton from "../assets/delete_button.png";
import { Modal } from "@mui/material";
import SavePopup from "./Modal";

import { getWallets } from "./loadFilter";
import { DropDownSelectOrder } from "./DropDownMenu";

import { columns } from "./TableSettings/FilterTable.js"

import { useFilters } from "./FilterProvider";
import { useSubscription } from "./subscriptionContext";

import { v4 as uuidv4, validate  } from 'uuid';

import { usePopup } from "./popupContext";

import { Tooltip } from "./Menu"

import { DropdownFilter } from "./DropDownMenu";

import { useParams } from 'react-router-dom';

import walletAd from "../assets/wallet_ad.png"
import filterImg from "../assets/filter.png"
import trashIcon from "../assets/icons/icon_trash.svg"


function getListFields(selectedFilters, fieldsByFilter) {
    return selectedFilters.flatMap(key => fieldsByFilter[key] || []);
}

function getAllUsingFields(selectedFilters, fieldsByFilter, params) {
    const fieldsList = getListFields(selectedFilters, fieldsByFilter)
    return Object.fromEntries(fieldsList.map(key => [key, params[key]]).filter(pair => pair[1] !== undefined));
}

function prepareDataJson(token, filterId, orderType, params, fieldsByFilter) {
    const filters = Array.from(params.selectedFilters)

    return {
        'token': token,
        'order_by': {
            'filter_id': filterId,
            'order_type': orderType,
        },
        'filters': {
            "selected_filters": filters,
            "fields": getAllUsingFields(filters, fieldsByFilter, params.fields)
        }
    }
}

function convertToJson(dict) {
    let resDict = {}
    resDict.selectedFilters = Array.from(dict.selectedFilters)
    resDict.fields = dict.fields
    return resDict
}

function convertToDict(dict) {
    dict.selectedFilters = new Set(dict.selectedFilters)

    Object.keys(dict.fields).forEach(key => {
        // console.log('key', key, dict[key], Array.isArray(dict[key]))
        if(Array.isArray(dict.fields[key])) {
            dict.fields[key] = new Set(dict.fields[key])
        }
    })
    return dict
}

function debug(params, filterConfig) {
    console.log("DEBUG PARAMS")
    console.log(params)
    console.log(filterConfig)
    return [params, filterConfig]
}


function Button(props) {

    const inHeaderStyle = {
        height: '36px',
        marginLeft: '0',
        marginRight: '40px',
    }

    return (
        <div className="button-class button-query" style={props.isHeader ? inHeaderStyle : (props.left ? {marginRight: 'auto', marginLeft: '0'} : {})} onClick={() => {console.log("SAVE try"); props.handleFunc(props.filter)}}>
            <p style={{padding: '0em 2em', margin: 0}}>{props.name}</p>
        </div>
    )
}

function ButtonQuery(props) {

    // const queryStyle = {
    //     marginLeft: '10px',
    //     backgroundColor: '#B3D0F7',
    //     color: '#050110',
    //     border: 'none',
    // }

    return (
        <div className="button-class button-query" onClick={() => {props.getData(); props.scroll();}}>
            <p style={{padding: '0em 2em', margin: 0}}>GET WALLETS</p>
        </div>
    )
}

const fieldByType = {
    'int': (props) => {return <IntegerInput {...props} /> },
    'float':(props) => {return <FloatInput {...props}/> },
    'date':(props) => {return <DateInput {...props}/> },
    'multi-select': (props) => {return <MultiSelectField {...props} /> },
    'select': (props) => {return <SelectField {...props} />},
}

function FilterQuery(props) { 
    return (
        <div className="filter-query">
            <FilterName onClick={props.isReadOnly ? props.showWarningIsReadOnly:()=>{}}name={props.name}/>
            {/* <FieldName name={'lol'} /> */}
            {props.config.subparams.map((field, index) => {
                // console.log("QUERY FILTER", field, props)
                // console.log("QUERY FILTER", field, props.params)
                return (<div style={{display: 'flex'}}>
                            <FieldName name={field.title_before} /> 
                            {props.isReadOnly && field.type !== 'multi-select' && field.type !== 'select' ? <FilterName onClick={props.showWarningIsReadOnly} name={props.params.fields[field.id]}/> : fieldByType[field.type]({
                                handleFieldChange: props.handleFieldChange, 
                                field: field, 
                                params: props.params,
                                isReadOnly: props.isReadOnly,
                                showWarningIsReadOnly: props.showWarningIsReadOnly,
                            })} 
                            {field.title_after !== null && <FieldName name={field.title_after} />
                            }
                        </div>)
            })}
            <div onClick={() => {if(!props.isReadOnly) {
                                    props.params.selectedFilters.delete(props.id); 
                                    props.handleDelete(props.params.selectedFilters)
                                }else{
                                    props.showWarningIsReadOnly()
                                }}} className={`criteria-icon with-hover`}>
            {/* props.handleDelete(props.params.selectedFilters.delete(props.id)) */}
                <img src={trashIcon} />
            </div>
        </div>
    )
}

export function convertTokensToList(tokens) {
    let listTokens = []
    for (const [key, value] of Object.entries(tokens)) {
        let newValue = value
        newValue['shortName'] = key
        listTokens.push(newValue)
    }

    return listTokens
}

const listTokens = convertTokensToList(tokens)

function mergeFilters(localFilters, supabaseFilters) {
    // console.log("Merge filters")
    // console.log("local filtters list", localFilters)
    // console.log("supabase filters", supabaseFilters)
    const mergedFilters = [...localFilters]; // start with a copy of local filters
    
    // A helper function to find a filter by its ID
    const findFilterById = (array, id) => array.find(filter => filter.id === id);
  
    // Loop through each Supabase filter
    supabaseFilters.forEach(supabaseFilter => {
      const correspondingLocalFilter = findFilterById(localFilters, supabaseFilter.id);
  
      // If this Supabase filter doesn't exist in `localStorage`, add it
      if (!correspondingLocalFilter) {
        mergedFilters.push({...supabaseFilter, isSaved: true});
      } else {
        // If the Supabase filter is more recent, replace the local version
        if (new Date(supabaseFilter.date) > new Date(correspondingLocalFilter.date)) {
          const index = mergedFilters.indexOf(correspondingLocalFilter);
          mergedFilters[index] = supabaseFilter;
          mergedFilters[index].isSaved = true 
        }
      }
    });
  
    return mergedFilters;
  }

const convertForStorage = (filter, criteriaFieldsMapping) => {
    const parameters = { ...filter.parameters };
    
    // Only save fields that belong to selected criteria
    const savedFields = {};
    parameters.selectedFilters.forEach(criteriaId => {
        criteriaFieldsMapping[criteriaId].forEach(fieldId => {
            if (parameters.fields[fieldId]) {
                savedFields[fieldId] = parameters.fields[fieldId] instanceof Set 
                    ? [...parameters.fields[fieldId]]
                    : parameters.fields[fieldId];
            }
        });
    });

    parameters.fields = savedFields;
    parameters.selectedFilters = [...parameters.selectedFilters];

    return { ...filter, parameters };
};

const parseFromStorage = (storedFilter, defaultParameters) => {
    const parameters = { ...storedFilter.parameters };
    
    parameters.selectedFilters = new Set(parameters.selectedFilters);

    for (const [fieldId, value] of Object.entries(parameters.fields)) {
        if (Array.isArray(value)) {
            parameters.fields[fieldId] = new Set(value);
        }
    }

    // Merge with defaultParameters to ensure missing fields are filled
    parameters.fields = {
        ...defaultParameters.fields, 
        ...parameters.fields
    };

    return { ...storedFilter, parameters };
};

function prepareDataToQueryFormat(activeFilter, criteriaFieldsMapping, packageLvl) {
    // console.log('before converting', activeFilter)
    const filter = convertForStorage(activeFilter, criteriaFieldsMapping)
    // console.log('after converting', filter)

    return {
        'token': filter.token,
        'order_by': filter.orderBy,
        'limit': (packageLvl > 0) ? '100':'25',
        'filters': {
            "selected_filters": filter.parameters.selectedFilters,
            "fields": filter.parameters.fields,
        }
    }
}

async function getFiltersFromSupabase(userId, supabaseClient) {
    // console.log("try to get list of filters from supabase")
    try {
        const {  data: userData, error: userError} = await supabaseClient
            .from('filters')
            .select('*')
            .eq('user_id', userId);
        // console.log('fetched data', data)
        if (userError) {
            console.error("Error fetching filters from Supabase:", userError);
            return { supabaseFilters: [], adminFilters: [] };
        }

        const adminUserId = '75e2d838-c6b2-46a2-bf5e-a6f1ba5b3c68'; 

        // Fetch public filters created by admin
        const { data: adminData, error: adminError } = await supabaseClient
            .from('filters')
            .select('*')
            .eq('user_id', adminUserId)
            .eq('is_public', true);

        if (adminError) {
            console.error("Error fetching admin public filters from Supabase:", adminError);
            // Even if admin filters fail to fetch, return user filters
            return { supabaseFilters: userData || [], adminFilters: [] };
        }

        return { supabaseFilters: userData || [], adminFilters: adminData || [] };
    } catch (err) {
        console.error("Error in getFiltersFromSupabase:", err);
        return { supabaseFilters: [], adminFilters: [] };
    }
}

const deleteFilterFromSupabase = async (filter, supabaseClient) => {
    const { id } = filter;
    try {
        const { data: filterData, error: filterError } = await supabaseClient
            .from('filters')
            .select('id')
            .eq('id', id)
             // Use .single() if you're expecting one or zero rows.
        console.log('DATA filters', filterData)
        if (filterError) throw filterError;

        if (filterData.length !== 0) {
            const { error: deleteError } = await supabaseClient
                .from('filters')
                .delete()
                .eq('id', id);

            if (deleteError) throw deleteError;

            // If successfully deleted, return success true
            return { success: true };
        } else {
            // If the filter does not exist on the server
            return { success: true, message: 'Filter deleted from local storage' };
        }
    } catch (error) {
        // Return success false and the error message
        return { success: false, message: error.message || 'Failed to delete filter' };
    }
};


const saveFilterToSupabase = async (filter, supabaseClient, user) => {
    console.log("function for saving");
    const { id, date, name, parameters, isSaved, token, orderBy } = filter; 
    console.log("id, date, name", id, date, name);

    // Check if the filter already exists in Supabase using the filter's id.
    const { data: existingFilter, error } = await supabaseClient
        .from('filters')
        .select('id')
        .eq('id', id);

    if (error) {
        console.error("Error fetching filter:", error.message);
        // Handle this error appropriately, maybe return an error message to the user or retry the operation.
        throw new Error('There is server error. Try later');
    }

    if (existingFilter && existingFilter.length === 0) {
        // Insert new filter
        const { data: insertResult, error: insertError } = await supabaseClient
            .from('filters')
            .insert({
                id: id,
                user_id: user.id,
                date: new Date(date).toISOString(),
                name: name,
                parameters: parameters, // Changed from 'parameters' to 'filter_data'
                is_public: true, // or whatever your default is
                token: token,
                orderBy: orderBy,
            });

        if (insertError) {
            console.error("Error inserting filter:", insertError.message);
            // Handle insert error
            throw new Error("You need a higher subscription tier");
        }

        console.log("Filter inserted successfully");
    } else if (existingFilter && existingFilter.length === 1) {
        // Update existing filter
        const { data: updateResult, error: updateError } = await supabaseClient
            .from('filters')
            .update({
                date: new Date(date).toISOString(),
                name: name,
                parameters: parameters, // Changed from 'parameters' to 'filter_data'
                // Add any other fields that might need updating.
                token: token,
                orderBy: orderBy,
            })
            .eq('id', id);

        if (updateError) {
            console.error("Error updating filter:", updateError.message);
            // Handle update error
            throw new Error("Your subscription tier expired. Please renew.");
        }

        console.log("Filter updated successfully");
    } else {
        // More than one filter with the same id, which is a data integrity issue.
        console.error("Multiple filters found with the same id. This should not happen.");
        // Handle this error appropriately. Maybe log this issue for further inspection or notify an admin.
        throw new Error("Multiple filters found with the same id. Please contact the developer.");
    }
};


export function FilterWrapper(props) {
    const { subscriptionTier, filterPackageLevel, subIsLoading } = useSubscription();
    const { filterId } = useParams();

    const navigate = useNavigate();

    if (subIsLoading) {
        console.log('loading sub', subIsLoading)
        return <div> Loading... </div>
    }

    if (subscriptionTier===0) {
        console.log("tier = 0", subscriptionTier, filterId)
        // if(filterId) {
        //     navigate(`/filter`)
        // }
        return <UnauthorizedScreen />
    }

    return <FilterLogic 
                supabaseClient={props.supabaseClient} 
                subscriptionTier={subscriptionTier}
                filterPackageLevel={filterPackageLevel}
                subIsLoading={subIsLoading}
                filterId={filterId}/>
}


export function FilterLogic({supabaseClient, subscriptionTier, filterPackageLevel, subIsLoading, filterId}) {
    // console.log('rerender FilterWrapper')
    // const { filterId } = useParams();
    const navigate = useNavigate();

    const { showToast, showConfirmation } = usePopup();
    // const { subscriptionTier, filterPackageLevel, subIsLoading } = useSubscription();

    const { user } = Auth.useUser();

    const [configData, setConfig] = useState(null)

    const [filters, setFilters] = useState([]);
    const [activeFilterId, setActiveFilterId] = useState(null);
    const [filtersKey, setFiltersKey] = useState(null);
    //  const [params, setParams] = useState(null)

    // New state for admin filters
    const [adminFilters, setAdminFilters] = useState([]);

    const [dataGridTable, setDataGridTable] = useState([])

    const getLocalStorageKey = () => {
        return subscriptionTier === 0 ? 'unauthorised_filters' : `${user?.id}_filters`;
    };

    const getActiveFilterIdStorageKey = () => {
        return subscriptionTier === 0 ? 'unauthorised_activeFilterId' : `${user?.id}_activeFilterId`;
    };

    useEffect(() => {
        if (filterId) {
            // Logic to handle the filterId
            if(validate(filterId) && [...filters, ...adminFilters].some(filter => filter.id === filterId)) {
                // console.log('filter try', filterId)
                setActiveFilterId(filterId)
            }
            // console.log('init path', filterId)
        }
    }, [filterId]);

    function createDefaultFilter() {
        return {
            id: uuidv4(), // Temporary ID
            parameters: configData.defaultParams, 
            is_public: false,
            name: 'Untitled',
            date: new Date(),
            token: 'ldo',
            orderBy: {
                filter_id: configData.criteriaForSorting[0].id,
                order_type: 'Desc',
            },
            isSaved: false,
            isReadOnly: false,
        };
    }

    useEffect(() => {
        async function fetchFilterParams() {
            const [config, criteriaForSorting, criteriaList, defaultParams, criteriaFieldsMapping] = await getFilterParams();
            console.log('Updating configData:', config);
            setConfig({ config, criteriaForSorting, criteriaList, defaultParams, criteriaFieldsMapping });
        }
        
        fetchFilterParams();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if(!configData) {
                return
            }

            // console.log("tier1", subscriptionTier, user)
            const localFiltersKey = getLocalStorageKey();
            setFiltersKey(localFiltersKey); 

            const localFiltersData = localStorage.getItem(localFiltersKey);
            const localFilters = localFiltersData ? JSON.parse(localFiltersData) : [];
            // console.log("tier", subscriptionTier)
            // console.log("local storage", localFilters, localFiltersKey)
            
            const activeFilterIdKey = getActiveFilterIdStorageKey();
            const storedActiveFilterId = localStorage.getItem(activeFilterIdKey);

            let supabaseFilters = []
            let adminFilters = []

            if (subscriptionTier > 0 && user.id ) {
                let fetchedFilters = await getFiltersFromSupabase(user.id, supabaseClient);
                supabaseFilters = fetchedFilters.supabaseFilters
                adminFilters = fetchedFilters.adminFilters
            }

            const resultingFilters = mergeFilters(localFilters, supabaseFilters)
            // console.log('active filter id', storedActiveFilterId)

            if (resultingFilters.length === 0) {
                // No filters exist, so initialize with a default filter
                // or leave as an empty array depending on your needs.
                // This is just an example to create a default filter:
                resultingFilters.push(createDefaultFilter());
            }

            console.log('Updating filters:', 1);
            setFilters(resultingFilters.map(filter => ({
                ...parseFromStorage(filter, configData.defaultParams),
                isReadOnly: false
            })));
            
            setAdminFilters(adminFilters.map(filter => ({
                ...parseFromStorage(filter, configData.defaultParams),
                isReadOnly: true,
                isSaved: true,
            })));
            
            if(activeFilterId === null) {
                if (storedActiveFilterId  && validate(storedActiveFilterId) && [...filters, ...adminFilters].some(filter => filter.id === storedActiveFilterId)) {
                    setActiveFilterId(storedActiveFilterId);
                } else {
                    setActiveFilterId(resultingFilters[0].id)
                    // handle the case where there's no activeFilterId stored, maybe default to the first filter's ID or another logic you might prefer
                }
            }
        }
        console.log('fecth cause smth change', subscriptionTier, user)
        fetchData();
    }, [subscriptionTier, user, configData]);
    // }, [ user ]);

    function checkValid() {
        if(filterPackageLevel === 0) {
            if(filters.length >= 2) {
                showConfirmation(
                    'Limit Reached', 
                    [`You've reached your filter creation limit of two. To add more, please delete an existing filter or consider upgrading your access for higher limits.`, `To upgrade your limits:`, `1. Join our Discord and navigate to the #smart-money channel.`, `2. Post "want more limits cherry!" in the chat.`, `3. Receive your limit increase!`, `This is so easy.`],
                    [
                      { label: 'Stay as is 😞', onClick: () => {} },
                      { label: 'GO TO DISCORD 👾', style: {backgroundColor: '#bed9f7', border: '2px solid #bed9f7', color: 'black', fontFamily: 'SFProBold', fontSize: '16px'}, onClick: () => {window.open('https://discord.gg/27yKY5jbRE')} } // No-op for "No" button
                    ]
                 );
                return false
            } else {
                return true
            }
        } else if(filterPackageLevel === 1) {
            if(filters.length >= 7) {
                showConfirmation(
                    'Limit Reached', 
                    [`You've reached your filter creation limit of seven. To add more, please delete an existing filter.`],
                    [
                      { label: 'Okay 👍', onClick: () => {} },
                    ]
                 );
                return false
            } else {
                return true
            }
        } else if(filterPackageLevel === 2) {
            if(filters.length >= 20) {
                showConfirmation(
                    'Limit Destroyed', 
                    [`Stop it. You're on the verge of a breakthrough. Talk to a CEO to push your limits.`],
                    [
                      { label: 'Okay 👍', onClick: () => {} },
                    ]
                 );
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    function checkAbilityToSave() {
        if(filterPackageLevel === 0) {
            showConfirmation(
                'Save Restricted', 
                [`Your current subscription tier does not support saving filters. To enable this feature, please consider upgrading.`, `To unlock savings:`, `1. Join our Discord and navigate to the #smart-money channel.`, `2. Post "want more limits cherry!" in the chat.`, `3. Receive your limit increase!`, `This is so easy.`],
                [
                  { label: 'Stay as is 😞', onClick: () => {} },
                  { label: 'GO TO DISCORD 👾', style: {backgroundColor: '#bed9f7', border: '2px solid #bed9f7', color: 'black', fontFamily: 'SFProBold', fontSize: '16px'}, onClick: () => {window.open('https://discord.gg/27yKY5jbRE')} } // No-op for "No" button
                ]
             );
             return false
        } else {
            return true
        }
    }

    function createFilter() {
        if(checkValid()) {
            const newFilter = createDefaultFilter();

            console.log('Updating filters create')
            setFilters(prevFilters => [...prevFilters, newFilter]);

            // Optionally, set the new filter as the active one:
            setActiveFilterId(newFilter.id);
        }
    }

    const handleSaveFilter = async (filter) => {
        if (checkAbilityToSave()) {
            try {
                await saveFilterToSupabase(convertForStorage(filter, configData.criteriaFieldsMapping), supabaseClient, user);
                // Optionally: Update the filter's isSaved flag to true if needed
                handleSavedChange(true)
                showToast("Filter saved successfully!", "success");
            } catch (error) {
                console.error("Failed to save filter:", error);
                showToast(`Failed to save filter:${error.message}`, "error")
            }
        }
    };
    
    const handleCopyAdminFilter = (adminFilter) => {
        if(checkValid()) {
            const newFilter = {
                ...adminFilter,
                id: uuidv4(), // Generate a new unique ID
                name: adminFilter.name + " (Copy)", // Add " (Copy)" to the name
                isReadOnly: false, // The copied filter should be editable
                isSaved: false, // Since it's a new filter
                is_public: false,
            };
        
            // Add the new filter to the user's filters
            console.log('Updating filters copy')
            setFilters((prevFilters) => [...prevFilters, newFilter]);
        
            // Optionally, set the new filter as the active one
            setActiveFilterId(newFilter.id);
        }
    };

    const handleDeleteFilter = async (filter) => {
        try {
            const result = await deleteFilterFromSupabase(filter, supabaseClient);
            console.log("result", result)
    
            if (result.success) {
                console.log('Updating filters delete')
                setFilters(currentFilters => {
                    const updatedFilters = currentFilters.filter(f => f.id !== filter.id);

                    // Set the active filter ID after updating the filters array
                    // If no filters are left, create a default one
                    if (updatedFilters.length === 0) {
                        // console.log('defaultFilter')
                        const defaultFilter = createDefaultFilter();
                        // console.log('defaultFilter', defaultFilter)
                        updatedFilters.push(defaultFilter);
                        // console.log('updatedFilters', updatedFilters)
                        setActiveFilterId(defaultFilter.id);
                    } else if (activeFilterId === filter.id) {
                        // If the deleted filter was the active one, set a new active filter
                        setActiveFilterId(updatedFilters[0].id);
                    }
                    
                    return updatedFilters;
                })

                showToast('Filter deleted successfully', 'success');
            } else {
                // Show error toast with the returned message
                showToast(result.message, 'error');
            }
        } catch (error) {
            console.error("Failed to delete filter:", error);
            showToast('An unexpected error occurred', 'error');
        }
    };

    const confirmDelete = (filter) => {
        showConfirmation(
          'Are you sure?', 
          ['Are you sure you want to delete? You will not be able to restore this filter.'],
          [
            { label: 'Yes', onClick: () => handleDeleteFilter(filter) },
            { label: 'No', onClick: () => {} } // No-op for "No" button
          ]
        );
      };
    
    // Ensure `showToast` is defined in your component or passed as a prop,
    // and is a function that displays toast notifications.
    

    function handleSavedChange(newVar)  {
        setFilters(prevFilters => {
            const updatedFilters = prevFilters.map(filter => {
                if (filter.id === activeFilterId) {
                    return { ...filter, isSaved: newVar, is_public: true};
                }
                return filter;
            });
            // Persist to localStorage and possibly to the server here
            return updatedFilters;
        });
    }

    const handleFilterFieldValueChange = (field, newValue) => {
        setFilters(prevFilters => {
            const updatedFilters = [...prevFilters];
            const activeFilter = updatedFilters.find(filter => filter.id === activeFilterId);
            if (activeFilter) {
                activeFilter.parameters.fields[field] = newValue;
                activeFilter.isSaved = false
            }
            return updatedFilters;
        });
    };

    const handleSelectedFiltersChange = (newValue) => {
        setFilters(prevFilters => {
            const updatedFilters = [...prevFilters];
            const activeFilter = updatedFilters.find(filter => filter.id === activeFilterId);
            if (activeFilter) {
                activeFilter.parameters.selectedFilters = newValue;
                activeFilter.isSaved = false
            }
            return updatedFilters;
        });
    };

    function handleSelectedTokenChange(newToken) {
        setFilters(prevFilters => {
            const updatedFilters = prevFilters.map(filter => {
                if (filter.id === activeFilterId) {
                    return { ...filter, token: newToken, isSaved: false };
                }
                return filter;
            });
            // Persist to localStorage and possibly to the server here
            return updatedFilters;
        });
    }

    function handleNameChange(newName) {
        setFilters(prevFilters => {
            const updatedFilters = prevFilters.map(filter => {
                if (filter.id === activeFilterId) {
                    return { ...filter, name: newName, isSaved: false };
                }
                return filter;
            });
            // Persist to localStorage and possibly to the server here
            return updatedFilters;
        });
    }

    // Function to update the orderBy filter_id
    function handleOrderByFilterIdChange(newFilterId) {
        setFilters(prevFilters => {
            const updatedFilters = prevFilters.map(filter => {
                if (filter.id === activeFilterId) {
                    return {
                        ...filter,
                        orderBy: {
                            ...filter.orderBy,
                            filter_id: newFilterId,
                        },
                        isSaved: false,
                    };
                }
                return filter;
            });
            // Persist to localStorage and possibly to the server here
            return updatedFilters;
        });
    }

    // Function to update the orderBy order_type
    function handleOrderByTypeChange(newOrderType) {
        setFilters(prevFilters => {
            const updatedFilters = prevFilters.map(filter => {
                if (filter.id === activeFilterId) {
                    return {
                        ...filter,
                        orderBy: {
                            ...filter.orderBy,
                            order_type: newOrderType,
                        },
                        isSaved: false,
                    };
                }
                return filter;
            });
            // Persist to localStorage and possibly to the server here
            return updatedFilters;
        });
    }



    const activeFilter = filters.find(filter => filter.id === activeFilterId) ||
                            adminFilters.find(filter => filter.id === activeFilterId)

    useEffect(() => {
        if (filtersKey && filters.length > 0) {
            // const filtersKey = getLocalStorageKey();
            localStorage.setItem(filtersKey, JSON.stringify(filters.map(filter => convertForStorage(filter, configData.criteriaFieldsMapping))));
        }
        // console.log("GET filters", JSON.parse(localStorage.getItem('filters')))
    }, [filters]);

    useEffect(() => {
        if (activeFilterId !== null) {
            setDataGridTable([])
            navigate(`/filter/${activeFilterId}`)
            const activeFilterIdKey = getActiveFilterIdStorageKey();
            // console.log('store active', activeFilterId, activeFilterIdKey)
            localStorage.setItem(activeFilterIdKey, activeFilterId);
        }
    }, [activeFilterId]);

    if (!configData || !activeFilter) {
        console.log('load configData or active filter problem', configData)
        console.log('active filter:', activeFilter)
        return <div> Loading... </div>
    }

    return (
        <main className="main" style={{ minWidth: '1200px', overflowX: 'auto' }}>
            <MenuFilters
                filtersByGroup={{
                    "Template Filters": adminFilters,
                    "Custom Filters": filters
                }}
                activeFilterId={activeFilterId}
                onSelectFilter={(id) => {console.log('ID new', id); setActiveFilterId(id);}}
                createFilter={createFilter}
            />
            <Filter3 
            config={configData.config} 
            criteriaForSorting={configData.criteriaForSorting}
            criteriaList={configData.criteriaList}
            filter={activeFilter}
            fieldsByCriteriaId={configData.fieldsByCriteriaId}

            handleFilterFieldValueChange={handleFilterFieldValueChange}
            handleSelectedFiltersChange={handleSelectedFiltersChange}

            handleSaveFilter={handleSaveFilter}
            handleDeleteFilter={confirmDelete}

            handleCopyAdminFilter={handleCopyAdminFilter}

            handleSelectedTokenChange={handleSelectedTokenChange}
            handleOrderByFilterIdChange={handleOrderByFilterIdChange}
            handleOrderByTypeChange={handleOrderByTypeChange}
            
            fieldsMapping={configData.criteriaFieldsMapping}
            handleNameChange={handleNameChange}

            showToast={showToast}

            dataGridTable={dataGridTable}
            setDataGridTable={setDataGridTable}

            filterPackageLevel={filterPackageLevel}
            />
        </main>

    )
}

const EditIcon = ({ onClick }) => (
    <span style={{ cursor: 'pointer' }} onClick={onClick}>
      ✎
    </span>
  );

const HeaderComponent = ({filter, onNameChange, isReadOnly=false, handleCopy, handleDelete, handleSave}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState(filter.name);

    useEffect(() => {
        setEditName(filter.name);
    }, [filter.name]);

    const handleEditClick = () => {
        setIsEditing(true);
      };
    
      const handleNameChange = (event) => {
        setEditName(event.target.value);
      };
    
      const handleBlur = () => {
        if (editName.trim() !== ''  && editName !== filter.name ) {
          onNameChange(editName);
        }
        setIsEditing(false);
      };
    
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.target.blur();
        }
      };

      return (
        <div className="query-field" style={{minHeight: '64px', maxHeight: '64px', padding: '0', alignItems: 'center'}}>
          {isEditing ? (
            <input
              type="text"
              value={editName}
              onChange={handleNameChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              autoFocus
              className="edit-name-input" // Add your styling class here
            />
          ) : (
            <h3>
              {filter.name} 
              <span>{"    "}</span>
              {!isReadOnly && <EditIcon onClick={handleEditClick} />}
            </h3>
          )}
          <div style={{marginLeft: '10px', marginRight: 'none', display: 'flex', flexDirection: 'row', gap: '10px'}}>
            <div className="tooltip">
                <p className="filter-tag">
                    {isReadOnly ? '📖 read-only' : (filter.isSaved ? '🏡 saved' : '⚠️ unsaved')}
                </p>
                {!filter.isSaved && (
                    <span className="tooltiptext">
                        ⚠️ This filter has unsaved changes.<br /> It's currently stored in your browser's memory and could be lost at any time.<br />  To save the filter and make it public, click on the "SAVE" button.
                    </span>
                )}
            </div>
            <p className="filter-tag">{filter.is_public ? '👥 public':'👤 private'}</p>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', marginRight: '40px', marginLeft: 'auto', height: '40px'}}>
            {!filter.isReadOnly && <DropdownFilter filter={filter} handleCopy={handleCopy} handleDelete={handleDelete}/>}
            <Button
                filter={filter}
                name={isReadOnly ? "COPY":"SAVE"}
                // isHeader={true}
                // setModalIsOpen={toggleModalFunction}
                handleFunc={isReadOnly ? handleCopy:handleSave}/>
          </div>
        </div>
      );
};


export function Filter3(
        {
            config,
            criteriaForSorting,
            criteriaList,
            filter,
            fieldsByCriteriaId,
            handleFilterFieldValueChange: prophandleFilterFieldValueChange,
            handleSelectedFiltersChange: prophandleSelectedFiltersChange,
            handleSaveFilter: prophandleSaveFilter,
            handleDeleteFilter: prophandleDeleteFilter,
            handleSelectedTokenChange: prophandleSelectedTokenChange,
            handleOrderByFilterIdChange: prophandleOrderByFilterIdChange,
            handleOrderByTypeChange: prophandleOrderByTypeChange,
            handleCopyAdminFilter: prophandleCopyAdminFilter,
            fieldsMapping,
            handleNameChange: prophandleNameChange,
            showToast,
            dataGridTable,
            setDataGridTable,
            filterPackageLevel,
        }
    ) {
    const [loadingData, setLoading] = useState(false)
    const [loadingPercent, setLoadingPercent] = useState(0);
    const [gridKey, setGridKey] = useState(Date.now());
    const [currentColumns, setCurrentColumns] = useState(columns);

    // console.log("COLUMNS", currentColumns, columns)

    const tableComponent = useRef(null);

    const tableRef = useRef()

    const [triggerClick, setTriggerClick] = useState(false);

    const handleCriteriaButtonClick = () => {
        // Update the state to trigger the action in the child component
        setTriggerClick(prevState => !prevState);
    };

    const handleButtonClick = () => {
        console.log("SCROLL button click")
        tableComponent.current.scrollIntoView({ behavior: 'smooth' });
    };

    const withLoadingCheck = (handlerFunction) => {
        return (...args) => {
          if (loadingData) {
            showToast('Please wait until the current process finishes.', 'warning');
            return;
          }
          handlerFunction(...args);
        };
    };
    const handleFilterFieldValueChange = withLoadingCheck(prophandleFilterFieldValueChange)
    const handleSelectedFiltersChange = withLoadingCheck(prophandleSelectedFiltersChange)
    const handleSaveFilter = withLoadingCheck(prophandleSaveFilter)
    const handleDeleteFilter = withLoadingCheck(prophandleDeleteFilter)
    const handleSelectedTokenChange = withLoadingCheck(prophandleSelectedTokenChange)
    const handleOrderByFilterIdChange = withLoadingCheck(prophandleOrderByFilterIdChange)
    const handleOrderByTypeChange = withLoadingCheck(prophandleOrderByTypeChange)
    const handleNameChange = withLoadingCheck(prophandleNameChange)
    const handleCopyAdminFilter = withLoadingCheck(prophandleCopyAdminFilter)

    useEffect(() => {
        let interval;
    
        if (loadingData) {
            interval = setInterval(() => {
                setLoadingPercent(prevPercent => {
                    if (prevPercent >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return prevPercent + 100 / 120;
                });
            }, 1000);
        } else {
            clearInterval(interval);
            setLoadingPercent(0);
        }
    
        // Cleanup on unmount or if loadingData changes
        return () => clearInterval(interval);
    }, [loadingData]);
    
    useEffect(() => {
        if (loadingPercent >= 100) {
            setLoading(false);
            setLoadingPercent(0);
        }
    }, [loadingPercent]);

    async function getData() {
        console.log("START request")
        setLoading(true)
        try {
            const jsonData = prepareDataToQueryFormat(filter, fieldsMapping, filterPackageLevel)
            console.log("JSON DATA", jsonData)
            const response = await getWallets(JSON.stringify(jsonData), filter.token)
            console.log("response", response)
            setGridKey(Date.now());

            setDataGridTable(response)
        } catch( error) {
            console.log("error")
        } finally {
            setLoading(false)
        }
        // console.log("GET DATA WALLETS", response)
    }

    function showWarningIsReadOnly() {
        showToast(`📖 read-only filters are not editable. To customize a filter, please click the 'Copy' button located in the top right corner.`, 'warning');
    }
    
    return (
        <div className="main-canva" style={{overflow: 'auto', height: 'calc(100vh - 62px)'}}>
            {/* <div className="query-field">
                <h3>{filter.name}</h3>
            </div> */}
            <HeaderComponent 
                    filter={filter} 
                    onNameChange={handleNameChange} 
                    isReadOnly={filter.isReadOnly}
                    handleCopy={handleCopyAdminFilter}
                    handleDelete={handleDeleteFilter}
                    handleSave={handleSaveFilter}
                    />
            <div className="query-field">
                <div className="query-left">
                    <div className="query-element">
                        <h1>TOKEN</h1>
                        <h3>Choose the ERC20 token, which you are interested in</h3>
                        {/* <DropdownMenuSingleSelect items={listTokens} size={5} /> */}
                        <DropDownTokens showToast={showToast} isReadOnly={filter.isReadOnly} items={listTokens} size={5} selectedToken={filter.token} setNewToken={handleSelectedTokenChange}/>
                    </div>
                    {/* <div className="query-element">
                        <h1>CRITERIA</h1>
                        <h3>Choose criteria, which you are interested in</h3>
                        <DropDownParameterFiltering isReadOnly={filter.isReadOnly} items={criteriaList} params={filter.parameters.selectedFilters} handleItemChange={handleSelectedFiltersChange} size={3} />
                    </div> */}
                    <div className="query-element">
                        <h1>ORDER BY</h1>
                        <h3>The addresses will be lined up in ascending or descending order of the parameter you select below.</h3>
                        <div className="order-element">
                            <div className="order-left">
                                <DropDownSelectOrder isReadOnly={filter.isReadOnly} index={filter.orderBy.filter_id} setNewIndex={handleOrderByFilterIdChange} items={criteriaForSorting} size={5} />
                            </div>
                            <div className="order-right">
                                <DropdownMenuSingleSelect isReadOnly={filter.isReadOnly} getItemFunc={(items, el) => {return items[0].name === el ? items[0] : items[1]}} el={filter.orderBy.order_type} setEl={(item) => {handleOrderByTypeChange(item.name)}} items={[{'name': 'Asc'}, {'name': 'Desc'}]} size={5}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="query-right">
                    <div className="query-element">
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div className="query-element">
                                <h1>FILTER</h1>
                                <h3>Addresses meeting the following criteria <br></br>will be returned.</h3>
                            </div>
                            {!filter.isReadOnly && <div style={{marginTop: 'auto', marginBottom: '1em', marginLeft: 'auto'}}>
                                <DropDownParameterFiltering isReadOnly={filter.isReadOnly} triggerClick={triggerClick} items={criteriaList} params={filter.parameters.selectedFilters} handleItemChange={handleSelectedFiltersChange} handleCriteriaButtonClick={handleCriteriaButtonClick} size={3} />
                            </div>}
                        </div>
                        {/* <div style={{marginBottom: '10px'}}>
                            <DropDownParameterFiltering items={criteriaList} params={filter.parameters.selectedFilters} handleItemChange={handleSelectedFiltersChange} size={3} width={250}/>
                        </div> */}

                        <div className="query-element-filter" style={{paddingBottom: '10px', border: filter.isReadOnly ? 'none':''}}> 
                            {criteriaList.map((criteria, index) => (
                                filter.parameters.selectedFilters.has(criteria.id) 
                                    && 
                                (filter.parameters.length !==0) 
                                    && 
                                <FilterQuery 
                                    isReadOnly={filter.isReadOnly} 
                                    name={criteria.name} 
                                    config={config[index]} 
                                    params={filter.parameters}
                                    id={criteria.id}
                                    handleFieldChange={handleFilterFieldValueChange}
                                    handleDelete={handleSelectedFiltersChange}
                                    showWarningIsReadOnly={showWarningIsReadOnly}/>
                            ))}
                            <div className="filter-button" style={{width: '45px', height: '45px', marginLeft:10}} onClick={filter.isReadOnly ? showWarningIsReadOnly:handleCriteriaButtonClick}>
                                <div  className="plus-sign" style={{fontSize: '30px'}}>
                                    <div className="horizontal-bar"></div>
                                    <div className="vertical-bar"></div>
                                </div>
                            </div>
                            {/* {modalIsOpen && <SavePopup modalIsOpen={modalIsOpen} toggleModalFunction={toggleModalFunction} subscriptionTier={subscriptionTier} updateFilterName={updateFilterName} supabaseClient={props.supabaseClient} params={params} user={user} filterId={selectedFilter}/>} */}
                        </div>                    
                    </div>
                </div>
            </div>
            <div className="query-field" style={{alignItems: 'center'}}>
                <div className="query-element" ref={tableComponent}>
                            <h2 className="query-header">Wallets</h2>
                            <h3>Obtained wallets meeting the filter criteties above.</h3>
                            <h3 style={{margin:0}}>🚀 Do you want more than 25 wallets to be returned? Ask in our <a href={'https://discord.gg/83GV9zTt2r'} style={{color:'white'}}>Discord</a></h3>
                </div>
                <div style={{height: '40px', display: 'flex', marginLeft: 'auto', marginRight: '0px'}}>
                    <ButtonQuery getData={getData} scroll={handleButtonClick}/>
                </div>
            </div>
            <div className="query-field" style={{padding: 0}}>
                <div className="chart2">
                        <div className="tableContainer" style={{overflow: 'none', height: '480px'}}>
                            <div style={{width: '100%', height: '100%'}}>
                                <BigTable 
                                    // initialState={tableIndexes[indexNames[selectedIndex]].initialState} 
                                    gridKey={gridKey}
                                    tableData={dataGridTable} 
                                    tableLoading={loadingData}
                                    columnType={currentColumns}
                                    tableRef={tableRef}
                                    loadingPercent={loadingPercent}
                                    setCurrentColumns={setCurrentColumns}
                                /> 
                            </div>
                        </div>
                    </div>
            </div>
            <div className="query-field">
                <div className="query-element" style={{marginBottom: '20px', marginTop: '10px'}}>
                        <h3 style={{margin:0}}>🤯 Still not sure how to use it?</h3>
                        <h3 style={{margin:0, marginTop:'5px'}}>📚 Read our <a href={'https://docs.cherry-pick.xyz/wallet-search-tool/product-guide'} style={{color:'white'}}>Tutorials</a></h3>
                        <h3 style={{marginTop:'5px'}}>🎤 Join our <a href={'https://discord.gg/83GV9zTt2r'} style={{color:'white'}}>Discord</a>, we hold an educational broadcast once a week.</h3>
                </div>
            </div>
        </div>
    )
}

function UnauthorizedScreen(props) {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const navigate = useNavigate();

    const handleMouseMove = (e) => {
        setMousePosition({ x: e.clientX, y: e.clientY });
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const calcImageTransform = (speed) => {
        const xRotation = (mousePosition.y - window.innerHeight / 2) * speed;
        const yRotation = (mousePosition.x - window.innerWidth / 2) * speed;
        return `rotateX(${xRotation}deg) rotateY(${yRotation}deg)`;
    };

    return (
        <div className="unauthorized-screen">
            <div className="text-section">
                <h1 className="gradientColorBlue">Filter & Find <br/>ETH Address </h1>
                <p>A unique free solution for finding smart money and fund addresses in the Ethereum ecosystem!</p>
                <p>Filter addresses by:
                    <ul>
                        <li>win rate</li>
                        <li>average ROI per transaction</li>
                        <li>net realised P&L</li>
                        <li>labels: funds, persons</li>
                        <li>interaction with DEX/CEX</li>
                    </ul>
                </p>
                <p>Boost your investing decisions, by</p>
                <button className="button-submit" onClick={()=>{{navigate("/login", {state:{type:'signup'}})}}} style={{fontSize:'20px', width: '200px'}}>SIGN UP</button>
            </div>
            <div className="image-section">
                <img 
                    src={filterImg}
                    className="parallax-image" 
                    style={{ transform: calcImageTransform(0.05) }}
                />
                <img 
                    src={walletAd}
                    className="parallax-image" 
                    style={{ transform: calcImageTransform(0.05) }}
                />
            </div>
        </div>
    );
}