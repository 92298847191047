import './Account.css'
import { Auth } from "@supabase/auth-ui-react";


import { useNavigate} from 'react-router-dom'

import { useSubscription } from './subscriptionContext'
import { useEffect } from 'react';

async function cancelSubscription(userId) {
    try {
      console.log('send data to cancel', userId, JSON.stringify({ userId }))
      const response = await fetch('https://xjllubhirqqferhrmgbs.supabase.co/functions/v1/cancel-autorenew', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });
  
      const data = await response.json();
      if (response.ok) {
        console.log('Subscription canceled:', data);
        window.location.reload()
      } else {
        console.error('Error canceling subscription:', data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
}

function Card(props) {
    return (
        <div className='account-card'>
            <h1 className='account-card-header'>
                {props.header}
            </h1>
            {props.fields.map((field) => {return (<p className='account-card-field'>{field.name}: {field.link? (<a style={{fontFamily: 'SFProReg', color: '#CC4E85'}} href={`${field.link}`}>{field.value}</a>) :field.value}</p>)})}
            {props.customLayout}
        </div>
    )
}

function convertDate(dateTime) {
    if(dateTime === '-') {
        return dateTime
    }
    const date = new Date(dateTime);

    const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false // Use 24-hour format
      }).format(date);

    return formattedDate
}

export function Account(props) {
    const { user } = Auth.useUser();
    console.log("USERRR account", user)
    const { subscriptionTier, filterPackageLevel, subscriptionValidUntil, subIsLoading, referralCode, refDiscount, renewableSub } = useSubscription();

    useEffect(() => {
        if (!user) {
          const timer = setTimeout(() => {
            // Redirect to the login page after 5 seconds
            // If using React Router:
            // navigate('/login');
            console.log('redirect timer: account')
            // If not using React Router:
            window.location.href = '/login';
          }, 1000); // 5000 milliseconds = 5 seconds
    
          // Cleanup function to clear the timer if the component unmounts
          // or if the user logs in before the timer expires
          return () => clearTimeout(timer);
        }
      }, [user]); 

    if(!user) {
        return (
            <div className="account-main">
                <div className='account-section'>
                    <h1 className='account-header'>Please Log In</h1>
                </div>
            </div>
        )
    }
    return (
        <div className="account-main">
            <div className='account-section'>
                <h1 className='account-header'>My Account</h1>
                <div className='card-section'>
                    <Card 
                        header={"General Information"} 
                        fields={
                            [
                                {name: 'Your Email', value: user?.email},
                                {name: 'Referral Code Used', value: refDiscount > 0 
                                ? `${referralCode} (-${refDiscount}% discount)` 
                                : `${referralCode}`}
                            ]
                        }/>
                    <Card 
                        header={"Active Subscription"} 
                        fields={
                            [
                                {name: 'Plan Name', value: subscriptionTier===2 ? "Standard": "Demo", link: "/pricing"},
                                {name: 'Expiry Date', value: subscriptionTier===2 ? convertDate(subscriptionValidUntil):"-"},
                                {name: 'Renewable', value: renewableSub ? 'Yes': 'No'},
                            ]
                        }
                        customLayout={
                            renewableSub && (
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <a style={{fontFamily: 'SFProReg', color: 'white', fontSize: '14px'}} href="#" onClick={()=>{cancelSubscription(user.id)}}>Cancel Auto-Renew</a>               
                                </div>
                            )
                        }/>
                </div>
                <div className='card-section'>
                    <Card 
                        header={"Do you need help?"}
                        fields={[
                        ]}
                        customLayout={
                            (
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <p className='text-card-description'>If you have any problems or questions related to payment or subscription, please contact the support service in any way convenient for you:</p>
                                    <ul style={{ color: '#CC4E85'}}>
                                        <li><a style={{fontFamily: 'SFProReg', color: '#CC4E85'}} href="https://twitter.com/cherrypick_xyz">Twitter</a></li>
                                        <li><a style={{fontFamily: 'SFProReg', color: '#CC4E85'}} href="https://t.me/+w7w4rlpdh-M3MmQ8">Telegram</a></li>
                                        <li><a style={{fontFamily: 'SFProReg', color: '#CC4E85'}} href="https://discord.gg/AWkjXPCkCY">Discord</a></li>
                                        <li><a style={{fontFamily: 'SFProReg', color: '#CC4E85'}} href="mailto:info@cherry-pick.xyz">Email</a></li>
                                    </ul>
                                    
                                </div>
                            )
                        }
                        />
                </div>
            </div>
        </div>
    )
}