import { DataGrid } from '@mui/x-data-grid';
import { TokenTableField, ShowPercentChange, ShowDollarFlow } from './utils';
import { addCommas, parseValueForTooltip } from '../ChartSettings/utils';

export const columns = [
    { 
        field: 'token', 
        headerName: 'Token', 
        width: 200,
        cellClassName: 'datagrid-token',
        renderCell: (props) => { return (<TokenTableField value={props.value}/>) }
    },
    {
        field: 'price_0d',
        headerName: 'Price',
        width: 100,
        headerClassName: 'datagrid-separator-price',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'balance_dollar_cex_1d',
        headerName: 'CEX Reserves ($)',
        width: 110,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },  
    {
        field: 'balance_cex_1d',
        headerName: 'CEX Reserves',
        width: 130,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'balance_cex_percent_1d',
        headerName: '% of Supply',
        width: 110,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<p>{parseValueForTooltip(props.value)}%</p>)},
    },  
    {
        field: 'netflow_dollar_total_1d',
        headerName: '1d Netflow',
        width: 110,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowDollarFlow value={props.value} />)},
    },
    {
        field: 'netflow_dollar_total_7d',
        headerName: '7d Netflow',
        width: 110,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<ShowDollarFlow value={props.value} />)},
    },
    // {
    //     field: 'mid_term_change_30d',
    //     headerName: '30d Δ%',
    //     width: 70,
    //     headerClassName: 'datagrid-separator-mid-term',
    //     renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    // },
    // {
    //     field: 'long_term_1d',
    //     headerName: 'Long Term (3m-1y)',
    //     width: 120,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>{parseValueForTooltip(props.value)}%</p>)},
    // },  
    // {
    //     field: 'long_term_change_30d',
    //     headerName: '30d Δ%',
    //     width: 70,
    //     headerClassName: 'datagrid-separator-long-term',
    //     renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    // },
    // {
    //     field: 'super_long_term_1d',
    //     headerName: 'Super Long Term (1Y+)',
    //     width: 100,
    //     headerClassName: 'datagrid-separator-disable',
    //     renderCell: (props) => { return (<p>{parseValueForTooltip(props.value)}%</p>)},
    // },  
    // {
    //     field: 'super_long_term_change_30d',
    //     headerName: '30d Δ%',
    //     width: 70,
    //     headerClassName: 'datagrid-separator-long-term',
    //     renderCell: (props) => { return (<ShowPercentChange value={props.value} />)},
    // },
]

export const initialState = {
    sorting: {
      sortModel: [{ field: 'balance_dollar_cex_1d', sort: 'desc' }],
    },
}