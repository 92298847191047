import { React, useState, useEffect, useRef, Component} from "react";
import {Tooltip} from 'react-tooltip';
import { data } from "./Chart";

import { DropdownMenuSingleSelect, DropDownMultiSelect, DropdownMenuSingleSelectSmall, DropDownSmallMultiSelect } from "./DropDownMenu";

class Input extends Component {
    constructor(props) {
        super(props);
        // console.log("INPUT", props, this.props.props)
        this.state = {
            // value: String(props.params.fields[props.field.id]),
            error: '',
            showTooltip: false,
        };

        this.id = props.field.id
        this.min = props.field.min;
        this.max = props.field.max;

        // this.value = String(props.params.fields[props.field.id])
        // this.params = props.params

        // this.handleFieldChange = props.handleFieldChange

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if showTooltip state has changed
        if (prevState.showTooltip !== this.state.showTooltip) {
          if (this.state.showTooltip) {
            // If showTooltip has changed to true, start a timeout
            this.timer = setTimeout(() => {
              this.setState({ showTooltip: false });
              // Tooltip.hide();
            }, 1500);
          } else {
            // If showTooltip has changed to false, clear the timeout
            clearTimeout(this.timer);
          }
        }
    }

    componentWillUnmount() {
        // Clear timeout if the component is unmounted
        clearTimeout(this.timer);
    }
    
  
    handleInputChange = (event) => {
        const newValue = event.target.value;

        const [validValue, newestValue, errorValue] = this.validateValue(newValue)
        if (validValue) {
            this.setState({error: '', showTooltip: false})
            this.props.handleFieldChange(this.id, newestValue)
        } else {
            this.setState({error: errorValue, showTooltip: true})
        }
    }

    validateValue(value) {
        return [true, value, '']
    }
  
    render() {
      return (
        <div className="field">
            <input
                className="field-input"
                // autoFocus
                placeholder='Search...'
                type="text" 
                value={this.props.params.fields[this.id]} 
                onChange={this.handleInputChange} 
            />
            <Tooltip 
                style={{backgroundColor: 'rgb(29, 26, 45)', borderRadius: '8px', fontFamily: 'SFProLight'}}
                anchorSelect=".field-input"
                place="bottom" 
                effect="solid"
                isOpen={this.state.showTooltip}
                // isOpen={this.state.error !== ''} 
                // delayShow={1000}
                // delayHide={1000}
                >
                {this.state.error}
            </Tooltip>
        </div>
      );
    }
  }
  
  export class IntegerInput extends Input {
    validateValue(value) {
      // Only accept integers that don't start with 0
      return [value === '' || /^[1-9][0-9]*$/.test(value), value,
                'Wrong input. Please enter an integer that does not start with 0']
    }
  }
  
  export class FloatInput extends Input {
    // validateValue(value) {
    //   // Only accept floats
    //   let newValue = ''
    //   if(value === '') {
    //     return [true, '', '']
    //   }
    //   if(value[0] === '-') {

    //   }
    //   return [value === '' || /^(\d+\.?\d*|\.\d+)$/.test(value), value.replace(/^0+(?=\d\.)/, '0').replace(/^0+(?=\d)/, ''),
    //                 'Please enter a floating point number without leading zeroes before the decimal point']
    // }
    validateValue(value) {
        // Direct return for empty string

        const floatRegex = /^(\d+\.?\d*|\.\d+)$/;
        // Check for negative sign and prepare number part
        const isNegative = value.startsWith('-');
        const numberPart = isNegative ? value.slice(1) : value; // Using slice for potential performance benefit

        // Validate the float number part
        // console.log('number', numberPart, isNegative)
        if (numberPart === '') {
            return [true, isNegative ? '-':'', ''];
        }
        const isValidFloat = floatRegex.test(numberPart);

        // Remove leading zeros, reconstruct final value
        console.log('number part', numberPart)
        const formattedNumberPart = numberPart.replace(/^0+(?=\d\.)/, '0').replace(/^0+(?=\d)/, '');
        let finalValue = isNegative ? `-${formattedNumberPart}` : formattedNumberPart;
  
        let parsedValue = parseFloat(finalValue);
        console.log('parsed value', parsedValue, this.min, this.max)
        if (!isNaN(parsedValue)) {
            if (this.min !== null && parsedValue < this.min) {
                parsedValue = this.min;
            }
            if (this.max !== null && parsedValue > this.max) {
                parsedValue = this.max;
            }
            finalValue = parsedValue.toString();
        }

        // Error message for invalid input
        const errorMessage = isValidFloat ? '' : 'Please enter a valid floating point number without leading zeroes before the decimal point';

        return [isValidFloat, finalValue, errorMessage];
    }
  }

  export class DateInput extends Input {
    handleInputChange = (event) => {
        let originalVal = event.target.value;

        let val = originalVal.replace(/[^0-9.]/g, '');

        if (val === originalVal){
    
        // Add dot after day and month when they are typed out
        if (val.length === 2 || val.length === 5) {
          if (this.props.params.fields[this.id].length === val.length - 1) {
            val += '.';
          }
        }
    
        // Only allow valid partial and complete dates
        if (/^\d{0,2}(\.\d{0,2}(\.\d{0,4})?)?$/.test(val)) {
          let dateParts = val.split('.');
    
          // check day
          if (dateParts.length > 0 && dateParts[0] !== '' &&  dateParts[0].length === 2) {
            if (dateParts[0] <= 0) {
              dateParts[0] = '01';
            } else if (dateParts[0] > 31) {
              dateParts[0] = '31';
            }
          }
    
          // check month
          if (dateParts.length > 1 && dateParts[1] !== '' &&  dateParts[1].length === 2) {
            if (dateParts[1] <= 0) {
              dateParts[1] = '01';
            } else if (dateParts[1] > 12) {
              dateParts[1] = '12';
            }
          }
    
          // check year
          if (dateParts.length > 2 && dateParts[2].length === 4) {
            let currentYear = new Date().getFullYear();
            if (dateParts[2] < 2009) {
              dateParts[2] = '2009';
            } else if (dateParts[2] > currentYear) {
              dateParts[2] = currentYear.toString();
            }
          }
    
          // If the date is complete, check if it's valid
          if (dateParts.length === 3 && dateParts[0] !== '' && dateParts[1] !== '' && dateParts[2] !== '' && dateParts[2].length === 4) {
            let tempDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    
            // If the date is not valid (for example, February 30), adjust the day to the closest valid day
            if (tempDate.getDate() !== Number(dateParts[0])) {
              tempDate.setDate(0); // this sets the date to the last day of the previous month
              dateParts[0] = tempDate.getDate().toString().padStart(2, '0');
            }
          }
    
          val = dateParts.join('.');
    
          // this.setState({ value: val });
          this.props.handleFieldChange(this.id, val)
            }
        } else {
            this.setState({error: 'Please write down date in format dd.mm.yyyy', showTooltip: true})
        }
    }
    render() {
        return (
            <div className="field" style={{width: '110px'}}>
                <input
                    className="field-input"
                    
                    // autoFocus
                    type="text" 
                    pattern="\d{2}.\d{2}.\d{4}" 
                    placeholder="dd.mm.yyyy"
                    value={this.props.params.fields[this.id]} 
                    onChange={this.handleInputChange} 
                />
                <Tooltip 
                    style={{backgroundColor: 'rgb(29, 26, 45)', borderRadius: '8px', fontFamily: 'SFProLight'}}
                    anchorSelect=".field-input"
                    place="bottom" 
                    effect="solid"
                    isOpen={this.state.showTooltip}
                    // isOpen={this.state.error !== ''} 
                    // delayShow={1000}
                    // delayHide={1000}
                    >
                    {this.state.error}
                </Tooltip>
            </div>)
    } 
  }


export const SelectField = (props) => {
    return (
      <div onClick={props.isReadOnly ? () => props.showWarningIsReadOnly(): ()=>{}} className="field" style={{border: '0', width: 'auto'}}>
        <DropdownMenuSingleSelectSmall isReadOnly={props.isReadOnly} handleItemChange={props.handleFieldChange} field={props.field.id} params={props.params.fields[props.field.id]} items={props.field.subsubparams} size={5}/>
      </div>
    )
}

export const MultiSelectField = (props) => {
    // console.log("MULTI SELE", props.field.subsubparams)
    return (
        <div onClick={props.isReadOnly ? () => props.showWarningIsReadOnly(): ()=>{}} className="field" style={{border: '0', width: 'auto'}}>
            <DropDownSmallMultiSelect isReadOnly={props.isReadOnly} handleItemChange={props.handleFieldChange} field={props.field.id} params={props.params.fields[props.field.id]} items={props.field.subsubparams} size={5}/>
        </div>
    )
}


export function Field(props) {
    const [searchInput, setSearchInput] = useState("");

    return (
        <div className="field" >
            <input className="field-input"
                autoFocus
                placeholder='Search...'
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)} />
        </div>
    )
}

export function FilterName(props) {
    return (
        <div onClick={() => props.onClick()} className="field" style={{cursor: 'auto', width: 'auto', border: 'none', backgroundColor: 'rgba(29, 26, 45, 0.8)', minWidth: '80px'}}>
            <p style={{marginRight: '16px'}}>{props.name}</p>
        </div>
    )
}

export function FieldName(props) {
    return (
        <div className="field without-border" style={{width: 'auto', background: 'none'}}>
            <p style={{marginLeft: '0px'}}>{props.name}</p>
        </div>
    )
}