import { supabase } from "@supabase/auth-ui-shared";

// Function to get the subscription tier for a user
export const getSubscriptionTier = async (userId, supabase) => {
  try {
    const { data, error } = await supabase
      .from('user_subscriptions')
      .select('tier_id, valid_until, renewable')
      .eq('user_id', userId)
      .single();
    console.log("loaded", data)
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching subscription tier:', error);
    return null;
  }
};

export const getPackageTier = async (userId, supabase) => {
  try {
    const { data, error } = await supabase
      .from('user_packages')
      .select('tier_id, valid_until')
      .eq('user_id', userId)
      .single();
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching subscription tier:', error);
    return null;
  }
};

export const getReferralInfo = async (userId, supabase) => {
  try {
    // First, fetch the referral code associated with the user
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('referral_code')
      .eq('id', userId)
      .single();
    
    if (userError) throw userError;
    if (!userData.referral_code) {
      return {
        referralCode: '-',
        isActive: false,
        discountPercentage: 0
      }
    }

    // Now, use the referral_code to fetch more details from the referrals table
    const { data: referralData, error: referralError } = await supabase
      .from('referrals')
      .select('active, discount_percentage')
      .eq('referral_code', userData.referral_code)
      .single();

    if (referralError) throw referralError;

    // Combine user data and referral details into one object
    return {
      referralCode: userData.referral_code,
      isActive: referralData.active,
      discountPercentage: referralData.discount_percentage
    };

  } catch (error) {
    console.error('Error fetching referral info:', error.message);
    return null;  // Return null or handle error as needed
  }
}

// Function to verify if the subscription is still valid
export const isSubscriptionValid = (validUntil) => {
  return new Date(validUntil) > new Date();
};

// Function to insert a new user subscription
export const insertUserSubscription = async (userId, tierId, validUntil, supabase) => {
  try {
    const { data, error } = await supabase
      .from('user_subscriptions')
      .insert([
        { user_id: userId, tier_id: tierId, valid_until: validUntil }
      ])
      .select('*');

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error inserting user subscription:', error);
    return null;
  }
};

// Function to update a user's subscription
export const updateUserSubscription = async (userId, tierId, validUntil, supabase) => {
  try {
    const { data, error } = await supabase
      .from('user_subscriptions')
      .update({ tier_id: tierId, valid_until: validUntil })
      .eq('user_id', userId)
      .select('*');

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating user subscription:', error);
    return null;
  }
};

const responsesTypes = {
    unauthorized: {
        show: false,
        redirectPath: '/login',
        pay: false,
    },
    error: {
      show: true,
      header: 'Something going wrong...',
      messages: ['Please try later'],
    },
    firstTimeSubscription: {
        show: true,
        redirectPath: '/link',
        header: '🥳 Congratulations 🥳',
        messages: ['As one of our early users, we are gifting you a one-month subscription to Standard plan on our platform.',
        'Thank you for your support, and we wish you successful trading decisions!',
        'We hope CherryPick will assist you in this endeavor.']
    },
    wantedHigherTier: {
      show: true,
      redirectPath: '/link',
      header: '🥳 Congratulations 🥳',
      messages:
      ['As one of our early users, we are gifting you a one-month subscription to our Standard plan on our platform.',
      `Unfortunately, the Pro Plan is still in development, so we can't provide access yet. But we appreciate your interest and will notify you by email as soon as it's ready.`,
      'Thanks for your interest!']
    },
    alreadyHad: {
      show: true,
      header: 'You have an active subscription',
      messages: ['Currently, you have an active subscription that is at or above the plan level you wish to purchase.'],
      pay: false,
    },
    notAvailableYet: {
      show: true,
      header: 'Thanks for your interest! 🙏',
      messages: [`Unfortunately, the Pro Plan is still in development, so we can't provide access yet.`, 
    `But we appreciate your interest and will notify you by email as soon as it's ready.`]
    },
    success: {
      show: false,
      pay: true,
    }
}

export const tryToBuy = async (user, tierId, validUntil, supabase) => {
    if(user) {
        const userId = user.id
        console.log("TYR TO BYY")
        const data = await getSubscriptionTier(userId, supabase)
        console.log("DAARTA", data)
        if(data) {
            if(isSubscriptionValid(data.valid_until)) {
                if(tierId > data.tier_id) {
                    // const result = await updateUserSubscription(userId, tierId, validUntil, supabase)
                    // if(result) {
                    //   return responsesTypes.firstTimeSubscription
                    // } else {
                    //   return responsesTypes.error
                    // }
                    return responsesTypes.notAvailableYet
                } else {
                  return responsesTypes.alreadyHad
                }
            } else {
                const result = await updateUserSubscription(userId, 2, validUntil, supabase)
                // console.log("UPDATE SUBSCRIRPTION - ", result)
                if(result) {
                  if(tierId > 2) {
                    return responsesTypes.wantedHigherTier
                  } else {
                    return responsesTypes.firstTimeSubscription
                  }
                } else {
                  return responsesTypes.error
                }
            }
        } else {
            const result = await insertUserSubscription(userId, 2, validUntil, supabase)
            if(result) {
              if(tierId > 2) {
                return responsesTypes.wantedHigherTier
              } else {
                return responsesTypes.firstTimeSubscription
              }
            } else {
              return responsesTypes.error
            }
        }
    } else {
      return responsesTypes.unauthorized
    }
}

export const verifyBeforePayment = async (user, tierId, validUntil, supabase) => {
    if(user) {
        const userId = user.id
        const data = await getSubscriptionTier(userId, supabase)
        if(data) {
            if(isSubscriptionValid(data.valid_until)) {
              return responsesTypes.alreadyHad
            }
        } 
        return responsesTypes.success
    } else {
        return responsesTypes.unauthorized
    }
}