import { DataGrid } from '@mui/x-data-grid';
import { TokenTableField, ShowPercentChange } from './utils';
import { addCommas, parseValueForTooltip } from '../ChartSettings/utils';

import { ShowAddress } from './utils';
import { ShowDollar } from '../dataPortfolio';


export const columns7days = [
    { 
        field: 'number', 
        headerName: '', 
        width: 20,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{props.value}</p>)},
    },
    { 
        field: 'address', 
        headerName: 'Address', 
        width: 110,
        // headerClassName: 'datagrid-separator-disable', 
        renderCell: (props) =>  { return (<ShowAddress value={props.value.address} token={props.value.token} tag={props.value.tag} />)},
    },
    {
        field: 'nmpl_part',
        headerName: '7d Profit / Loss ($)',
        width: 140,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'roi_part',
        headerName: '7d ROI (%)',
        width: 100,
        // headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<ShowDollar value={props.value} percent={true}  withoutDollar={true}/> )}
    },
    {
        field: 'nmpl',
        headerName: 'All Time Profit / Loss ($)',
        width: 140,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'roi',
        headerName: 'All Time ROI (%)',
        width: 140,
        renderCell: (props) => {return (<ShowDollar value={props.value} percent={true}  withoutDollar={true}/> )}
    },
    { 
        field: 'balance', 
        headerName: 'Balance', 
        width: 100,
        cellClassName: 'datagrid-balance',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'netflow',
        headerName: '7d Δ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}  withoutDollar={true}/>) },
    },
    { 
        field: 'balance_dollar', 
        headerName: 'Balance ($)', 
        width: 100,
        // cellClassName: 'datagrid-balance',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    
    // {
    //     field: 'nupl',
    //     headerName: 'Unrealised Profit / Loss ($)',
    //     width: 170,
    //     renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    // },
]





export const columns30days = [
    { 
        field: 'number', 
        headerName: '', 
        width: 20,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{props.value}</p>)},
    },
    { 
        field: 'address', 
        headerName: 'Address', 
        width: 110,
        // headerClassName: 'datagrid-separator-disable', 
        renderCell: (props) =>  { return (<ShowAddress value={props.value.address} token={props.value.token} tag={props.value.tag}/>)},
    },
    {
        field: 'nmpl_part',
        headerName: '30d Profit / Loss ($)',
        width: 140,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'roi_part',
        headerName: '30d ROI (%)',
        width: 100,
        renderCell: (props) => {return (<ShowDollar value={props.value} percent={true}  withoutDollar={true}/> )}
    },
    // {
    //     field: 'netflow',
    //     headerName: '30d Netfow (Token)',
    //     width: 140,
    //     renderCell: (props)  => { return (<ShowDollar value={props.value}  withoutDollar={true}/>) },
    // },
    {
        field: 'nmpl',
        headerName: 'All Time Profit / Loss ($)',
        width: 140,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'roi',
        headerName: 'All Time ROI (%)',
        width: 100,
        renderCell: (props) => {return (<ShowDollar value={props.value} percent={true}  withoutDollar={true}/> )}
    },
    { 
        field: 'balance', 
        headerName: 'Balance', 
        width: 100,
        cellClassName: 'datagrid-balance',
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) =>  { return (<p>{parseValueForTooltip(props.value)}</p>)},
    },
    {
        field: 'netflow',
        headerName: '30d Δ',
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}  withoutDollar={true}/>) },
    },
    { 
        field: 'balance_dollar', 
        headerName: 'Balance ($)', 
        width: 100,
        cellClassName: 'datagrid-balance',
        renderCell: (props) =>  { return (<p>${parseValueForTooltip(props.value)}</p>)},
    },
    // {
    //     field: 'nupl',
    //     headerName: 'Unrealised Profit / Loss ($)',
    //     width: 170,
    //     renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    // },
]