import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "@supabase/auth-ui-react";
import { ButtonSignUp } from "./Navigation";

import { checkAddress } from "./loadData";

import "./Explore.css"

function ExploreButton(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)

    const navigate = useNavigate()

    const styleSignUp = {
        background: isMouseEnter? '#FF54A9': '#EE288A',
        borderColor: isMouseEnter? '#FF54A9': '#EE288A',
    }

    function checkField(e, address) {
        e.preventDefault();
        if(!address.startsWith('0x')) {
            props.setErrorText("")
            props.setError(true)
            return
        }
        if(address.length !== 42) {
            props.setErrorText("")
            props.setError(true)
            return
        }
        checkAddress(address).then((data) => {
            // console.log("RESPONSE", data)
            if(data.valid_address) {
                navigate(`/portfolio/${props.address}/balance`)
            } else {
                props.setError(true)
                props.setErrorText(data.error_message)
                // console.log(data.error_message)
            }
        })
    }

    return (
        <button 
            className="exploreButton"
            style={styleSignUp}
            onMouseEnter={ () => setMouseEnter(true) } 
            onMouseLeave={ () => setMouseEnter(false) }
            onClick={(e) => {checkField(e, props.address)}}
        >
            <p>EXPLORE</p>
        </button>
    )
}

export function DefaultWallet(props) {
    return (
        <div className="default-wallet" onClick={()=>props.setFunc(props.address)}>
            <span style={{fontFamily:"SFProLight", fontSize: "14px", color: 'white'}}>{props.name? props.name :props.address.slice(0, 7)+'...'}</span>
            <svg class="redirect-icon-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="17" height="17">
                <path d="M6 18L18 6" stroke="#ffffff" stroke-width="2" />
                <path d="M12 6H18V12" fill="none" stroke="#ffffff" stroke-width="2" />
            </svg>
        </div>
    )
}

export function Explore(props) {
    const [address, setAddress] = useState("")
    const { user } = Auth.useUser();

    const [errorInField, setError] = useState(false)

    const [errorText, setErrorText] = useState("")

    return (
        <div className="exploreBlock">
            { !user && <div className="locker" style={{position: "absolute", 
                  width: '100%',
                  height: '100%',
                //   height: 'calc(100% - 130px)', 
                //  background: 'red',
                  zIndex: 1, 
                  marginTop: 350,
                  display: 'flex',
                //   justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                //   backdropFilter: 'blur(5px)',
                  }}>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'}}> To use Wallet Explorer you can try </h3>
                    <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px', marginBottom: '0px'    }}> to hack this page or just sign up!  😉🍒 </h3>
                    {/* <h3 style={{fontFamily: 'SFProMedium', color: 'white', fontSize: '20px'}}>  </h3> */}
                    {/* <ButtonSignUp withoutMargin={true} /> */}
                </div> }
            <form style={{display: 'flex', flexDirection: 'column', width: '90%', maxWidth: '600px'}}>

                <label style={{color: 'white', marginBottom: '25px', fontSize: '22px', 
                fontFamily:'SFProBold'}}>Wallet Explorer</label>
                <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                    <p style={{color:'white', fontFamily: 'SFProReg', fontSize: '14px', margin: '0px', marginRight: '8px'}}>Try our default wallets</p>
                    <div style={{display: 'flex', flexDirection: 'row', gap: '4px'}}>
                        <DefaultWallet address={'0x176F3DAb24a159341c0509bB36B833E7fdd0a132'} name={'Justin Sun'} setFunc={setAddress}/>
                        <DefaultWallet address={'0x81b2587F04dDf814094aB88eFdD4f29eE3407437'} name={'CZ'} setFunc={setAddress}/>
                        <DefaultWallet address={'0xcd531ae9efcce479654c4926dec5f6209531ca7b'} setFunc={setAddress}/>
                        {/* <DefaultWallet address={'0xcd531ae9efcce479654c4926dec5f6209531ca7b'} setFunc={setAddress}/> */}
                    </div>
                </div>
                <label style={{color:'white', fontSize: '14px', fontFamily: 'SFProReg', marginBottom: '6px'}}>ETH address
                </label>
                <input 
                        style={{
                            backgroundColor: 'transparent',
                            outline: 'none',
                            border: '2px solid rgba(255, 255, 255, 1)',
                            borderRadius: '2px',
                            color: 'white',
                            fontSize: '14px',
                            height: '50px',
                            textIndent: '16px',
                        }}
                        placeholder='Enter ETH address'
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                <ExploreButton address={address} setError={setError} setErrorText={setErrorText}/>
                {errorInField && 
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <p style={{color: 'white', fontFamily: 'SFProLight', fontSize: '14px'}}> {errorText === ""? "You wrote invalid address." : errorText} </p>
                </div>}
            </form>
        </div>
    )
}