import './Home.css'

import cardImg from '../assets/onchain_img.png'

import { useNavigate } from 'react-router-dom'

import api from '../assets/icons/icon_api.png'
import charts from '../assets/icons/icon_charts.png'
import ffa from '../assets/icons/icon_ffa.png'
import leaderboard from '../assets/icons/icon_leaderboards.png'
import tokens from '../assets/icons/icon_tokens.png'
import wallet from '../assets/icons/icon_wallet_explorer.png'

const CardTexts = [
    {
        header: 'Charts',
        text: 'Explore on-chain activity to improve your investment and trading strategy.',
        redirectPath: '/ldo',
        new: true,
        cardImg: charts,
    },
    {
        header: 'Token Ranking',
        text: 'Rank and compare assets by macro metrics.',
        redirectPath: '/token-tracker',
        cardImg: tokens,
    },
    {
        header: 'API',
        text: 'Backtest your trading strategy using platform data via API.',
        redirectLink: 'https://docs.cherry-pick.xyz/',
        cardImg: api,
    },
]

const CardTextsSM = [
    {
        header: 'Wallet Search',
        text: 'A Unique tool for searching Smart Money addresses within the blockchain based on your custom filters',
        comingSoon: false,
        // new: true,
        redirectPath: '/filter',
        cardImg: ffa,
    },
    {
        header: 'Leaderboards',
        text: 'Get access to leaderboards of addresses of the best traders, whales, funds and more.',
        redirectPath: '/leaderboard',
        cardImg: leaderboard,
    },
    {
        header: 'Wallet Explorer',
        text: 'Explore the address strategy and find out the profitability of each of its trades.',
        redirectPath: '/explore',
        new: true,
        cardImg: wallet,
    },
    // {
    //     header: 'Alerts',
    //     text: 'On-chain metrics provides various aggregates and indicators based on the blockchain transactions.',
    // },
]

function Card(props) {
    const navigate = useNavigate()

    return (
        <div className='card-home' style={props.comingSoon ? {border: "1px solid #1D1A2D", backgroundColor: 'rgba(29, 26, 45, 1.0)', cursor: 'auto', opacity: 0.5} :{}} onClick={() => props.redirectLink ? window.open(props.redirectLink, '_blank') : navigate(props.redirectPath)}>
            <div className='card-home-contain' style={props.comingSoon ? {opacity: 1.0} : {}}>
                <h1 className='card-home-header'>
                    {props.header}
                </h1>
                <p className='card-home-text'>
                    {props.text}
                </p>
            </div>
            <img src={props.image} alt="background" className="background-image" />
            {props.comingSoon && <div className='card-home-comingsoon'>COMING SOON</div>}
            {/* {props.new && <div className='card-home-comingsoon'>NEW 🔥🔥🔥</div>} */}
            {props.new && props.header === 'Charts' && <div className='card-home-comingsoon'>NEW 300 TOKENS 🔥</div>}
            {props.new && props.header === 'Wallet Explorer' && <div className='card-home-comingsoon'>NEW PAGE 🔥</div>}
        </div>
    )
}

export function Home(props) {
    return (
        <div className="home-main">
            <div className='home-body'>
                <div className='home-section'>
                    <h1 className='card-home-header'>Welcome to CherryPick!</h1>
                    <p className='card-home-text' style={{color: 'white',width:'100%'}}>Here you will find on-chain metrics for altcoins, detailed cohort analysis, and tools for effortlessly searching wallet groups based on financial metrics.</p>
                </div>
            </div>
            <div className='home-body'>
                <div className='home-section'>
                    <h1>On-chain Analysis</h1>
                    {/* <h4>On-chain analysis is the analysis of blockchain activity in order to determine market sentiment, macro and micro market conditions for investment and trading decisions.<br></br>
                        This analysis is suitable for making decisions about buying or selling coins that have been on the market for a long time and have a large number of on-chain transactions.
                        Best option if you wanna trading ERC20 blue chips.</h4> */}
                    <div className='card-home-carousel'>
                        {CardTexts.map(item => <Card header={item.header} text={item.text} image={item.cardImg} redirectPath={item.redirectPath} redirectLink={item.redirectLink} comingSoon={item.comingSoon} new={item.new}/>)}
                    </div>
                    {/* <Card header={'On-chain Metrics'} text={'On-chain metrics provides various aggregates and indicators based on the blockchain transactions.'} image={cardImg} /> */}
                </div>
            </div>
            <div className='home-body' style={{marginTop: '40px', marginBottom: '200px'}}>
                <div className='home-section'>
                    <h1>Follow Smart Money</h1>
                    {/* <h4>The Follow Smart Money strategy is to repeat the strategy of addresses that are smart money such as funds, insiders, whales or just successful traders.
Here we offer you a tool to find Smart Money wallets, learn their strategy, and an alert system to follow their strategy.</h4> */}
                    <div className='card-home-carousel'>
                        {CardTextsSM.map(item => <Card header={item.header} text={item.text} image={item.cardImg} redirectPath={item.redirectPath} redirectLink={item.redirectLink} comingSoon={item.comingSoon} new={item.new}/>)}
                    </div>
                </div>
            </div>
        </div>
    )
}