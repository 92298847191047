import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
//import watermarkPlugin from 'chartjs-plugin-watermark';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {parseBigValues, titleLabel, titleTooltip, scaleX, scalePrice, parseValueForTooltip} from './utils.js'

import "./PortfolioPNL.css";

// import imagesrc from  "../../assets/watermark.png"
// const image = new Image();

// image.src = imagesrc  

Tooltip.positioners.customLinePositioner = function(elements, eventPosition) {
    // Initialize variables to store the best x and y positions
    let x = 0;
    let y = 0;

    // Check if there are elements
    if (elements.length > 0) {
        // Use the first element to determine the position
        // This example takes the first element, but you can use any logic to choose the element
        x = elements[0].element.x;
        y = elements[0].element.y;
    } else {
        // Fallback to the event position if no elements are active
        x = eventPosition.x;
        y = eventPosition.y;
    }

    return {
        x: x,
        y: y
    };
};

export const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
    // plugin,
    //watermarkPlugin,
  );

export const calculatePercentChange = (changeValue, firstValue) => {
    if (firstValue === 0) {
      if (changeValue > 0) {
        return Infinity; // Infinite increase
      } else if (changeValue < 0) {
        return -Infinity; // Infinite decrease
      } else {
        return 0; // No change
      }
    } else {
      return ((changeValue / firstValue) * 100).toFixed(2);
    }
};

export const data = {
    // labels: [],
    datasets: [
        {
            type: 'line',
            label: 'PNL',
            data: [],
            fill: true,
            borderColor: 'rgba(238, 40, 138, 1.0)',
            tension: 0.1,
            pointRadius: 0.0,
            borderWidth: 1,
            // backgroundColor: 'rgb(255, 255, 255)',
            backgroundColor: 'rgba(238, 40, 138, 0.0)',
            yAxisID: 'y',
        },
    ],
};

export const dataRealisedPL = {
    // labels: [],
    datasets: [
        {
            type: 'line',
            label: 'PNL',
            data: [],
            fill: true,
            borderColor: 'rgba(238, 40, 138, 1.0)',
            tension: 0.1,
            pointRadius: 0.0,
            borderWidth: 1,
            // backgroundColor: 'rgb(255, 255, 255)',
            backgroundColor: 'rgba(238, 40, 138, 0.0)',
            yAxisID: 'y',
        },
        {
            type: 'bar',
            label: 'Profit',
            data: [],
            fill: true,
            borderColor: 'rgba(172, 209, 251, 1.0)',
            tension: 0.1,
            pointRadius: 0.0,
            borderWidth: 0,
            backgroundColor: 'rgba(172, 209, 251, 1.0)',
            // backgroundColor: 'rgba(238, 40, 138, 0.0)',
            yAxisID: 'y',
        },
        {
            type: 'bar',
            label: 'Loss',
            data: [],
            fill: true,
            borderColor: 'rgba(238, 40, 138, 1.0)',
            tension: 0.1,
            pointRadius: 0.0,
            borderWidth: 0,
            // backgroundColor: 'rgb(255, 255, 255)',
            backgroundColor: 'rgba(238, 40, 138, 1.0)',
            yAxisID: 'y',
        },
    ],
};

// export const data = (canvas) => {
//     const ctx = canvas.getContext('2d');
//     const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
//     gradient.addColorStop(0, 'rgba(238, 40, 138, 0.5)');
//     gradient.addColorStop(1, 'rgba(238, 40, 138, 0)');
  
//     return {
//       datasets: [
//         {
//           label: 'Net Worth',
//           data: [100, 200, 300, 400, 500, 600, 700], // Example data
//           fill: true,
//           borderColor: 'rgb(238, 40, 138)',
//           backgroundColor: gradient,
//           tension: 0.4,
//           pointRadius: 0,
//           borderWidth: 2,
//         },
//       ],
//     };
//   };


export const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: function(context) {
          const { chart, tooltip } = context;
      
          // Select the container of the chart to append tooltips
          let container = chart.canvas.parentNode;
      
          // Tooltip for y-axis value (Net Worth)
          let tooltipElY = document.getElementById('chartjs-tooltip-y');
      
          // Create element on first render
          if (!tooltipElY) {
              tooltipElY = document.createElement('div');
              tooltipElY.id = 'chartjs-tooltip-y';
              tooltipElY.style.position = 'absolute';
              tooltipElY.style.color = 'white';
              tooltipElY.style.pointerEvents = 'none';
              container.appendChild(tooltipElY);
          }
      
          // Tooltip for x-axis value (Date)
          let tooltipElX = document.getElementById('chartjs-tooltip-x');
      
          // Create element on first render
          if (!tooltipElX) {
              tooltipElX = document.createElement('div');
              tooltipElX.id = 'chartjs-tooltip-x';
              tooltipElX.style.position = 'absolute';
              tooltipElX.style.color = 'white';
              tooltipElX.style.pointerEvents = 'none';
              container.appendChild(tooltipElX);
          }
      
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
              tooltipElY.style.opacity = 0;
              tooltipElX.style.opacity = 0;
              return;
          }
      
          // Set tooltip content
          if (tooltip.body) {
              const bodyLines = tooltip.body.map(b => b.lines);
              let currentValue = bodyLines.length ? bodyLines[0][0] : '';
              if (typeof currentValue === 'object') {
                  currentValue = currentValue?.y
              }
              const positiveValue = currentValue >= 0
              const colorTooltip = positiveValue ? 'positive':'negative'
              const displayValue = (positiveValue ? "+": "-") + "$" + parseValueForTooltip(Math.abs(currentValue.toFixed(2)))
              const valueText = `
                  <div class="pnl-tooltip-text ${colorTooltip}">${displayValue}</div>
              `;
            //   const valueText = `
            //       <div class="pnl-tooltip-text">$${parseValueForTooltip(currentValue)}</div>
            //       <div class="pnl-tooltip-percent ${percentClass}">${prefix}${percentChange}% ($${parseValueForTooltip(Math.abs(changeValue))})</div>
            //   `;
              const dateValue = tooltip.title.length ? formatDate(tooltip.title[0]) : '';
              const dateText = `
                  <div class="pnl-tooltip-date">${dateValue}</div>
              `;
      
              tooltipElY.innerHTML = valueText;
              tooltipElX.innerHTML = dateText;
          }
      
          // Position tooltips
          const chartArea = chart.chartArea;
          const middleY = (chartArea.bottom + chartArea.top) / 2;
          const tooltipHeightY = tooltipElY.offsetHeight;
          const tooltipWidthX = tooltipElX.offsetWidth;

          let topY;
          if (tooltip.caretY < middleY) {
              // Caret is in the upper half, place tooltip below
              topY = tooltip.caretY + 5;
          } else {
              // Caret is in the lower half, place tooltip above
              topY = tooltip.caretY - tooltipHeightY - 5;
          }

          tooltipElY.style.left = `${chartArea.left}px`;
          tooltipElY.style.top = `${topY}px`;
      
          let leftX = tooltip.caretX - tooltipWidthX / 2;
          leftX = Math.max(chartArea.left, Math.min(chartArea.right - tooltipWidthX, leftX));
          tooltipElX.style.left = `${leftX}px`;
          tooltipElX.style.top = `${chartArea.bottom + 10}px`;
      
          tooltipElY.style.opacity = 1;
          tooltipElX.style.opacity = 1;
      }, 
          callbacks: {
            label: function (context) {
              return context.raw.y || context.raw;
            },
          },
          intersect: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: false,
        grid: {
            display: false, // Ensure grid lines are displayed
          },
        // type: 'logarithmic',
        // ticks: {
        //   callback: function (val) {
        //     return `$${val}`;
        //   },
        //   color: '#9AA2AA',
        // },
      },
    },
};

function formatValue(value) {
    const positiveValue = value >= 0
    const colorTooltip = positiveValue ? 'positive':'negative'
    const displayValue = (positiveValue ? "+": "-") + "$" + parseValueForTooltip(Math.abs(value.toFixed(2)))

    return {
        displayValue: displayValue,
        colorTooltip: colorTooltip
    };
}
 
export const optionsRealisedPL = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
      axis: 'x',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'customLinePositioner',
        external: function(context) {
          const { chart, tooltip } = context;
      
          // Select the container of the chart to append tooltips
          let container = chart.canvas.parentNode;
      
          // Tooltip for y-axis value (Net Worth)
          let tooltipElY = document.getElementById('chartjs-tooltip-y-realised');
      
          // Create element on first render
          if (!tooltipElY) {
              tooltipElY = document.createElement('div');
              tooltipElY.id = 'chartjs-tooltip-y-realised';
              tooltipElY.style.position = 'absolute';
              tooltipElY.style.color = 'white';
              tooltipElY.style.pointerEvents = 'none';
              container.appendChild(tooltipElY);
          }
      
          // Tooltip for x-axis value (Date)
          let tooltipElX = document.getElementById('chartjs-tooltip-x-realised');
      
          // Create element on first render
          if (!tooltipElX) {
              tooltipElX = document.createElement('div');
              tooltipElX.id = 'chartjs-tooltip-x-realised';
              tooltipElX.style.position = 'absolute';
              tooltipElX.style.color = 'white';
              tooltipElX.style.pointerEvents = 'none';
              container.appendChild(tooltipElX);
          }
      
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
              tooltipElY.style.opacity = 0;
              tooltipElX.style.opacity = 0;
              return;
          }
      
          // Set tooltip content
          if (tooltip.body) {
              const bodyLines = tooltip.body.map(b => b.lines);
              console.log('bodylines', bodyLines)
              let currentValue = bodyLines.length ? bodyLines[0][0] : '';
              let profitValue = bodyLines.length ? bodyLines[1][0] : '';
              let lossValue = bodyLines.length ? bodyLines[2][0] : '';
              if (typeof currentValue === 'object') {
                  currentValue = currentValue?.y
              }
              if (typeof profitValue === 'object') {
                    profitValue = profitValue?.y
              }
              if (typeof lossValue === 'object') {
                lossValue = lossValue?.y
              }
            //   const positiveValue = currentValue >= 0
            //   const colorTooltip = positiveValue ? 'positive':'negative'
            //   const displayValue = (positiveValue ? "+": "-") + "$" + parseValueForTooltip(Math.abs(currentValue.toFixed(2)))

              const formattedValue = formatValue(currentValue)
              const formattedProfitValue = formatValue(profitValue)
              const formattedLossValue = formatValue(lossValue)
              const valueText = `
                  <div class="pnl-tooltip-text">${formattedValue.displayValue}</div>
                  <div class="pnl-tooltip-percent positive">${formattedProfitValue.displayValue}</div>
                  <div class="pnl-tooltip-percent negative">${formattedLossValue.displayValue}</div>
              `;
            //   const valueText = `
            //       <div class="pnl-tooltip-text">$${parseValueForTooltip(currentValue)}</div>
            //       <div class="pnl-tooltip-percent ${percentClass}">${prefix}${percentChange}% ($${parseValueForTooltip(Math.abs(changeValue))})</div>
            //   `;
              const dateValue = tooltip.title.length ? formatDate(tooltip.title[0]) : '';
              const dateText = `
                  <div class="pnl-tooltip-date">${dateValue}</div>
              `;
      
              tooltipElY.innerHTML = valueText;
              tooltipElX.innerHTML = dateText;
          }
      
          // Position tooltips
          const chartArea = chart.chartArea;
          const middleY = (chartArea.bottom + chartArea.top) / 2;
          const tooltipHeightY = tooltipElY.offsetHeight;
          const tooltipWidthX = tooltipElX.offsetWidth;

        //   console.log('chart values middleY tooltipHeight and width', middleY, tooltipHeightY, tooltipWidthX)

          let topY;
          if (tooltip.caretY < middleY) {
              // Caret is in the upper half, place tooltip below
              topY = tooltip.caretY + 5;
          } else {
              // Caret is in the lower half, place tooltip above
              topY = tooltip.caretY - tooltipHeightY - 5;
          }

          tooltipElY.style.left = `${chartArea.left}px`;
          tooltipElY.style.top = `${topY}px`;

        //   console.log('topY', topY)
      
          // let topY = tooltip.caretY - tooltipHeightY - 5;
          // topY = Math.max(chartArea.top, Math.min(chartArea.bottom - tooltipHeightY, topY));
          // tooltipElY.style.left = `${chartArea.left}px`;
          // tooltipElY.style.top = `${topY}px`;
      
          let leftX = tooltip.caretX - tooltipWidthX / 2;
          leftX = Math.max(chartArea.left, Math.min(chartArea.right - tooltipWidthX, leftX));
          tooltipElX.style.left = `${leftX}px`;
          tooltipElX.style.top = `${chartArea.bottom + 10}px`;
      
          tooltipElY.style.opacity = 1;
          tooltipElX.style.opacity = 1;
      }, 
          callbacks: {
            label: function (context) {
              // Return only the value, without the label
              return context.raw.y || context.raw;
            },
          },
          intersect: false,
      },
    },
    scales: {
      x: {
        display: false,
        stacked: true,
      },
      y: {
        display: false,
        beginAtZero: false,
        grid: {
            display: false, // Ensure grid lines are displayed
          },

      },
    },
}