import React, { useRef, useEffect, useState } from 'react';
import './LoadingPlaceholder.css'; // Make sure to import the CSS file

export const LoadingPlaceholder = ({ isLoading, children, height, width, margin='5px', marginLeft='0px', flex='none' }) => {
    return (
        <div style={{ height, width: width, position: 'relative', marginBottom: margin, marginLeft:marginLeft, flex: flex }}>
            {isLoading ? (
                <div className="placeholder" style={{ height }}>
                    <div className="animated-background"></div>
                </div>
            ) : (
                children
            )}
        </div>
    );
};

export const ChartLoadingPlaceholder = ({ isLoading, height }) => {
    return (
        <div className="chart-placeholder">
            {isLoading && (
                <>
                    <div className="gradient-effect"></div>
                </>
            )}
        </div>
    );
};