import { DataGrid } from '@mui/x-data-grid';
import { TokenTableField, ShowPercentChange } from './utils';
import { addCommas, parseValueForTooltip } from '../ChartSettings/utils';
import { ShowDollar } from '../dataPortfolio';

function timeSince(date) {
    let num_of_visual_dates = 0
    let result = ""

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
        if (num_of_visual_dates < 2) {
            result += Math.floor(interval) + " years ";
            seconds -= Math.floor(interval) * 31536000
            num_of_visual_dates += 1
        }
    }

    interval = seconds / 2592000;
    if (interval > 1) {
        if (num_of_visual_dates < 2) {
        result +=  Math.floor(interval) + " months ";
        seconds -= Math.floor(interval) * 2592000
        num_of_visual_dates += 1
        }
    }

    interval = seconds / 86400;
    if (interval > 1) {
        if (num_of_visual_dates < 2) {
      result += Math.floor(interval) + " days ";
      seconds -= Math.floor(interval) * 86400
      num_of_visual_dates += 1
        }
    }
    interval = seconds / 3600;
    if (interval > 1) {
        if (num_of_visual_dates < 2) {
      result += Math.floor(interval) + " hours ";
      seconds -= Math.floor(interval) * 3600
      num_of_visual_dates += 1
    }
    }
    interval = seconds / 60;
    if (interval > 1) {
        if (num_of_visual_dates < 2) {
      result += Math.floor(interval) + " minutes";
      seconds -= Math.floor(interval) * 60
      num_of_visual_dates += 1
    }
    }
    // return Math.floor(seconds) + " seconds";
    return result
  }


export const columns = [
    { 
        field: 'transaction_hash', 
        headerName: 'Hash', 
        width: 90,
        cellClassName: 'datagrid-token',
        renderCell: (props) => { return (<a href={`https://etherscan.io/tx/${props.value}`} target={'_blank'} style={{color: 'white'}}> {props.value.slice(0, 8)} </a>) }
    },
    { 
        field: 'from', 
        headerName: 'From', 
        width: 110,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => { return (<a> {props.value} </a>) }
    },
    { 
        field: 'to', 
        headerName: 'To', 
        width: 200,
        cellClassName: 'datagrid-token',
        renderCell: (props) => { return (<a> {props.value} </a>) }
    },
    {
        field: 'value',
        headerName: 'Value',
        width: 110,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'value_dollar',
        headerName: 'Value ($)',
        width: 110,
        renderCell: (props) => {return (<p>${parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'type',
        type: 'singleSelect',
        headerName: 'Type',
        valueOptions: ['Received', 'Sent'],
        width: 100,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p style={{color: props.value === 'Sent' ? "#DB3F88": "#6DDFEB"}}>{props.value}</p>)}
    },
    {
        field: 'npl',
        headerName: 'Profit / Loss ($)',
        width: 140,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props)  => { return (<ShowDollar value={props.value}/>) },
    },
    {
        field: 'cost_basis',
        headerName: 'Cost Basis ($)',
        width: 110,
        headerClassName: 'datagrid-separator-disable',
        renderCell: (props) => {return (<p>{props.value === '' ? '':'$'+parseValueForTooltip(props.value)}</p>)}
    },
    {
        field: 'roi',
        headerName: 'ROI (%)',
        width: 100,
        renderCell: (props) => {return (<ShowDollar value={props.value} percent={true}  withoutDollar={true}/> )}
    },
    {
        field: 'block_timestamp',
        headerName: 'Time',
        width: 170,
        renderCell: (props) => {return (<p> {timeSince(props.value*1000)} ago</p>)}
    }
]

export const initialState = {
    sorting: {
      sortModel: [{ field: 'mvrv_1d', sort: 'desc' }],
    },
}