import React, { useState, useEffect } from 'react';
import './Toast.css';

const Toast = ({ message, type }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [shouldShow, setShouldShow] = useState(false); // new state to handle the show class
  
    useEffect(() => {
      if (message) {
        setIsVisible(true);
        // We set a timeout to add the show class after the component is mounted
        // This will trigger the CSS transition for appearing
        const showTimeout = setTimeout(() => {
          setShouldShow(true);
        }, 100); // this timeout should be less than the CSS transition duration
  
        // Set a timeout to remove the toast after 3 seconds
        const hideTimeout = setTimeout(() => {
          // First, we remove the show class to trigger the disappearing transition
          setShouldShow(false);
          // Then, after the transition duration, we set isVisible to false
          setTimeout(() => {
            setIsVisible(false);
          }, 300); // this should match the CSS transition duration
        }, 4500);
  
        // Cleanup timeouts when the component is unmounted or before the next effect runs
        return () => {
          clearTimeout(showTimeout);
          clearTimeout(hideTimeout);
        };
      }
    }, [message]);
  
    // Don't render the component until isVisible is true
    if (!isVisible) return null;
  
    return (
      <div className={`toast ${type} ${shouldShow ? 'show' : ''}`}>
        {message}
      </div>
    );
  };

// const Toast = ({ message, type }) => {
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     if (message) {
//       setIsVisible(true);
//       setTimeout(() => {
//         setIsVisible(false);
//       }, 3000); // The toast will disappear after 3 seconds
//     }
//   }, [message]);

//   if (!isVisible) return null;
//   console.log('show toaster')
//   return (
//     <div className={`toast ${type} show`}>
//       {message}
//     </div>
//   );
// };

export default Toast;