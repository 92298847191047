import { parseValueForTooltip } from "./utils";

export const data = {
    labels: ['<1d', '1d-1w', '1w-1m', '1m-3m', '3m-6m', '6m-1y', '1y-2y', '2y-3y', '3y-5y'],
    datasets: [
      {
        label: '# of Votes',
        data: [5, 21, 8, 3, 9, 2, 6, 8, 0],
        backgroundColor: [
          'rgba(2, 4, 90, 0.9)',
          'rgba(24, 61, 133, 0.9)',
          'rgba(51, 117, 177, 0.9)',
          'rgba(66, 148, 194, 0.9)',
          'rgba(80, 178, 212, 0.9)',
          'rgba(110, 199, 224, 0.9)',
          'rgba(162, 222, 237, 0.9)',
          'rgba(185, 230, 242, 0.9)',
          'rgba(209, 239, 247, 0.9)',
        ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        // ],
        borderWidth: 0,
      },
    ],
}
export const options =  {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'nearest',
      // axis: 'x',
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          color: 'rgb(255, 255, 255)',
        }
      },
      custom_canvas_background_image: false,   // disable plugin 'p1' for this instance
      tooltip: {
        callbacks: {
          label: function titleLabel(context) {
            let label = context.label || '';
            if (label) {
              label += ': $' +  parseValueForTooltip(context.parsed) ;
            }
            return label
          }
        },
    },
    },
    cutout: 33,
    layout: {
      padding: {
        bottom: 20,
        top: 10,
      }
  }
}