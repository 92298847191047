// import { ButtonLogin } from "./Navigation";
import { useState } from "react"

import posthog from 'posthog-js' // new

export function Button(props) {
    const [isMouseEnter, setMouseEnter] = useState(false)

    // const navigate = useNavigate()

    // const styleLogin = {
    //     // background: isMouseEnter? '#FF54A9': '#EE288A'
    //     background: 'transparent',
    //     color:  isMouseEnter? '#EE288A' : 'white',
    //     // borderColor: '#EE288A',
    //     // borderWidth: '1px',   
    // }

    const blackStyleLogin = {
        background: 'transparent',
        // color:  isMouseEnter? '#EE288A' : 'white',
        color: 'white',
        background: isMouseEnter? '#1D1A2D': 'black',
        border: '2px solid #FFFAFC',
        borderRadius: '8px',
        marginLeft: 'auto',
        marginRight: 0,
        borderColor: 'black',
        // padding: '0em 1em',
    }

    // if (props.withoutMargin) {
    //     styleLogin['marginRight'] = '10px'
    //     blackStyleLogin['marginRight'] = '10px'
    // }

    return (
        <button 
            data-attr={''}
            className={"login"}
            style={blackStyleLogin}
            onMouseEnter={ () => setMouseEnter(true) } 
            onMouseLeave={ () => setMouseEnter(false) }
            onClick={props.acceptFunction}
            // onClick={() => {props.link? window.open(props.link, '_blank') : props.path? navigate(props.path) : navigate("/login", {state:{type:'sign_in'}})}}
        >
            <p style={{fontSize: '16px', padding: '0em 2em', whiteSpace: 'nowrap',}}> {props.name? props.name:'LOG IN'}</p>
        </button>
    )
}

function CookieBanner() {
    const [showBanner, setShowBanner] = useState(true);

    const acceptCookies = () => { 
        posthog.opt_in_capturing(); // new
        setShowBanner(false);
    };

    return (
        <div>
        {showBanner && <div style={{zIndex: '1000', position: 'absolute', bottom: '20px', width: '85%', background: 'white', left:0,
        right:0,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '15px',
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        borderRadius: '8px',
        paddingRight: '30px',
        paddingLeft: '30px'}}>
            <p style={{fontFamily: 'SFProLight'}}>
                We use third-party cookies on our websites to enhance your experience, analyze our traffic, and for security and marketing.<br></br> For more info, see our <a href="https://cherry-pick.xyz/cookie-policy" style={{color:'rgb(219, 62, 136)'}}>Cookie Policy</a>.
            </p>
            <Button name='ACCEPT' withoutMargin={true} blackTheme={true} acceptFunction={acceptCookies}/>
            {/* <button type="button">Accept cookies</button>
            <button type="button">Decline cookies</button> */}
        </div>}
      </div>
    );
  }
  
  export default CookieBanner;