import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
//import watermarkPlugin from 'chartjs-plugin-watermark';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {parseBigValues, titleLabel, titleTooltip, scaleX, scalePrice, parseValueForTooltip} from './utils.js'

import "./NetWorth.css";

// import imagesrc from  "../../assets/watermark.png"
// const image = new Image();

// image.src = imagesrc  

export const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
    // plugin,
    //watermarkPlugin,
  );

// export const data = (canvas) => {
//     const ctx = canvas.getContext('2d');
//     const gradient = ctx.createLinearGradient(0, 0, 0, 400);
//     gradient.addColorStop(0, 'rgba(40, 226, 238, 0.5)');  // Start color: vibrant with some transparency
//     gradient.addColorStop(1, 'rgba(40, 226, 238, 0)');  // End color: fully transparent

//     return {
//         datasets: [
//             {
//                 label: 'Net Worth',
//                 data: [], // You should populate this with your data
//                 fill: 'start',
//                 borderColor: 'rgb(40, 226, 238)', // Vibrant line color
//                 tension: 0.4,
//                 pointRadius: 0,
//                 borderWidth: 2,
//                 backgroundColor: gradient, // Gradient applied here
//                 yAxisID: 'y',
//             },
//         ],
//     };
// };

export const calculatePercentChange = (changeValue, firstValue) => {
    if (firstValue === 0) {
      if (changeValue > 0) {
        return Infinity; // Infinite increase
      } else if (changeValue < 0) {
        return -Infinity; // Infinite decrease
      } else {
        return 0; // No change
      }
    } else {
      return ((changeValue / firstValue) * 100).toFixed(2);
    }
};

export const data = {
    // labels: [],
    datasets: [
        {
            label: 'Net Worth',
            data: [],
            fill: true,
            borderColor: 'rgba(238, 40, 138, 1.0)',
            tension: 0.1,
            pointRadius: 0.0,
            borderWidth: 1,
            // backgroundColor: 'rgb(255, 255, 255)',
            backgroundColor: 'rgba(238, 40, 138, 0.0)',
            yAxisID: 'y',
        },
    ],
};

// export const data = (canvas) => {
//     const ctx = canvas.getContext('2d');
//     const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
//     gradient.addColorStop(0, 'rgba(238, 40, 138, 0.5)');
//     gradient.addColorStop(1, 'rgba(238, 40, 138, 0)');
  
//     return {
//       datasets: [
//         {
//           label: 'Net Worth',
//           data: [100, 200, 300, 400, 500, 600, 700], // Example data
//           fill: true,
//           borderColor: 'rgb(238, 40, 138)',
//           backgroundColor: gradient,
//           tension: 0.4,
//           pointRadius: 0,
//           borderWidth: 2,
//         },
//       ],
//     };
//   };


export const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      intersect: false,
      axis: 'x',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: function(context) {
          const { chart, tooltip } = context;
      
          // Select the container of the chart to append tooltips
          let container = chart.canvas.parentNode;
      
          // Tooltip for y-axis value (Net Worth)
          let tooltipElY = document.getElementById('chartjs-tooltip-y');
      
          // Create element on first render
          if (!tooltipElY) {
              tooltipElY = document.createElement('div');
              tooltipElY.id = 'chartjs-tooltip-y';
              tooltipElY.style.position = 'absolute';
              tooltipElY.style.color = 'white';
              tooltipElY.style.pointerEvents = 'none';
              container.appendChild(tooltipElY);
          }
      
          // Tooltip for x-axis value (Date)
          let tooltipElX = document.getElementById('chartjs-tooltip-x');
      
          // Create element on first render
          if (!tooltipElX) {
              tooltipElX = document.createElement('div');
              tooltipElX.id = 'chartjs-tooltip-x';
              tooltipElX.style.position = 'absolute';
              tooltipElX.style.color = 'white';
              tooltipElX.style.pointerEvents = 'none';
              container.appendChild(tooltipElX);
          }
      
          // Hide if no tooltip
          if (tooltip.opacity === 0) {
              tooltipElY.style.opacity = 0;
              tooltipElX.style.opacity = 0;
              return;
          }
      
          // Set tooltip content
          if (tooltip.body) {
              const bodyLines = tooltip.body.map(b => b.lines);
              let currentValue = bodyLines.length ? bodyLines[0][0] : '';
              if (typeof currentValue === 'object') {
                  currentValue = currentValue?.y
              }
              const firstValue = chart.data.datasets[0].data[0].y;
              const changeValue = (currentValue - firstValue);
              const percentChange = calculatePercentChange(changeValue, firstValue);
              const percentClass = percentChange >= 0 ? 'positive' : 'negative';
              const prefix = percentChange >= 0 ? '+':'';
              const valueText = `
                  <div class="networth-tooltip-text">$${parseValueForTooltip(currentValue)}</div>
                  <div class="networth-tooltip-percent ${percentClass}">${prefix}${percentChange}% ($${parseValueForTooltip(Math.abs(changeValue))})</div>
              `;
              const dateValue = tooltip.title.length ? formatDate(tooltip.title[0]) : '';
              const dateText = `
                  <div class="networth-tooltip-date">${dateValue}</div>
              `;
      
              tooltipElY.innerHTML = valueText;
              tooltipElX.innerHTML = dateText;
          }
      
          // Position tooltips
          const chartArea = chart.chartArea;
          const middleY = (chartArea.bottom + chartArea.top) / 2;
          const tooltipHeightY = tooltipElY.offsetHeight;
          const tooltipWidthX = tooltipElX.offsetWidth;

          let topY;
          if (tooltip.caretY < middleY) {
              // Caret is in the upper half, place tooltip below
              topY = tooltip.caretY + 5;
          } else {
              // Caret is in the lower half, place tooltip above
              topY = tooltip.caretY - tooltipHeightY - 5;
          }

          tooltipElY.style.left = `${chartArea.left}px`;
          tooltipElY.style.top = `${topY}px`;
      
          // let topY = tooltip.caretY - tooltipHeightY - 5;
          // topY = Math.max(chartArea.top, Math.min(chartArea.bottom - tooltipHeightY, topY));
          // tooltipElY.style.left = `${chartArea.left}px`;
          // tooltipElY.style.top = `${topY}px`;
      
          let leftX = tooltip.caretX - tooltipWidthX / 2;
          leftX = Math.max(chartArea.left, Math.min(chartArea.right - tooltipWidthX, leftX));
          tooltipElX.style.left = `${leftX}px`;
          tooltipElX.style.top = `${chartArea.bottom + 10}px`;
      
          tooltipElY.style.opacity = 1;
          tooltipElX.style.opacity = 1;
      }, 
        // external: function(context) {
        //         const { chart, tooltip } = context;

        //         // Tooltip for y-axis value (Net Worth)
        //         let tooltipElY = document.getElementById('chartjs-tooltip-y');

        //         // Create element on first render
        //         if (!tooltipElY) {
        //             tooltipElY = document.createElement('div');
        //             tooltipElY.id = 'chartjs-tooltip-y';
        //             tooltipElY.style.position = 'absolute';
        //             // tooltipElY.style.background = 'rgba(0, 0, 0, 0.7)';
        //             tooltipElY.style.color = 'white';
        //             tooltipElY.style.pointerEvents = 'none';
        //             // tooltipElY.style.borderRadius = '3px';
        //             // tooltipElY.style.pointerEvents = 'none';
        //             // tooltipElY.style.padding = '5px';
        //             document.body.appendChild(tooltipElY);
        //         }

        //         // Tooltip for x-axis value (Date)
        //         let tooltipElX = document.getElementById('chartjs-tooltip-x');

        //         // Create element on first render
        //         if (!tooltipElX) {
        //             tooltipElX = document.createElement('div');
        //             tooltipElX.id = 'chartjs-tooltip-x';
        //             tooltipElX.style.position = 'absolute';
        //             // tooltipElX.style.background = 'rgba(0, 0, 0, 0.7)';
        //             tooltipElX.style.color = 'white';
        //             tooltipElY.style.pointerEvents = 'none';
        //             // tooltipElX.style.borderRadius = '3px';
        //             // tooltipElX.style.pointerEvents = 'none';
        //             // tooltipElX.style.padding = '5px';
        //             document.body.appendChild(tooltipElX);
        //         }

        //         // Hide if no tooltip
        //         if (tooltip.opacity === 0) {
        //             tooltipElY.style.opacity = 0;
        //             tooltipElX.style.opacity = 0;
        //             return;
        //         }

        //         // Retrieve the first dataset value
        //         const firstValue = chart.data.datasets[0].data[0].y;
        //         // console.log('first point', firstValue)

        //         // Set Text
        //         if (tooltip.body) {
        //             const bodyLines = tooltip.body.map(b => b.lines);
        //             // console.log("body", bodyLines[0][0])
        //             let currentValue = bodyLines.length ? bodyLines[0][0] : '';
        //             if (typeof currentValue === 'object') {
        //                 currentValue = currentValue?.y
        //             }
        //             // console.log('body current value', currentValue?.y, firstValue)
        //             const changeValue = (currentValue - firstValue)
        //             // const percentChange = (changeValue / firstValue * 100).toFixed(2);
        //             // console.log('calculate percent change', changeValue, firstValue, currentValue)
        //             const percentChange = calculatePercentChange(changeValue, firstValue)
        //             const percentClass = percentChange >= 0 ? 'positive' : 'negative';
        //             // console.log('percent - ', currentValue, firstValue)
        //             const prefix = percentChange >= 0 ? '+':''
        //             const valueText = `
        //             <div class="networth-tooltip-text">$${parseValueForTooltip(currentValue)}</div>
        //             <div class="networth-tooltip-percent ${percentClass}">${prefix}${percentChange}% ($${parseValueForTooltip(Math.abs(changeValue))})</div>
        //             `;
        //             const dateValue = tooltip.title.length ? formatDate(tooltip.title[0]) : '';

        //             const dateText = `
        //             <div class="networth-tooltip-date">${dateValue}</div>
        //             `

        //             tooltipElY.innerHTML = valueText;
        //             tooltipElX.innerHTML = dateText;
        //         }

        //         const position = chart.canvas.getBoundingClientRect();
        //         const caretY = tooltip.caretY;
        //         const caretX = tooltip.caretX;

        //         tooltipElY.style.opacity = 1;
        //         tooltipElX.style.opacity = 1;

        //         // Position the y-axis tooltip above the y-axis line, on the left side of the canvas
        //         const tooltipHeightY = tooltipElY.offsetHeight;
        //         const leftY = chart.scales.x.left + 0; 
        //         const topY = caretY - tooltipHeightY - 5; 

        //         tooltipElY.style.left = position.left + window.pageXOffset + leftY + 'px';
        //         tooltipElY.style.top = position.top + window.pageYOffset + topY + 'px';

        //         const tooltipWidthX = tooltipElX.offsetWidth;
        //         const tooltipHeightX = tooltipElX.offsetHeight;
        //         const leftX = Math.max(chart.scales.x.left, Math.min(chart.scales.x.right - tooltipWidthX, caretX - tooltipWidthX / 2));
        //         const topX = chart.scales.y.bottom + 10; 

        //         tooltipElX.style.left = position.left + window.pageXOffset + leftX + 'px';
        //         tooltipElX.style.top = position.top + window.pageYOffset + topX + 'px';
        //   },
          callbacks: {
            label: function (context) {
              // Return only the value, without the label
              return context.raw.y || context.raw;
            },
          },
          intersect: false,
        // mode: 'index',
        // intersect: false,
        // position: 'nearest',
        // callbacks: {
        //   label: function (context) {
        //     return `Net Worth: $${context.parsed.y.toFixed(2)}`;
        //   },
        //   title: function (context) {
        //     return `Date: ${context[0].label}`;
        //   },
        // },
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: false,
        grid: {
            display: false, // Ensure grid lines are displayed
          },
        // type: 'logarithmic',
        // ticks: {
        //   callback: function (val) {
        //     return `$${val}`;
        //   },
        //   color: '#9AA2AA',
        // },
      },
    },
};