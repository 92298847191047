import { React, useState} from "react";

export default function DescriptionBlock(props) {
    let descriptions
    if(!props.header) {
        descriptions = props.texts.map((text, index) => {
            const len = props.texts.length - 1
            return (
                <p className="descriptionBlock-text" style={{marginTop: (index===0) ? "22px": "",
                                                            marginBottom: (index===len) ? (props.marginB ? props.marginB:"16px"): ""}}> {text} </p>
            )
        })
    } else {
        descriptions = ( 
            <div className="descriptionBlock-header"> 
                <p style={{marginBottom: '10px'}}> { props.texts } </p>
            </div>
        )
    }
    return (
        <div className="descriptionBlock">
            { descriptions }
        </div>
    )
    // console.log("Descriptions", descriptions)
    // console.log("desc prop", descriptions[0].styles)
    // return (
    //     <div className="descriptionBlock">
    //         { descriptions }
    //     </div>
    // )
    // for(i=0; i<props.texts.length; i+=1) {
    //     if
    // }
    // return (
    //     <div className="descriptionBlock">

    //     </div>
    // )
    // return (
    //     <div className="description">
    //         <div className="descriptionHeader">
    //             <div className="descriptionHeader-text">
    //                 Description
    //             </div>
    //             </div>
    //             <div className="descriptionBlock">
    //                 <p className="descriptionBlock-text" style={{marginTop:"22px", marginBottom:"16px"}}>
    //                     MPL is the total value of profits and losses in dollar terms for all coins traded at a given time. A coin is said to be trading at a profit if its purchase price is lower than the current market price. A coin is traded at a loss if its buy price is higher than the current market price.
    //                 </p>
    //             </div>
    //             <div className="descriptionBlock">
    //             <div className="descriptionBlock-header">
    //                 <p style={{marginBottom: '10px'}}>How do I use MPL in trading ?</p>
    //             </div>
    //             </div>
    //             <div className="descriptionBlock">
    //                 <p className="descriptionBlock-text" style={{marginTop:"22px"}}> 
    //                     Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.
    //                 </p>
    //                 <p className="descriptionBlock-text"> 
    //                     Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.
    //                 </p>
    //                 <p className="descriptionBlock-text" style={{marginBottom:"16px"}}> 
    //                     Identifying local/global price peaks. Profits peaks indicate that a large number of investors are selling their assets to the upside. This can mean that the price of the asset is now overvalued and leads to a change in market sentiment. Thus, Profits peaks often correlate with local/global price peaks. The 14/30 day moving average is the best fit in this approach, as it is less sensitive to random noise.
    //                 </p>
    //             </div>
    //         </div>
    // )
}