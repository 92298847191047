import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale,
    LineController,
    BarController,
    Filler,
    TimeScale,
  } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import "chartjs-adapter-date-fns";
import {titleLabel, titleTooltip, scaleX, scalePrice, parseIntValueForTooltip} from './utils.js'
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    LineController,
    BarController,
    Filler,
    TimeScale,
  );

const ColorMap2 = ['rgba(236,27,122,255)', 
  'rgba(238,96,144,255)', 
  'rgba(243,137,166,255)', 
  // 'rgba(233,174,194,255)',
  'rgba(253,211,222,255)', 
  'rgba(223,240,216,255)', 
  'rgb(195,227,181)', 
  'rgb(163,213,148)', 
  'rgba(144,200,121,255)', 
  'rgba(112,192,86,255)']

const color2 = 'rgb(85,61,91)'  // best
const color3 = 'rgb(59,41,64)' 

export const data = {
    // labels: [],
    datasets: [
        {
          label: 'Price',
          type: 'line',
          data: [],
          fill: false,
          borderColor: 'rgb(255, 255, 255)',
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: 'rgb(255, 255, 255)',
          yAxisID: 'y1',
          order: 1,
        },
        {
          label: '$0-100',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap2[0],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap2[0],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '$100-1k',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap2[1],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap2[1],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '$1k-10k',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap2[2],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap2[2],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '$10k-100k',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap2[3],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap2[3],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '$100k-1M',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap2[4],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap2[4],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '$1M-10M',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap2[5],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap2[5],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: '$10M+',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: ColorMap2[6],
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: ColorMap2[6],
          yAxisID: 'y',
          order: 2,
        },
        {
          label: 'Exchanges',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: color2,
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: color2,
          yAxisID: 'y',
          order: 2,
        },
        {
          label: 'Smart Contracts',
          type: 'line',
          fill: { target: {value: 0}},
          data: [],
          borderColor: color3,
          tension: 0,
          pointRadius: 0.0,
          borderWidth: 1,
          backgroundColor: color3,
          yAxisID: 'y',
          order: 2,
        },
    ],
};


export const optionsLimited100 = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
                overScaleMode: 'y',
            },
            pan: {
                enabled: true,
                overScaleMode: 'y',
            },
            limits: {
              x: {minRange: 5},
              y: {min: 0, max: 100},
              y1: {enabled: false}
            }
        },
        legend: {
            display: true,
            position: 'top',
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
              label: titleLabel,
              title: titleTooltip,
            },
        },
    },
    scales: {
        x: scaleX,
        y: {
          position: 'left',
          grid: {
              display: false,
          },
          ticks: {
            beginAtZero: true,
          },
          type: 'linear',
          stacked: true,
          max: 100,
          color: '#9AA2AA',
          font: {
            size: 12,
            family: 'SFProLight',
          },
      },
      y1: scalePrice,
    },
  };

  export const options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    },
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
                overScaleMode: 'y',
            },
            pan: {
                enabled: true,
                overScaleMode: 'y',
            },
            limits: {
              x: {minRange: 5},
              // y: {min: 0, max: 100},
              y1: {enabled: false}
            }
        },
        legend: {
            display: true,
            position: 'top',
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ' +  parseIntValueForTooltip(context.parsed.y) ;
                }
                return label;
              },
              title: titleTooltip,
            },
        },
    },
    scales: {
        x: scaleX,
        y: {
          position: 'left',
          grid: {
              display: false,
          },
          ticks: {
            beginAtZero: true,
          },
          type: 'linear',
          stacked: true,
          // max: 30,
          color: '#9AA2AA',
          font: {
            size: 12,
            family: 'SFProLight',
          },
      },
      y1: scalePrice,
    },
  };